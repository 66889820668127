import React from 'react';
import styled from 'styled-components';
import BasePage from 'components/layout/BasePage';
import Icon from 'components/common/Icon';

import verifyAclUser from 'helpers/verifyAclUser';
import acl from 'helpers/acl';

import {
  FaRegHandshake
} from 'react-icons/fa';

import {
  ImBubbles4,
} from 'react-icons/im';

import { MdCardTravel, MdOutlineMonetizationOn, MdStore } from 'react-icons/md';

const Reports = () => {
  const title = 'Relátorios';

  return (
    <>
      <BasePage title={title}>
        <Body>
          <IconsSide>
            {
              (verifyAclUser(acl.report_visits)) && (
                <Icon icon={<FaRegHandshake />} name="Visitas" route="relatorios/visitas" />
              )
            }
            {
              (verifyAclUser(acl.report_visit_consultant)) && (
                <Icon icon={<MdCardTravel />} name="Visitas por Consultor" route="relatorios/visitasconsultor" />
              )
            }
            {
              (verifyAclUser(acl.report_contacts)) && (
                <Icon icon={<ImBubbles4 />} name="Contatos" route="relatorios/contatos" />
              )
            }
            {
              (verifyAclUser(acl.report_store_contacts)) && (
                <Icon icon={<MdOutlineMonetizationOn />} name="Contatos Financeiro" route="relatorios/contatofinanceiro" />
              )
            }
            {
              (verifyAclUser(acl.report_stores)) && (
                <Icon icon={<MdStore />} name="Drogarias" route="relatorios/drogarias" />
              )
            }
            {
              (verifyAclUser(acl.report_stores_admin)) && (
                <Icon icon={<MdStore />} name="Drogarias (admin)" route="relatorios/drogariasadmin" />
              )
            }
          </IconsSide>
        </Body>
      </BasePage>
    </>
  );
};

const Body = styled.div`
  margin-top: 15px;
  display: grid;
  grid-template-columns: 540px 1fr;
  gap: 10px; 
`;

const IconsSide = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(7, 1fr);
  gap: 10px;

  @media(max-width: 800px) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(5, 1fr);
  }
`;

export default Reports;
