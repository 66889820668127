import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import InputField from 'components/common/InputField';
import CheckField from 'components/common/CheckField';
import ButtonCase from 'components/common/ButtonCase';
import Button from 'components/common/Button';

const Form = ({
  values,
  handleSubmit,
  validations
}) => {
  const formik = useFormik({
    initialValues: values,
    onSubmit: handleSubmit,
    validationSchema: Yup.object(validations)
  });

  return (
    <Body onSubmit={formik.handleSubmit}>
      <InputField
        id="contactName"
        name="contactName"
        label="Nome:"
        placeholder="Nome Sobrenome"
        isRequired
        type="text"
        error={formik.errors.contactName}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        value={formik.values.contactName}
        isError={formik.errors.contactName && formik.touched.contactName}
      />
      <InputField
        id="contactEmail"
        name="contactEmail"
        label="Email"
        placeholder="exemplo@exemplo.com"
        isRequired
        type="text"
        error={formik.errors.contactEmail}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        value={formik.values.contactEmail}
        isError={formik.errors.contactEmail && formik.touched.contactEmail}
      />
      <InputField
        id="contactPhoneNumber"
        name="contactPhoneNumber"
        label="Telefone:"
        placeholder="(00) 0 0000-0000"
        isRequired
        type="text"
        error={formik.errors.contactPhoneNumber}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        value={formik.values.contactPhoneNumber}
        isError={formik.errors.contactPhoneNumber && formik.touched.contactPhoneNumber}
        mask="(99) 9 9999-9999"
      />
      <CheckField
        id="office"
        name="office"
        label="Contato Principal"
        type="checkbox"
        error={formik.errors.office}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        value="mainContact"
      />
      <CheckField
        id="office"
        name="office"
        label="Contato Financeiro"
        type="checkbox"
        error={formik.errors.office}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        value="financialContact"
      />
      <CheckField
        id="office"
        name="office"
        label="Comprador Geral"
        type="checkbox"
        error={formik.errors.office}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        value="general"
      />
      <CheckField
        id="office"
        name="office"
        label="Comprador de Ético"
        type="checkbox"
        error={formik.errors.office}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        value="ethical"
      />
      <CheckField
        id="office"
        name="office"
        label="Comprador de Genérico"
        type="checkbox"
        error={formik.errors.office}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        value="generic"
      />
      <CheckField
        id="office"
        name="office"
        label="Comprador de Similar"
        type="checkbox"
        error={formik.errors.office}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        value="similar"
      />
      <CheckField
        id="office"
        name="office"
        label="Comprador de Perfumaria"
        type="checkbox"
        error={formik.errors.office}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        value="perfumery"
      />
      <ButtonCase>
        <Button type="submit">Adicionar</Button>
      </ButtonCase>
    </Body>
  );
};

const Body = styled.form`
`;

Form.propTypes = {
  values: PropTypes.objectOf(PropTypes.any).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  validations: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Form;
