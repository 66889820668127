import React from 'react';
import moment from 'moment';
import Table from 'components/tableComponents/CompleteTable';
import { useStores } from 'providers/Stores/stores';

import NoData from 'components/common/NoData';
import { cnpjMask } from 'helpers/Mask';

import { TableCaseIcon } from 'components/tableComponents';

import InitialEdit from './InitialEdit';
import ViewData from './ViewData';
import ChangeStatus from './ChangeStatus';

const CallsTable = () => {
  const { stores, loading } = useStores();

  const columns = React.useMemo(
    () => [
      {
        Header: 'COD',
        accessor: 'legacyCode',
        defaultFilter: true,
      },
      {
        Header: 'RAZÃO SOCIAL',
        accessor: 'storeName',
        defaultFilter: true,
      },
      {
        Header: 'NOME FANTASIA',
        accessor: 'tradeName',
        defaultFilter: true,
      },
      {
        Header: 'CNPJ',
        accessor: 'registrationNumber',
        defaultFilter: true,
        Cell: (i) => {
          const { cell } = i;
          const data = cell.row.original;
          return cnpjMask(data.registrationNumber);
        }
      },
      {
        Header: 'CIDADE',
        accessor: 'addressData.city',
        defaultFilter: true,
        Cell: (i) => {
          const { cell } = i;
          const data = cell.row.original;
          return (
            <span>
              {data.addressData.city} - {data.addressData.state}
            </span>
          );
        }
      },
      {
        Header: 'ANJO',
        accessor: 'angelData.name',
        defaultFilter: true,
        Cell: (i) => {
          const { cell } = i;
          const data = cell.row.original;
          return (
            <span>
              {data.angelData.name} {data.angelData.surname}
            </span>
          );
        }
      },
      {
        Header: 'CONSUL',
        accessor: 'consultantData.name',
        defaultFilter: true,
        Cell: (i) => {
          const { cell } = i;
          const data = cell.row.original;
          return (
            <span>
              {data.consultantData.name} {data.consultantData.surname}
            </span>
          );
        }
      },
      {
        Header: 'ATEND. COMERCIAL',
        accessor: 'commercialAttendantData.name',
        defaultFilter: true,
        Cell: (i) => {
          const { cell } = i;
          const data = cell.row.original;
          return (
            <span>
              {data.commercialAttendantData.name} {data.commercialAttendantData.surname}
            </span>
          );
        }
      },
      {
        Header: 'DAT.CAD',
        accessor: 'createdAt',
        Cell: (i) => {
          const { cell } = i;
          const data = cell.row.original;
          return moment(data.createdAt).format('DD/MM/YYYY');
        }
      },
      {
        Header: '',
        accessor: 'id',
        canFilter: null,
        Cell: (i) => {
          const { cell } = i;
          const data = cell.row.original;
          return (
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
              <TableCaseIcon>
                <InitialEdit data={data} exibition="table" />
                <ViewData data={data} exibition="table" />
                <ChangeStatus data={data} exibition="table" />
              </TableCaseIcon>
            </div>
          );
        }
      }
    ],
    []
  );

  return (
    <>
      {((stores.length > 0) && (loading === false) && (
        <Table data={stores} columns={columns} changeInactiveStyle="true" marginTop="-30px" />
      ))}
      {(stores.length === 0) && (loading === false) && (
        <NoData />
      )}
    </>
  );
};

export default CallsTable;
