import React from 'react';

import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import styled from 'styled-components';

import InputField from 'components/common/InputField';
import Case from 'components/common/Case';
import Button from 'components/common/Button';
import CancelButton from 'components/common/CancelButton';
import ButtonCase from 'components/common/ButtonCase';

import fetchAddress from 'service/ViaCepService';

const CompanyForm = ({
  validations, handleSubmit, handleCancel, values, disabled
}) => {
  const formik = useFormik({
    initialValues: values,
    onSubmit: handleSubmit,
    validationSchema: Yup.object(validations),
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Case gridTemplateColumns="2fr 2fr 1fr">
        <InputField
          id="storeName"
          name="storeName"
          label="Razão Social:"
          placeholder="Razão Social"
          isRequired
          type="text"
          error={formik.errors.storeName}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.storeName}
          isError={formik.errors.storeName && formik.touched.storeName}
        />
        <InputField
          id="tradeName"
          name="tradeName"
          label="Nome Fantasia:"
          placeholder="Nome Fantasia"
          isRequired
          type="text"
          error={formik.errors.tradeName}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.tradeName}
          isError={formik.errors.tradeName && formik.touched.tradeName}
        />
        <InputField
          id="registrationNumber"
          name="registrationNumber"
          label="CNPJ:"
          placeholder="XX.XXX.XXX/XXXX-XX"
          isRequired
          type="text"
          error={formik.errors.registrationNumber}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.registrationNumber}
          isError={formik.errors.registrationNumber && formik.touched.registrationNumber}
          mask="99.999.999/9999-99"
        />
      </Case>
      <Case gridTemplateColumns="1fr 2fr 2fr">
        <InputField
          id="zipcode"
          name="zipcode"
          label="CEP:"
          placeholder="00000-000"
          isRequired
          type="text"
          error={formik.errors.zipcode}
          onBlur={formik.handleBlur}
          onChange={(e) => {
            formik.handleChange(e);
            fetchAddress(e, formik.setFieldValue);
          }}
          value={formik.values.zipcode}
          isError={formik.errors.zipcode && formik.touched.zipcode}
          disabled={disabled}
        />
        <InputField
          id="city"
          name="city"
          label="Cidade:"
          placeholder="Cidade"
          isRequired
          type="text"
          error={formik.errors.city}
          onBlur={formik.handleBlur}
          value={formik.values.city}
          isError={formik.errors.city && formik.touched.city}
          disabled={disabled}
        />
        <InputField
          id="address"
          name="address"
          label="Logradouro:"
          placeholder="Rua, Avenida ..."
          isRequired
          type="text"
          error={formik.errors.address}
          onBlur={formik.handleBlur}
          onChange={(e) => {
            formik.handleChange(e);
          }}
          value={formik.values.address}
          isError={formik.errors.address && formik.touched.address}
          disabled={disabled}
        />
      </Case>
      <Case gridTemplateColumns="1fr 1fr 2fr 1fr">
        <InputField
          id="number"
          name="number"
          label="Número:"
          placeholder="000"
          isRequired
          type="text"
          error={formik.errors.number}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.number}
          isError={formik.errors.number && formik.touched.number}
          disabled={disabled}
        />
        <InputField
          id="complement"
          name="complement"
          label="Complemento:"
          placeholder="Complemento"
          type="text"
          error={formik.errors.complement}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.complement}
          isError={formik.errors.complement && formik.touched.complement}
          disabled={disabled}
        />
        <InputField
          id="neighborhood"
          name="neighborhood"
          label="Bairro:"
          placeholder="Bairro"
          isRequired
          type="text"
          error={formik.errors.neighborhood}
          onBlur={formik.handleBlur}
          onChange={(e) => {
            formik.handleChange(e);
          }}
          value={formik.values.neighborhood}
          isError={formik.errors.neighborhood && formik.touched.neighborhood}
          disabled={disabled}
        />
        <InputField
          id="state"
          name="state"
          label="UF:"
          placeholder="Estado"
          isRequired
          type="text"
          error={formik.errors.state}
          onBlur={formik.onBlur}
          onChange={(e) => {
            formik.handleChange(e);
          }}
          value={formik.values.state}
          isError={formik.errors.state && formik.touched.state}
          disabled={disabled}
        />
      </Case>
      <ButtonCase>
        <CancelButton onClose={handleCancel}>Cancelar</CancelButton>
        <Button type="submit">Salvar</Button>
      </ButtonCase>
    </Form>
  );
};

const Form = styled.form`
  width: 800px;
  padding: 5px;
`;

CompanyForm.propTypes = {
  validations: PropTypes.objectOf(PropTypes.any).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  values: PropTypes.objectOf(PropTypes.any).isRequired,
  disabled: PropTypes.string,
};

CompanyForm.defaultProps = {
  disabled: '',
};

export default CompanyForm;
