import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

function CardIcon({
  children, marginleft
}) {
  return (
    <Icon marginleft={marginleft}>
      {children}
    </Icon>
  );
}

const Icon = styled.span`
  margin-left: ${(props) => props.marginleft};
  align-items: center;
  text-align: center;
  cursor: pointer; 
`;

CardIcon.propTypes = {
  children: PropTypes.element.isRequired,
  marginleft: PropTypes.string,
};

CardIcon.defaultProps = {
  marginleft: '15px',
};

export default CardIcon;
