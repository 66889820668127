import axios from 'axios';
import { API } from 'helpers/constant';

const getUsers = () => axios.get(`${API}/users`)
  .then((res) => res.data)
  .catch((error) => error[0]);

const getUsersByUserType = (usetypeId) => axios.get(`${API}/users/usertypeId/${usetypeId}`)
  .then((res) => res.data)
  .catch((error) => error[0]);

const createUsers = (data) => axios.post(`${API}/users`, data)
  .then((res) => res.data)
  .catch((error) => error.response.data);

const updateUser = (id, data) => axios.put(`${API}/users/${id}`, data, {})
  .then((res) => res.data)
  .catch((error) => error[0]);

const resetUserPassword = (registrationNumber) => axios.put(`${API}/users/resetPassword/${registrationNumber}`)
  .then((res) => res.data)
  .catch((error) => error[0]);

export {
  getUsers,
  createUsers,
  updateUser,
  getUsersByUserType,
  resetUserPassword
};
