import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useHistory, Link } from 'react-router-dom';
import Swal from 'sweetalert2';

import colors from 'assets/style/colors';
import LogoImage from 'assets/images/logo.svg';
import getLoggedUser from 'helpers/getLoggedUser';
import { toastError } from 'helpers/notifications';

import { logoutService } from 'service/LoginService';
import { countCalls } from 'service/CallsService';

import { FaRegUserCircle } from 'react-icons/fa';
import { VscBell, VscBellDot } from 'react-icons/vsc';

import { isAuthorized } from 'helpers/isAuthorized';

const TopBar = ({ stateMenu }) => {
  const history = useHistory();
  const [user, setUser] = useState({});
  const [userMenuState, setUserMenuState] = useState(false);
  const [counter, setCounter] = useState(0);
  const [messageState, setMessageState] = useState(false);

  const getStarter = async () => {
    const callsData = localStorage.getItem('CallsCounter');
    if (!callsData) {
      const dbCalls = await countCalls();
      localStorage.setItem('CallsCounter', dbCalls);
      return dbCalls;
    } return callsData;
  };

  useEffect(async () => {
    const dbCalls = await countCalls();
    const data = await getStarter();
    const count = dbCalls - data;
    if (count > 0) {
      setCounter(count);
      toastError(`Você possui ${count} chamado(s) aberto(s)`);
    } else {
      localStorage.setItem('CallsCounter', dbCalls);
    }
  }, []);

  useEffect(async () => {
    const userData = await getLoggedUser();
    setUser(userData);
  }, []);

  isAuthorized().then((autorized) => {
    if (!autorized) {
      logoutService();
      history.push('/login');
    }
    return autorized;
  });

  const openMenu = (state) => {
    if (state) {
      return '';
    } return 'none';
  };

  const setCssMenu = () => {
    if (stateMenu) {
      return (
        'width: calc(100% - 200px); left: 200px;'
      );
    } return 'width: 100%;';
  };

  const handleLogout = () => {
    Swal.fire({
      title: 'Você quer realmente sair?',
      text: 'lembre-se de salvar tudo antes de sair',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#224772',
      cancelButtonColor: '#224772',
      confirmButtonText: 'Sim, eu quero sair',
      cancelButtonText: 'Não, eu não quero sair'
    }).then((result) => {
      if (result.isConfirmed) {
        logoutService();
        history.push('/login');
      }
    });
  };

  return (
    <>
      <ContentMobile>
        <Image src={LogoImage} alt="CompanyName" onClick={() => history.push('/home')} />
        <Button onClick={() => setUserMenuState(!userMenuState)}>
          <FaRegUserCircle style={{ color: '#fff', fontSize: '20px' }} />
          <Span> { user.name } </Span> - <span> { user.userType } </span>
        </Button>
        <Menu display={openMenu(userMenuState)}>
          <Item onClick={() => history.push('/sistema/usuarios')}>Editar Usuário</Item>
          <Item onClick={() => handleLogout()}>Sair</Item>
        </Menu>
      </ContentMobile>
      <ContentDesktop cssStateProprieties={setCssMenu()}>
        <DivMessages onClick={() => setMessageState(!messageState)}>
          {(counter !== 0) && (<VscBellDot style={{ color: '#F5796C', fontSize: '20px' }} />)}
          {(counter === 0) && (<VscBell style={{ fontSize: '20px' }} />)}
          {(counter !== 0) && (<span style={{ color: '#F5796C', fontSize: '10px' }}>{counter}</span>)}
          {(counter === 0) && (<span style={{ fontSize: '10px' }}>{counter}</span>)}
        </DivMessages>
        <MenuMessages display={openMenu(messageState)}>
          {(counter !== 0) && (
            <>
              <MessageItem>Você possui {counter} chamado(s) não vizualizado(s)</MessageItem>
              <FooterMessage
                to="/chamados"
                onClick={() => {
                  setCounter(0);
                  localStorage.removeItem('CallsCounter');
                }}
              >Chamados
              </FooterMessage>
            </>
          )}
          {(counter === 0) && (<MessageItem>Você possui não possui novos chamados</MessageItem>)}
        </MenuMessages>
        {/* <Button onClick={() => {
          setCounter(0);
          localStorage.removeItem('CallsCounter');
        }}
        >
          {counter}
        </Button> */}
        <Button onClick={() => setUserMenuState(!userMenuState)}>
          <FaRegUserCircle style={{ color: '#fff', fontSize: '18px' }} />
          <Span> { user.name } </Span> - <span> { user.userType } </span>
        </Button>
        <Menu display={openMenu(userMenuState)}>
          <Item onClick={() => history.push('/sistema/usuarios')}>Editar Usuário</Item>
          <Item onClick={() => handleLogout()}>Sair</Item>
        </Menu>
      </ContentDesktop>
    </>
  );
};

const ContentMobile = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  background: ${colors.primaryLight};
  padding: 10px;
  @media(min-width: 800px) {
    display: none;
  }
`;

const ContentDesktop = styled.div`
  padding: 3.5px;
  background: ${colors.primaryLight};
  height: 40px;
  top: 0;
  position: fixed;
  ${(props) => props.cssStateProprieties}
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 99;

  @media(max-width: 800px) {
    display: none;
  }
`;

const DivMessages = styled.div`
  margin-right: 10px;
  cursor: pointer;
`;

const MenuMessages = styled.div`
  position: absolute;
  top: 41px;
  right: 203px;
  z-index: 897;
  display: ${(props) => props.display};
  background-color: white;
  border: 1px solid rgba(0,0,0,.15);
  border-radius: .25rem;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 18%);
`;

const FooterMessage = styled(Link)`
  text-align: center;
  border-top: 1px solid rgba(0,0,0,.15);
  padding: 2px;
  display: -webkit-flex;
  display: flex;
  display: block;
  text-align: center;
  color: #20314F;
  cursor: pointer;
  text-decoration: none;
`;

const MessageItem = styled.li`
  list-style: none;
  padding: 3px;
  background-color: #fff;
  padding: 10px;
  transition: 0.3s;
  :hover{
    background-color: #F3F3F3;
  }
`;

const Span = styled.span`
  font-weight: 800;
`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  background: ${colors.primaryBlue};
  margin-right: 10px;
  color: #fff;
  height: 30px;
  border-radius: 6px;
  transition: 0.3s;
  opacity: 1;
  float: right;
  cursor: pointer;
  padding: 4px;

  :hover{
    opacity: 0.8;
  };
  
  span {
    text-transform: capitalize;
    margin-left: 5px;
    margin-right: 5px;
  }
  
  @media(max-width: 800px) {
    margin-top: 10px;
    height: 40px;
    padding: 0;
    span {
      text-transform: capitalize;
      font-weight: bolder;
      margin-left: 2px;
    }
  }
`;

const Image = styled.img`
  height: 50px;
  width: 50px;
`;

const Menu = styled.div`
  position: absolute;
  top: 35px;
  right: 13px;
  z-index: 897;
  border: 1px solid #93D367;
  display: ${(props) => props.display};

  @media(max-width: 800px) {
    top: 65px;
    right: 20px;
  }
`;

const Item = styled.li`
  list-style: none;
  padding: 3px;
  background-color: #fff;
  cursor: pointer;
  :hover{
    background-color: #93D367;
    color: #fff;
  }
`;

TopBar.propTypes = {
  stateMenu: PropTypes.bool.isRequired,
};

export default TopBar;
