import React from 'react';
import styled from 'styled-components';

import BasePage from 'components/layout/BasePage';
import TitleBar from 'components/layout/TitleBar';

import CompanyGrid from './CompanyGrid';
import CompanyCreate from './CompanyCreate';

const Company = () => {
  const title = 'Registro de Visita';

  return (
    <BasePage title={title}>
      <TopMobile>
        <CreateMobile>
          <TitleBar>
            Empresas
          </TitleBar>
          <CompanyCreate />
        </CreateMobile>
      </TopMobile>
      <CompanyGrid />
    </BasePage>
  );
};

const TopMobile = styled.div`
  margin-bottom: 12px;
  @media(min-width: 800px) {
    display: none;
  }
`;

const CreateMobile = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
`;

export default Company;
