import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const FieldSection = ({ children, title, color }) => (
  <FieldSet>
    <Legend color={color}>{title}</Legend>
    {children}
  </FieldSet>
);

const FieldSet = styled.fieldset`
  border: 1px solid #908F93;
  padding: 5px;
  margin-bottom: 10px;
`;

const Legend = styled.legend`
  color: ${(props) => props.color};;
  font-size: 20px;
  font-weight: 600;
  margin-left: 21px;
  padding: 5px;
  @media(max-width: 800px) {
  font-size: 15px;
  padding: 7px;
  }
`;

FieldSection.propTypes = {
  children: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  color: PropTypes.string,
};

FieldSection.defaultProps = {
  color: '#838594'
};

export default FieldSection;
