import React from 'react';
import { DiaryProvider } from 'providers/diary';
import Diary from './Diary';

const index = () => (
  <DiaryProvider>
    <Diary />
  </DiaryProvider>
);

export default index;
