const cleanDataToString = (data) => data.replace(/[^0-9]/gm, '');

const manipulateDataToSQL = (data) => {
  const substSpace = data.replace(/\s/g, '-');
  const breakAcent = substSpace.normalize('NFD');
  const removeAcent = breakAcent.replace(/[^a-zA-Zs\-s]/g, '');
  return removeAcent;
};

export { cleanDataToString, manipulateDataToSQL };
