import React from 'react';
import PropTypes from 'prop-types';

import AreaChart from 'components/common/AreaChart';
import CardChart from 'components/common/CardChart';
import BarChart from 'components/chart/BarChart';
import Case from 'components/common/Case';
// import SmallInfoCard from 'components/common/SmallInfoCard';

function CallsLeadersDashboard({ data }) {
  return (
    <Case gridTemplateColumns="1fr">
      <AreaChart title="CHAMADOS">
        <CardChart title="Chamados ativos por pessoa">
          <BarChart data={data.activeCallsByUser} label={['', 'Ativos']} width="1000px" />
          <Case gridTemplateColumns="1fr 1fr 1fr 1fr" margin="20px 0 0 0" alignItems="center" justifyContent="center" display="flex">
            {/*
              <SmallInfoCard title="ABERTOS" backgroundColor="#DD4B39">
                  <span style={{ color: 'white' }}>{data.openCalls}</span>
                </SmallInfoCard>
                <SmallInfoCard title="PENDENTES" backgroundColor="#00C0EF">
                  <span style={{ color: 'white' }}>{data.pendingCalls}</span>
                </SmallInfoCard>
                <SmallInfoCard title="EXECUTANDO" backgroundColor="#3D9970">
                  <span style={{ color: 'white' }}>{data.executingCalls}</span>
                </SmallInfoCard>
            */}
          </Case>
        </CardChart>
      </AreaChart>
    </Case>
  );
}

CallsLeadersDashboard.propTypes = {
  data: PropTypes.element.isRequired,
};

export default CallsLeadersDashboard;
