import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import { createStoreContract } from 'service/ContractService';

import { OptionsProvider } from 'providers/Contracts/options';

import BasePage from 'components/layout/BasePage';
import LoadingBlocked from 'components/common/LoadingBlocked';
import { toastSuccess, toastError } from 'helpers/notifications';
import { PDF_MICROSERVICE } from 'helpers/constant';

import ContractsForm from './ContractsForm';

const ContractsCreate = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const initialValues = {
    brandId: '',
    storeName: '',
    tradeName: '',
    registrationNumber: '',
    zipcode: '',
    address: '',
    number: '',
    complement: '',
    neighborhood: '',
    city: '',
    state: '',
    phoneNumber: '',
    email: '',
    economicGroup: '',
    ownerName: '',
    ownerNationalNumber: '',
    ownerEmail: '',
    ownerPhoneNumber: '',
    startDate: '',
    system: '',
    comments: '',
    welcome: false,
    welcomeDescription: '',
    pbm: false,
    pbmDescription: '',
    comercial: false,
    comercialDescription: '',
    obsAngel: false,
    obsAngelDescription: '',
    administrativo: false,
    administrativoDescription: '',
    mentoring: false,
    mentoringDescription: '',
    marketing: false,
    marketingDescription: ''
  };

  const onSubmit = async (values) => {
    setLoading(true);
    const data = values;
    if (data.economicGroup === '1') {
      data.storeMainId = localStorage.getItem('searchStoreID');
      data.storeMainName = localStorage.getItem('searchStoreName');
    }
    const contacts = JSON.parse(localStorage.getItem('contacts'));
    const res = await createStoreContract([data, contacts]);
    if (res.name === 'ApplicationError') {
      setLoading(false);
      toastError(res.errors[0]);
    } else {
      await new Promise(() => {
        setTimeout(
          async () => {
            const contractName = await res.contractName;
            window.open(`${PDF_MICROSERVICE}/contracts/${contractName}/${contractName}-${1}.pdf`, '_blank');
            localStorage.removeItem('contacts');
            localStorage.removeItem('searchStoreID');
            localStorage.removeItem('searchStoreName');
            localStorage.removeItem('storeAngel');
            localStorage.removeItem('storeConsultant');
            setLoading(false);
            toastSuccess('Contrato Gerado!');
            history.push('/contratos');
          },
          5000
        );
      });
    }
  };

  const onCancel = () => {
    history.push('/contratos');
  };

  const validationSchema = {
    brandId: Yup.string().required('Bandeira não pode estar vazio'),
    tradeName: Yup.string().required('Nome Fantasia não pode estar vazio'),
    registrationNumber: Yup.string().required('CNPJ não pode estar vazio').length(18, 'CNPJ com formato incorreto!'),
    phoneNumber: Yup.string().required('Número de telefone não pode estar vazio'),
    email: Yup.string().email('E-mail inválido').required('E-mail não pode estar vazio'),
    economicGroup: Yup.string().required('Grupo Econômico não pode estar vazio'),
    ownerName: Yup.string().required('Nome do responsável não pode estar vazio'),
    ownerNationalNumber: Yup.string().required('CPF do responsável não pode estar vazio'),
    ownerEmail: Yup.string().email('E-mail inválido').required('E-mail do responsável não pode estar vazio'),
    ownerPhoneNumber: Yup.string().required('Telefone do responsável não pode estar vazio'),
    system: Yup.string().required('Sistema não pode estar vazio'),
    zipcode: Yup.string().required('Informe o CEP'),
    address: Yup.string().required('Não pode ser enviado em branco'),
    city: Yup.string().required('Não pode ser enviado em branco'),
    number: Yup.number('Não aceita letras').required('Não pode ser enviado em branco'),
    neighborhood: Yup.string().required('Não pode ser enviado em branco'),
    state: Yup.string().required('Não pode ser enviado em branco'),
  };

  return (
    <>
      <BasePage>
        <OptionsProvider>
          <ContractsForm
            values={initialValues}
            handleSubmit={onSubmit}
            handleCancel={onCancel}
            validations={validationSchema}
          />
          <LoadingBlocked active={loading} />
        </OptionsProvider>
      </BasePage>
    </>

  );
};

export default ContractsCreate;
