import axios from 'axios';
import { API } from 'helpers/constant';

const loginService = async (data) => axios
  .post(`${API}/users/signin`, data)
  .then((res) => res)
  .catch((error) => {
    if (!error.response) {
      return false;
    }
    return error.response.data;
  });

const logoutService = () => {
  localStorage.setItem('profile', '');
  window.location.reload();
};

const validateTokenService = async (data) => axios
  .post(`${API}/users/validateToken`, data)
  .then((res) => res)
  .catch((error) => {
    if (!error.response) {
      return false;
    }
    return error.response.data;
  });

const updateStorePassword = async (id, data) => {
  await axios.put(`${API}/users/storePassword/${id}`, data)
    .then((res) => res.data)
    .catch((error) => error);
};
export {
  loginService,
  logoutService,
  validateTokenService,
  updateStorePassword
};
