import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const TableTh = ({ children, gridTemplateColumns, backgroundColor }) => (
  <TableCase
    gridTemplateColumns={gridTemplateColumns}
    backgroundColor={backgroundColor}
  >{children}
  </TableCase>
);

const TableCase = styled.div`
  display: grid;
  grid-template-columns: ${(props) => props.gridTemplateColumns};
  align-items: center;
  text-align: center;
  :hover {
    background-color: #78d6ff;
  }
`;

TableTh.propTypes = {
  children: PropTypes.PropTypes.arrayOf(PropTypes.element).isRequired,
  gridTemplateColumns: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
};

TableTh.defaultProps = {
  backgroundColor: 'none'
};

export default TableTh;
