import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { useCalls } from 'providers/calls';

import SelectField from 'components/common/SelectField';
import SearchStore from 'components/skilled/SearchStore';
import TextAreaField from 'components/common/TextAreaField';
import Button from 'components/common/Button';
import CancelButton from 'components/common/CancelButton';
import ButtonCase from 'components/common/ButtonCase';

const CallsForm = ({
  values,
  handleSubmit,
  handleCancel,
  validations,
  editForm,
}) => {
  const formik = useFormik({
    initialValues: values,
    onSubmit: handleSubmit,
    validationSchema: Yup.object(validations),
  });

  const { userTypes, users } = useCalls();
  const [options, setOptions] = useState(0);
  const [hidenOption, setHidenOptions] = useState(true);
  const [usersByOptions, setUsersByOptions] = useState([]);

  useEffect(() => {
    if (options === '5') {
      setHidenOptions(false);
    } else {
      setHidenOptions(true);
    }
  }, [options]);

  useEffect(() => {
    if (options === '3') {
      formik.setFieldValue('userId', parseInt(localStorage.getItem('storeAngel'), 10));
    }
    if (options === '16') {
      formik.setFieldValue('userId', parseInt(localStorage.getItem('storeCommercialAttendant'), 10));
    }
  }, [options]);

  useEffect(() => {
    const fetchData = async () => {
      if (options !== 0) {
        const results = users.filter(
          (i) => (i.usertypeId === parseInt(options, 10))
        );
        setUsersByOptions(results);
      } else {
        setUsersByOptions(users);
      }
    };
    fetchData();
  }, [options]);

  return (
    <Form data-testid="client-form" onSubmit={formik.handleSubmit}>
      <SearchStore
        label="Drogaria"
        name="storeID"
        storeName={values.storeName}
        storeId={values.storeId}
        isRequired
        type="text"
        error={formik.errors.storeName}
        onBlur={formik.handleBlur}
        onChange={(e) => {
          formik.handleChange(e);
        }}
      />
      {!editForm && (
        <>
          <SelectField
            id="sector"
            name="sector"
            label="Setor Responsável"
            isRequired
            value={formik.values.sector}
            onBlur={formik.handleBlur}
            onChange={(e) => {
              formik.handleChange(e);
              // setOptions(e.nativeEvent.srcElement.options.selectedIndex);
              setOptions(e.target.value);
            }}
            options={userTypes}
            error={formik.errors.sector}
            isError={formik.errors.sector && formik.touched.sector}
          />
          {hidenOption && (
          <SelectField
            id="userId"
            name="userId"
            label="Responsável Pelo Chamado"
            value={formik.values.userId}
            onBlur={formik.handleBlur}
            onChange={(e) => {
              formik.handleChange(e);
            }}
            options={usersByOptions}
            error={formik.errors.userId}
            isError={formik.errors.userId && formik.touched.userId}
          />
          )}
          {options === '5' && (
            <><span>Responsável:</span> <span style={{ textTransform: 'capitalize' }}>{localStorage.getItem('storeConsultant')}</span></>
          )}
        </>
      )}
      <TextAreaField
        id="text"
        name="text"
        label="Descrição"
        placeholder="Escreva o motivo do Chamado"
        isRequired
        error={formik.errors.text}
        onBlur={formik.handleBlur}
        onChange={(e) => {
          formik.handleChange(e);
        }}
        value={formik.values.text}
        isError={formik.errors.text && formik.touched.text}
        height="200px"
      />
      <ButtonCase>
        <CancelButton onClose={handleCancel}>Cancelar</CancelButton>
        <Button type="submit">Salvar</Button>
      </ButtonCase>
    </Form>
  );
};

const Form = styled.form`  
  padding: 5px;
  width: 30vw;

  @media(max-width: 800px) {
    width: 100%;
    overflow: auto;
  }
`;

CallsForm.propTypes = {
  validations: PropTypes.objectOf(PropTypes.any).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  values: PropTypes.objectOf(PropTypes.any).isRequired,
  editForm: PropTypes.bool,
};

CallsForm.defaultProps = {
  editForm: 0
};

export default CallsForm;
