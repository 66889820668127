import React from 'react';
import styled from 'styled-components';

const Refresh = () => {
  const refresh = () => {
    window.location.reload();
  };

  return (
    <RefreshCase>
      Ops! Algo inesperado aconteceu e o compomente não pôde ser carregado,
      <Span onClick={() => refresh()}>Clique aqui</Span>
      para atualizar sua página
    </RefreshCase>
  );
};

const RefreshCase = styled.div`
  font-weight: 500;
  margin-top: 10px;
  margin-left: 5px;
`;

const Span = styled.span`
  margin: 0px 5px;
  text-decoration: underline;
  cursor: pointer;
`;

export default Refresh;
