import axios from 'axios';
import { API } from 'helpers/constant';

const createCompany = (data) => axios.post(`${API}/companies`, data)
  .then((res) => res.data)
  .catch((error) => error(error));

const listCompany = () => axios.get(`${API}/companies`)
  .then((res) => (res.data))
  .catch((error) => error(error[0]));

const getCompany = (id) => axios.get(`${API}/companies/${id}`)
  .then((res) => res.data)
  .catch((error) => error(error[0]));

const updateCompany = (id, data) => axios.put(`${API}/companies/${id}`, data)
  .then((res) => (res.data))
  .catch((error) => error(error));

const changeCompany = (id, data) => axios.put(`${API}/companies/change/${id}`, data)
  .then((res) => (res.data))
  .catch((error) => error(error));

const deleteCompany = (id) => axios.delete(`${API}/companies/${id}`)
  .then((res) => res.data)
  .catch((error) => error(error[0]));

export {
  createCompany, listCompany, getCompany, updateCompany, deleteCompany, changeCompany
};
