import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  getContactsReports,
  getUserContactsReports,
} from 'service/ReportService';

import verifyAclUser from 'helpers/verifyAclUser';
import acl from 'helpers/acl';
import getProfile from 'helpers/getProfile';

export const ContactsReportsContext = React.createContext({});

export const ContactsReportsProvider = ({ children }) => {
  const [contactsReportsData, setContactsReportsData] = useState([]);
  const [loading, setLoading] = useState(true);

  const list = async () => {
    setLoading(true);
    let data = [];
    try {
      const profile = getProfile();
      if (verifyAclUser(acl.angel)) {
        data = await getUserContactsReports(profile.user.id);
      } else {
        data = await getContactsReports();
      }
      setContactsReportsData(data);
    } catch {
      setContactsReportsData(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      list();
    };
    fetchData();
  }, []);

  return (
    <ContactsReportsContext.Provider value={{
      contactsReportsData,
      setContactsReportsData,
      loading,
      setLoading,
      list,
    }}
    >
      {children}
    </ContactsReportsContext.Provider>
  );
};

ContactsReportsProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export const useContactsReports = () => React.useContext(ContactsReportsContext);
