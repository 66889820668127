import styled from 'styled-components';

const Tr = styled.tr`
  :nth-child(even) {
    background-color: #F1F3F4;
  }
  :hover {
    background-color: #78d6ff;
    transition: 0.4s;
  }
`;

export default Tr;
