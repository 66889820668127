import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  getDashboardUser,
} from 'service/ReportService';
import getProfile from 'helpers/getProfile';

export const UserDashboardContext = React.createContext({});

export const UserDashboardProvider = ({ children }) => {
  const [userDashboardData, setUserDashboardData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const profile = getProfile();
      try {
        const data = await getDashboardUser(profile.user.id);
        setUserDashboardData(data);
      } catch {
        setUserDashboardData(false);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <UserDashboardContext.Provider value={{
      userDashboardData,
      loading,
    }}
    >
      {children}
    </UserDashboardContext.Provider>
  );
};

UserDashboardProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export const useUserDashboard = () => React.useContext(UserDashboardContext);
