import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { toastSuccess } from 'helpers/notifications';

import {
  createCall,
  listCalls,
  updateCall,
  deleteCall,
  getCallsTypes,
  listByIdAndUserType,
  getCallStatus,
  transferCall,
  getCallHistory,
  interactionsCall,
  reopenCall,
  getCallLog
} from 'service/CallsService';

import { getProfile, getProfileUserType } from 'helpers/getProfileUserType';

import { getUserType } from 'service/UserTypeService';
import { getUsers } from 'service/UsersService';

export const CallsContext = React.createContext([]);

export const CallsProvider = ({ children }) => {
  const [callsData, setCallsData] = useState([]);
  const [reload, setReload] = useState(false);
  const [users, setUsers] = useState([]);
  const [userTypes, setUserTypes] = useState([]);
  const [sector, setSector] = useState(false);
  const [endedCalls, setEndedCalls] = useState(false);
  const [endedCallsAdm, setEndedCallsAdm] = useState(false);
  const [callsTypes, setCallsTypes] = useState([]);
  const [callStatus, setCallStatus] = useState([]);
  const [loading, setLoading] = useState(true);
  const [blockLoading, setBlockLoading] = useState(false);
  const [openCalls, setOpenCalls] = useState(false);

  const create = async (values) => {
    setBlockLoading(true);
    await createCall(values);
    setBlockLoading(false);
    setReload(!reload);
    toastSuccess('Salvo com sucesso!');
  };

  const update = async (id, values) => {
    await updateCall(id, values);
    setReload(!reload);
    toastSuccess('Alterado com sucesso!');
  };

  const reopen = async (id, values) => {
    setBlockLoading(true);
    await reopenCall(id, values);
    setBlockLoading(false);
    setReload(!reload);
    toastSuccess('Finalizado com sucesso!');
  };

  const updateTransferCall = async (id, values) => {
    setBlockLoading(true);
    await transferCall(id, values);
    setBlockLoading(false);
    setReload(!reload);
    toastSuccess('Transferido com sucesso!');
  };

  const updateInteractionsCall = async (id, values) => {
    setBlockLoading(true);
    await interactionsCall(id, values);
    setBlockLoading(false);
    setReload(!reload);
    toastSuccess('Atualizado com sucesso!');
  };

  const deleteRegister = async (id) => {
    await deleteCall(id);
    setReload(!reload);
    toastSuccess('Finalizado com sucesso!');
  };

  const list = async () => {
    const profile = getProfile();
    if (getProfileUserType([1, 2])) {
      /* Confere se o usuário logado tem
        permissão de Admin ou Gerente */
      const data = await listCalls();
      if (endedCallsAdm && sector) {
        /* Confere se os Switchs "Chamados
        Concluídos" e "Todos os Chamados"
        estão selecionados */

        /* Retorna todos os chamados FECHADOS */
        const results = data.filter(
          (i) => (i.status === 2)
        );
        setCallsData(results);
        setLoading(false);
        return results;
      }
      if (endedCallsAdm) {
        /* Confere se o Switch "Chamados Concluídos"
        está selecionado */

        /* Retorna os chamados para a Diretoria
        que estão FECHADOS */
        const results = data.filter(
          (i) => (i.sector === 2 && i.status === 2)
        );
        setCallsData(results);
        setLoading(false);
        return data;
      }
      if (sector) {
        /* Confere se o Switch "Todo os Chamados"
        está selecionado */

        /* Retorna todos os chamados ABERTOS */
        const results = data.filter(
          (i) => (i.status !== 2)
        );
        setCallsData(results);
        setLoading(false);
        return results;
      }
      /* Retorna os chamados para a Diretoria
      que estão ABERTOS */
      const results = data.filter(
        (i) => (i.sector === 2 && i.sector === 15 && i.status !== 2)
      );
      setCallsData(results);
      setLoading(false);
      return data;
    }
    if (getProfileUserType([15])) {
      /* Confere se o usuário logado tem
        permissão de Admin ou Gerente */
      const data = await listCalls();
      if (endedCallsAdm && sector) {
        /* Confere se os Switchs "Chamados
        Concluídos" e "Todos os Chamados"
        estão selecionados */

        /* Retorna todos os chamados FECHADOS */
        const results = data.filter(
          (i) => (i.status === 2)
        );
        setCallsData(results);
        setLoading(false);
        return results;
      }
      if (endedCallsAdm) {
        /* Confere se o Switch "Chamados Concluídos"
        está selecionado */

        /* Retorna os chamados para a Diretoria
        que estão FECHADOS */
        const results = data.filter(
          (i) => (i.sector === 15 && i.status === 2)
        );
        setCallsData(results);
        setLoading(false);
        return data;
      }
      if (sector) {
        /* Confere se o Switch "Todo os Chamados"
        está selecionado */

        /* Retorna todos os chamados ABERTOS */
        const results = data.filter(
          (i) => (i.status !== 2)
        );
        setCallsData(results);
        setLoading(false);
        return results;
      }
      /* Retorna os chamados para a Diretoria
      que estão ABERTOS */
      const results = data.filter(
        (i) => (i.sector === 15 && i.status !== 2)
      );
      setCallsData(results);
      setLoading(false);
      return data;
    }
    if (endedCalls && openCalls) {
      /* Confere se os Switchs "Chamados Conluídos"
      e "Abertos por mim" estão selecionados */

      /* Retorna os Chamados abertos por mim que
      estão FECHADOS */
      const data = await listCalls();
      const results = data.filter(
        (i) => (i.openBy === profile.user.id && i.status === 2)
      );
      setCallsData(results);
      setLoading(false);
      return results;
    }
    if (openCalls) {
      /* Confere se o Switch "Abertos por mim"
      está selecionado */

      /* Retorna os Chamados abertos por mim que
      estão ABERTOS */
      const data = await listCalls();
      const results = data.filter(
        (i) => (i.openBy === profile.user.id && i.status !== 2)
      );
      setCallsData(results);
      setLoading(false);
      return results;
    }
    if (endedCalls) {
      /* Confere se o Switch "Chamados Conluídos"
      está selecionado */

      /* Retorna os Chamados do usuário/setor que estão FECHADOS */
      const data = await listByIdAndUserType(profile.user.id, profile.user.userTypeId);
      const results = data.filter(
        (i) => (i.status === 2)
      );
      setCallsData(results);
      setLoading(false);
      return results;
    }
    /* Retorna os Chamados do usuário/setor que estão ABERTOS */
    const data = await listByIdAndUserType(profile.user.id, profile.user.userTypeId);
    const results = data.filter(
      (i) => (i.status !== 2)
    );
    setCallsData(results);
    setLoading(false);
    return data;
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await getUsers();
      const results = data.filter(
        (i) => (i.active === true)
      );
      setUsers(results);
    };
    fetchData();
  }, []);

  const getHistory = async (id) => {
    const data = await getCallHistory(id);
    return data;
  };

  const getLogs = async (id) => {
    const data = await getCallLog(id);
    return data;
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await getUserType();
      const results = data.filter(
        (i) => ((i.id !== 1) && (i.id !== 13) && (i.id !== 14))
      );
      setUserTypes(results);
    };
    fetchData();
  }, []);

  useEffect(() => {
    list();
  }, [reload, sector, endedCalls, endedCallsAdm, openCalls]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getCallsTypes();
      setCallsTypes(data);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getCallStatus();
      setCallStatus(data);
    };
    fetchData();
  }, []);

  return (
    <CallsContext.Provider value={{
      callsData,
      setCallsData,
      userTypes,
      users,
      list,
      create,
      update,
      deleteRegister,
      sector,
      setSector,
      callsTypes,
      endedCalls,
      setEndedCalls,
      endedCallsAdm,
      setEndedCallsAdm,
      loading,
      openCalls,
      setOpenCalls,
      blockLoading,
      callStatus,
      updateTransferCall,
      getHistory,
      getLogs,
      updateInteractionsCall,
      reopen
    }}
    >
      {children}
    </CallsContext.Provider>
  );
};

CallsProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export const useCalls = () => React.useContext(CallsContext);
