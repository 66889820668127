import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import bounce from 'helpers/bounce';
import colors from 'assets/style/colors';
import Label from 'components/common/Label';
import Asterisk from 'components/common/Asterisk';

import * as StoresService from 'service/StoresService';

const SearchStore = ({
  placeholder, storeName, storeId, label, ...props
}) => {
  const [searchList, setSearchList] = useState([]);
  const [errorMessage, setErrorMessage] = useState();
  const [storeID, setstoreID] = useState('');
  const [visible, setVisible] = useState(false);
  const [searchTerm, setSearchValue] = useState(storeName);
  const [filteredList, setFilteredList] = useState([]);
  const debouncedSearchTerm = bounce(searchTerm, 500);
  useEffect(() => {
    const filterItems = (query) => searchList.filter(
      (el) => el.storeName.toLowerCase().indexOf(query.toLowerCase()) > -1
      || String(el.legacyCode).indexOf(query.toLowerCase()) > -1
    );
    setFilteredList(filterItems(debouncedSearchTerm));
  }, [debouncedSearchTerm]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await StoresService.getStores();
      setSearchList(response);
    };
    fetchData();
  }, []);

  const setValue = (value) => {
    setstoreID(value.id);
    setSearchValue(value.storeName);
    setVisible(false);
    setFilteredList([]);
    localStorage.setItem('searchStoreID', value.id);
    localStorage.setItem('searchStoreName', value.storeName);
    localStorage.setItem('storeAngel', value.angelData.id);
    localStorage.setItem('storeCommercialAttendant', value.commercialAttendantData.id);
    localStorage.setItem('storeConsultant', value.consultantData.name);
  };
  return (
    <div>
      <Label htmlFor="storeId"> {label} </Label>
      <Asterisk />
      <input
        {...props}
        type="text"
        placeholder={placeholder}
        value={searchTerm}
        autoComplete="off"
        onChange={(e) => {
          setSearchValue(e.target.value);
          setErrorMessage(false);
        }}
        onBlur={(e) => {
          if (!e.target.value) {
            setErrorMessage(true);
          }
        }}
        onClick={() => setVisible(true)}
      />
      <input
        type="hidden"
        name="storeId"
        value={storeID}
      />
      {
        (visible && searchTerm) && (
          <List>
            {filteredList.map(
              (value) => (
                <Li
                  key={value.legacyCode}
                  onClick={() => {
                    setValue(value);
                    setFilteredList([]);
                  }}
                  color={!value.active && (
                    'color: #7a191f; background-color: #fae7e8;'
                  )}
                >
                  {value.legacyCode} - {value.storeName.toUpperCase()}
                  - {value.addressData.city.charAt(0).toUpperCase()
                  + value.addressData.city.slice(1)} - {value.addressData.state}
                </Li>
              )
            )}
          </List>
        )
      }
      {
        (errorMessage) && (<Error>Selecione uma drogaria antes de avançar! </Error>)
      }
    </div>
  );
};

const List = styled.div`
  max-height: 200px;
  background-color: #fff;
  overflow-x: hidden;
  overflow-y: scroll;
  z-index: 999;
  position: absolute;
`;

const Li = styled.li`
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 40px;
  padding: 5px 20px 0px 20px;
  text-align: left;
  font-size: 14px;
  overflow: hidden;
  :hover{
    background-color: #93D367;
  }
  border: solid 1px #E5EBF0;
  border-top: 0;
  ${(props) => props.color}
`;

const Error = styled.div`
  padding-left: 1px;
  color: ${colors.errorColor};
  width: 100%;
  font-size: 10px;
  margin-top: 5px;
  margin-bottom: 8px;
`;

SearchStore.propTypes = {
  placeholder: PropTypes.string,
  storeName: PropTypes.string,
  storeId: PropTypes.number,
  label: PropTypes.string,
};

SearchStore.defaultProps = {
  placeholder: 'Buscar',
  storeName: '',
  storeId: null,
  label: '',
};
export default SearchStore;
