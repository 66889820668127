import jwtDecode from 'jwt-decode';

const getProfile = () => {
  const jwtToken = JSON.parse(localStorage.getItem('profile')).token;
  const decoded = jwtDecode(jwtToken);
  const profile = { user: decoded };
  return profile;
};

export default getProfile;
