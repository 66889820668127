import React from 'react';
import { AiOutlineUser } from 'react-icons/ai';
import { BsPeople } from 'react-icons/bs';

import Loading from 'components/common/Loading';
// import CallsDashboard from 'components/dashboard/calls/CallsDashboard';
import Case from 'components/common/Case';
import Refresh from 'components/common/Refresh';
import InfoCard from 'components/common/InfoCard';
import StoreExpansionDashboard from 'components/dashboard/stores/StoreExpansionDashboard';

import { useExpansionDashboard } from 'providers/Dashboard/expansion';

function Dashboard() {
  const { expansionDashboardData, loading } = useExpansionDashboard();

  function setComponent() {
    if (!expansionDashboardData) {
      return (<Refresh />);
    } return (
      <>
        <Loading active={loading} />
        { !loading && (
        <>
          <Case gridTemplateColumns="1fr 1fr 1fr 1fr">
            <InfoCard title="CHAMADOS ATIVOS MEUS" backgroundColor="#DD4B39" data={expansionDashboardData.userActiveCalls}>
              <AiOutlineUser style={{ color: 'white' }} />
            </InfoCard>
            <InfoCard title="CHAMADOS ATIVOS SETOR" backgroundColor="#ffcd17" data={expansionDashboardData.sectorActiveCalls}>
              <BsPeople style={{ color: 'white' }} />
            </InfoCard>
          </Case>
          <StoreExpansionDashboard data={expansionDashboardData} />
          {/* <CallsDashboard data={expansionDashboardData.activeCalls} /> */}
        </>
        )}
      </>
    );
  }
  return (
    <>
      {setComponent()}
    </>
  );
}

export default Dashboard;
