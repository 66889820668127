import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';
import Swal from 'sweetalert2';
import { GiHamburgerMenu } from 'react-icons/gi';
import { CgClose } from 'react-icons/cg';
import { TiArrowSortedDown } from 'react-icons/ti';
import { FaUserCircle } from 'react-icons/fa';

import { logoutService } from 'service/LoginService';
import AssociateClick from 'service/AssociateService';
import CALENDARIO_TRADE from 'assets/images/associate/CALENDARIO_TRADE.png';
import PACOTE_DE_PUBLICAÇÕES from 'assets/images/associate/PACOTE_DE_PUBLICAÇÕES.png';
import NOVO_TABLOIDE from 'assets/images/associate/NOVO_TABLOIDE.png';
import RADIO_RMC from 'assets/images/associate/RADIO_RMC.png';
import CARNAVAL from 'assets/images/associate/PACOTE_DE_PUBLICAÇÕES_CARNAVAL.png';
import CARNAVAL_MOBILE from 'assets/images/associate/PACOTE_DE_PUBLICAÇÕES_CARNAVAL_v2.png';
import decodeToken from 'helpers/decodeToken';
import { getToken } from 'service/StoresService';
import { BiHome } from 'react-icons/bi';

function AssociateMarketingMaterial() {
  const profile = decodeToken();
  const [linkedfarmaToken, setLinkedfarmaToken] = useState('');
  useEffect(() => {
    const fetchData = async () => {
      const storeData = await getToken(profile.username);
      if (storeData?.linkedfarmaToken) {
        setLinkedfarmaToken(storeData.linkedfarmaToken);
      }
    };
    fetchData();
  }, []);
  const [visible, setVisible] = useState(false);
  const [userMenuState, setUserMenuState] = useState(false);
  const route = `http://www.linkedfarma.com.br/melhorcompra?action=site&value=${linkedfarmaToken}`;
  const history = useHistory();
  const handleLogout = () => {
    Swal.fire({
      title: 'Você quer realmente sair?',
      text: 'lembre-se de salvar tudo antes de sair',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#224772',
      cancelButtonColor: '#224772',
      confirmButtonText: 'Sim, eu quero sair',
      cancelButtonText: 'Não, eu não quero sair',
    }).then((result) => {
      if (result.isConfirmed) {
        logoutService();
        history.push('/login');
      }
    });
  };
  const openMenu = (state) => {
    if (state) {
      return '';
    }
    return 'none';
  };

  return (
    <>
      <MobileButton visible={visible} onClick={() => setVisible(true)}>
        {visible ? <CloseIcon /> : <OpenIcon />}
      </MobileButton>
      <Container visible={visible} onClick={() => setVisible(false)}>
        <NavBar>
          <MenuContainer>
            <MenuLink to="/Loja">
              <HomeIcon />
            </MenuLink>
            <MenuLink
              onClick={() => {
                window.open(
                  'https://redemelhorcompra.academiaabrafad.com.br',
                  '_blank'
                );
              }}
            >
              ENSINO FARMA
            </MenuLink>
            <MenuLink
              onClick={() => {
                window.open('https://melhorcompra.minharadio.fm/', '_blank');
              }}
            >
              RÁDIO
            </MenuLink>
            <MenuLink onClick={() => window.open(`${route}`, '_self')}>
              PEDIDOS
            </MenuLink>
            <Mobile>
              <MenuLink onClick={() => handleLogout()}>SAIR</MenuLink>
            </Mobile>
          </MenuContainer>
          <Desktop>
            <UserInfo onClick={() => setUserMenuState(!userMenuState)}>
              <UserIcon />
              <Surname>{profile.surname}</Surname>
              <ArrowIcon />
            </UserInfo>
            <Options display={openMenu(userMenuState)}>
              <Item onClick={() => handleLogout()}>Sair</Item>
            </Options>
          </Desktop>
        </NavBar>
        <Section>
          <DesktopBody>
            <BannersArea>
              <Banner1>
                <LinkDownload
                  onClick={() => {
                    AssociateClick({
                      userId: profile.id,
                      name: 'RADIO_RMC_DOWNLOAD',
                    });
                    window.open(
                      'https://play.google.com/store/apps/details?id=io.kodular.apprdio.radiomc'
                    );
                  }}
                />
                <LinkVideo
                  onClick={() => {
                    AssociateClick({
                      userId: profile.id,
                      name: 'RADIO_RMC_DOWNLOAD',
                    });
                    window.open(
                      'https://play.google.com/store/apps/details?id=io.kodular.apprdio.radiomc'
                    );
                  }}
                />
              </Banner1>
              <Banner2>
                <LinkDownload
                  onClick={() => {
                    AssociateClick({
                      userId: profile.id,
                      name: 'PACOTE_DE_PUBLICACOES_DOWNLOAD',
                    });
                    window.open(
                      `https://bifrost-app-b4ivn.ondigitalocean.app/v1/files?category=marketing&name=Pacote de publicações ${profile.brand}`
                    );
                  }}
                />
                <LinkVideo
                  onClick={() => {
                    AssociateClick({
                      userId: profile.id,
                      name: 'PACOTE_DE_PUBLICACOES_DOWNLOAD',
                    });
                    window.open(
                      `https://bifrost-app-b4ivn.ondigitalocean.app/v1/files?category=marketing&name=Pacote de publicações ${profile.brand}`
                    );
                  }}
                />
              </Banner2>
              <Banner3>
                <LinkDownload
                  onClick={() => {
                    AssociateClick({
                      userId: profile.id,
                      name: 'NOVO_TABLOIDE_DOWNLOAD',
                    });
                    window.open(
                      'https://bifrost-app-b4ivn.ondigitalocean.app/v1/files?category=marketing&name=Tablóide'
                    );
                  }}
                />
                <LinkVideo
                  onClick={() => {
                    AssociateClick({
                      userId: profile.id,
                      name: 'NOVO_TABLOIDE_DOWNLOAD',
                    });
                    window.open(
                      'https://bifrost-app-b4ivn.ondigitalocean.app/v1/files?category=marketing&name=Tablóide'
                    );
                  }}
                />
              </Banner3>
              <Banner4>
                <LinkDownload
                  onClick={() => {
                    AssociateClick({
                      userId: profile.id,
                      name: 'CALENDARIO_TRADE_DOWNLOAD',
                    });
                    window.open(
                      'https://drive.google.com/file/d/17Pl3YMiUI0JlMBdI9iT5jF9eH1vO1T--/view'
                    );
                  }}
                />
                <LinkVideo
                  onClick={() => {
                    AssociateClick({
                      userId: profile.id,
                      name: 'CALENDARIO_TRADE_DOWNLOAD',
                    });
                    window.open(
                      'https://drive.google.com/file/d/17Pl3YMiUI0JlMBdI9iT5jF9eH1vO1T--/view'
                    );
                  }}
                />
              </Banner4>
              <Banner7>
                <LinkDownloadBanner5
                  onClick={() => {
                    AssociateClick({
                      userId: profile.id,
                      name: 'CARNAVAL',
                    });
                    window.open(
                      'https://bifrost-app-b4ivn.ondigitalocean.app/v1/files?category=marketing&name=Pacote de Carnaval'
                    );
                  }}
                />
              </Banner7>
            </BannersArea>
          </DesktopBody>
        </Section>
      </Container>
      <MobileBody visible={visible}>
        <BannersArea>
          <Banner1>
            <LinkDownload
              onClick={() => {
                AssociateClick({
                  userId: profile.id,
                  name: 'RADIO_RMC_DOWNLOAD',
                });
                window.open(
                  'https://play.google.com/store/apps/details?id=io.kodular.apprdio.radiomc'
                );
              }}
            />
            <LinkVideo
              onClick={() => {
                AssociateClick({
                  userId: profile.id,
                  name: 'RADIO_RMC_DOWNLOAD',
                });
                window.open(
                  'https://play.google.com/store/apps/details?id=io.kodular.apprdio.radiomc'
                );
              }}
            />
          </Banner1>
          <Banner2>
            <LinkDownload
              onClick={() => {
                AssociateClick({
                  userId: profile.id,
                  name: 'PACOTE_DE_PUBLICACOES_DOWNLOAD',
                });
                window.open(
                  `https://bifrost-app-b4ivn.ondigitalocean.app/v1/files?category=marketing&name=Pacote de publicações ${profile.brand}`
                );
              }}
            />
            <LinkVideo
              onClick={() => {
                AssociateClick({
                  userId: profile.id,
                  name: 'PACOTE_DE_PUBLICACOES_DOWNLOAD',
                });
                window.open(
                  `https://bifrost-app-b4ivn.ondigitalocean.app/v1/files?category=marketing&name=Pacote de publicações ${profile.brand}`
                );
              }}
            />
          </Banner2>
          <Banner3>
            <LinkDownload
              onClick={() => {
                AssociateClick({
                  userId: profile.id,
                  name: 'NOVO_TABLOIDE_DOWNLOAD',
                });
                window.open(
                  'https://bifrost-app-b4ivn.ondigitalocean.app/v1/files?category=marketing&name=Tablóide'
                );
              }}
            />
            <LinkVideo
              onClick={() => {
                AssociateClick({
                  userId: profile.id,
                  name: 'NOVO_TABLOIDE_DOWNLOAD',
                });
                window.open(
                  'https://bifrost-app-b4ivn.ondigitalocean.app/v1/files?category=marketing&name=Tablóide'
                );
              }}
            />
          </Banner3>
          <Banner4>
            <LinkDownload
              onClick={() => {
                AssociateClick({
                  userId: profile.id,
                  name: 'CALENDARIO_TRADE_DOWNLOAD',
                });
                window.open(
                  'https://drive.google.com/file/d/17Pl3YMiUI0JlMBdI9iT5jF9eH1vO1T--/view'
                );
              }}
            />
            <LinkVideo
              onClick={() => {
                AssociateClick({
                  userId: profile.id,
                  name: 'CALENDARIO_TRADE_DOWNLOAD',
                });
                window.open(
                  'https://drive.google.com/file/d/17Pl3YMiUI0JlMBdI9iT5jF9eH1vO1T--/view'
                );
              }}
            />
          </Banner4>
          <Banner7Mobile>
            <LinkDownloadBanner5
              onClick={() => {
                AssociateClick({
                  userId: profile.id,
                  name: 'CARNAVAL',
                });
                window.open(
                  'https://bifrost-app-b4ivn.ondigitalocean.app/v1/files?category=marketing&name=Pacote de Carnaval'
                );
              }}
            />
          </Banner7Mobile>
        </BannersArea>
      </MobileBody>
    </>
  );
}
const Banner1 = styled.div`
  background-image: url(${RADIO_RMC});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 768px) {
    width: 80vw;
    height: calc(80vw / 120 * 35);
  }
`;
const Banner2 = styled.div`
  background-image: url(${PACOTE_DE_PUBLICAÇÕES});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 768px) {
    width: 80vw;
    height: calc(80vw / 120 * 35);
  }
`;
const Banner3 = styled.div`
  background-image: url(${NOVO_TABLOIDE});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 768px) {
    width: 80vw;
    height: calc(80vw / 120 * 35);
  }
`;
const Banner4 = styled.div`
  background-image: url(${CALENDARIO_TRADE});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 768px) {
    width: 80vw;
    height: calc(80vw / 120 * 35);
  }
`;
const Banner7 = styled.div`
  background-image: url(${CARNAVAL});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  justify-content: center;
  width: 100%;
`;
const Banner7Mobile = styled.div`
  background-image: url(${CARNAVAL_MOBILE});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: grid;
  width: 80vw;
`;
const BannersArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 90vw;
  gap: 1rem;

  @media (max-width: 768px) {
    width: 80vw;
  }
`;
const LinkDownload = styled.div`
  width: 20vw;
  height: calc(40vw / 120 * 35);
  cursor: pointer;
  @media (max-width: 768px) {
    width: 40vw;
    height: calc(80vw / 120 * 35);
  }
`;
const LinkVideo = styled.div`
  width: 20vw;
  height: calc(40vw / 120 * 35);
  cursor: pointer;
  @media (max-width: 768px) {
    width: 40vw;
    height: calc(80vw / 120 * 35);
  }
`;
const LinkDownloadBanner5 = styled.div`
  width: 81vw;
  height: calc(40vw / 120 * 35);
  cursor: pointer;
  @media (max-width: 768px) {
    width: 80vw;
    height: calc(80vw / 120 * 35);
  }
`;
const MobileBody = styled.div`
  ${({ visible }) => css`
    display: none;
    @media (max-width: 768px) {
      ${!visible && 'display: initial;'}
      display: flex;
      padding-top: 15vh;
      align-items: center;
      justify-content: center;
    }
  `}
`;
const DesktopBody = styled.div`
  background-color: #f3f3f3;
  margin-top: 3.5rem;
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 2rem;
  padding-top: 1rem;

  @media (max-width: 768px) {
    padding-top: 80px;
  }
`;
const HomeIcon = styled(BiHome)`
  color: white;
  cursor: pointer;
`;
const ArrowIcon = styled(TiArrowSortedDown)`
  color: white;
  position: absolute;
  right: 0px;
  margin-right: 5px;
`;
const UserIcon = styled(FaUserCircle)`
  color: white;
  font-size: 12px;
`;
const UserInfo = styled.span`
  width: 280px;
  text-transform: uppercase;
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
`;
const Options = styled.div`
  position: absolute;
  top: 50px;
  right: 5px;
  z-index: 5;
  border: 1px solid #c0c0c0;
  border-radius: 0 0 6px 6px;
  display: ${(props) => props.display};
  background-color: white;
  color: grey;
`;
const Item = styled.li`
  width: 150px;
  list-style: none;
  text-align: center;
  padding: 10px 62px;
  cursor: pointer;
  :hover {
    background-color: #93d367;
    color: #fff;
  }
`;
const Mobile = styled.span``;
const Desktop = styled.span`
  cursor: pointer;
  position: absolute;
  right: 0%;
  top: 0%;
  font-family: 'Montserrat';
  font-weight: 500;
  text-decoration: none;
  font-size: 14px;
  padding: 18px 18px 18px 10px;
  color: white;
  display: block;
  :hover {
    background: #253469;
  }
`;
const Surname = styled.div`
  margin-left: 8px;
  width: 265px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CloseIcon = styled(CgClose)`
  color: white;
  cursor: pointer;
`;

const OpenIcon = styled(GiHamburgerMenu)`
  color: white;
  cursor: pointer;
`;

const Container = styled.div`
  ${({ visible }) => css`
    width: 100%;
    height: calc(100vh - 56px);
    border-bottom: #dddddd;
    transition: all 300ms ease-in-out;
    @media (max-width: 768px) {
      height: 0;
      visibility: hidden;
      opacity: 0;
      ${visible && 'visibility: visible; opacity: 1;'}
    }
  `}
`;

const Section = styled.div`
  padding-bottom: 0;
  @media (max-width: 768px) {
    height: 1rem;
    background: -webkit-gradient(
        linear,
        left top,
        right top,
        from(#6b8fc9),
        to(#324682)
      )
      no-repeat;
    background: -moz-linear-gradient(left, #6b8fc9, #324682);
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    align-items: center;
    overflow-y: auto;
  }
`;

const MobileButton = styled.button`
  ${({ visible }) => css`
    z-index: 6;
    position: fixed;
    top: 20px;
    right: 20px;
    width: 40px;
    height: 40px;
    background: #243470;
    border: 1px solid #cccccc;
    border-radius: 4px;
    color: white;
    display: none;
    pointer-events: ${visible ? 'none' : 'all'};
    @media (max-width: 768px) {
      display: flex;
      align-items: center;
      justify-content: center;
      pointer: cursor;
    }
    > svg {
      width: 18px;
      height: 18px;
    }
  `}
`;

const NavBar = styled.div`
  background: -webkit-gradient(
      linear,
      left top,
      right top,
      from(#6b8fc9),
      to(#324682)
    )
    no-repeat;
  background: -moz-linear-gradient(left, #6b8fc9, #324682);
  z-index: 5;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  ${Mobile} {
    display: none;
  }
  ${Desktop} {
    display: initial;
  }
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) {
    display: block;
    text-align: center;
    padding: 50px 0 50px 0;
    ${Mobile} {
      display: initial;
    }
    ${Desktop} {
      display: none;
    }
  }
`;
const MenuContainer = styled.div`
  display: flex;
  @media (max-width: 768px) {
    flex-flow: column wrap;
    align-content: center;
    text-align: center;
    margin-top: 2rem;
  }
`;
const MenuLink = styled(Link)`
  cursor: pointer;
  font-family: 'Montserrat';
  font-weight: 500;
  text-decoration: none;
  font-size: 14px;
  padding: 18px;
  color: white;
  display: block;
  position: relative;
  :hover {
    background: #253469;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export default AssociateMarketingMaterial;
