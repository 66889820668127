import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  getDashboardLeader,
} from 'service/ReportService';
import getProfile from 'helpers/getProfile';

export const LeaderDashboardContext = React.createContext({});

export const LeaderDashboardProvider = ({ children }) => {
  const [leaderDashboardData, setLeaderDashboardData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const profile = getProfile();
      try {
        const data = await getDashboardLeader(profile.user.id);
        setLeaderDashboardData(data);
      } catch {
        setLeaderDashboardData(false);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <LeaderDashboardContext.Provider value={{
      leaderDashboardData,
      loading,
    }}
    >
      {children}
    </LeaderDashboardContext.Provider>
  );
};

LeaderDashboardProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export const useLeaderDashboard = () => React.useContext(LeaderDashboardContext);
