import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

function CardFooter({
  children, gridTemplateColumns, display
}) {
  return (
    <Footer gridTemplateColumns={gridTemplateColumns} display={display}>
      {children}
    </Footer>
  );
}

const Footer = styled.span`
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 5px;
  width: 100%;
  height: 18px;
  padding: 5px;
  border-radius: 4px;
  display: ${(props) => props.display};
  justify-content: space-between;
  text-align: right;
`;

CardFooter.propTypes = {
  children: PropTypes.element.isRequired,
  gridTemplateColumns: PropTypes.string,
  display: PropTypes.string
};

CardFooter.defaultProps = {
  gridTemplateColumns: '1fr',
  display: 'flex'
};

export default CardFooter;
