import React from 'react';
import styled from 'styled-components';
import BasePage from 'components/layout/BasePage';
import Icon from 'components/common/Icon';
import IconExternal from 'components/common/IconExternal';

import {
  AiOutlineShop,
  AiOutlineUser,
  AiOutlinePieChart,
  AiOutlineFileDone
} from 'react-icons/ai';
import { VscFeedback } from 'react-icons/vsc';
import { CgNotes } from 'react-icons/cg';
import { FaTasks, FaRegChartBar } from 'react-icons/fa';
import { RiHammerLine } from 'react-icons/ri';

import verifyAclUser from 'helpers/verifyAclUser';
import acl from 'helpers/acl';

function Home() {
  return (
    <>
      <BasePage>
        <Body>
          <IconsSide>
            <Icon icon={<AiOutlinePieChart />} name="Dashboard" route="/dashboard" />
            <Icon icon={<AiOutlineShop />} name="Drogarias" route="/drogarias" />
            <Icon icon={<CgNotes />} name="Diário" route="/diario" />
            <Icon icon={<FaTasks />} name="Chamados" route="/chamados" />
            {(verifyAclUser(acl.router_contracts)) && <Icon icon={<AiOutlineFileDone />} name="Contratos" route="/contratos" />}
            <Icon icon={<AiOutlineUser />} name="Usuários" route="/sistema/usuarios" />
            {((verifyAclUser(acl.router_reports)) && (
              <Icon icon={<FaRegChartBar />} name="Relatórios" route="/relatorios" />
            ))}
            <Icon icon={<RiHammerLine />} name="Notas de Atualização" route="/notasdeatualizacao" />
            <IconExternal icon={<VscFeedback />} name="Feedbacks" route="http://bit.ly/feedbacksisrmc" />
          </IconsSide>
        </Body>
      </BasePage>
    </>
  );
}

const Body = styled.div`
  margin-top: 15px;
  display: grid;
  grid-template-columns: 540px 1fr;
  gap: 10px; 
`;

const IconsSide = styled.div`
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: repeat(7, 1fr);
  gap: 10px;
  color: #fff;

  @media(max-width: 800px) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(5, 1fr);
  }

  @media(max-width: 400px) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(5, 1fr);
  }
`;

export default Home;
