import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Label from 'components/common/Label';
import Asterisk from 'components/common/Asterisk';
import MensageError from 'components/common/MensageError';

const TextAreaField = ({
  label,
  name,
  mask,
  placeholder,
  onChange,
  value,
  isRequired,
  error,
  isError,
  onBlur,
  height,
}) => (
  <Case>
    <Label htmlFor={name}>{label}</Label> {isRequired && (<Asterisk />) }
    <TextArea
      id={name}
      name={name}
      placeholder={placeholder}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      mask={mask}
      height={height}
    />
    { isError && <MensageError>{error}</MensageError> }
  </Case>
);

const TextArea = styled.textarea`
  resize: none;
  height: ${(props) => props.height};
`;

const Case = styled.div`
  margin-bottom: 5px;
`;

TextAreaField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  isRequired: PropTypes.bool,
  mask: PropTypes.string,
  isError: PropTypes.string.isRequired,
  onBlur: PropTypes.func.isRequired,
  height: PropTypes.string,
};

TextAreaField.defaultProps = {
  isRequired: false,
  mask: '',
  error: '',
  height: '150px',
};
export default TextAreaField;
