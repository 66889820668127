import React from 'react';
import { AiOutlineUser } from 'react-icons/ai';
import { BsPeople } from 'react-icons/bs';
import { FiPhoneCall } from 'react-icons/fi';

import Case from 'components/common/Case';

import Loading from 'components/common/Loading';
// import CallsDashboard from 'components/dashboard/calls/CallsDashboard';
import InfoCard from 'components/common/InfoCard';
import Refresh from 'components/common/Refresh';
import ContactDashboard from 'components/dashboard/contacts/ContactDashboard';

import { useAngelDashboard } from 'providers/Dashboard/angel';

function DashboardAngels() {
  const { angelDashboardData, loading } = useAngelDashboard();

  function setComponent() {
    if (!angelDashboardData) {
      return (<Refresh />);
    } return (
      <>
        <Loading active={loading} />
        { !loading && (
        <>
          <Case gridTemplateColumns="1fr 1fr 1fr 1fr">
            <InfoCard title="CHAMADOS ATIVOS MEUS" backgroundColor="#DD4B39" data={angelDashboardData.userActiveCalls}>
              <AiOutlineUser style={{ color: 'white' }} />
            </InfoCard>
            <InfoCard title="CHAMADOS ATIVOS SETOR" backgroundColor="#ffcd17" data={angelDashboardData.sectorActiveCalls}>
              <BsPeople style={{ color: 'white' }} />
            </InfoCard>
            <InfoCard title="TODOS CONTATOS" backgroundColor="#139F5B" data={angelDashboardData.numberOfContacts}>
              <FiPhoneCall style={{ color: 'white' }} />
            </InfoCard>
            <InfoCard title="CONTATOS TELEFONE" backgroundColor="#4285F4" data={angelDashboardData.phoneContactsOfMonth}>
              <FiPhoneCall style={{ color: 'white' }} />
            </InfoCard>
          </Case>
          <ContactDashboard data={angelDashboardData} title="MEUS CONTATOS" />
          {/* <CallsDashboard data={angelDashboardData.activeCalls} /> */}
        </>
        )}
      </>
    );
  }

  return (
    <>
      {setComponent()}
    </>
  );
}

export default DashboardAngels;
