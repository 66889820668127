import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import { useStores } from 'providers/Stores/stores';
import { useProfile } from 'providers/Stores/profile';
import { CaseIcon } from 'components/card';
import { TableIcon } from 'components/table';

import { IoCreateOutline } from 'react-icons/io5';

import Modal from 'components/common/Modal';
import EditForm from './EditForm';

const InitialEdit = ({ data, exibition }) => {
  const { update } = useStores();
  const { searchStore } = useProfile();
  const [visible, setVisible] = useState(false);

  const changeModalState = async (parameter) => {
    setVisible(parameter);
  };

  const validateData = (value, key) => {
    if (value === null) {
      return 1;
    } return value[key];
  };
  const validateTxt = (value, key) => {
    if (value === null) {
      return '';
    } return value[key];
  };
  // const validateContact = (arr, key) => {
  //   const carveup = arr.map((cel) => {
  //     if (cel.mainContact) {
  //       return cel[key];
  //     } return null;
  //   });
  //   const filterArr = carveup.filter((val) => Boolean(val));
  //   return filterArr;
  // };

  const initialValues = {
    storeName: data.storeName.toUpperCase(),
    tradeName: data.tradeName.toUpperCase(),
    phoneNumber: data.phoneNumber,
    email: data.email,
    brandId: data.brandData.id,
    angelId: data.angelData.id,
    consultantId: data.consultantData.id,
    commercialAttendantId: data.commercialAttendantData.id,

    gefad: validateData(data.storeServicesData, 'gefad'),
    gefadTxt: validateTxt(data.storeServicesData, 'gefadTxt'),
    farmacia360: validateData(data.storeServicesData, 'farmacia360'),
    farmacia360Txt: validateTxt(data.storeServicesData, 'farmacia360Txt'),
    contabilidade: validateData(data.storeServicesData, 'contabilidade'),
    contabilidadeTxt: validateTxt(data.storeServicesData, 'contabilidadeTxt'),
    clinicarx: validateData(data.storeServicesData, 'clinicarx'),
    clinicarxTxt: validateTxt(data.storeServicesData, 'clinicarxTxt'),
    precificacao: validateData(data.storeServicesData, 'precificacao'),
    precificacaoTxt: validateTxt(data.storeServicesData, 'precificacaoTxt'),
    brasilcard: validateData(data.storeServicesData, 'brasilcard'),
    brasilcardTxt: validateTxt(data.storeServicesData, 'brasilcardTxt'),
    financialConvenios: validateData(data.storeServicesData, 'financialConvenios'),
    financialConveniosTxt: validateTxt(data.storeServicesData, 'financialConveniosTxt'),
    financialCard: validateData(data.storeServicesData, 'financialCard'),
    financialCardTxt: validateTxt(data.storeServicesData, 'financialCardTxt'),
    cielo: validateData(data.storeServicesData, 'cielo'),
    cieloTxt: validateTxt(data.storeServicesData, 'cieloTxt'),
    conekta: validateData(data.storeServicesData, 'conekta'),
    conektaTxt: validateTxt(data.storeServicesData, 'conektaTxt'),
    consult: validateData(data.storeServicesData, 'consult'),
    consultTxt: validateTxt(data.storeServicesData, 'consultTxt'),
    memed: validateData(data.storeServicesData, 'memed'),
    memedTxt: validateTxt(data.storeServicesData, 'memedTxt'),
    fidelidade: validateData(data.storeServicesData, 'fidelidade'),
    fidelidadeTxt: validateTxt(data.storeServicesData, 'fidelidadeTxt'),
    mypharma: validateData(data.storeServicesData, 'mypharma'),
    mypharmaTxt: validateTxt(data.storeServicesData, 'mypharmaTxt'),
    abcfarma: validateData(data.storeServicesData, 'abcfarma'),
    abcfarmaTxt: validateTxt(data.storeServicesData, 'abcfarmaTxt'),
  };

  const onSubmit = async (values) => {
    await update(data.id, values);
    setVisible(false);
    if (exibition === 'card') {
      searchStore();
    }
  };

  const onCancel = () => {
    changeModalState(false);
  };

  const validationSchema = {
    storeName: Yup.string().required('Informe a Razão Social.'),
    tradeName: Yup.string().required('Informe o Nome Fantasia.'),
    email: Yup.string().email().required('Informe o email da drogaria'),
    phoneNumber: Yup.string().required('Informe o telefone da drogaria'),
    brandId: Yup.string().required('Informe a Bandeira.'),
    angelId: Yup.string().required('Informe o Anjo.'),
    consultantId: Yup.string().required('Informe o Consultor'),
    commercialAttendantId: Yup.string().required('Informe o Atendente Comercial'),
  };

  const title = <span style={{ textTransform: 'capitalize' }}>Editar - {data.storeName}</span>;

  return (
    <span>
      {exibition === 'card' && (
        <CaseIcon marginleft="10px">
          <IoCreateOutline title="Editar" onClick={() => changeModalState(true)} />
        </CaseIcon>
      )}
      {exibition === 'table' && (
        <TableIcon>
          <IoCreateOutline title="Editar" onClick={() => changeModalState(true)} />
        </TableIcon>
      )}
      <Modal visible={visible} onClose={() => changeModalState(false)} title={title}>
        <EditForm
          values={initialValues}
          handleSubmit={onSubmit}
          handleCancel={onCancel}
          validations={validationSchema}
        />
      </Modal>
    </span>
  );
};

InitialEdit.propTypes = {
  data: PropTypes.arrayOf(PropTypes.element).isRequired,
  exibition: PropTypes.string.isRequired,
};

export default InitialEdit;
