import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';
import Swal from 'sweetalert2';
import { GiHamburgerMenu } from 'react-icons/gi';
import { FaDownload, FaUserCircle } from 'react-icons/fa';
import { CgClose } from 'react-icons/cg';
import { RiShoppingCartFill } from 'react-icons/ri';
import { TiArrowSortedDown } from 'react-icons/ti';

import { logoutService } from 'service/LoginService';
import BANNER_CONSULTE from 'assets/images/associate/BANNER_CONSULTE.png';
import BANNER_RECUPERA from 'assets/images/associate/BANNER_RECUPERA.png';
import BANNER_ENSINOFARMA from 'assets/images/associate/BANNER_ENSINOFARMA.png';
import BANNER_ITAU from 'assets/images/associate/BANNER_PARCERIA_ITAU.jpg';

import decodeToken from 'helpers/decodeToken';
import { getToken, getStore } from 'service/StoresService';
import Modal from 'components/common/Modal';
import Button from 'components/common/Button';
import AssociateClick from 'service/AssociateService';
import FirstAccess from './FirstAccess';

function Associate() {
  const profile = decodeToken();
  const [useStore, setUseStore] = useState();
  useEffect(() => {
    const list = async () => {
      const getResult = await getStore(profile.id);
      setUseStore(getResult);
    };
    list();
  }, []);
  const angelMensage = `http://api.whatsapp.com/send?1=pt_BR&phone=+55${useStore?.angelData.phone_number}&text=Ola+quero+saber+mais+sobre+o+Consult.`;
  const [popupVisible, setPopupVisible] = useState(true);
  const [linkedfarmaToken, setLinkedfarmaToken] = useState('');
  useEffect(() => {
    const fetchData = async () => {
      const storeData = await getToken(profile.username);
      if (storeData?.linkedfarmaToken) {
        setLinkedfarmaToken(storeData.linkedfarmaToken);
      }
    };
    fetchData();
  }, []);
  const changeModalState = (parameter) => {
    setPopupVisible(parameter);
  };
  const [visible, setVisible] = useState(false);
  const [userMenuState, setUserMenuState] = useState(false);
  const route = `http://www.linkedfarma.com.br/melhorcompra?action=site&value=${linkedfarmaToken}`;
  const history = useHistory();
  const handleLogout = () => {
    Swal.fire({
      title: 'Você quer realmente sair?',
      text: 'lembre-se de salvar tudo antes de sair',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#224772',
      cancelButtonColor: '#224772',
      confirmButtonText: 'Sim, eu quero sair',
      cancelButtonText: 'Não, eu não quero sair'
    }).then((result) => {
      if (result.isConfirmed) {
        logoutService();
        history.push('/login');
      }
    });
  };

  const openMenu = (state) => {
    if (state) {
      return '';
    } return 'none';
  };

  return (
    <>
      <Modal visible={popupVisible} onClose={() => changeModalState(false)} title="Nova condição especial!">
        <ModalImage
          src={BANNER_ITAU}
          alt="Banner"
          onClick={() => window.open('https://drive.google.com/file/d/1QigpQNwZS5XA3d_2ALyg9_ioAnfO_Brh/view?usp=sharing')}
        />
        <ButtonModal>
          <Button onClick={() => changeModalState(false)}> Fechar </Button>
        </ButtonModal>
      </Modal>
      <MobileButton visible={visible} onClick={() => setVisible(true)}>
        {visible ? (
          <CloseIcon />
        ) : (
          <OpenIcon />
        )}
      </MobileButton>
      <Container visible={visible} onClick={() => setVisible(false)}>
        <NavBar>
          <MenuContainer>
            <MenuLink onClick={() => window.open('https://redemelhorcompra.academiaabrafad.com.br', '_blank')}>
              ENSINO FARMA
            </MenuLink>
            <MenuLink onClick={() => window.open('https://melhorcompra.minharadio.fm/', '_blank')}>
              RÁDIO
            </MenuLink>
            <MenuLink onClick={() => window.open(`${route}`, '_self')}>
              PEDIDOS
            </MenuLink>
            <Mobile>
              <MenuLink onClick={() => handleLogout()}>
                SAIR
              </MenuLink>
            </Mobile>
          </MenuContainer>
          <Desktop>
            <UserInfo onClick={() => setUserMenuState(!userMenuState)}>
              <UserIcon />
              <Surname>{profile.surname}</Surname>
              <ArrowIcon />
            </UserInfo>
            <Options display={openMenu(userMenuState)}>
              <Item onClick={() => handleLogout()}>Sair</Item>
            </Options>
          </Desktop>
        </NavBar>
        <Section>
          <DesktopBody>
            <CaseButton>
              <ContrastCase>
                <ContrastButton onClick={() => window.open(`${route}`, '_self')}>
                  <ShoppingIcon />
                  CLIQUE AQUI E FAÇA SEUS PEDIDOS
                </ContrastButton>
                <MarketingButton to="/loja-material-marketing">
                  <DownloadIcon />
                  DOWNLOAD MATERIAL DE MARKETING
                </MarketingButton>
              </ContrastCase>
            </CaseButton>
            <BannersArea>
              <Case display="flex">
                <BannerButton>
                  <Image
                    src={BANNER_CONSULTE}
                    alt="Banner"
                    onClick={() => { AssociateClick({ userId: profile.id, name: 'BANNER_CONSULTE' }); window.open(angelMensage); }}
                  />
                </BannerButton>
                <BannerNoButton>
                  <Image
                    src={BANNER_RECUPERA}
                    alt="Banner"
                    onClick={() => {
                      AssociateClick({ userId: profile.id, name: 'BANNER_RECUPERA' });
                      window.open('https://youtu.be/8gX58NM2_tU');
                      window.location.href = 'https://drive.google.com/file/d/1gBiGMfUyApgqtU22E4uhkTo_i1otDxBq/view?usp=sharing';
                    }}
                  />
                </BannerNoButton>
              </Case>
              <Case>
                <BannerNoButton>
                  <Image
                    src={BANNER_ENSINOFARMA}
                    alt="Banner"
                    onClick={() => {
                      AssociateClick({ userId: profile.id, name: 'BANNER_ENSINOFARMA' });
                      window.open('https://redemelhorcompra.academiaabrafad.com.br', '_blank');
                    }}
                  />
                </BannerNoButton>
              </Case>
            </BannersArea>
          </DesktopBody>
        </Section>
        <FirstAccess isFirstAcess={profile.firstLogin} userId={profile.id} />
      </Container>
      <MobileBody visible={visible}>
        <CaseButton>
          <ContrastCase>
            <ContrastButton onClick={() => window.open(`${route}`, '_self')}>
              <ShoppingIcon />
              CLIQUE AQUI E FAÇA SEUS PEDIDOS
            </ContrastButton>
            <MarketingButton to="/loja-material-marketing">
              <DownloadIcon />
              DOWNLOAD MATERIAL DE MARKETING
            </MarketingButton>
          </ContrastCase>
        </CaseButton>
        <BannersArea>
          <Case display="flex">
            <BannerButton>
              <Image
                src={BANNER_CONSULTE}
                alt="Banner"
                onClick={() => { AssociateClick({ userId: profile.id, name: 'BANNER_CONSULTE' }); window.open(angelMensage); }}
              />
            </BannerButton>
            <BannerNoButton>
              <Image
                src={BANNER_RECUPERA}
                alt="Banner"
                onClick={() => {
                  AssociateClick({ userId: profile.id, name: 'BANNER_RECUPERA' });
                  window.open('https://www.recuperacredito.com.br/');
                }}
              />
            </BannerNoButton>
          </Case>
          <Case>
            <BannerNoButton>
              <Image
                src={BANNER_ENSINOFARMA}
                alt="Banner"
                onClick={() => {
                  AssociateClick({ userId: profile.id, name: 'BANNER_ENSINOFARMA' });
                  window.open('https://redemelhorcompra.academiaabrafad.com.br', '_blank');
                }}
              />
            </BannerNoButton>
          </Case>
        </BannersArea>
      </MobileBody>
    </>
  );
}
const ButtonModal = styled.div`
  text-align: right;
  padding: 15px;
`;

const ModalImage = styled.img`
  padding: 15px;
  border-radius: 25px;
  width: 100%;
`;

const BannersArea = styled.div`
  width: 85%;
  display: grid;
  place-items: center;

 @media (max-width: 768px) {
  width: 100%;
  }

`;

const Case = styled.div`
  display: ${(props) => props.display};
  place-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
  padding: 0;
  width: 100%;

  @media (max-width: 768px) {
     display: grid;
  }

`;

const CaseButton = styled.div`
  margin-top: 1em;
  margin-bottom: 1em;
  display: flex;
  justify-content: center;
  width: 100%;
`;

const ArrowIcon = styled(TiArrowSortedDown)`
  color: white;
  position: absolute;
  right: 0px;
  margin-right: 5px;
`;

const UserIcon = styled(FaUserCircle)`
  color: white;
  font-size: 12px;
`;

const DownloadIcon = styled(FaDownload)`
color: white;
cursor: pointer;
margin-right: 5px;
`;

const ShoppingIcon = styled(RiShoppingCartFill)`
  color: white;
  cursor: pointer;
  margin-right: 5px;
`;

const ContrastCase = styled.div`
  background-color: #F3F3F3;
  padding: 1em;
  width: 85%;
  border-radius: 10px;
  display: grid;
  grid-gap: 3em;
  place-items: center;
  flex-direction: row;
  grid-template-columns: 1fr 1fr;
  
  @media (max-width: 768px) {
    margin-top: 80px;
    grid-template-columns: 1fr;
  }
`;

const BannerButton = styled.a`
  padding: 0;
  margin: 0;
  width: 85%;
  cursor: pointer;
`;

const BannerNoButton = styled.span`
  padding: 0;
  margin: 0;
  width: 85%;
  cursor: default;
`;

const MarketingButton = styled(Link)`
  font-size: 15px;
  width: 100%;
  font-family: 'Montserrat';
  font-weight: 250;
  text-align: center;
  padding: 2vh;
  background-color: #4D68A3;
  color: white;
  border-radius: 4px;
  text-decoration: none;
  cursor: pointer;

  :hover{
    text-decoration: none;
    background-color: #324682;
  }

  @media (max-width: 768px) {
    width: 85%;
    font-size: 13px;
  }

`;

const ContrastButton = styled.a`
  font-size: 15px;
  width: 100%;
  font-family: 'Montserrat';
  font-weight: 250;
  text-align: center;
  padding: 2vh;
  background-color: #4D68A3;
  color: white;
  cursor: pointer;
  border-radius: 4px;
  
  :hover{
    text-decoration: none;
    background-color: #324682;
  }

  @media (max-width: 768px) {
    width: 85%;
    font-size: 13px;
  }

`;
const UserInfo = styled.span`
  width: 280px;
  text-transform: uppercase;
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
`;
const Options = styled.div`
  position: absolute;
  top: 50px;
  right: 5px;
  z-index: 5;
  border: 1px solid #C0C0C0;
  border-radius: 0 0 6px 6px;
  display: ${(props) => props.display};
  background-color: white;
  color: grey;
`;
const Item = styled.li`
  width: 150px;
  list-style: none;
  text-align: center;
  padding: 10px 62px;
  cursor: pointer;
  :hover{
    background-color: #93D367;
    color: #fff;
  }
`;
const Mobile = styled.span``;
const Desktop = styled.span`
  cursor: pointer;
  position: absolute;
  right: 0%;
  top: 0%;
  font-family: 'Montserrat';
  font-weight: 500;
  text-decoration: none;
  font-size: 14px;
  padding: 18px 18px 18px 10px;
  color: white;
  display: block;
  :hover {
    background: #253469;
  }
`;
const Surname = styled.div`
  margin-left: 8px;
  width: 265px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CloseIcon = styled(CgClose)`
  color: white;
  cursor: pointer;
`;

const OpenIcon = styled(GiHamburgerMenu)`
  color: white;
  cursor: pointer;
`;

const Container = styled.div`
  ${({ visible }) => css`
    width: 100%;
    height: calc(100vh - 200px);
    border-bottom: #DDDDDD;
    transition: all 300ms ease-in-out;
    @media (max-width: 768px) {
      height: 0;
      visibility: hidden;
      opacity: 0;
      ${visible && 'visibility: visible; opacity: 1;'}
    }
  `}
`;

const Section = styled.div`
  padding-bottom: 0;
  @media (max-width: 768px) {
    height: 1rem;
    background: -webkit-gradient(linear, left top, right top, from(#6B8FC9), to(#324682)) no-repeat;
    background: -moz-linear-gradient(left, #6B8FC9, #324682);
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    align-items: center;
    overflow-y: auto;
  }
`;

const MobileButton = styled.button`
  ${({ visible }) => css`
    z-index: 6;
    position: fixed;
    top: 20px;
    right: 20px;
    width: 40px;
    height: 40px;
    background: #243470;
    border: 1px solid #CCCCCC;
    border-radius: 4px;
    color: white;
    display: none;
    pointer-events: ${visible ? 'none' : 'all'};
    @media (max-width: 768px) {
      display: flex;
      align-items: center;
      justify-content: center;
      pointer: cursor;
    }
    > svg {
      width: 18px;
      height: 18px;
    }
  `}
`;

const NavBar = styled.div`
  background: -webkit-gradient(linear, left top, right top, from(#6B8FC9), to(#324682)) no-repeat;
  background: -moz-linear-gradient(left, #6B8FC9, #324682);
  z-index: 5;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  ${Mobile} {
    display: none;
  }
  ${Desktop}{
    display: initial;
  }
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) {
    display: block;
    text-align: center;
    padding: 50px 0 50px 0;
    ${Mobile} {
      display: initial;
    }
    ${Desktop}{
      display: none;
    }
  }
`;

const MenuContainer = styled.div`
  display: flex;
  @media (max-width: 768px) {
    flex-flow: column wrap;
    align-content: center;
    text-align: center;
    margin-top: 2rem;
  }
`;

const MenuLink = styled.a`
  cursor: pointer;
  font-family: 'Montserrat';
  font-weight: 500;
  text-decoration: none;
  font-size: 14px;
  padding: 18px;
  color: white;
  display: block;
  position: relative;
  :hover {
    background: #253469;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const DesktopBody = styled.div`
  background-color: #F3F3F3;
  margin-top: 3.5rem;
  padding-bottom: 20px;
  display: grid;
  place-items: center;
  @media (max-width: 768px) {
    padding-top: 80px;
  }
`;

const Image = styled.img`
  display: grid;
  place-items: center;
  margin: auto;
  width: 100%;
  cursor: pointer;
`;

const MobileBody = styled.div`
  ${({ visible }) => css`
    display: none;
    @media (max-width: 768px) {
      ${!visible && 'display: initial;'}
    }
  `}
`;

export default Associate;
