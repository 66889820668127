import React, {
  useState,
  useEffect,
  createContext,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import { toastSuccess } from 'helpers/notifications';

import {
  createCompany,
  listCompany,
  updateCompany,
  deleteCompany,
  changeCompany,
} from 'service/CompanyService';

export const CompanyContext = createContext([]);

export const CompanyProvider = ({ children }) => {
  const [companyData, setCompanyData] = useState([]);
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(true);

  const create = async (values) => {
    await createCompany(values);
    setReload(!reload);
    toastSuccess('Salvo com sucesso!');
  };

  const update = async (id, value) => {
    await updateCompany(id, value);
    setReload(!reload);
    toastSuccess('Alterado com sucesso!');
  };

  const change = async (id, value) => {
    await changeCompany(id, value);
    setReload(true);
    toastSuccess('Alterado com sucesso!');
    setReload();
  };

  const deleteRegister = async (id) => {
    await deleteCompany(id);
    setReload(!reload);
    toastSuccess('Excluido com sucesso!');
  };

  const list = async () => {
    setLoading(true);
    const data = await listCompany();
    setCompanyData(data);
    setLoading(false);
  };

  useEffect(() => {
    list();
  }, [reload]);

  return (
    <CompanyContext.Provider value={{
      companyData,
      setCompanyData,
      list,
      create,
      update,
      change,
      deleteRegister,
      loading,
    }}
    >
      {children}
    </CompanyContext.Provider>
  );
};

CompanyProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export const useCompany = () => useContext(CompanyContext);
