import React, { useState } from 'react';
import * as Yup from 'yup';

import { useCompany } from 'providers/company';

import Modal from 'components/common/Modal';
import Button from 'components/common/Button';

import CompanyForm from './CompanyForm';

const CompanyCreate = () => {
  const { create } = useCompany();
  const [visible, setVisible] = useState(false);

  const initialValues = {
    storeName: '',
    tradeName: '',
    registrationNumber: '',
    zipcode: '',
    address: '',
    number: '',
    complement: '',
    neighborhood: '',
    city: '',
    state: '',
  };

  const changeModalState = (parameter) => {
    setVisible(parameter);
  };

  const onSubmit = async (values) => {
    await create(values);
    setVisible(false);
  };

  const onCancel = () => {
    changeModalState(false);
  };

  const validationSchema = {
    storeName: Yup.string().required('Informe a razão social'),
    tradeName: Yup.string().required('Informe o nome fantasia'),
    registrationNumber: Yup.string().required('Informe o CNPJ').length(18, 'Formato inválido'),
    zipcode: Yup.string().required('Informe o CEP'),
    address: Yup.string().required('Não pode ser enviado em branco'),
    city: Yup.string().required('Não pode ser enviado em branco'),
    number: Yup.number('Não aceita letras').required('Não pode ser enviado em branco'),
    neighborhood: Yup.string().required('Não pode ser enviado em branco'),
    state: Yup.string().required('Não pode ser enviado em branco'),
  };

  return (
    <>
      <Button type="button" onClick={() => changeModalState(true)}>Novo</Button>
      <Modal visible={visible} onClose={() => changeModalState(false)} title="Cadastro de empresa">
        <CompanyForm
          values={initialValues}
          handleSubmit={onSubmit}
          handleCancel={onCancel}
          validations={validationSchema}
        />
      </Modal>
    </>
  );
};

export default CompanyCreate;
