import React from 'react';
import styled from 'styled-components';
import { AiOutlineUser } from 'react-icons/ai';
import { BsPeople } from 'react-icons/bs';

import { useCalls } from 'providers/calls';
import { useUserDashboard } from 'providers/Dashboard/user';

import BasePage from 'components/layout/BasePage';
import TitleBar from 'components/layout/TitleBar';
import SwitchButton from 'components/common/SwitchButton';
import Loading from 'components/common/Loading';
import InfoCard from 'components/common/InfoCard';

import { getProfileUserType } from 'helpers/getProfileUserType';

import verifyAclUser from 'helpers/verifyAclUser';
import acl from 'helpers/acl';

import CallsCreate from './CallsCreate';
import CallsGrid from './CallsGrid';

const Calls = () => {
  const {
    endedCalls,
    setEndedCalls,
    loading,
    setOpenCalls,
    openCalls,
  } = useCalls();
  const { userDashboardData } = useUserDashboard();

  return (
    <BasePage title="Registro de Chamados">
      <DesktopMode>
        {
          verifyAclUser(acl.calls_info_cards) && (
            <CardDiv>
              <InfoCard title="CHAMADOS ABERTOS MEUS" backgroundColor="#DD4B39" data={userDashboardData.userActiveCalls}>
                <AiOutlineUser style={{ color: 'white' }} />
              </InfoCard>
              <InfoCard title="CHAMADOS ABERTOS SETOR" backgroundColor="#ffcd17" data={userDashboardData.sectorActiveCalls}>
                <BsPeople style={{ color: 'white' }} />
              </InfoCard>
            </CardDiv>
          )
        }
      </DesktopMode>
      <TopMobile>
        <CreateMobile>
          <TitleBar>
            Chamados
          </TitleBar>
          <CallsCreate />
          {(!getProfileUserType([1, 2, 15])) && (
          <div>
            <div>
              <SwitchButton widthTag="250px" tag="Abertos por mim" value={openCalls} setActiveValue={setOpenCalls} />
              <SwitchButton widthTag="200px" tag="Concluídos" value={endedCalls} setActiveValue={setEndedCalls} />
            </div>
          </div>
          )}
        </CreateMobile>
      </TopMobile>
      <CallsGrid />
      <Loading active={loading} />
    </BasePage>
  );
};

const DesktopMode = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 2fr;
  gap: 10px;
  @media(max-width: 800px) {
    display: none;
  }
`;

const CardDiv = styled.div`
  display: flex;
  gap: 25px;

  @media(max-width: 400px) {
    display: none;
  }

`;

const TopMobile = styled.div`
  margin-bottom: 12px;
  @media(min-width: 800px) {
    display: none;
  }
`;

const CreateMobile = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
`;
export default Calls;
