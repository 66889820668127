import React from 'react';
import styled from 'styled-components';

import Table from 'components/tableComponents/CompleteTable';

import { useVisitsReport } from 'providers/Reports/visits';
import { CSVLink } from 'react-csv';
import { RiFileDownloadLine } from 'react-icons/ri';

import TitleBar from 'components/layout/TitleBar';
import Loading from 'components/common/Loading';
import Refresh from 'components/common/Refresh';
import NoData from 'components/common/NoData';

const Visits = () => {
  const { visitsReportData, loading } = useVisitsReport();

  const columns = React.useMemo(
    () => [
      {
        Header: 'COD',
        accessor: 'Cod',
        defaultFilter: true,
      },
      {
        Header: 'RAZÃO SOCIAL',
        accessor: 'RazaoSocial',
        defaultFilter: true,
      },
      {
        Header: 'CIDADE',
        accessor: 'Cidade',
        defaultFilter: true,
      },
      {
        Header: 'UF',
        accessor: 'Uf',
        defaultFilter: true,
      },
      {
        Header: 'CADASTRO',
        accessor: 'Cadastro',
        defaultFilter: true,
        Cell: (i) => {
          const { cell } = i;
          const data = cell.row.original;
          return data.Cadastro;
        }
      },
      {
        Header: 'CONSULTOR',
        accessor: 'Consultor',
        defaultFilter: true,
      },
      {
        Header: 'DIAS',
        accessor: 'Dias1',
      },
      {
        Header: 'ÚLTIMA',
        accessor: 'Ultima',
        Cell: (i) => {
          const { cell } = i;
          const data = cell.row.original;
          if (data.Ultima) {
            return data.Ultima;
          }
          return '';
        }
      },
      {
        Header: 'DIAS',
        accessor: 'Dias2',
      },
      {
        Header: 'PENÚLTIMA',
        accessor: 'Penultima',
        Cell: (i) => {
          const { cell } = i;
          const data = cell.row.original;
          if (data.Penultima) {
            return data.Penultima;
          }
          return '';
        }
      },
      {
        Header: 'DIAS',
        accessor: 'Dias3',
      },
      {
        Header: 'ANTERIOR',
        accessor: 'Anterior1',
        Cell: (i) => {
          const { cell } = i;
          const data = cell.row.original;
          if (data.Anterior1) {
            return data.Anterior1;
          }
          return '';
        }
      },
      {
        Header: 'DIAS',
        accessor: 'Dias4',
      },
      {
        Header: 'ANTERIOR',
        accessor: 'Anterior2',
        Cell: (i) => {
          const { cell } = i;
          const data = cell.row.original;
          if (data.Anterior2) {
            return data.Anterior2;
          }
          return '';
        }
      },
      {
        Header: 'DIAS',
        accessor: 'Dias5',
      },
      {
        Header: 'ANTERIOR',
        accessor: 'Anterior3',
        Cell: (i) => {
          const { cell } = i;
          const data = cell.row.original;
          if (data.Anterior3) {
            return data.Anterior3;
          }
          return '';
        }
      },
    ],
    []
  );

  function setComponent() {
    if (!visitsReportData) {
      return (<Refresh />);
    } return (
      <>
        <TitleBar>
          Visitas
        </TitleBar>
        {((loading === false) && (visitsReportData.length > 0) && (
          <CSVLink data={visitsReportData} separator=";">
            <IconCase>
              <DownloadIcon title="Baixar Planilha" />
            </IconCase>
          </CSVLink>
        ))}
        <Loading active={loading} />
        {((visitsReportData.length > 0) && (loading === false) && (
          <Table data={visitsReportData} changeTableSize={1000000} columns={columns} marginTop="0px" sortOrder="visitReport" pagination={false} textMarking />
        ))}
        {(visitsReportData.length === 0) && (loading === false) && (
          <NoData />
        )}
      </>
    );
  }

  return (
    <>
      {setComponent()}
    </>
  );
};

const DownloadIcon = styled(RiFileDownloadLine)`
  font-size: 30px;
  width: 40px;
  margin-top: 5px;
  margin-right: 0px;
`;

const IconCase = styled.div`
  font-size: 23px;
  cursor: pointer;
  position: absolute;
  right: 0;
  margin-right: 2px;
  top: 110px;
`;

export default Visits;
