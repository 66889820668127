import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { format } from 'date-fns';
import {
  IoIosArrowUp,
  IoIosArrowDown
} from 'react-icons/io';

import { useCalls } from 'providers/calls';
import LoadingComponent from 'components/common/Loading';

const CallHistory = ({ callId }) => {
  const { getHistory } = useCalls();
  const [history, setHistory] = useState([]);
  const [atualPage, setAtualPage] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getHistory(callId);
      setHistory(data);
      setLoading(false);
    };
    fetchData();
  }, []);

  const handleDisplay = (state) => {
    if (state !== atualPage) {
      return 'none';
    } return '';
  };

  const handleLegth = (length) => {
    if (length === 0) {
      return 0;
    } return (length - 1);
  };

  const showPage = () => {
    const thisPage = history.length - atualPage;
    if (thisPage < 10) {
      return `0${thisPage}`;
    } return thisPage;
  };

  const showTotal = () => {
    if (history.length < 10) {
      return `0${history.length}`;
    } return history.length;
  };

  return (
    <Container>
      <LoadingComponent active={loading} />
      {((history.length === 0) && (!loading))
      && <NoData>Nenhuma interação Registrada!</NoData>}
      {((history.length !== 0) && (!loading))
      && (
      <ButtonDiv>
        <Button onClick={() => setAtualPage(atualPage + 1)} disabled={atualPage === (handleLegth(history.length))}><IoIosArrowUp style={{ color: 'white' }} /></Button>
        <span style={{ textTransform: 'lowercase', textAlign: 'center' }}>{showPage()} de {showTotal()}</span>
        <Button onClick={() => setAtualPage(atualPage - 1)} disabled={atualPage === 0}><IoIosArrowDown style={{ color: 'white' }} /></Button>
      </ButtonDiv>
      )}
      {history.map((idx, index) => (
        <InteractionDiv display={handleDisplay(index)}>
          {idx.flag === 1 && (
            <>
              <div style={{ height: '52px' }}>
                {idx.responsible && (
                <><Legend>Responsável: </Legend><span>{idx.responsibleData.name}</span></>
                )}
                <br />
                <Legend>Data: </Legend><span>{format(new Date(idx.createdAt), "dd/MM/yyyy'-'HH:mm")}</span>
              </div>
              <DescriptionArea>{idx.text}</DescriptionArea>
            </>
          )}
          {idx.flag === 2 && (
            <>
              <div style={{ height: '52px' }}>
                {idx.responsible && (
                <>
                  <Legend>Responsável atual: </Legend><span>{idx.responsibleData.name}</span>
                  <br />
                </>
                )}
                {!idx.responsible && (
                <>
                  <Legend>Setor Atual: </Legend><span>{idx.sectorData.name}</span>
                  <br />
                </>
                )}
                {idx.transferBy && (
                <>
                  <Legend>Transferido por: </Legend><span>{idx.transferByData.name}</span>
                  <br />
                </>
                )}
                <Legend>Data: </Legend><span>{format(new Date(idx.createdAt), "dd/MM/yyyy'-'HH:mm")}</span>
              </div>
              <DescriptionArea>{idx.text}</DescriptionArea>
            </>
          )}
          {((idx.flag === 3) && (idx.text)) && (
            <>
              <div style={{ height: '52px' }}>
                {idx.responsible && (
                <><Legend>Responsável: </Legend><span>{idx.responsibleData.name}</span></>
                )}
                <br />
                <Legend>Data: </Legend><span>{format(new Date(idx.createdAt), "dd/MM/yyyy'-'HH:mm")}</span>
              </div>
              <DescriptionArea>{idx.text}</DescriptionArea>
            </>
          )}
        </InteractionDiv>
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;

`;

const ButtonDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 65px;
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    padding: 4px;
  }
`;

const NoData = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 330px;
`;

const InteractionDiv = styled.div`
  padding: 3px;
  margin-top: 5px;
  display: ${(props) => props.display}
`;

const DescriptionArea = styled.div`
  border-radius: 8px;
  height: 125px;
  width: 400px;
  background-color: white;
  overflow: auto;
  padding: 5px;
`;

const Legend = styled.span`
  font-weight: bold;
`;

const Button = styled.button`
  background: #5CB85C;
  font-weight: 700;
  border: 0;
  margin: 0;
  color: #fff;
  cursor: pointer;
  height: 23px;
  width: 25px;
  border-radius: 4px;
  justify-content: flex-end;

  -webkit-box-shadow: 5px 3px 20px rgba(50, 50, 50, 0.2);
  -moz-box-shadow:    5px 3px 20px rgba(50, 50, 50, 0.2);
  box-shadow:         5px 3px 20px rgba(50, 50, 50, 0.2);

  transition: 0.3s;
  opacity: 1;
  margin: 5px;

  :disabled {
    background: #838594;
  }

  :hover{
    opacity: 0.8;
  };
`;

CallHistory.propTypes = {
  callId: PropTypes.number.isRequired,
};

export default CallHistory;
