const admin = '0|0|0';
const director = '3|3|0';
const angel = '2|5|1';
const angelLeader = '2|4|2';
const expansion = '6|10|1';
const expansionLeader = '6|9|2';
const internalConsultant = '11|19|1';
const externalConsultant = '5|8|1';
const marketingLeader = '7|11|2';
const marketing = '7|12|1';
const tiLeader = '10|17|2';
const ti = '10|18|1';
const administrative = '1|2|1';
const administrativeLeader = '1|1|2';
const pbmLeader = '9|15|2';
const pbm = '9|16|1';
const businessLeader = '4|6|2';
const business = '4|7|1';
const mentorship = '8|14|1';
const associate = '13|0|0';
const quality = '12|20|1';
const qualityLeader = '12|21|2';
const commercialAttendant = '13|22|1';
const commercialAttendantLeader = '13|23|2';

const acl = {

  // Usuários
  external_consultant: [externalConsultant],
  angel: [angel],
  internal_consultant: [internalConsultant],
  angel_leader: [angelLeader],
  expansion: [expansion],
  expansion_leader: [expansionLeader],

  // Rotas
  router_rmc: [
    admin,
    director,
    angel,
    angelLeader,
    expansion,
    expansionLeader,
    internalConsultant,
    externalConsultant,
    marketingLeader,
    marketing,
    tiLeader,
    ti,
    administrative,
    pbmLeader,
    pbm,
    business,
    businessLeader,
    mentorship,
    quality,
    qualityLeader,
    commercialAttendant,
    commercialAttendantLeader,
    administrativeLeader
  ],

  router_assiciate: [
    associate
  ],

  router_company: [admin, director],
  router_reports: [
    admin,
    director,
    angelLeader,
    angel,
    externalConsultant,
    internalConsultant,
    pbmLeader,
    qualityLeader,
    quality,
    tiLeader,
    qualityLeader,
    commercialAttendantLeader,
    administrativeLeader
  ],
  router_contacts: [admin, director, angelLeader, pbmLeader, qualityLeader],
  router_visits: [
    admin,
    director,
    externalConsultant,
    internalConsultant,
    angelLeader,
    pbmLeader,
    qualityLeader
  ],
  router_visit_consultant: [admin, director, angelLeader, pbmLeader, qualityLeader],
  router_store_contacts: [admin, director],
  router_contracts: [
    admin,
    administrative,
    administrativeLeader,
    director,
    externalConsultant,
    internalConsultant,
    expansionLeader,
    expansion
  ],

  // Home
  home_dashboard: [
    internalConsultant,
    expansion,
    admin,
    director,
    angelLeader,
    tiLeader,
    businessLeader,
    business,
    marketingLeader,
    ti,
    pbmLeader,
    administrative,
    administrativeLeader,
    expansionLeader,
    pbm,
    mentorship,
    marketing,
    angel,
    externalConsultant,
    quality,
    qualityLeader,
    commercialAttendant,
    commercialAttendantLeader,
  ],
  // Dashboards
  dashboard_director: [admin, director, qualityLeader],
  dashboard_leader: [
    tiLeader,
    businessLeader,
    marketingLeader,
    commercialAttendantLeader,
    administrativeLeader
  ],
  dashboard_angel_leader: [angelLeader],
  dashboard_expansion_leader: [expansionLeader],
  dashboard_angel: [angel],
  dashboard_expansion: [expansion],
  dashboard_internal_consultant: [internalConsultant],
  dashboard_user: [
    ti,
    pbmLeader,
    administrative,
    pbm,
    mentorship,
    marketing,
    externalConsultant,
    angel,
    business,
    quality,
    commercialAttendant,
  ],

  // Diário
  diary_contact: [angel, angelLeader],

  // Relatorios
  report_visits: [
    admin,
    director,
    externalConsultant,
    internalConsultant,
    angelLeader,
    pbmLeader,
    qualityLeader,
    qualityLeader,
  ],
  report_visit_consultant: [admin, director, angelLeader, pbmLeader, qualityLeader],
  report_contacts: [
    admin,
    director,
    angelLeader,
    internalConsultant,
    angel,
    pbmLeader,
    qualityLeader
  ],
  report_store_contacts: [admin, director, qualityLeader],
  router_stores: [
    angelLeader,
    angel,
    externalConsultant,
    internalConsultant,
    pbmLeader,
    commercialAttendantLeader,
    administrativeLeader
  ],
  router_stores_admin: [
    admin,
    director,
    qualityLeader,
    tiLeader,
    quality
  ],
  report_stores: [
    angelLeader,
    angel,
    externalConsultant,
    internalConsultant,
    pbmLeader,
    commercialAttendantLeader,
    administrativeLeader
  ],
  report_stores_admin: [
    admin,
    director,
    qualityLeader,
    tiLeader,
    quality
  ],

  calls_info_cards: [
    externalConsultant,
    internalConsultant,
    expansion,
    angelLeader,
    tiLeader,
    business,
    businessLeader,
    marketingLeader,
    ti,
    pbmLeader,
    administrative,
    administrativeLeader,
    expansionLeader,
    pbm,
    mentorship,
    marketing,
    angel,
    quality,
    qualityLeader,
    commercialAttendant,
    commercialAttendantLeader,
  ],

  // Perfil
  disable_store: [admin, director],
  documents: [admin, administrative, administrativeLeader],
  comments: [admin,
    director,
    angel,
    internalConsultant,
    externalConsultant,
    administrative,
    administrativeLeader
  ],

  // Botões
  create_store: [admin, director, administrative, administrativeLeader],

  // Sistema
  parametrization: [angelLeader, admin],
  documentation: [admin],
  service_upload: [angelLeader, admin],
  manipulate_store_contact: [
    admin,
    angel,
    administrative,
    director,
    internalConsultant,
    administrativeLeader
  ],
  manipulate_linked_token: [admin, business, businessLeader, commercialAttendantLeader]

};

export default acl;
