import React from 'react';
import styled from 'styled-components';
import BasePage from 'components/layout/BasePage';
import TitleBar from 'components/layout/TitleBar';

import { useStores } from 'providers/Stores/stores';

import Loading from 'components/common/Loading';
import LoadingBlocked from 'components/common/LoadingBlocked';

import StoresGrid from './StoresGrid';

require('assets/style/SwitchButton.css');

const Stores = () => {
  const { loading, loadingBlocked } = useStores();

  return (
    <BasePage>
      <TopMobile>
        <CreateMobile>
          <TitleBar>
            Drogarias
          </TitleBar>
        </CreateMobile>
      </TopMobile>
      <StoresGrid />
      <Loading active={loading} />
      <LoadingBlocked active={loadingBlocked} />
    </BasePage>
  );
};

const TopMobile = styled.div`
  margin-bottom: 12px;
  @media(min-width: 800px) {
    display: none;
  }
`;

const CreateMobile = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
`;

export default Stores;
