import React, { useState } from 'react';
import styled from 'styled-components';

import {
  BsFillGridFill
} from 'react-icons/bs';

import {
  FaThList
} from 'react-icons/fa';

import IconCase from '../../components/skilled/TopPage/IconCase';

import DiaryCreate from './DiaryCreate';
import DiaryTable from './DiaryTable';
import DiaryCard from './DiaryCard';

const DiaryGrid = () => {
  const [tabletyle, setTaableStyle] = useState(false);

  const previewStyle = (value) => {
    setTaableStyle(value);
  };

  const setColor = (parameter, style) => {
    if (parameter === 'table') {
      if (style) {
        return '';
      } return '#224772';
    }
    if (!style) {
      return '';
    } return '#224772';
  };

  return (
    <>
      <DesktopMode>
        <Grid>
          <DiaryCreate />
          <IconCase>
            <TableIcon title="Tabela" color={setColor('table', tabletyle)} onClick={() => previewStyle(false)} />
          </IconCase>
          <IconCase>
            <BsFillGridFill title="Card" color={setColor('card', tabletyle)} onClick={() => previewStyle(true)} />
          </IconCase>
        </Grid>
        { tabletyle && (
          <DiaryCard />
        )}
        { !tabletyle && (
          <DiaryTable />
        )}
      </DesktopMode>
      <PhoneMode>
        <DiaryCard />
      </PhoneMode>
    </>
  );
};

const Grid = styled.div`
  display: flex;
  justify-content: flex-end;
 @media(max-width: 800px) {
   display: none;
 }
`;

const DesktopMode = styled.div`
  @media(max-width: 800px) {
    display: none;
  }
`;

const PhoneMode = styled.div`
  @media(min-width: 800px) {
    display: none;
  }
`;

const TableIcon = styled(FaThList)`
  color: ${(props) => props.color};
`;

export default DiaryGrid;
