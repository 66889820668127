import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import { DualRing } from 'react-spinners-css';
import parse from 'html-react-parser';

import { toastError } from 'helpers/notifications';

import { useStores } from 'providers/Stores/stores';
import { useProfile } from 'providers/Stores/profile';
import { streamPDF } from 'service/ContractService';

import FieldSection from 'components/common/FieldSection';
import SelectField from 'components/common/SelectField';
import Case from 'components/common/Case';
import Button from 'components/common/Button';

const Active = ({ oldStoreData }) => {
  const {
    changeStatus,
    activeStoreData,
    setActiveModalState,
    angels,
    consultant,
  } = useStores();
  const { searchStore } = useProfile();

  const [contractLoader, setContractLoader] = useState(true);
  const [contractText, setContractText] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      const text = await streamPDF(activeStoreData.id);
      if (text) {
        setContractText(text);
      } else {
        toastError('Algo deu errado com a geração do contrato!');
      }
      setContractLoader(false);
    }; fetchData();
  }, []);

  const values = {
    angelId: oldStoreData.angelData.id,
    consultantId: oldStoreData.consultantData.id,
    brandId: activeStoreData.brand,
    contractId: activeStoreData.id,
    services: activeStoreData.services,
    userId: activeStoreData.userId
  };

  const validations = {
    angelId: Yup.string().required('Informe o Anjo'),
    consultantId: Yup.string().required('Informe o Consultor'),
    brandId: Yup.string().required('Informe a Bandeira'),
  };

  const handleSubmit = async (dataValues) => {
    const data = dataValues;
    data.obj = 'active';
    await changeStatus(oldStoreData.id, data);
    setActiveModalState(0);
    searchStore();
  };

  const formik = useFormik({
    initialValues: values,
    validationSchema: Yup.object(validations),
    onSubmit: handleSubmit,
  });

  return (
    <Body>
      <Container>
        {contractLoader && (<LoadingDiv><DualRing color="#5CB85C" size="60" /></LoadingDiv>)}
        {parse(contractText)}
      </Container>
      <Form onSubmit={formik.handleSubmit}>
        <FieldSection title="Dados de Controle">
          <Case gridTemplateColumns="1fr 1fr">
            <SelectField
              id="angelId"
              name="angelId"
              label="Anjo"
              value={formik.values.angelId}
              onBlur={formik.handleBlur}
              onChange={(e) => {
                formik.handleChange(e);
              }}
              options={angels}
              error={formik.errors.angelId}
              isRequired
              isError={formik.errors.angelId && formik.touched.angelId}
            />
            <SelectField
              id="consultantId"
              name="consultantId"
              label="Consultor"
              value={formik.values.consultantId}
              onBlur={formik.handleBlur}
              onChange={(e) => {
                formik.handleChange(e);
              }}
              options={consultant}
              error={formik.errors.consultantId}
              isRequired
              isError={formik.errors.consultantId && formik.touched.consultantId}
            />
          </Case>
        </FieldSection>
        <ButtonDiv>
          <Button type="submit">
            Ativar
          </Button>
        </ButtonDiv>
      </Form>
    </Body>
  );
};

const Container = styled.div`
  height: 570px;
  overflow: scroll;
  color: black;
  padding: 50px;
  height: 500;
  width: 790px;
`;

const LoadingDiv = styled.div`
  padding-top: 250px;
  padding-left: 395px;
`;

const Form = styled.form`  
  padding: 5px;
  overflow: auto;
`;

const ButtonDiv = styled.div`
  text-align: right;
`;

const Body = styled.div`
  overflow: scroll;
  max-height: 600px;
`;

Active.propTypes = {
  oldStoreData: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default Active;
