import React from 'react';

import PropTypes from 'prop-types';

import AreaChart from 'components/common/AreaChart';
import CardChart from 'components/common/CardChart';
import Case from 'components/common/Case';
import BarChart from 'components/chart/BarChart';
import BarChartRangeFilter from 'components/chart/BarChartRangeFilter';
import PieChart from 'components/chart/PieChart';

function StoreExpansionDashboard({ data }) {
  return (
    <>
      <AreaChart title="PERFORMANCE">
        <Case gridTemplateColumns="2fr 1fr">
          <CardChart title="Motivos de desligamento">
            <PieChart data={data.reasonsInactive} label={['Motivos', 'Quantidade']} width="600px" />
          </CardChart>
          <CardChart title="Lojas por bandeiras">
            <PieChart data={data.storesByBrand} label={['Bandeiras', 'Quantidade']} width="400px" />
          </CardChart>
        </Case>
        <Case gridTemplateColumns="1fr">
          <CardChart title="Lojas nos últimos 6 meses">
            <BarChart data={data.storesByPeriod} label={['', 'Novas', 'Desativas']} width="1050px" colorLabel={['#4285F4', '#DB4538']} />
          </CardChart>
          <CardChart title="Lojas ativas por estado">
            <BarChartRangeFilter data={data.storesByState} maxValue={data.activeAllStores} label={['', 'Ativas']} width="1050px" height="400px" colorLabel={['#4285F4']} />
          </CardChart>
        </Case>
      </AreaChart>
    </>
  );
}

StoreExpansionDashboard.propTypes = {
  data: PropTypes.element.isRequired,
};

export default StoreExpansionDashboard;
