import axios from 'axios';
import { API } from 'helpers/constant';

const createCall = async (data) => {
  await axios.post(`${API}/calls`, data)
    .then((res) => res.data)
    .catch((error) => error);
};

const getCalls = (id) => axios.get(`${API}/calls/${id}`)
  .then((res) => res.data)
  .catch((error) => error);

const listCalls = () => axios.get(`${API}/calls`)
  .then((res) => res.data)
  .catch((error) => error);

const updateCall = async (id, data) => {
  await axios.put(`${API}/calls/${id}`, data)
    .then((res) => res.data)
    .catch((error) => error);
};

const reopenCall = async (id, data) => {
  await axios.put(`${API}/calls/reopen/${id}`, data)
    .then((res) => res.data)
    .catch((error) => error);
};

const transferCall = async (id, data) => {
  await axios.put(`${API}/calls/transfer/${id}`, data)
    .then((res) => res.data)
    .catch((error) => error);
};

const interactionsCall = async (id, data) => {
  await axios.put(`${API}/calls/interactions/${id}`, data)
    .then((res) => res.data)
    .catch((error) => error);
};

const getCallHistory = (id) => axios.get(`${API}/calls/history/${id}`)
  .then((res) => res.data)
  .catch((error) => error);

const getCallLog = (id) => axios.get(`${API}/calls/log/${id}`)
  .then((res) => res.data)
  .catch((error) => error);

const deleteCall = async (id) => {
  await axios.delete(`${API}/calls/${id}`)
    .then((res) => res.data)
    .catch((error) => error);
};

const getCallsTypes = () => axios.get(`${API}/callsTypes`)
  .then((res) => res.data)
  .catch((error) => error);

const getCallStatus = () => axios.get(`${API}/callstatus`)
  .then((res) => res.data)
  .catch((error) => error);

const listByIdAndUserType = (userid, usertypeid) => axios.get(`${API}/calls/userid/${userid}/usertype/${usertypeid}`)
  .then((res) => res.data)
  .catch((error) => error);

const countCalls = () => axios.post(`${API}/calls/count`)
  .then((res) => res.data)
  .catch((error) => error);

export {
  createCall,
  getCalls,
  listCalls,
  updateCall,
  deleteCall,
  getCallsTypes,
  listByIdAndUserType,
  getCallStatus,
  transferCall,
  getCallHistory,
  interactionsCall,
  reopenCall,
  getCallLog,
  countCalls
};
