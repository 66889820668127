import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const List = ({ children, listStyleType }) => (
  <ListComponent listStyleType={listStyleType}>{children}</ListComponent>
);

const ListComponent = styled.li`
  list-style-type: ${(props) => props.listStyleType};
`;

List.propTypes = {
  children: PropTypes.element.isRequired,
  listStyleType: PropTypes.string,
};

List.defaultProps = {
  listStyleType: 'none',
};

export default List;
