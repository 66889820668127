import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { useOptions } from 'providers/Contracts/options';

import fetchAddress from 'service/ViaCepService';

import SelectField from 'components/common/SelectField';
import InputField from 'components/common/InputField';
import SelectBoolField from 'components/common/SelectBoolField';
import TextAreaField from 'components/common/TextAreaField';
import FieldSection from 'components/common/FieldSection';
import CheckBooleanField from 'components/common/CheckBooleanField';

import SearchStore from 'components/skilled/SearchStore';
import Button from 'components/common/Button';
import ButtonCase from 'components/common/ButtonCase';
import CancelButton from 'components/common/CancelButton';

import Contacts from './Contacts';

const ContractsForm = ({
  validations,
  handleSubmit,
  handleCancel,
  values
}) => {
  const { brands, stores, systems } = useOptions();

  const [visibleFields, setVisibleFields] = useState(values.economicGroup);
  const welcomeRef = useRef({ value: values.welcome });
  const pbmRef = useRef({ value: values.pbm });
  const comercialRef = useRef({ value: values.comercial });
  const obsAngelRef = useRef({ value: values.obsAngel });
  const obsAdministrativoRef = useRef({ value: values.administrativo });
  const obsMentoringRef = useRef({ value: values.mentoring });
  const obsMarketingRef = useRef({ value: values.marketing });

  const handleWlcome = useCallback(() => {
    welcomeRef.current.value = !welcomeRef.current.value;
  }, []);
  const handlePbm = useCallback(() => {
    pbmRef.current.value = !pbmRef.current.value;
  }, []);
  const handleComercial = useCallback(() => {
    comercialRef.current.value = !comercialRef.current.value;
  }, []);
  const handleObsAngel = useCallback(() => {
    obsAngelRef.current.value = !obsAngelRef.current.value;
  }, []);
  const handleObsAdministrativo = useCallback(() => {
    obsAdministrativoRef.current.value = !obsAdministrativoRef.current.value;
  }, []);
  const handleObsMentoring = useCallback(() => {
    obsMentoringRef.current.value = !obsMentoringRef.current.value;
  }, []);
  const handleObsMarketing = useCallback(() => {
    obsMarketingRef.current.value = !obsMarketingRef.current.value;
  }, []);

  const formik = useFormik({
    initialValues: values,
    onSubmit: handleSubmit,
    validationSchema: Yup.object(validations),
  });

  return (
    <>
      <Form>
        <FieldSection title="Dados de Drogaria">
          <SelectField
            id="brandId"
            name="brandId"
            label="Bandeira"
            value={formik.values.brandId}
            onBlur={formik.handleBlur}
            onChange={(e) => {
              formik.handleChange(e);
            }}
            options={brands}
            error={formik.errors.brandId}
            isRequired
            isError={formik.errors.brandId && formik.touched.brandId}
          />
          <InputField
            id="storeName"
            name="storeName"
            label="Razão Social:"
            placeholder="Razão Social"
            isRequired
            type="text"
            error={formik.errors.storeName}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.storeName}
            isError={formik.errors.storeName && formik.touched.storeName}
          />
          <InputField
            id="tradeName"
            name="tradeName"
            label="Nome Fantasia:"
            placeholder="Nome Fantasia"
            isRequired
            type="text"
            error={formik.errors.tradeName}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.tradeName}
            isError={formik.errors.tradeName && formik.touched.tradeName}
          />
          <InputField
            id="registrationNumber"
            name="registrationNumber"
            label="CNPJ:"
            placeholder="XX.XXX.XXX/XXXX-XX"
            isRequired
            type="text"
            error={formik.errors.registrationNumber}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.registrationNumber}
            isError={formik.errors.registrationNumber && formik.touched.registrationNumber}
            mask="99.999.999/9999-99"
          />
          <InputField
            id="phoneNumber"
            name="phoneNumber"
            label="Telefone da Drogaria:"
            placeholder="(00)9 0000-0000"
            isRequired
            type="text"
            error={formik.errors.phoneNumber}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.phoneNumber}
            isError={formik.errors.phoneNumber && formik.touched.phoneNumber}
            mask="(99)9 9999-9999"
          />
          <InputField
            id="email"
            name="email"
            label="Email da Drogaria:"
            placeholder="exemplo@exemplo.com"
            isRequired
            type="text"
            error={formik.errors.email}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.email}
            isError={formik.errors.email && formik.touched.email}
          />
          <SelectBoolField
            id="economicGroup"
            name="economicGroup"
            label="Possui grupo econômico?"
            value={formik.values.economicGroup}
            onBlur={formik.handleBlur}
            onChange={(e) => {
              formik.handleChange(e);
              setVisibleFields(e.target.value);
            }}
            error={formik.errors.economicGroup}
            isRequired
            isError={formik.errors.economicGroup && formik.touched.economicGroup}
          />
          {(visibleFields === '1') && (
            <SearchStore name="storeMainId" storeName={stores.storeName} storeId={stores.legacyCode} label="Selecione a drogaria principal:" />
          )}
          <SelectField
            id="system"
            name="system"
            label="Sistema"
            value={formik.values.system}
            onBlur={formik.handleBlur}
            onChange={(e) => {
              formik.handleChange(e);
            }}
            options={systems}
            error={formik.errors.system}
            isRequired
            isError={formik.errors.system && formik.touched.system}
          />
        </FieldSection>
        <FieldSection title="Dados de Endereço">
          <InputField
            id="zipcode"
            name="zipcode"
            label="CEP:"
            placeholder="00000-000"
            isRequired
            type="text"
            error={formik.errors.zipcode}
            onBlur={formik.handleBlur}
            onChange={(e) => {
              formik.handleChange(e);
              fetchAddress(e, formik.setFieldValue);
            }}
            value={formik.values.zipcode}
            isError={formik.errors.zipcode && formik.touched.zipcode}
          />
          <InputField
            id="address"
            name="address"
            label="Logradouro:"
            placeholder="Rua, Avenida ..."
            isRequired
            type="text"
            error={formik.errors.address}
            onBlur={formik.handleBlur}
            onChange={(e) => {
              formik.handleChange(e);
            }}
            value={formik.values.address}
            isError={formik.errors.address && formik.touched.address}
          />
          <InputField
            id="number"
            name="number"
            label="Número:"
            placeholder="000"
            isRequired
            type="text"
            error={formik.errors.number}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.number}
            isError={formik.errors.number && formik.touched.number}
          />
          <InputField
            id="complement"
            name="complement"
            label="Complemento:"
            placeholder="Complemento"
            type="text"
            error={formik.errors.complement}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.complement}
            isError={formik.errors.complement && formik.touched.complement}
          />
          <InputField
            id="neighborhood"
            name="neighborhood"
            label="Bairro:"
            placeholder="Bairro"
            isRequired
            type="text"
            error={formik.errors.neighborhood}
            onBlur={formik.handleBlur}
            onChange={(e) => {
              formik.handleChange(e);
            }}
            value={formik.values.neighborhood}
            isError={formik.errors.neighborhood && formik.touched.neighborhood}
          />
          <InputField
            id="state"
            name="state"
            label="UF:"
            placeholder="Estado"
            isRequired
            type="text"
            error={formik.errors.state}
            onBlur={formik.onBlur}
            onChange={(e) => {
              formik.handleChange(e);
            }}
            value={formik.values.state}
            isError={formik.errors.state && formik.touched.state}
          />
          <InputField
            id="city"
            name="city"
            label="Cidade:"
            placeholder="Cidade"
            isRequired
            type="text"
            error={formik.errors.city}
            onBlur={formik.onBlur}
            onChange={(e) => {
              formik.handleChange(e);
            }}
            value={formik.values.city}
            isError={formik.errors.city && formik.touched.city}
          />
        </FieldSection>
        <FieldSection title="Sócio Administrador(Quem Assina)">
          <InputField
            id="ownerName"
            name="ownerName"
            label="Nome do administrador:"
            placeholder="Nome Sobrenome"
            isRequired
            type="text"
            error={formik.errors.ownerName}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.ownerName}
            isError={formik.errors.ownerName && formik.touched.ownerName}
          />
          <InputField
            id="ownerNationalNumber"
            name="ownerNationalNumber"
            label="CPF do administrador:"
            placeholder="000.000.000-00"
            isRequired
            type="text"
            error={formik.errors.ownerNationalNumber}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.ownerNationalNumber}
            isError={formik.errors.ownerNationalNumber && formik.touched.ownerNationalNumber}
            mask="999.999.999-99"
          />
          <InputField
            id="ownerEmail"
            name="ownerEmail"
            label="Email do administrador:"
            placeholder="exemplo@exemplo.com"
            isRequired
            type="text"
            error={formik.errors.ownerEmail}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.ownerEmail}
            isError={formik.errors.ownerEmail && formik.touched.ownerEmail}
          />
          <InputField
            id="ownerPhoneNumber"
            name="ownerPhoneNumber"
            label="Telefone do administrador:"
            placeholder="(00) 9 0000-0000"
            isRequired
            type="text"
            error={formik.errors.ownerPhoneNumber}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.ownerPhoneNumber}
            isError={formik.errors.ownerPhoneNumber && formik.touched.ownerPhoneNumber}
            mask="(99) 9 9999-9999"
          />
        </FieldSection>
        <FieldSection title="Dados do contrato">
          <InputField
            id="startDate"
            name="startDate"
            label="Data de início:"
            placeholder="01/01/2000"
            isRequired
            type="text"
            error={formik.errors.startDate}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.startDate}
            isError={formik.errors.startDate && formik.touched.startDate}
          />
        </FieldSection>
      </Form>
      <ContainerContacts>
        <FieldSection title="Contatos">
          <Contacts />
        </FieldSection>
      </ContainerContacts>
      <Form>
        <FieldSection title="Comentários">
          <TextAreaField
            id="comments"
            name="comments"
            placeholder="Observação exemplo"
            error={formik.errors.comments}
            onBlur={formik.handleBlur}
            onChange={(e) => {
              formik.handleChange(e);
            }}
            value={formik.values.comments}
            isError={formik.errors.comments && formik.touched.comments}
          />
        </FieldSection>
        <FieldSection title="Chamados">
          <CheckBooleanField
            id="administrativo"
            name="administrativo"
            label="Administrativo"
            type="checkbox"
            error={formik.errors.administrativo}
            onBlur={formik.handleBlur}
            onChange={(e) => {
              formik.handleChange(e);
              handleObsAdministrativo();
            }}
            value={formik.values.administrativo}
          />
          {obsAdministrativoRef.current.value && (
          <InputField
            id="administrativoDescription"
            name="administrativoDescription"
            label="Descrição"
            type="text"
            error={formik.errors.administrativoDescription}
            onBlur={formik.handleBlur}
            onChange={(e) => {
              formik.handleChange(e);
            }}
            value={formik.values.administrativoDescription}
            isError={
              formik.errors.administrativoDescription && formik.touched.administrativoDescription
            }
          />
          )}
          <CheckBooleanField
            id="welcome"
            name="welcome"
            label="Boas Vindas (Consultor)"
            type="checkbox"
            error={formik.errors.welcome}
            onBlur={formik.handleBlur}
            onChange={(e) => {
              formik.handleChange(e);
              handleWlcome();
            }}
            value={formik.values.welcome}
          />
          {welcomeRef.current.value && (
          <InputField
            id="welcomeDescription"
            name="welcomeDescription"
            label="Descrição"
            type="text"
            error={formik.errors.welcomeDescription}
            onBlur={formik.handleBlur}
            onChange={(e) => {
              formik.handleChange(e);
            }}
            value={formik.values.welcomeDescription}
            isError={formik.errors.welcomeDescription && formik.touched.welcomeDescription}
          />
          )}
          <CheckBooleanField
            id="comercial"
            name="comercial"
            label="Comercial"
            type="checkbox"
            error={formik.errors.comercial}
            onBlur={formik.handleBlur}
            onChange={(e) => {
              formik.handleChange(e);
              handleComercial();
            }}
            value={formik.values.comercial}
          />
          {comercialRef.current.value && (
          <InputField
            id="comercialDescription"
            name="comercialDescription"
            label="Descrição"
            type="text"
            error={formik.errors.comercialDescription}
            onBlur={formik.handleBlur}
            onChange={(e) => {
              formik.handleChange(e);
            }}
            value={formik.values.comercialDescription}
            isError={formik.errors.comercialDescription && formik.touched.comercialDescription}
          />
          )}
          <CheckBooleanField
            id="marketing"
            name="marketing"
            label="Marketing"
            type="checkbox"
            error={formik.errors.marketing}
            onBlur={formik.handleBlur}
            onChange={(e) => {
              formik.handleChange(e);
              handleObsMarketing();
            }}
            value={formik.values.marketing}
          />
          {obsMarketingRef.current.value && (
          <InputField
            id="marketingDescription"
            name="marketingDescription"
            label="Descrição"
            type="text"
            error={formik.errors.marketingDescription}
            onBlur={formik.handleBlur}
            onChange={(e) => {
              formik.handleChange(e);
            }}
            value={formik.values.marketingDescription}
            isError={
              formik.errors.marketingDescription && formik.touched.marketingDescription
            }
          />
          )}
          <CheckBooleanField
            id="mentoring"
            name="mentoring"
            label="Mentoria"
            type="checkbox"
            error={formik.errors.mentoring}
            onBlur={formik.handleBlur}
            onChange={(e) => {
              formik.handleChange(e);
              handleObsMentoring();
            }}
            value={formik.values.mentoring}
          />
          {obsMentoringRef.current.value && (
          <InputField
            id="mentoringDescription"
            name="mentoringDescription"
            label="Descrição"
            type="text"
            error={formik.errors.mentoringDescription}
            onBlur={formik.handleBlur}
            onChange={(e) => {
              formik.handleChange(e);
            }}
            value={formik.values.mentoringDescription}
            isError={
              formik.errors.mentoringDescription && formik.touched.mentoringDescription
            }
          />
          )}
          <CheckBooleanField
            id="obsAngel"
            name="obsAngel"
            label="Observações para o Anjo"
            type="checkbox"
            error={formik.errors.obsAngel}
            onBlur={formik.handleBlur}
            onChange={(e) => {
              formik.handleChange(e);
              handleObsAngel();
            }}
            value={formik.values.obsAngel}
          />
          {obsAngelRef.current.value && (
          <InputField
            id="obsAngelDescription"
            name="obsAngelDescription"
            label="Descrição"
            type="text"
            error={formik.errors.obsAngelDescription}
            onBlur={formik.handleBlur}
            onChange={(e) => {
              formik.handleChange(e);
            }}
            value={formik.values.obsAngelDescription}
            isError={formik.errors.obsAngelDescription && formik.touched.obsAngelDescription}
          />
          )}
          <CheckBooleanField
            id="pbm"
            name="pbm"
            label="Pbm"
            type="checkbox"
            error={formik.errors.pbm}
            onBlur={formik.handleBlur}
            onChange={(e) => {
              formik.handleChange(e);
              handlePbm();
            }}
            value={formik.values.pbm}
          />
          {pbmRef.current.value && (
          <InputField
            id="pbmDescription"
            name="pbmDescription"
            label="Descrição"
            type="text"
            error={formik.errors.pbmDescription}
            onBlur={formik.handleBlur}
            onChange={(e) => {
              formik.handleChange(e);
            }}
            value={formik.values.pbmDescription}
            isError={formik.errors.pbmDescription && formik.touched.pbmDescription}
          />
          )}
        </FieldSection>
      </Form>
      <ButtonCase>
        <CancelButton onClose={handleCancel}>Cancelar</CancelButton>
        <Button onClick={() => formik.handleSubmit()}>Salvar</Button>
      </ButtonCase>
    </>
  );
};

const Form = styled.form`
  margin: 0 auto;
  width: 95%;
`;

const ContainerContacts = styled.div`
  margin: 0 auto;
  width: 95%;
`;

ContractsForm.propTypes = {
  validations: PropTypes.objectOf(PropTypes.any).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  values: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ContractsForm;
