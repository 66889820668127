import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CSVLink } from 'react-csv';
import { RiFileDownloadLine } from 'react-icons/ri';

import Table from 'components/tableComponents/CompleteTable';
import TitleBar from 'components/layout/TitleBar';
import Case from 'components/common/Case';
import { cnpjMask, phoneMask } from 'helpers/Mask';

const Financial = ({ financialData }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: 'COD',
        accessor: 'Cod',
        defaultFilter: true,
      },
      {
        Header: 'Razão Social',
        accessor: 'RazaoSocial',
        defaultFilter: true
      },
      {
        Header: 'CNPJ',
        accessor: 'CNPJ',
        defaultFilter: true,
        Cell: (i) => {
          const { cell } = i;
          const data = cell.row.original;
          return <span>{cnpjMask(data.CNPJ)}</span>;
        }
      },
      {
        Header: 'Anjo',
        accessor: 'Anjo',
        defaultFilter: true
      },
      {
        Header: 'Telefone',
        accessor: 'Telefone',
        defaultFilter: true,
        Cell: (i) => {
          const { cell } = i;
          const data = cell.row.original;
          return <span>{phoneMask(data.Telefone)}</span>;
        }
      },
      {
        Header: 'Email',
        accessor: 'Email',
        defaultFilter: true,
        Cell: (i) => {
          const { cell } = i;
          const data = cell.row.original;
          return <Span>{data.Email}</Span>;
        }
      },
    ],
    []
  );

  function setComponent() {
    return (
      <>
        <TitleBar>
          Contatos Financeiro
        </TitleBar>
        <SearchBox>
          <Case gridTemplateColumns="1fr 1fr 1fr 1fr" justifyContent="flex-end" display="flex">
            {((financialData.length > 0) && (
              <CSVLink data={financialData} separator=";">
                <IconCase>
                  <DownloadIcon title="Baixar Planilha" />
                </IconCase>
              </CSVLink>
            ))}
          </Case>
        </SearchBox>
        {((financialData.length > 0) && (
          <Table data={financialData} changeTableSize={50} columns={columns} marginTop="0px" filename="my-file.csv" />
        ))}
        {(financialData.length === 0) && (
          'Carregando...'
        )}
      </>
    );
  }

  return (
    <>
      {setComponent()}
    </>
  );
};

const Span = styled.div`
  text-transform: lowercase;
`;

const DownloadIcon = styled(RiFileDownloadLine)`
  font-size: 30px;
  width: 40px;
  margin-top: 5px;
  margin-right: 0px;
`;

const IconCase = styled.div`
  font-size: 23px;
  cursor: pointer;
`;

const SearchBox = styled.div`
  position: absolute;
  right: 0;
  margin-right: 2px;
  top: 110px;
`;

Financial.propTypes = {
  financialData: PropTypes.element.isRequired,
};

export default Financial;
