import React, {
  useState,
  useEffect,
  createContext,
  useContext
} from 'react';
import PropTypes from 'prop-types';

import { getAllStoresAdmin } from 'service/ReportService';

import verifyAclUser from 'helpers/verifyAclUser';
import acl from 'helpers/acl';

export const StoresAdminReportContext = createContext({});

export const StoresAdminReportProvider = ({ children }) => {
  const [storesAdminReportData, setStoresAdminReportData] = useState([]);
  const [loading, setLoading] = useState(true);

  const list = async () => {
    setLoading(true);
    let data = [];
    try {
      if (verifyAclUser(acl.report_stores_admin) || verifyAclUser(acl.report_stores_admin)) {
        data = await getAllStoresAdmin();
      }
      setStoresAdminReportData(data);
    } catch {
      setStoresAdminReportData(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      list();
    };
    fetchData();
  }, []);

  return (
    <StoresAdminReportContext.Provider value={{
      storesAdminReportData,
      setStoresAdminReportData,
      loading,
      setLoading,
    }}
    >
      {children}
    </StoresAdminReportContext.Provider>
  );
};

StoresAdminReportProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export const useStoresAdminReport = () => useContext(StoresAdminReportContext);
