import styled from 'styled-components';

const Th = styled.span`
  :hover {
    opacity: 0.5;
    transition: 1s;
  }
`;

export default Th;
