import axios from 'axios';
import { API } from 'helpers/constant';

const createDocumentationTypes = async (data) => {
  await axios.post(`${API}/storedocumentationtypes`, data)
    .then((res) => res.data)
    .catch((error) => error);
};

const getDocumentationTypes = () => axios.get(`${API}/storedocumentationtypes/`)
  .then((res) => res.data)
  .catch((error) => error);

const updateDocumentationTypes = async (id, data) => {
  await axios.put(`${API}/storedocumentationtypes/${id}`, data)
    .then((res) => res.data)
    .catch((error) => error);
};

const deleteDocumentationTypes = async (id) => {
  await axios.delete(`${API}/storedocumentationtypes/${id}`)
    .then((res) => res.data)
    .catch((error) => error);
};

export {
  createDocumentationTypes,
  getDocumentationTypes,
  updateDocumentationTypes,
  deleteDocumentationTypes
};
