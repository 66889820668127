import React from 'react';
import { CallsProvider } from 'providers/calls';
import CallsCreateNoButton from './CallsCreateNoButton';

const exportCallsCreate = ({ visibleCalls }) => (
  <CallsProvider>
    <CallsCreateNoButton visibleCalls={visibleCalls} />
  </CallsProvider>
);

export default exportCallsCreate;
