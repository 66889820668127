import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment';

import {
  IoEyeOutline,
} from 'react-icons/io5';

import { useDiary } from 'providers/diary';
import { CaseIcon } from 'components/card';
import { TableIcon } from 'components/table';
import Modal from 'components/common/Modal';

const ViewData = ({ data, exibition }) => {
  const { diaryContact } = useDiary();
  moment.locale('pt-br');
  const [visible, setVisible] = useState(false);
  const [diaryContactData, setDiaryContactData] = useState([]);

  const handleBool = (boolData) => {
    if (boolData) {
      return 'Sim';
    } return 'Não';
  };

  const fetchData = async () => {
    const contactData = await diaryContact(data.id);
    setDiaryContactData(contactData);
  };

  const title = `${data.diarytypes.name} numero ${data.id}`;

  const closeModal = () => {
    setVisible(false);
  };
  const opemModal = () => {
    fetchData();
    setVisible(true);
  };

  const handleActivePassive = (bool) => {
    if (bool === 1) {
      return 'Ativo';
    } return 'Passivo';
  };

  return (
    <>
      {exibition === 'card' && (
        <CaseIcon marginleft="5px">
          <IoEyeOutline title="Visualizar" onClick={() => opemModal(true)} />
        </CaseIcon>
      )}
      {exibition === 'table' && (
        <TableIcon>
          <IoEyeOutline title="Visualizar" onClick={() => opemModal()} />
        </TableIcon>
      )}
      { visible && (
        <Modal visible={visible} onClose={() => closeModal()} title={title}>
          <BodyModal>
            <Style>Controle do Diário</Style>
            <><Legend>Drogaria: </Legend> <span style={{ textTransform: 'uppercase' }}>{data.stores.legacyCode} - {data.stores.storeName}</span></>
            <br />
            <><Legend>Registrado por: </Legend> <span style={{ textTransform: 'capitalize' }}>{data.userData.name}</span></>
            {((diaryContactData.dealtWith !== null) && (diaryContactData.dealtWith !== '')) && (<><br /><Legend>Tratado com: </Legend> <span style={{ textTransform: 'capitalize' }}>{diaryContactData.dealtWith}</span></>)}
            {((diaryContactData.diarywaytypesData) && (diaryContactData.diarywaytypesData !== '')) && (<><br /><Legend>Tipo de Contato: </Legend> <span style={{ textTransform: 'capitalize' }}>{diaryContactData.diarywaytypesData.name}</span></>)}
            {((diaryContactData.diarySubtypesData) && (diaryContactData.diarySubtypesData !== '')) && (<><br /><Legend>Assunto: </Legend> <span style={{ textTransform: 'capitalize' }}>{diaryContactData.diarySubtypesData.name}</span></>)}
            {((diaryContactData.flow !== null) && (diaryContactData.flow !== '')) && (<><br /><Legend>Fluxo: </Legend> <span style={{ textTransform: 'capitalize' }}>{handleActivePassive(diaryContactData.flow)}</span></>)}
            {((diaryContactData.firstTry !== null) && (diaryContactData.firstTry !== '')) && (<><br /><Legend>Primeira Tentativa: </Legend> <span style={{ textTransform: 'capitalize' }}>{diaryContactData.firstTry}</span></>)}
            {((diaryContactData.secondTry !== null) && (diaryContactData.secondTry !== '')) && (<><br /><Legend>Segunda Tentativa: </Legend> <span style={{ textTransform: 'capitalize' }}>{diaryContactData.secondTry}</span></>)}
            {((diaryContactData.thirdTry !== null) && (diaryContactData.thirdTry !== '')) && (<><br /><Legend>Terceira Tentativa: </Legend> <span style={{ textTransform: 'capitalize' }}>{diaryContactData.thirdTry}</span></>)}
            {((diaryContactData.success !== null) && (diaryContactData.success !== '')) && (<><br /><Legend>Obteve Sucesso? </Legend> <span style={{ textTransform: 'capitalize' }}>{handleBool(diaryContactData.success)}</span></>)}
            <><br /><Legend>Dia do Diário: </Legend> { moment(data.date).format('DD/MM/YYYY') } </>
            <><br /><Legend>Data do registro do Diário: </Legend> { moment(data.createdAt).format('DD/MM/YYYY HH:mm') } </>
            <><br /><Legend>Data da atualização do Diário: </Legend> { moment(data.updatedAt).format('DD/MM/YYYY HH:mm') } </>
            <Style>Descrição do Diário</Style>
            {data.text}
          </BodyModal>
        </Modal>
      )}
    </>
  );
};

const Style = styled.div`
  text-align: center;
  letter-spacing: .04em;
  font-weight: 600;
  text-shadow: 1px 1px white, 1px 1px #333;
  margin: 10px 0px;
  padding: 10px 0px;
  border-bottom: solid 1px #c6c9cc;
`;

const BodyModal = styled.div`
  padding: 10px;
  width: 30vw;
  font-size: 14px;

  @media(max-width: 800px) {
    width: 100%;
    overflow: auto;
  }
`;

const Legend = styled.span`
  font-weight:bold;
`;

ViewData.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
  exibition: PropTypes.string.isRequired,
};

ViewData.defaultProps = {
  data: {}
};

export default ViewData;
