const AlertWarning = (msg) => {
  const message = {
    title: 'Atenção',
    text: msg,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#5CB85C',
    cancelButtonColor: '#CCCCCC',
    confirmButtonText: 'Sim',
    cancelButtonText: 'Cancelar'
  };
  return message;
};

export default AlertWarning;
