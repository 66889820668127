import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { toastSuccess } from 'helpers/notifications';

import {
  createDiary,
  getAllDiary,
  updateDiary,
  deleteDiary,
  getDiaryTypes,
  getDiarySubtypes,
  getDiaryWayTypes,
  diaryContact
} from 'service/DiaryService';

export const DiaryContext = React.createContext([]);

export const DiaryProvider = ({ children }) => {
  const [diaryData, setDiaryData] = useState([]);
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(true);
  const [diaryTypes, setDiaryTypes] = useState([]);
  const [diarySubtypes, setDiarySubtypes] = useState([]);
  const [wayTypes, setWayTypes] = useState([]);

  const create = async (values) => {
    await createDiary(values);
  };

  const diaryFinally = (msg = 'Salvo com Sucesso!') => {
    setReload(!reload);
    toastSuccess(msg);
  };

  const update = async (id, values) => {
    await updateDiary(id, values);
    setReload(!reload);
    toastSuccess('Alterado com sucesso!');
  };

  const deleteRegister = async (id) => {
    await deleteDiary(id);
    setReload(!reload);
    toastSuccess('Excluido com sucesso!');
  };

  const list = async () => {
    const data = await getAllDiary();
    setLoading(false);
    setDiaryData(data);
  };

  useEffect(() => {
    const fetchData = async () => {
      const types = await getDiaryTypes();
      setDiaryTypes(types);
      const subTypes = await getDiarySubtypes();
      setDiarySubtypes(subTypes);
      const ways = await getDiaryWayTypes();
      setWayTypes(ways);
    };
    fetchData();
  }, []);

  useEffect(() => {
    list();
  }, [reload, loading]);

  return (
    <DiaryContext.Provider value={{
      diaryData,
      setDiaryData,
      list,
      create,
      update,
      deleteRegister,
      diaryTypes,
      diarySubtypes,
      setDiarySubtypes,
      getDiarySubtypes,
      diaryFinally,
      loading,
      wayTypes,
      diaryContact
    }}
    >
      {children}
    </DiaryContext.Provider>
  );
};

DiaryProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export const useDiary = () => React.useContext(DiaryContext);
