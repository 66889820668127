import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Span = ({ children, type }) => {
  const handleColor = () => {
    if (type === 1) {
      return (
        <Div><SpanCase background="#F44336">{children}</SpanCase></Div>
      );
    }
    if (type === 3) {
      return (
        <Div><SpanCase background="#FFA726">{children}</SpanCase></Div>
      );
    }
    if (type === 4) {
      return (
        <Div><SpanCase background="#1E88E5">{children}</SpanCase></Div>
      );
    }
    return (
      <Div><SpanCase background="#00C853">{children}</SpanCase></Div>
    );
  };

  return (
    <>
      {handleColor()}
    </>
  );
};

const Div = styled.div`
  padding: 2px 6px;
`;

const SpanCase = styled.div`
  font-size: 10px;
  font-weight: 700;
  text-align: center;
  color: white; 
  background: ${(props) => props.background};
  border-radius: 10px;
  padding: 1px 10px;
`;

Span.propTypes = {
  children: PropTypes.string.isRequired,
  type: PropTypes.number.isRequired,
};

export default Span;
