import React from 'react';
import BasePage from 'components/layout/BasePage';
import { ConsultantVisitReportsProvider } from 'providers/Reports/consultantVisit';
import ConsultantVisit from './ConsultantVisit';

const index = () => (
  <BasePage>
    <ConsultantVisitReportsProvider>
      <ConsultantVisit />
    </ConsultantVisitReportsProvider>
  </BasePage>
);

export default index;
