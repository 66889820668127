import axios from 'axios';
import { API, UPLOAD_FILE_MICROSERVICE } from 'helpers/constant';

const createDocumentation = (data) => axios
  .post(`${API}/storeDocumentation`, data)
  .then((res) => res.data)
  .catch((error) => error[0]);

const deleteDocumentation = (id) => axios.delete(`${API}/storeDocumentation/${id}`)
  .then((res) => res.data)
  .catch((error) => {
    if (!error.response) {
      return false;
    }
    return error.response.data;
  });

const getDocumentation = (storeId, type) => axios
  .get(`${API}/storeDocumentation/storeId/${storeId}/typeId/${type}`)
  .then((res) => res.data)
  .catch((error) => error[0]);

const getDocumentationFile = async (path) => {
  const response = await axios.post(`${UPLOAD_FILE_MICROSERVICE}/path`, { filePath: path });
  const b64 = response.data;
  return b64;
};

const confirmDocumentationFile = async (path, name) => {
  const response = await axios.post(`${UPLOAD_FILE_MICROSERVICE}/stores`, { filePath: path, fileName: name });
  const b64 = response.data;
  return b64;
};

export {
  createDocumentation,
  getDocumentation,
  deleteDocumentation,
  getDocumentationFile,
  confirmDocumentationFile
};
