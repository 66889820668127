import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import axios from 'axios';
import { UPLOAD_FILE_MICROSERVICE } from 'helpers/constant';

import PropTypes from 'prop-types';
import Label from 'components/common/Label';
import Asterisk from 'components/common/Asterisk';
import MensageErro from 'components/common/MensageError';
import LoadingBlocked from 'components/common/LoadingBlocked';
import {
  AiOutlineCloudUpload,
  AiOutlineCheckCircle,
  AiOutlineCloseCircle,
  AiFillFileExcel,
  AiFillFilePdf
} from 'react-icons/ai';

const FileField = ({
  label,
  internalLabel,
  name,
  onChange,
  isRequired,
  acceptFiles,
  error,
  isError,
  value,
  onBlur,
  setFieldValue,
  maximumSize,
  route,
  backendPath
}) => {
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
  } = useDropzone({
    accept: acceptFiles,
    maxFiles: 1,
    multiple: false,
    maxSize: maximumSize,
    minSize: 1
  });

  const [fileBackendPath, setFilebakcendPath] = useState('');
  const [loading, setLoading] = useState(false);
  const submit = async (uploadFile) => {
    const data = new FormData();
    data.append('file', uploadFile);
    let val = '';
    if (backendPath === '') {
      val = await axios
        .post(`${UPLOAD_FILE_MICROSERVICE}/${route}`, data)
        .then((res) => res.data)
        .catch((erro) => erro[0]);
    } else {
      val = await axios
        .post(`${UPLOAD_FILE_MICROSERVICE}/${route}/${backendPath}`, data)
        .then((res) => res.data)
        .catch((erro) => erro[0]);
    }
    return val;
  };

  const renderDragMessage = () => {
    if (!isDragActive) {
      return (
        <Message>
          <UploadIcon />
          {internalLabel} <br />
        </Message>
      );
    }
    if (isDragReject) {
      return (
        <Message type="error">
          <BugIcon />
          Quantidade ou formato do arquivo não suportado
        </Message>
      );
    }
    return (
      <Message type="success">
        <CheckIcon />
        Solte aqui
      </Message>
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      if (acceptedFiles.length) {
        setLoading(true);
        const file = await submit(acceptedFiles[0]);
        setLoading(false);
        setFieldValue(name, file.filename);
        setFilebakcendPath(file.path);
      }
    };
    fetchData();
  }, [acceptedFiles]);

  return (
    <>
      <LoadingBlocked active={loading} />
      <Label htmlFor={name}>{label}</Label> {isRequired && (<Asterisk>*</Asterisk>) }
      <Case className="container">
        <Drop
          {...getRootProps({ className: 'dropzone' })}
          isDragAccept={isDragActive}
          isDragReject={isDragReject}
        >
          <input {...getInputProps()} />
          <input
            id={name}
            name={name}
            type="hidden"
            onChange={onChange}
            onBlur={onBlur}
            value={value}
          />
          {renderDragMessage()}
          {((acceptedFiles.length > 0) && (acceptFiles === 'image/*')) && (<Img src={`${UPLOAD_FILE_MICROSERVICE}/${fileBackendPath}`} alt="Imagem" />)}
          {((acceptedFiles.length > 0) && (acceptFiles === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) && (<AiFillFileExcel style={{ fontSize: '60px' }} />)}
          {((acceptedFiles.length > 0) && (acceptFiles === 'application/pdf')) && (<AiFillFilePdf style={{ fontSize: '60px' }} />)}
        </Drop>
      </Case>
      { isError && <div><MensageErro>{error}</MensageErro></div> }
    </>
  );
};

const UploadIcon = styled(AiOutlineCloudUpload)`
  font-size: 32px;
  margin-right: 10px;
`;

const CheckIcon = styled(AiOutlineCheckCircle)`
  font-size: 32px;
  margin-right: 10px;
`;

const BugIcon = styled(AiOutlineCloseCircle)`
  font-size: 32px;
  margin-right: 10px;
`;

const Case = styled.section`
  border: 2px dashed #C4B7B7;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 3px;
  font-size: 11px;
`;
const messageColors = {
  default: '#D5CCCC',
  error: '#e57878',
  success: '#5CB85C'
};

const Message = styled.span`
  width: 100%;
  display: flex;
  color: ${(props) => messageColors[props.type || 'default']};
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  margin: 3px;
`;
const Img = styled.img`
  height: 50px;
  margin: 10px;
`;
const Drop = styled.div`
  display: flex;
  height: 70px;
  align-items: center;
  justify-content: space-between;
`;

FileField.propTypes = {
  label: PropTypes.string.isRequired,
  internalLabel: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  acceptFiles: PropTypes.string.isRequired,
  error: PropTypes.string,
  value: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  isError: PropTypes.bool.isRequired,
  onBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  maximumSize: PropTypes.number,
  route: PropTypes.string.isRequired,
  backendPath: PropTypes.string
};

FileField.defaultProps = {
  isRequired: false,
  error: '',
  maximumSize: 500000,
  backendPath: ''
};

export default FileField;
