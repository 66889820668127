import React, { useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import Swal from 'sweetalert2';

import { TiArrowSortedDown, TiArrowSortedUp } from 'react-icons/ti';
import {
  HiOutlineInboxIn,
} from 'react-icons/hi';

import { useCalls } from 'providers/calls';

import NoData from 'components/common/NoData';
import { TableCaseIcon } from 'components/tableComponents';

import Table from 'components/tableComponents/CompleteTable';
import Span from 'components/common/Span';
import AlertWarning from 'components/alert/AlertWarning';
import LoadingBlocked from 'components/common/LoadingBlocked';
import getProfile from 'helpers/getProfile';

import CallsEdit from './CallsEdit';

import CallTransfer from './CallTransfer';
import CallInteractions from './CallInteractions';
import CallHistory from './CallHistory';
import CallsLog from './CallsLog';

const CallsTable = () => {
  const {
    callsData, reopen, blockLoading, loading
  } = useCalls();
  const profile = getProfile();
  const [visible, setVisible] = useState(true);

  const reopenCall = async (id) => {
    await Swal.fire(AlertWarning('Tem certeza que deseja reabrir esse chamado?')).then(async (result) => {
      if (result.isConfirmed) {
        await reopen(id, { userId: profile.user.id });
      }
    });
    setVisible(!visible);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'callNumber',
        defaultFilter: true,
      },
      {
        Header: 'COD',
        accessor: 'stores.legacyCode',
        defaultFilter: true,
      },
      {
        Header: 'RAZÃO SOCIAL',
        accessor: 'stores.storeName',
        defaultFilter: true,
      },
      {
        Header: 'DESCRIÇÃO',
        accessor: 'text',
        defaultFilter: true,
      },
      {
        Header: 'RESPONSÁVEL',
        accessor: 'userData.name',
        defaultFilter: true,
        Cell: (i) => {
          const { cell } = i;
          const data = cell.row.original;
          if (!data.userData) {
            return data.sectorData.name;
          } if (profile.user.id === data.userData.id) {
            return <span style={{ fontWeight: 'bold' }}>{data.userData.name}</span>;
          } return data.userData.name;
        }
      },
      {
        Header: 'ABERTO POR',
        accessor: 'openByData.name',
        defaultFilter: true,
        Cell: (i) => {
          const { cell } = i;
          const data = cell.row.original;
          return <span>{data.openByData.name}</span>;
        }
      },
      {
        Header: 'DATA',
        accessor: 'createdAt',
        Cell: (i) => {
          const { cell } = i;
          const data = cell.row.original;
          return moment(data.createdAt).format('DD/MM/YYYY');
        }
      },
      {
        Header: 'STATUS',
        accessor: 'callStatusData.name',
        Cell: (i) => {
          const { cell } = i;
          const data = cell.row.original;
          return <Span type={data.callStatusData.id}>{data.callStatusData.name}</Span>;
        }
      },
      {
        Header: '',
        accessor: 'id',
        Cell: (i) => {
          const { cell } = i;
          const data = cell.row.original;
          return (
            <TableCaseIcon>
              {data.status !== 2 && (<CallsEdit data={data} exibition="table" />)}
              {data.status === 2 && (<HiOutlineInboxIn title="Reabrir" onClick={() => reopenCall(data.id)} />)}
              <span {...cell.row.getToggleRowExpandedProps()}>
                {cell.row.isExpanded ? <TiArrowSortedUp title="Esconder" /> : <TiArrowSortedDown title="Expandir" />}
              </span>
            </TableCaseIcon>
          );
        }
      }
    ],
    []
  );

  const renderRowSubComponent = React.useCallback(
    ({ row }) => {
      const data = row.original;
      return (
        <ColapseContainer>
          <RightSide>
            <div>
              <span style={{ fontWeight: 'bold' }}>Data do Registro:</span> { moment(data.createdAt).format('DD/MM/YYYY HH:mm') }
              <br />
              <span style={{ fontWeight: 'bold' }}>Data da Atualização:</span> { moment(data.updatedAt).format('DD/MM/YYYY HH:mm') }
            </div>
            <div>
              <span style={{ textTransform: 'capitalize', fontSize: '15px' }}>Descrição:</span>
              <DescriptionArea>{data.text}</DescriptionArea>
            </div>
          </RightSide>
          <CallHistory callId={data.id} />
          <ButtonCase>
            {data.status !== 2 && <CallInteractions data={data} />}
            {data.status !== 2 && <CallTransfer data={data} />}
            <CallsLog callData={data} />
          </ButtonCase>
        </ColapseContainer>
      );
    },
    []
  );

  return (
    <>
      <LoadingBlocked active={blockLoading} />
      {((callsData.length > 0) && (loading === false) && (
        <Table
          marginTop="-30px"
          data={callsData}
          columns={columns}
          renderRowSubComponent={renderRowSubComponent}
          changeTableSize={50}
        />
      ))}
      {(callsData.length === 0) && (loading === false) && (
        <NoData />
      )}
    </>
  );
};

const ColapseContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 0.5fr;
  gap: 10px;
  margin: 10px;
  height: 200px;
  color: #224772;
  font-size: 12px;
  background-color: #DAE6F3;
  border-radius: 8px;
`;

const RightSide = styled.div`
  margin: 10px;
  border-right: 1px solid #000;
  padding-right: 20px;
`;

const DescriptionArea = styled.div`
  border-radius: 8px;
  height: 125px;
  width: 400px;
  background-color: white;
  overflow: auto;
  padding: 5px;
`;

const ButtonCase = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 10px;
`;

export default CallsTable;
