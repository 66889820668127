import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  getDashboardLeaderAngel,
} from 'service/ReportService';
import getProfile from 'helpers/getProfile';

export const LeaderAngelDashboardContext = React.createContext({});

export const LeaderAngelDashboardProvider = ({ children }) => {
  const [leaderAngelDashboardData, setLeaderAngelDashboardData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [periodStart, setPeriodStart] = useState('mês');
  const [periodEnd, setPeriodEnd] = useState('mês');

  const list = async () => {
    setLoading(true);
    const profile = getProfile();
    try {
      const data = await getDashboardLeaderAngel(profile.user.id);
      setLeaderAngelDashboardData(data);
    } catch {
      setLeaderAngelDashboardData(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      list();
    };
    fetchData();
  }, []);

  return (
    <LeaderAngelDashboardContext.Provider value={{
      leaderAngelDashboardData,
      setLeaderAngelDashboardData,
      loading,
      setLoading,
      list,
      periodStart,
      setPeriodStart,
      periodEnd,
      setPeriodEnd,
    }}
    >
      {children}
    </LeaderAngelDashboardContext.Provider>
  );
};

LeaderAngelDashboardProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export const useLeaderAngelDashboard = () => React.useContext(LeaderAngelDashboardContext);
