import React from 'react';
import { StoresProviders } from 'providers/Stores/stores';
import { CallsProvider } from 'providers/calls';
import { DiaryProvider } from 'providers/diary';
import Stores from './Stores';

const index = () => (
  <StoresProviders>
    <CallsProvider>
      <DiaryProvider>
        <Stores />
      </DiaryProvider>
    </CallsProvider>
  </StoresProviders>
);

export default index;
