import React, { useRef } from 'react';
import styled from 'styled-components';

import moment from 'moment';
import Swal from 'sweetalert2';
import PropTypes from 'prop-types';

import AreaChart from 'components/common/AreaChart';
import CardChart from 'components/common/CardChart';
import Case from 'components/common/Case';
import BarChart from 'components/chart/BarChart';
import BarChartRangeFilter from 'components/chart/BarChartRangeFilter';
import PieChart from 'components/chart/PieChart';
import Button from 'components/common/Button';

import { getDashboardDirectorForPeriod } from 'service/ReportService';
import { useDirectorDashboard } from 'providers/Dashboard/directors';

function StoresDirectorDashboard({ data }) {
  const {
    setDashboardDirectorData,
    dashboardDirectorData,
    setLoading,
    setPeriod,
    list,
    period,
  } = useDirectorDashboard();

  const startDate = useRef('');
  const endDate = useRef('');

  const handleDate = async () => {
    if ((startDate.current.value) && (endDate.current.value)) {
      setLoading(true);
      const periodStart = (moment(startDate.current.value).format('DD/MM/YYYY'));
      const periodEnd = (moment(endDate.current.value).format('DD/MM/YYYY'));
      const formatPeriod = `do ${periodStart} a  ${periodEnd}`;
      setPeriod(formatPeriod);
      const chartData = await getDashboardDirectorForPeriod(
        startDate.current.value, endDate.current.value
      );
      setDashboardDirectorData(chartData);
      setLoading(false);
    } else if ((startDate.current.value === '') && (endDate.current.value === '')) {
      setPeriod('');
      list();
    } else {
      Swal.fire(
        'Aviso',
        'Informe o período para a pesquisa',
      );
    }
  };

  return (
    <>
      <AreaChart title="PERFORMANCE">
        <SearchCase>
          <Case gridTemplateColumns="2fr 2fr 1fr">
            <input ref={startDate} type="date" id="startDate" />
            <input ref={endDate} type="date" id="endDate" />
            <SearchButton type="button" onClick={handleDate}>Pesquisar</SearchButton>
          </Case>
        </SearchCase>
        <Case gridTemplateColumns="2fr 1fr">
          <CardChart title={`Motivos de desligamento ${period}`}>
            <PieChart data={dashboardDirectorData.reasonsInactive} label={['Motivos', 'Quantidade']} width="600px" />
          </CardChart>
          <CardChart title="Lojas por bandeiras">
            <PieChart data={data.storesByBrand} label={['Bandeiras', 'Quantidade']} width="400px" />
          </CardChart>
        </Case>
        <Case gridTemplateColumns="1fr">
          <CardChart title="Lojas nos últimos 6 meses">
            <BarChart data={data.storesByPeriod} label={['', 'Novas', 'Desativas']} width="1050px" colorLabel={['#4285F4', '#DB4538']} />
          </CardChart>
          <CardChart title="Lojas ativas por estado">
            <BarChartRangeFilter data={data.storesByState} maxValue={data.activeAllStores} label={['', 'Ativas']} width="1050px" height="400px" colorLabel={['#4285F4']} />
          </CardChart>
        </Case>
      </AreaChart>
    </>
  );
}

const SearchCase = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 0 0 5px 10px;
`;

const SearchButton = styled(Button)`
  height: 30px;
  margin-right: 0px;
`;

StoresDirectorDashboard.propTypes = {
  data: PropTypes.element.isRequired,
};

export default StoresDirectorDashboard;
