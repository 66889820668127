import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { uploadService } from 'service/StoresService';
import LoadingBlocked from 'components/common/LoadingBlocked';

import Icon from 'components/common/Icon';
import Modal from 'components/common/Modal';
import FileField from 'components/common/FileField';
import Button from 'components/common/Button';

const UploadStoreService = () => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const values = {
    fileName: '',
  };

  const validations = {
    fileName: Yup.string().required('Selecione um documento'),
  };

  const handleSubmit = async (data) => {
    setLoading(true);
    await uploadService({ filePath: 'private/storeServices', fileName: data.fileName });
    setLoading(false);
    setVisible(false);
  };

  const formik = useFormik({
    initialValues: values,
    validationSchema: Yup.object(validations),
    onSubmit: handleSubmit,
  });

  return (
    <>
      <LoadingBlocked active={loading} />
      <Icon icon={<AiOutlineCloudUpload />} name="Upload de Serviços" route="/sistema" onClick={() => setVisible(!visible)} />
      <Modal visible={visible} onClose={() => setVisible(false)} title="Upload de Serviços">
        <BodyModal>
          <Form onSubmit={formik.handleSubmit}>
            <FileField
              name="fileName"
              acceptFiles="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              id="fileName"
              label="Raio X"
              internalLabel="Clique ou arraste seu arquivo"
              isRequired
              error={formik.errors.fileName}
              setFieldValue={formik.setFieldValue}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.initialValues.fileName}
              isError={formik.errors.fileName && formik.touched.fileName}
              route="temp"
              maximumSize={10000000}
            />
            <ButtonDiv>
              <Button type="submit">
                Upload
              </Button>
            </ButtonDiv>
          </Form>
        </BodyModal>
      </Modal>
    </>
  );
};

const Form = styled.form`  
  padding: 5px;
  overflow: auto;
`;

const BodyModal = styled.div`
  width: 30vw;
  margin: 10px;
`;

const ButtonDiv = styled.div`
  text-align: right;
  margin-top: 10px;
`;

export default UploadStoreService;
