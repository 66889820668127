import styled from 'styled-components';

const CardTitle = styled.div`
  margin: 10px;
  color: '#F1F3F4';
  font-size: 15px;
  font-weight: 600;
  max-width: 324px;
  overflow: hidden; 
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export default CardTitle;
