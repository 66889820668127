import React, { useState } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import { MdGeneratingTokens, MdOutlineAdd } from 'react-icons/md';
import { IoCreateOutline } from 'react-icons/io5';
import { RiLockPasswordLine } from 'react-icons/ri';

import { useProfile } from 'providers/Stores/profile';
import { resetUserPassword } from 'service/UsersService';
import verifyAclUser from 'helpers/verifyAclUser';
import acl from 'helpers/acl';

import Case from 'components/common/Case';
import Modal from 'components/common/Modal';
import TextAreaField from 'components/common/TextAreaField';
import Button from 'components/common/Button';
import CancelButton from 'components/common/CancelButton';
import { toastSuccess } from 'helpers/notifications';

const LinkedToken = ({ data }) => {
  const [visible, setVisible] = useState(false);

  const {
    submitToken
  } = useProfile();

  const initialValues = {
    params: data.registrationNumber,
    linkedfarmaToken: ''
  };
  const validations = {
    linkedfarmaToken: Yup.string().required('Informe Token'),
  };
  const onSubmit = async (values) => {
    await submitToken(values);
    setVisible(false);
    toastSuccess('Token atualizado com sucesso');
  };
  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema: Yup.object(validations),
  });
  const resetPassword = () => {
    Swal.fire({
      title: 'Você quer resetar a senha?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#224772',
      cancelButtonColor: '#224772',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.isConfirmed) {
        resetUserPassword(data.registrationNumber);
        toastSuccess('Senha resetada com sucesso');
      }
    });
  };

  return (
    (verifyAclUser(acl.manipulate_linked_token)) && (
    <>
      <BoxCase>
        <FieldSet fieldStyle={data.active ? 'background-color: #FFFFFF' : 'background-color: #fae7e8'}>
          <LinkedTokenIcon iconStyle={data.active ? 'color: #A6D16E' : 'color: #8f5340'} /> LinkedFarma Token
        </FieldSet>
        <Line>
          <Case gridTemplateColumns="1fr 1fr">
            {!data.linkedfarmaToken && (
              <>
                <span style={{ color: 'red' }}>Token Pendente</span>
                <div style={{ textAlign: 'end' }}>
                  <AddLinkedIcon onClick={() => setVisible(true)} />
                </div>
              </>
            )}
            {data.linkedfarmaToken && (
              <>
                <span style={{ color: '#93D367' }}>Token Cadastrado</span>
                <div style={{ textAlign: 'end' }}>
                  <EditLinkedIcon title="Editar Token" onClick={() => setVisible(true)} />
                  <ResetPasswordIcon title="Resetar Senha" onClick={() => resetPassword()} />
                </div>
              </>
            )}
          </Case>
        </Line>
      </BoxCase>
      <Modal visible={visible} onClose={() => setVisible(false)} title="LinkedToken">
        <FormData onSubmit={formik.handleSubmit}>
          <TextAreaField
            id="linkedfarmaToken"
            name="linkedfarmaToken"
            placeholder="LinkedFarma Token"
            error={formik.errors.linkedfarmaToken}
            onBlur={formik.handleBlur}
            onChange={(e) => {
              formik.handleChange(e);
            }}
            value={formik.values.linkedfarmaToken}
            isError={formik.errors.linkedfarmaToken && formik.touched.linkedfarmaToken}
          />
          <ButtonCase>
            <CancelButton onClose={() => setVisible(false)}>Cancelar</CancelButton>
            <Button type="submit">Salvar</Button>
          </ButtonCase>
        </FormData>
      </Modal>
    </>
    )
  );
};

const BoxCase = styled.div`
  margin: 35px 8px 8px 8px;
  padding: 0 20px 20px 20px;
  border: 1px solid #9A9A9A;
  border-radius: 4px;   
`;

const FieldSet = styled.span`
  position: relative;
  right: 10px;
  top: -12px;
  ${(props) => props.fieldStyle};
  padding: 0 10px;
  font-family: Montserrat;
  font-weight: 400;
`;

const Line = styled.div`
  margin-left: 12px;
  text-transform: capitalize;
  font-size: 16px;
  margin-bottom: 22px;
  font-family: Montserrat;
  font-weight: 400;
`;

const LinkedTokenIcon = styled(MdGeneratingTokens)`
  ${(props) => props.iconStyle};
`;

const AddLinkedIcon = styled(MdOutlineAdd)`
  font-size: 25px;
  cursor: pointer;
`;
const EditLinkedIcon = styled(IoCreateOutline)`
  font-size: 20px;
  cursor: pointer;
`;

const ResetPasswordIcon = styled(RiLockPasswordLine)`
  font-size: 20px;
  cursor: pointer;
  margin-left: 10px;
`;

LinkedToken.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

const FormData = styled.form`  
  width: 30vw;
  margin: 10px;
`;

const ButtonCase = styled.div`
  text-align: right;
  margin-top: 30px;
`;

export default LinkedToken;
