import React from 'react';
import moment from 'moment';
import styled from 'styled-components';

import { useCalls } from 'providers/calls';

import { Card, CardIcon, CardFooter } from 'components/card';
import Case from 'components/common/Case';
import NoData from 'components/common/NoData';

import CallsEdit from './CallsEdit';
import CallsEnd from './CallsEnd';
import ViewData from './ViewData';

const CallsCard = () => {
  const { callsData } = useCalls();

  const cardStyle = (status) => {
    if (status === 2) {
      return 'background-color: #eef8e5';
    } return 'background-color: #fcebeb';
  };

  return (
    <Container>
      <Grid>{ (
        callsData.map((data) => (
          <Card key={data.id} cardStyle={cardStyle(data.status)}>
            <Case gridTemplateColumns="1fr 1fr" alignItems="center" justifyContent="space-between" display="flex">
              <Header>
                <LegacyCode>{data.stores.legacyCode}</LegacyCode>
                <StoreName>{data.stores.storeName}</StoreName>
              </Header>
            </Case>
            <NoteBody gridTemplateColumns="1fr">
              {data.text}
            </NoteBody>
            <CardFooter gridTemplateColumns="1fr 1fr 1fr">
              <div>
                {data.userData && data.userData.name}
                {!data.userData && data.sectorData.name}
              </div>
              <div>
                {moment(data.createdAt).format('DD/MM/YYYY')}
              </div>
              <div>
                <CardIcon marginleft="5px">
                  {data.status !== 2 && (<CallsEdit data={data} exibition="card" />)}
                  <ViewData data={data} exibition="card" />
                  <CallsEnd data={data} exibition="card" />
                </CardIcon>
              </div>
            </CardFooter>
          </Card>
        ))
      ) }
        { !callsData.length && (<NoData />)}
      </Grid>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 113px;

  @media(max-width: 400px) {
    margin-top: 0;
  }
`;

const Grid = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1fr 1fr;
 @media(max-width: 800px) { 
  grid-template-columns: 1fr;
 }
`;

const NoteBody = styled.div`
  overflow: hidden;
  max-height: 150px;
 @media(max-width: 800px) {
  max-height: 100px;
 }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 13px;
  color: #20314F;
`;

const LegacyCode = styled.div`
  font-size: 26px;
  font-weight: 600;
  margin-right: 1rem;
  line-height: 26px;
`;

const StoreName = styled.div`
  font-size: 15px;
  font-weight: 600;
`;

export default CallsCard;
