import React from 'react';
import BasePage from 'components/layout/BasePage';
import { ContactsReportsProvider } from 'providers/Reports/contacts';
import Contacts from './Contacts';

const index = () => (
  <BasePage>
    <ContactsReportsProvider>
      <Contacts />
    </ContactsReportsProvider>
  </BasePage>
);

export default index;
