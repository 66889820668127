const colors = {
  // primaryBlue: '#003F6A',
  primaryBlue: '#224772',
  primaryGreen: '#93D367',

  primaryLight: '#dae6f3',
  secondary: '#9ADB66',
  errorColor: '#EF5350',

  text: '#20314F',
  white: '#fff',
  muteText: '#838594',
  lightGray: '#F9FBFC',
};

export default colors;
