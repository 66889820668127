import React from 'react';
import styled from 'styled-components';
import {
  AiOutlineAppstore,
  AiOutlineShop,
  // AiOutlineControl,
  AiOutlineCode,
  AiOutlinePieChart,
  AiOutlineFileDone,
  // AiOutlineSync
} from 'react-icons/ai';
// import { RiRoadMapLine } from 'react-icons/ri';

import verifyAclUser from 'helpers/verifyAclUser';
import acl from 'helpers/acl';

import { VscFeedback } from 'react-icons/vsc';

import { /* CgPill, */ CgNotes } from 'react-icons/cg';
// import { MdAttachMoney } from 'react-icons/md';
import { FaTasks, FaRegChartBar } from 'react-icons/fa';
import { RiHammerLine } from 'react-icons/ri';

import ItemMenu from './ItemMenu';
import ExternalItemMenu from './ExternalItemMenu';

const Menu = () => (
  <>
    <ItemMenu route="/home" label="Home" icon={<AiOutlineAppstore />} />
    <ItemMenu route="/dashboard" label="Dashboard" icon={<AiOutlinePieChart />} />
    <ItemMenu route="/drogarias" label="Drogarias" icon={<AiOutlineShop />} />
    <ItemMenu route="/diario" label="Diário" icon={<CgNotes />} />
    <ItemMenu route="/chamados" label="Chamados" icon={<FaTasks />} />
    {((verifyAclUser(acl.router_reports)) && (
      <ItemMenu route="/relatorios" label="Relatórios" icon={<FaRegChartBar />} />
    ))}
    {((verifyAclUser(acl.router_contracts)) && (
      <ItemMenu route="/contratos" label="Contratos" icon={<AiOutlineFileDone />} />
    ))}
    <ItemMenu route="/sistema" label="Sistema" icon={<AiOutlineCode />} />
    <Footer>
      <ItemMenu route="/notasdeatualizacao" label="Notas de Atualização" icon={<RiHammerLine />} />
      <ExternalItemMenu route="http://bit.ly/feedbacksisrmc" label="Feedback" icon={<VscFeedback />} />
    </Footer>
  </>
);

/*
  Rotas Antigas:
  <ItemMenu route="/home" label="Home" icon={<AiOutlineAppstore />} />
    <ItemMenu route="/drogarias/lista" label="Drogarias" icon={<AiOutlineShop />} />
    <ItemMenu route="/visitas" label="Visitas" icon={<RiRoadMapLine />} />
    <ItemMenu route="/financeiro" label="Financeiro" icon={<MdAttachMoney />} />
    <ItemMenu route="/pbm" label="Rascunho" icon={<CgPill />} />
    <ItemMenu route="/rotinas" label="Rotinas" icon={<AiOutlineSync />} />
    <ItemMenu route="/sistema" label="Sistema" icon={<AiOutlineCode />} />
    <ItemMenu route="/site" label="Cofigurações do Site" icon={<AiOutlineControl />} />
*/

const Footer = styled.div`
  position: fixed;
  bottom: 0;
`;

export default Menu;
