import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Card from 'components/card/Card';

import Case from 'components/common/Case';
import List from 'components/common/List';
import CardFooter from 'components/card/CardFooter';
import Shorten from 'components/common/Shorten';
import { phoneMask } from 'helpers/Mask';
import { getProfile, getProfileUserType } from 'helpers/getProfileUserType';

import UsersEdit from './UsersEdit';
import ViewData from './ViewData';

const setColor = (value) => {
  if (value) {
    return 'white';
  } return '#fae7e8';
};

const UsersGrid = ({ users, usersType }) => {
  const profile = getProfile();

  const handleUser = users.filter(
    (i) => (i.id === profile.user.id)
  );

  return (
    <BaseCase>{
      profile.user.id !== 1 && handleUser.map((data) => (
        <Card key={data.id} cardStyle={`background-color: ${setColor(data.active)}`}>
          <Case alignItems="left" gap="0px">
            <List><SubtitleCase>Nome: </SubtitleCase>{data.name}</List>
            <List><SubtitleCase>Username: </SubtitleCase>{data.username}</List>
            <List><SubtitleCase>Cargo: </SubtitleCase>{data.usertype.name}</List>
            <Shorten maxWidth="200px">
              <SubtitleCase>Email: </SubtitleCase>{data.email}
            </Shorten>
            <List>
              <SubtitleCase>Telefone: </SubtitleCase> {!data.phoneNumber && (
                'Não cadastrado'
              )}
              {data.phoneNumber && (
                phoneMask(data.phoneNumber)
              )}
            </List>
          </Case>
          <CardFooter gridTemplateColumns=" 1fr 1fr" display="initial">
            <ViewData>{data}</ViewData>
            <UsersEdit usersType={usersType}>{data}</UsersEdit>
          </CardFooter>
        </Card>
      ))
    }{
      getProfileUserType([1]) && users.map((data) => (
        <Card key={data.id} cardStyle={`background-color: ${setColor(data.active)}`}>
          <Case alignItems="left" gap="0px">
            <List><SubtitleCase>Nome: </SubtitleCase>{data.name}</List>
            <List><SubtitleCase>Username: </SubtitleCase>{data.username}</List>
            <List><SubtitleCase>Cargo: </SubtitleCase>{data.usertype.name}</List>
            <Shorten maxWidth="200px">
              <SubtitleCase>Email: </SubtitleCase>{data.email}
            </Shorten>
            <List>
              <SubtitleCase>Telefone: </SubtitleCase> {!data.phoneNumber && (
                'Não cadastrado'
              )}
              {data.phoneNumber && (
                phoneMask(data.phoneNumber)
              )}
            </List>
          </Case>
          <CardFooter gridTemplateColumns=" 1fr 1fr" display="initial">
            <ViewData>{data}</ViewData>
            <UsersEdit usersType={usersType}>{data}</UsersEdit>
          </CardFooter>
        </Card>
      ))
    }
    </BaseCase>
  );
};

const BaseCase = styled.div`
  display: grid;
  gap: 15px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  @media(max-width: 800px) {
  grid-template-columns: 1fr;
  }
`;

const SubtitleCase = styled.span`
  font-weight: bold;
`;
UsersGrid.propTypes = {
  users: PropTypes.objectOf(PropTypes.any).isRequired,
  usersType: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default UsersGrid;
