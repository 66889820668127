import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import { getStoresContracts, destroyStoreContract } from 'service/ContractService';

import { toastSuccess, toastError } from 'helpers/notifications';

export const ContractsContext = React.createContext({});

export const ContractsProvider = ({ children }) => {
  const [contracts, setContracts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const contractsData = await getStoresContracts();
      setContracts(contractsData);
      setLoading(false);
    };
    fetchData();
  }, [loading]);

  const destroyContract = async (data) => {
    const res = await destroyStoreContract(data.id);
    if (res.name === 'ApplicationError') {
      setLoading(false);
      toastError(res.errors[0]);
    } else {
      setLoading(!loading);
      toastSuccess('Excluido com sucesso!');
    }
  };

  return (
    <ContractsContext.Provider value={{
      contracts,
      setContracts,
      loading,
      setLoading,
      contacts,
      setContacts,
      destroyContract,
      getStoresContracts
    }}
    >
      {children}
    </ContractsContext.Provider>
  );
};

ContractsProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export const useContracts = () => React.useContext(ContractsContext);
