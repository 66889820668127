import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

function TitleBar({ children }) {
  return (
    <TitleCase>
      <Title>
        {children}
      </Title>
    </TitleCase>
  );
}

const TitleCase = styled.div`
  display: flex;
  margin-bottom: 30px;
`;

const Title = styled.span`
  font-size: 30px;
  display: flex;
`;

TitleBar.propTypes = {
  children: PropTypes.string.isRequired,
};

export default TitleBar;
