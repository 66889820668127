import React, { useState } from 'react';
import styled from 'styled-components';
import {
  IoIosArrowBack,
  IoIosArrowForward
} from 'react-icons/io';

import BasePage from 'components/layout/BasePage';
import TitleBar from 'components/layout/TitleBar';

import Version3v0 from './Version3.0';

const index = () => {
  const atualState = 1;
  const [page, setPage] = useState(atualState);

  const previousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const nextPage = () => {
    if (page < atualState) {
      setPage(page + 1);
    }
  };
  return (
    <BasePage title="Notas de Atualização">
      <TopDesktop>
        <TitleBar>
          Notas de Atualização
        </TitleBar>
      </TopDesktop>
      <TopMobile>
        <TitleBar>
          Notas de Atualização
        </TitleBar>
      </TopMobile>
      <TopMobile>
        <ButtonMobile type="button" disabled={page === 1} onClick={() => previousPage()}>
          <IoIosArrowBack style={{ color: 'white' }} />
        </ButtonMobile>
        <ButtonMobile type="button" disabled={page === atualState} onClick={() => nextPage()}>
          <IoIosArrowForward style={{ color: 'white' }} />
        </ButtonMobile>
      </TopMobile>
      <TopDesktop>
        <ButtonDesktop type="button" disabled={page === 1} onClick={() => setPage(1)}>
          Versão 3.0.0
        </ButtonDesktop>
      </TopDesktop>
      {page === 1 && (
        <Version3v0 />
      )}
    </BasePage>
  );
};

const TopDesktop = styled.div`
  gap: 10px;
  @media(max-width: 800px) {
    display: none
  }
`;
const TopMobile = styled.div`
  margin-bottom: 12px;
  @media(min-width: 800px) {
    display: none;
  }
`;

const ButtonMobile = styled.button`
  background: #5CB85C;
  font-weight: 700;
  border: 0;
  margin: 0;
  color: #fff;
  cursor: pointer;
  height: 23px;
  width: 25px;
  border-radius: 4px;
  justify-content: flex-end;

  -webkit-box-shadow: 5px 3px 20px rgba(50, 50, 50, 0.2);
  -moz-box-shadow:    5px 3px 20px rgba(50, 50, 50, 0.2);
  box-shadow:         5px 3px 20px rgba(50, 50, 50, 0.2);

  transition: 0.3s;
  opacity: 1;
  margin: 5px;

  :disabled {
    background: #838594;
  }

  :hover{
    opacity: 0.8;
  };
`;

const ButtonDesktop = styled.button`
  background: #5CB85C;
  font-weight: 700;
  border: 0;
  margin: 0;
  color: #fff;
  cursor: pointer;
  height: 23px;
  width: 100px;
  border-radius: 4px;
  justify-content: flex-end;

  -webkit-box-shadow: 5px 3px 20px rgba(50, 50, 50, 0.2);
  -moz-box-shadow:    5px 3px 20px rgba(50, 50, 50, 0.2);
  box-shadow:         5px 3px 20px rgba(50, 50, 50, 0.2);

  transition: 0.3s;
  opacity: 1;
  margin: 5px;

  :disabled {
    background: #838594;
  }

  :hover{
    opacity: 0.8;
  };
`;

export default index;
