import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { DualRing } from 'react-spinners-css';

const LoadingBlocked = ({ active }) => (
  <>
    {active && (
      <Case>
        <Ring color="#5CB85C" size="100" />
        <Text>Por favor aguarde!</Text>
      </Case>
    )}
  </>
);

const Case = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.8 );
  z-index: 1898;
  color: #fff;
  font-size: 30px;
`;

const Text = styled.span`
  text-transform: capitalize;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; 
`;

const Ring = styled(DualRing)`
  margin-right: 20px;
`;

LoadingBlocked.propTypes = {
  active: PropTypes.bool.isRequired,
};

export default LoadingBlocked;
