import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { useDiary } from 'providers/diary';

import InputField from 'components/common/InputField';
import SelectField from 'components/common/SelectField';
import CheckBooleanField from 'components/common/CheckBooleanField';
import Case from 'components/common/Case';
import SearchStore from 'components/skilled/SearchStore';
import TextAreaField from 'components/common/TextAreaField';
import Button from 'components/common/Button';
import CancelButton from 'components/common/CancelButton';
import ButtonCase from 'components/common/ButtonCase';

const DiaryAngelForm = ({
  values, handleSubmit, handleCancel, validations, visibleFields
}) => {
  const { diarySubtypes, wayTypes } = useDiary();
  const [tryFields, setTryFields] = useState(visibleFields);
  const [successField, setSuccessField] = useState(visibleFields);
  const formik = useFormik({
    initialValues: values,
    onSubmit: handleSubmit,
    validationSchema: Yup.object(validations),
  });

  const flowArr = [
    { id: 1, name: 'Ativo' },
    { id: 2, name: 'Passivo' }
  ];

  const setVisibleFields = (cel) => {
    if (cel === '2') {
      setTryFields(true);
    } else {
      setTryFields(false);
    }
    if (cel === '2' || cel === '3') {
      setSuccessField(true);
    } else {
      setSuccessField(false);
    }
    if (cel === '1') {
      formik.values.success = true;
    }
    if (cel === '4') {
      formik.values.success = false;
    }
  };
  return (
    <Form data-testid="client-form" onSubmit={formik.handleSubmit}>
      <Case gridTemplateColumns="1fr 1fr 1fr">
        <SearchStore
          label="Drogaria"
          name="storeID"
          storeName={values.storeName}
          storeId={values.storeId}
          isRequired
          type="text"
          error={formik.errors.storeName}
          onBlur={formik.handleBlur}
          onChange={(e) => {
            formik.handleChange(e);
          }}
        />
        <InputField
          id="date"
          name="date"
          label="Data do Diário"
          placeholder="Informe o dia, mês e ano"
          isRequired
          type="date"
          error={formik.errors.date}
          onBlur={formik.handleBlur}
          onChange={(e) => {
            formik.handleChange(e);
          }}
          value={formik.values.date}
          isError={formik.errors.date && formik.touched.date}
        />
        <InputField
          id="dealtWith"
          name="dealtWith"
          label="Tratado com"
          placeholder="Informe o nome da pessoa"
          type="text"
          error={formik.errors.dealtWith}
          onBlur={formik.handleBlur}
          onChange={(e) => {
            formik.handleChange(e);
          }}
          value={formik.values.dealtWith}
          isError={formik.errors.dealtWith && formik.touched.dealtWith}
        />
      </Case>
      <Case gridTemplateColumns="1fr 1fr 1fr">
        <SelectField
          id="way"
          name="way"
          label="Contato via"
          value={formik.values.way}
          onBlur={formik.handleBlur}
          options={wayTypes}
          onChange={(e) => {
            formik.handleChange(e);
            setVisibleFields(e.target.value);
          }}
          error={formik.errors.way}
          isRequired
          isError={formik.errors.way && formik.touched.way}
        />
        <SelectField
          id="diarySubtype"
          name="diarySubtype"
          label="Assunto"
          isRequired
          value={formik.values.diarySubtype}
          onBlur={formik.handleBlur}
          onChange={(e) => {
            formik.handleChange(e);
          }}
          options={diarySubtypes}
          error={formik.errors.diarySubtype}
          isError={formik.errors.diarySubtype && formik.touched.diarySubtype}
        />
        <SelectField
          id="flow"
          name="flow"
          label="Fluxo"
          value={formik.values.flow}
          onBlur={formik.handleBlur}
          options={flowArr}
          onChange={formik.handleChange}
          error={formik.errors.flow}
          isRequired
          isError={formik.errors.flow && formik.touched.flow}
        />
      </Case>
      {tryFields && (
        <>
          <Case gridTemplateColumns="1fr 1fr 1fr">
            <InputField
              id="firstTry"
              name="firstTry"
              label="Primeira Tentativa"
              type="text"
              error={formik.errors.firstTry}
              onBlur={formik.handleBlur}
              onChange={(e) => {
                formik.handleChange(e);
              }}
              value={formik.values.firstTry}
              isError={formik.errors.firstTry && formik.touched.firstTry}
            />
            <InputField
              id="secondTry"
              name="secondTry"
              label="Segunda Tentativa"
              type="text"
              error={formik.errors.secondTry}
              onBlur={formik.handleBlur}
              onChange={(e) => {
                formik.handleChange(e);
              }}
              value={formik.values.secondTry}
              isError={formik.errors.secondTry && formik.touched.secondTry}
            />
            <InputField
              id="thirdTry"
              name="thirdTry"
              label="Terceira Tentativa"
              type="text"
              error={formik.errors.thirdTry}
              onBlur={formik.handleBlur}
              onChange={(e) => {
                formik.handleChange(e);
              }}
              value={formik.values.thirdTry}
              isError={formik.errors.thirdTry && formik.touched.thirdTry}
            />
          </Case>
        </>
      )}
      {successField && (
        <>
          <Case gridTemplateColumns="1fr">
            <CheckButtonDiv>
              <CheckBooleanField
                id="success"
                name="success"
                label="Sucesso"
                type="checkbox"
                error={formik.errors.success}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.success}
              />
            </CheckButtonDiv>
          </Case>
        </>
      )}
      <TextAreaField
        id="text"
        name="text"
        label="Descrição"
        placeholder="Escreva o motivo do Diário"
        isRequired
        error={formik.errors.text}
        onBlur={formik.handleBlur}
        onChange={(e) => {
          formik.handleChange(e);
        }}
        value={formik.values.text}
        isError={formik.errors.text && formik.touched.text}
        height="200px"
      />
      <ButtonCase>
        <CancelButton onClose={handleCancel}>Cancelar</CancelButton>
        <Button type="submit">Salvar</Button>
      </ButtonCase>
    </Form>
  );
};

const Form = styled.form`  
  padding: 5px;
  width: 50vw;

  @media(max-width: 800px) {
    width: 100%;
    overflow: auto;
  }
`;

const CheckButtonDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 13px;
`;

DiaryAngelForm.propTypes = {
  validations: PropTypes.objectOf(PropTypes.any).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  values: PropTypes.objectOf(PropTypes.any).isRequired,
  visibleFields: PropTypes.func,
};

DiaryAngelForm.defaultProps = {
  visibleFields: false
};

export default DiaryAngelForm;
