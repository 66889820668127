import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';

import { useCalls } from 'providers/calls';
import getProfile from 'helpers/getProfile';
import LoadingBlocked from 'components/common/LoadingBlocked';

import Modal from 'components/common/Modal';
import Button from 'components/common/Button';
import SelectField from 'components/common/SelectField';
import TextAreaField from 'components/common/TextAreaField';
import CancelButton from 'components/common/CancelButton';
import ButtonCase from 'components/common/ButtonCase';

const CallTransfer = ({ data }) => {
  const {
    userTypes,
    users,
    updateTransferCall,
    blockLoading
  } = useCalls();
  const profile = getProfile();
  const [visible, setVisible] = useState(false);
  const [options, setOptions] = useState(0);
  const [hidenOption, setHidenOptions] = useState(true);
  const [usersByOptions, setUsersByOptions] = useState([]);

  const title = 'Transferir Chamado';

  useEffect(() => {
    if (options === '5') {
      setHidenOptions(false);
    } else {
      setHidenOptions(true);
    }
  }, [options]);

  useEffect(() => {
    const fetchData = async () => {
      if (options !== 0) {
        const results = users.filter(
          (i) => (i.usertypeId === parseInt(options, 10))
        );
        setUsersByOptions(results);
      } else {
        setUsersByOptions(users);
      }
    };
    fetchData();
  }, [options]);

  const values = {
    text: '',
    sector: '',
  };

  const validationSchema = {
    text: Yup.string().required('Informe o motivo da transferência'),
    sector: Yup.string().required('Informe o setor responsável'),
  };

  const Submit = async (formData) => {
    const processedData = formData;
    if (options === '5') {
      processedData.userId = data.stores.consultant_id;
    }
    const newData = { transferUser: profile.user.id, ...processedData };
    await updateTransferCall(data.id, newData);
    setVisible(false);
  };

  const formik = useFormik({
    initialValues: values,
    onSubmit: Submit,
    validationSchema: Yup.object(validationSchema),
  });

  useEffect(() => {
    if (options === '3') {
      formik.setFieldValue('userId', data.stores.angel_id);
    }
  }, [options]);

  const onCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <LoadingBlocked active={blockLoading} />
      <ButtonCase>
        <Button onClick={() => setVisible(true)}>Transferir</Button>
      </ButtonCase>
      <Modal visible={visible} onClose={() => setVisible(false)} title={title}>
        <BodyModal>
          <Form data-testid="client-form" onSubmit={formik.handleSubmit}>
            <SelectField
              id="sector"
              name="sector"
              label="Setor Responsável"
              isRequired
              value={formik.values.sector}
              onBlur={formik.handleBlur}
              onChange={(e) => {
                formik.handleChange(e);
                // setOptions(e.nativeEvent.srcElement.options.selectedIndex);
                setOptions(e.target.value);
              }}
              options={userTypes}
              error={formik.errors.sector}
              isError={formik.errors.sector && formik.touched.sector}
            />
            {hidenOption && (
            <SelectField
              id="userId"
              name="userId"
              label="Responsável Pelo Chamado"
              value={formik.values.userId}
              onBlur={formik.handleBlur}
              onChange={(e) => {
                formik.handleChange(e);
              }}
              options={usersByOptions}
              error={formik.errors.userId}
              isError={formik.errors.userId && formik.touched.userId}
            />
            )}
            {/* {options === '3' && (
              <><span>Responsável:</span> <span style={{
                 textTransform: 'capitalize' }}>{localStorage.getItem('storeAngel')}</span></>
            )} */}
            {options === '5' && (
              <><span>Responsável:</span> <span style={{ textTransform: 'capitalize' }}>{localStorage.getItem('storeConsultant')}</span></>
            )}
            <TextAreaField
              id="text"
              name="text"
              label="Descrição"
              placeholder="Escreva sobre a tranferência do Chamado"
              isRequired
              error={formik.errors.text}
              onBlur={formik.handleBlur}
              onChange={(e) => {
                formik.handleChange(e);
              }}
              value={formik.values.text}
              isError={formik.errors.text && formik.touched.text}
              height="200px"
            />
            <ButtonCase>
              <CancelButton onClose={onCancel}>Cancelar</CancelButton>
              <Button type="submit">Transferir</Button>
            </ButtonCase>
          </Form>
        </BodyModal>
      </Modal>
    </>
  );
};

const BodyModal = styled.div`
  width: 30vw;

  @media(max-width: 800px) {
    width: 100%;
    overflow: auto;
  }
`;

const Form = styled.form`  
  padding: 5px;
  width: 30vw;

  @media(max-width: 800px) {
    width: 100%;
    overflow: auto;
  }
`;

CallTransfer.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default CallTransfer;
