import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { IoFilterOutline } from 'react-icons/io5';
import BasePage from 'components/layout/BasePage';

import { getUsers } from 'service/UsersService';
import { getUserType } from 'service/UserTypeService';

import TitleBar from 'components/layout/TitleBar';
import Loading from 'components/common/Loading';
import Case from 'components/common/Case';
import FilterGrid from 'components/common/FilterGrid';
import SwitchButton from 'components/common/SwitchButton';
import SearchBar from 'components/common/SearchBar';
import { getProfileUserType } from 'helpers/getProfileUserType';

import UsersGrid from './UsersGrid';
import UsersCreate from './UsersCreate';

const Users = () => {
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [activeValue, setActiveValue] = useState(true);
  const [usersType, setUsersType] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [showFilter, setShowFilter] = useState(false);
  const [debouncedValue, setDebouncedValue] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      const usersData = await getUsers();
      const results = usersData.filter(
        (i) => (i.active === activeValue)
      );
      setUsers(results);
      setLoading(false);
      const userTypeValues = await getUserType();
      setUsersType(userTypeValues);
    };
    fetchData();
  }, [activeValue]);

  useEffect(() => {
    const results = users.filter(
      (i) => (i.name.toLowerCase().includes(searchValue.toLowerCase())
      || i.email.toLowerCase().includes(searchValue.toLowerCase())
      )
    );
    setUsers(results);
  }, [searchValue, activeValue]);

  useEffect(() => {
    const results = users.filter(
      (i) => (i.usertype.name.toLowerCase().includes(debouncedValue.toLowerCase())
      )
    );
    setUsers(results);
  }, [debouncedValue, activeValue]);

  return (
    <>
      <BasePage>
        <TopDesktop>
          <Case gridTemplateColumns="1fr 1fr 6fr 1fr">
            {!showFilter && (
              <>
                <TitleBar>
                  Usuários
                </TitleBar>
                <SearchBar setValue={setSearchValue} />
                <IoFilterOutline style={{ cursor: 'pointer', fontSize: '25px' }} onClick={() => setShowFilter(!showFilter)} />
                {getProfileUserType([1]) && <UsersCreate usersType={usersType} />}
              </>
            )}
          </Case>
          <FilterGrid visible={showFilter} onCLose={() => setShowFilter(!showFilter)}>
            <div>
              Busque pelo cargo:
              <SearchBar placeholder="Cargo" marginLeft="0px" width="180px" setValue={setDebouncedValue} />
            </div>
            <div>
              Mostrar usuários inativos?
              <SwitchButton value={activeValue} setActiveValue={setActiveValue} />
            </div>
          </FilterGrid>
        </TopDesktop>
        <TopMobile>
          <TitleBar>
            Usuários
          </TitleBar>
        </TopMobile>
        <Loading active={loading} />
        <UsersGrid users={users} usersType={usersType} />
      </BasePage>
    </>
  );
};

const TopDesktop = styled.div`
  @media(max-width: 800px) {
    display: none
  }
`;

const TopMobile = styled.div`
  @media(min-width: 800px) {
    display: none;
  }
`;
export default Users;
