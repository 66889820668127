import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import { createDocumentation, confirmDocumentationFile } from 'service/StoreDocumentationService';
import { useProfile } from 'providers/Stores/profile';
import { toastError } from 'helpers/notifications';

import FileField from 'components/common/FileField';
import InputField from 'components/common/InputField';
import Button from 'components/common/Button';
import ButtonCase from 'components/common/ButtonCase';

const DocumentationForm = ({ typeId }) => {
  const { searchedStore, setImportModal, searchStore } = useProfile();
  const [validateButton, setValidadeButton] = useState(false);

  const handleSubmit = async (values) => {
    const data = values;
    setValidadeButton(true);
    const confirmDocPath = await confirmDocumentationFile(`private/stores/${searchedStore.id}`, data.fileName);
    if (confirmDocPath.status) {
      data.filePath = `${confirmDocPath.filePath}/${data.fileName}`;
      await createDocumentation(data);
      setImportModal(false);
      searchStore();
    } else {
      setImportModal(false);
      searchStore();
      toastError('Algo deu errado e o Documento não foi salvo!');
    }
  };

  const validateExpiredTime = [3, 5, 6];

  const formik = useFormik({
    initialValues: {
      storeId: searchedStore.id,
      type: typeId,
      fileName: '',
      expiredAt: '',
    },
    onSubmit: handleSubmit,
    validationSchema: Yup.object({
      fileName: Yup.string().required('Selecione um documento'),
      expiredAt: Yup
        .string()
        .when('type', {
          is: () => validateExpiredTime.indexOf(typeId) > -1,
          then: Yup.string().required('Data de expiração obrigatória')
        }),
    }),
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <FileField
        name="fileName"
        acceptFiles="application/pdf"
        id="fileName"
        label="Documento"
        internalLabel="Clique ou arraste seu arquivo"
        isRequired
        error={formik.errors.fileName}
        setFieldValue={formik.setFieldValue}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        value={formik.initialValues.fileName}
        isError={formik.errors.fileName && formik.touched.fileName}
        route="temp"
        maximumSize={10000000}
      />
      {(validateExpiredTime.indexOf(typeId) > -1) && (
        <InputField
          id="expiredAt"
          name="expiredAt"
          label="Data de Vencimento"
          placeholder="Informe o dia, mês e ano"
          type="date"
          isRequired
          error={formik.errors.expiredAt}
          onBlur={formik.handleBlur}
          onChange={(e) => {
            formik.handleChange(e);
          }}
          value={formik.values.expiredAt}
          isError={formik.errors.expiredAt && formik.touched.expiredAt}
        />
      )}
      <ButtonCase>
        <Button type="submit" disabled={validateButton}>Enviar</Button>
      </ButtonCase>
    </Form>
  );
};

const Form = styled.form`
  width: 550px;
  padding: 15px;
`;

DocumentationForm.propTypes = {
  typeId: PropTypes.number.isRequired,
};

export default DocumentationForm;
