import React from 'react';
import { AiOutlineUser } from 'react-icons/ai';
import { BsPeople } from 'react-icons/bs';
import { FiPhoneCall } from 'react-icons/fi';

import Loading from 'components/common/Loading';
import CallsDashboard from 'components/dashboard/calls/CallsDashboard';
import Case from 'components/common/Case';
import Refresh from 'components/common/Refresh';
import InfoCard from 'components/common/InfoCard';
import ContactDashboard from 'components/dashboard/contacts/ContactDashboard';

import { useIntConsultantDashboard } from 'providers/Dashboard/internalConsultant';

function Dashboard() {
  const { intConsultantDashboardData, loading } = useIntConsultantDashboard();

  function setComponent() {
    if (!intConsultantDashboardData) {
      return (<Refresh />);
    } return (
      <>
        <Loading active={loading} />
        { !loading && (
        <>
          <Case gridTemplateColumns="1fr 1fr 1fr 1fr">
            <InfoCard title="CHAMADOS ATIVOS MEUS" backgroundColor="#DD4B39" data={intConsultantDashboardData.userActiveCalls}>
              <AiOutlineUser style={{ color: 'white' }} />
            </InfoCard>
            <InfoCard title="CHAMADOS ATIVOS SETOR" backgroundColor="#ffcd17" data={intConsultantDashboardData.sectorActiveCalls}>
              <BsPeople style={{ color: 'white' }} />
            </InfoCard>
            <InfoCard title="TODOS CONTATOS" backgroundColor="#139F5B" data={intConsultantDashboardData.numberOfContacts}>
              <FiPhoneCall style={{ color: 'white' }} />
            </InfoCard>
            <InfoCard title="CONTATOS TELEFONE" backgroundColor="#4285F4" data={intConsultantDashboardData.phoneContactsOfMonth}>
              <FiPhoneCall style={{ color: 'white' }} />
            </InfoCard>
          </Case>
          <CallsDashboard data={intConsultantDashboardData.activeCalls} />
          <ContactDashboard data={intConsultantDashboardData} title="MEUS CONTATOS" />
        </>
        )}
      </>
    );
  }
  return (
    <>
      {setComponent()}
    </>
  );
}

export default Dashboard;
