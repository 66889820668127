import styled from 'styled-components';

const SwitchCase = styled.div`
  display: grid;
  justify-content: flex-end;
  cursor: pointer;
  margin-right: 5px;
  
  @media(max-width: 800px) {
    display: none;
  }
`;

export default SwitchCase;
