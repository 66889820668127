import React from 'react';
import moment from 'moment';

import { DeleteAlert } from 'components/alert';
import { useDiary } from 'providers/diary';

import {
  IoTrashOutline,
} from 'react-icons/io5';

// import {
//   AiOutlineArrowLeft,
//   AiOutlineArrowDown
// } from 'react-icons/ai';

import {
  TableCaseIcon,
  TableIcon
} from 'components/tableComponents';
import getProfile from 'helpers/getProfile';
import { toastError } from 'helpers/notifications';
import NoData from 'components/common/NoData';

import Table from 'components/tableComponents/CompleteTable';

import DiaryEdit from './DiaryEdit';
import ViewData from './ViewData';

const DiaryTable = () => {
  const { deleteRegister, diaryData, loading } = useDiary();

  const deleteDiaryById = async (id, userId) => {
    const profile = await getProfile();
    if ((profile.user.id === userId) || (profile.user.id === 1)) {
      await DeleteAlert(id, 'Tem certeza que excluir o registro dessa visita?', deleteRegister);
    } else {
      toastError('Você não tem permissão para excluir o registro de outro usuário!');
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        defaultFilter: true,
      },
      {
        Header: 'COD',
        accessor: 'stores.legacyCode',
        defaultFilter: true,
      },
      {
        Header: 'RAZÃO SOCIAL',
        accessor: 'stores.storeName',
        defaultFilter: true,
        Cell: (i) => {
          const { cell } = i;
          const data = cell.row.original;
          return data.stores.storeName.toUpperCase();
        }
      },
      {
        Header: 'DESCRIÇÃO',
        accessor: 'text',
        defaultFilter: true,
      },
      {
        Header: 'TIPO',
        accessor: 'diarytypes.name',
        selectFilter: true
      },
      {
        Header: 'USUÁRIO',
        accessor: 'userData.name',
        defaultFilter: true,
        mineFilter: true,
        Cell: (i) => {
          const { cell } = i;
          const data = cell.row.original;
          return `${data.userData.name} - ${data.userData.usertype.name}`;
        }
      },
      {
        Header: 'DATA',
        accessor: 'date',
        Cell: (i) => {
          const { cell } = i;
          const data = cell.row.original;
          return moment(data.date).format('DD/MM/YYYY');
        }
      },
      {
        Header: '',
        accessor: 'diarytypes.id',
        Cell: (i) => {
          const { cell } = i;
          const data = cell.row.original;
          return (
            <TableCaseIcon>
              <DiaryEdit data={data} exibition="table" />
              <ViewData data={data} exibition="table" />
              <TableIcon>
                <IoTrashOutline title="Excluir" onClick={() => deleteDiaryById(data.id, data.userData.id)} />
              </TableIcon>
            </TableCaseIcon>
          );
        }
      }
    ],
    []
  );

  return (
    <>
      {((diaryData.length > 0) && (loading === false) && (
        <Table data={diaryData} columns={columns} marginTop="-30px" />
      ))}
      {(diaryData.length === 0) && (loading === false) && (
        <NoData />
      )}
    </>
  );
};

export default DiaryTable;
