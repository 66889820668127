import React, { useState } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import PropTypes from 'prop-types';

import { useCalls } from 'providers/calls';

import { CaseIcon } from 'components/card';
import { TableIcon } from 'components/table';
import Modal from 'components/common/Modal';

import {
  IoCreateOutline,
} from 'react-icons/io5';

import CallsForm from './CallsForm';

const CallsEdit = ({ data, exibition }) => {
  const { update } = useCalls();
  const [visible, setVisible] = useState(false);

  const title = 'Editar Chamado';

  const initialValues = {
    storeName: data.stores.storeName,
    text: data.text,
  };

  const validationSchema = {
    text: Yup.string().required('Informe o motivo do Chamado'),
  };

  const changeModalState = (parameter) => {
    setVisible(parameter);
    if (parameter === false) {
      localStorage.removeItem('searchStoreID');
      localStorage.removeItem('searchStoreName');
      localStorage.removeItem('storeAngel');
      localStorage.removeItem('storeConsultant');
    }
  };

  const onCancel = () => {
    changeModalState(false);
    localStorage.removeItem('searchStoreID');
    localStorage.removeItem('searchStoreName');
    localStorage.removeItem('storeAngel');
    localStorage.removeItem('storeConsultant');
  };

  const onSubmit = async (values) => {
    const dataForm = values;

    const storeId = localStorage.getItem('searchStoreID');

    if (storeId) {
      dataForm.storeId = storeId;
    }

    await update(data.id, dataForm);
    setVisible(false);
    localStorage.removeItem('searchStoreID');
    localStorage.removeItem('searchStoreName');
    localStorage.removeItem('storeAngel');
    localStorage.removeItem('storeConsultant');
  };

  return (
    <>
      {exibition === 'card' && (
        <CaseIcon marginleft="5px">
          <IoCreateOutline title="Editar" onClick={() => changeModalState(true)} />
        </CaseIcon>
      )}
      {exibition === 'table' && (
        <TableIcon>
          <IoCreateOutline title="Editar" onClick={() => changeModalState(true)} />
        </TableIcon>
      )}
      { visible && (
        <Modal visible={visible} onClose={() => changeModalState(false)} title={title}>
          <BodyModal>
            <CallsForm
              values={initialValues}
              handleSubmit={onSubmit}
              handleCancel={onCancel}
              validations={validationSchema}
              editForm={1}
            />
          </BodyModal>
        </Modal>
      )}
    </>
  );
};

const BodyModal = styled.div`
  width: 30vw;

  @media(max-width: 800px) {
    width: 100%;
    overflow: auto;
  }
`;

CallsEdit.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  exibition: PropTypes.string.isRequired,
};

export default CallsEdit;
