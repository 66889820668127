import React from 'react';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import styled from 'styled-components';

import FieldSection from 'components/common/FieldSection';
import SelectField from 'components/common/SelectField';
import InputField from 'components/common/InputField';
import Case from 'components/common/Case';
import Button from 'components/common/Button';

import { useStores } from 'providers/Stores/stores';

import fetchAddress from 'service/ViaCepService';

const GenStoreForm = ({
  validations, values, handleSubmit
}) => {
  const formik = useFormik({
    initialValues: values,
    validationSchema: Yup.object(validations),
    onSubmit: handleSubmit,
  });

  const {
    angels,
    consultant,
    commercialAttendant
  } = useStores();

  return (
    <Form onSubmit={formik.handleSubmit}>
      <FieldSection title="Dados de Drogaria">
        <Case gridTemplateColumns="1fr 1fr">
          <InputField
            id="storeName"
            name="storeName"
            label="Razão Social:"
            placeholder="Razão Social"
            isRequired
            type="text"
            error={formik.errors.storeName}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.storeName}
            isError={formik.errors.storeName && formik.touched.storeName}
          />
          <InputField
            id="email"
            name="email"
            label="Email da Drogaria:"
            placeholder="exemplo@exemplo.com"
            isRequired
            type="text"
            error={formik.errors.email}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.email}
            isError={formik.errors.email && formik.touched.email}
          />
        </Case>
        <Case gridTemplateColumns="1.7fr 1fr">
          <InputField
            id="tradeName"
            name="tradeName"
            label="Nome Fantasia:"
            placeholder="Nome Fantasia"
            isRequired
            type="text"
            error={formik.errors.tradeName}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.tradeName}
            isError={formik.errors.tradeName && formik.touched.tradeName}
          />
          <InputField
            id="phoneNumber"
            name="phoneNumber"
            label="Telefone da Drogaria:"
            placeholder="(00)9 0000-0000"
            isRequired
            type="text"
            error={formik.errors.phoneNumber}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.phoneNumber}
            isError={formik.errors.phoneNumber && formik.touched.phoneNumber}
            mask="(99)9 9999-9999"
          />
        </Case>
      </FieldSection>
      <FieldSection title="Dados de Endereço">
        <Case gridTemplateColumns="1fr 2.5fr 0.8fr 2fr">
          <InputField
            id="zipcode"
            name="zipcode"
            label="CEP:"
            placeholder="00000-000"
            isRequired
            type="text"
            error={formik.errors.zipcode}
            onBlur={formik.handleBlur}
            onChange={(e) => {
              formik.handleChange(e);
              fetchAddress(e, formik.setFieldValue);
            }}
            value={formik.values.zipcode}
            isError={formik.errors.zipcode && formik.touched.zipcode}
          />
          <InputField
            id="address"
            name="address"
            label="Logradouro:"
            placeholder="Rua Exemplo"
            isRequired
            type="text"
            error={formik.errors.address}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.address}
            isError={formik.errors.address && formik.touched.address}
          />
          <InputField
            id="number"
            name="number"
            label="Número:"
            placeholder="000"
            isRequired
            type="text"
            error={formik.errors.number}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.number}
            isError={formik.errors.number && formik.touched.number}
          />
          <InputField
            id="neighborhood"
            name="neighborhood"
            label="Bairro:"
            placeholder="Bairro Exemplo"
            isRequired
            type="text"
            error={formik.errors.neighborhood}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.neighborhood}
            isError={formik.errors.neighborhood && formik.touched.neighborhood}
          />
        </Case>
        <Case gridTemplateColumns="3fr 1fr 4fr">
          <InputField
            id="city"
            name="city"
            label="Cidade:"
            placeholder="Cidade Exemplo"
            isRequired
            type="text"
            error={formik.errors.city}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.city}
            isError={formik.errors.city && formik.touched.city}
          />
          <InputField
            id="state"
            name="state"
            label="UF:"
            placeholder="EX"
            isRequired
            type="text"
            error={formik.errors.state}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.state}
            isError={formik.errors.state && formik.touched.state}
          />
          <InputField
            id="complement"
            name="complement"
            label="Complemento:"
            placeholder="Complemento Exemplo"
            type="text"
            error={formik.errors.complement}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.complement}
            isError={formik.errors.complement && formik.touched.complement}
          />
        </Case>
      </FieldSection>
      <FieldSection title="Dados de Controle">
        <Case gridTemplateColumns="1fr 1fr 1fr">
          <SelectField
            id="angelId"
            name="angelId"
            label="Anjo"
            value={formik.values.angelId}
            onBlur={formik.handleBlur}
            onChange={(e) => {
              formik.handleChange(e);
            }}
            options={angels}
            error={formik.errors.angelId}
            isRequired
            isError={formik.errors.angelId && formik.touched.angelId}
          />
          <SelectField
            id="consultantId"
            name="consultantId"
            label="Consultor"
            value={formik.values.consultantId}
            onBlur={formik.handleBlur}
            onChange={(e) => {
              formik.handleChange(e);
            }}
            options={consultant}
            error={formik.errors.consultantId}
            isRequired
            isError={formik.errors.consultantId && formik.touched.consultantId}
          />
          <SelectField
            id="commercialAttendantId"
            name="commercialAttendantId"
            label="Atendente Comercial"
            value={formik.values.commercialAttendantId}
            onBlur={formik.handleBlur}
            onChange={(e) => {
              formik.handleChange(e);
            }}
            options={commercialAttendant}
            error={formik.errors.commercialAttendantId}
            isRequired
            isError={formik.errors.commercialAttendantId && formik.touched.commercialAttendantId}
          />
        </Case>
      </FieldSection>
      <ButtonDiv>
        <Button type="submit">
          Gerar Drogaria
        </Button>
      </ButtonDiv>
    </Form>
  );
};

const Form = styled.form`  
  padding: 5px;
  overflow: auto;
`;

const ButtonDiv = styled.div`
  text-align: right;
`;

GenStoreForm.propTypes = {
  validations: PropTypes.objectOf(PropTypes.any).isRequired,
  values: PropTypes.objectOf(PropTypes.any).isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default GenStoreForm;
