import React from 'react';
import { ContractsProvider } from 'providers/Contracts/contracts';
import Contracts from './Contracts';

const index = () => (
  <ContractsProvider>
    <Contracts />
  </ContractsProvider>
);

export default index;
