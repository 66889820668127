import React, { useState } from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { IoCreateOutline } from 'react-icons/io5';

import { updateUser } from 'service/UsersService';

import { handleConditionalInput } from 'helpers/conditionalInput';
import Modal from 'components/common/Modal';
import CardIcon from 'components/card/CardIcon';

import UsersForm from './UsersForm';

const UsersCreate = ({ usersType, children }) => {
  const [visible, setVisible] = useState(false);

  const initialValues = {
    name: children.name,
    surname: children.surname || '',
    phoneNumber: children.phoneNumber,
    email: children.email,
    usertypeId: children.usertypeId,
    active: handleConditionalInput(children.active),
    username: children.username,
    password: '',
  };

  const changeModalState = (parameter) => {
    setVisible(parameter);
  };

  const onSubmit = async (values) => {
    await updateUser(children.id, values);
    document.location.reload();
  };

  const onCancel = () => {
    window.location.reload();
  };

  const validationSchema = {
    name: Yup.string().required('O nome é obrigatório para salvar o usuário'),
    surname: Yup.string().required('O sobrenome é obrigatório para salvar o usuário'),
    phoneNumber: Yup.string().required('O número de telefone é obrigatório para salvar o usuário'),
    email: Yup.string().email('Formato de email incorreto').required('O email é obrigatório para salvar o usuário'),
    usertypeId: Yup.string().required('O é obrigatório para salvar o usuário'),
    active: Yup.boolean().required('O status obrigatório para salvar o usuário'),
    username: Yup.string().required('O nome de usuário é obrigatório para salvar o usuário'),
  };

  return (
    <>
      <CardIcon>
        <IoCreateOutline onClick={() => changeModalState(true)} />
      </CardIcon>
      <Modal visible={visible} onClose={() => changeModalState(false)} title="Editar Usuário">
        <UsersForm
          values={initialValues}
          handleSubmit={onSubmit}
          handleCancel={onCancel}
          validations={validationSchema}
          usersType={usersType}
        />
      </Modal>
    </>
  );
};

UsersCreate.propTypes = {
  usersType: PropTypes.objectOf(PropTypes.any).isRequired,
  children: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default UsersCreate;
