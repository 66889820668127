import React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import verifyAclUser from 'helpers/verifyAclUser';

import { isAuthorized } from './isAuthorized';

const getToken = () => {
  const profile = localStorage.getItem('profile');
  if (!profile) {
    return false;
  }
  const AUTH_TOKEN = JSON.parse(profile);

  axios.defaults.headers.common.Authorization = `Bearer ${AUTH_TOKEN.token}`;

  return true;
};

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      (isAuthorized() && getToken() && verifyAclUser(rest.authProfiles))
        ? <Component {...props} />
        : <Redirect to={{ pathname: '/login' }} />
    )}
  />
);

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
};

export default PrivateRoute;
