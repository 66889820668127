import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Card = ({
  children,
  key,
  cardStyle,
  display,
  gridTemplateColumns
}) => (
  <Container
    key={key}
    cardStyle={cardStyle}
    display={display}
    gridTemplateColumns={gridTemplateColumns}
  >
    { children }
  </Container>
);

const Container = styled.div`
  display: ${(props) => props.display};
  grid-template-columns: ${(props) => props.gridTemplateColumns};
  flex: 1;
  flex-direction: column;
  border-radius: 3px;
  -webkit-box-shadow: 3px 3px 0px 0px rgba(180,198,212, 0.3);
  -moz-box-shadow:    3px 3px 0px 0px rgba(180,198,212, 0.3);
  box-shadow:         3px 3px 0px 0px rgba(180,198,212, 0.3);
  justify-content: space-between;
  padding: 10px;
  color: #4b5960;
  background-color: #F1F3F4;
  ${(props) => props.cardStyle}; 
`;

Card.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  key: PropTypes.string.isRequired,
  cardStyle: PropTypes.string,
  display: PropTypes.string,
  gridTemplateColumns: PropTypes.string,
};

Card.defaultProps = {
  cardStyle: '',
  display: 'flex',
  gridTemplateColumns: '1fr',
};
export default Card;
