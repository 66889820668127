import React from 'react';

function Lead() {
  const hi = 'ola, sou a pagina de Lead';
  return (
    <div>{hi}</div>
  );
}

export default Lead;
