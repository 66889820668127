import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import InputField from 'components/common/InputField';
import ButtonCase from 'components/common/ButtonCase';
import Button from 'components/common/Button';
import { toastError } from 'helpers/notifications';

import { useStores } from 'providers/Stores/stores';

import { getStoreContractByContractNumber } from 'service/ContractService';

const SearchStoreContract = ({ flag }) => {
  const {
    setCreatedStoreData,
    setCreateModalState,
    setActiveModalState,
    setActiveStoreData
  } = useStores();

  const handleFuction = (storeData) => {
    if (flag === '1') {
      setCreatedStoreData(storeData);
      setCreateModalState(1);
    } else {
      setActiveStoreData(storeData);
      setActiveModalState(1);
    }
  };

  const values = {
    contractCode: ''
  };
  const handleSubmit = async (value) => {
    const storeData = await getStoreContractByContractNumber(value.contractCode);
    if (storeData) {
      handleFuction(storeData);
    } else {
      toastError('Contrato não Encontrado!');
    }
  };
  const validations = {
    contractCode: Yup.string().required('Campo obrigatiório'),
  };

  const formik = useFormik({
    initialValues: values,
    onSubmit: handleSubmit,
    validationSchema: Yup.object(validations)
  });

  return (
    <Body>
      <form onSubmit={formik.handleSubmit}>
        <Case>
          <InputField
            id="contractCode"
            name="contractCode"
            label="Código do contrato:"
            placeholder="00000000X"
            isRequired
            type="text"
            error={formik.errors.contractCode}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.contractCode}
            isError={formik.errors.contractCode && formik.touched.contractCode}
          />
        </Case>
        <ButtonCase>
          <Button type="submit">Avançar</Button>
        </ButtonCase>
      </form>
    </Body>
  );
};

const Body = styled.div`
  width: 300px;
  margin: 10px;
`;

const Case = styled.div`
  height: 50px;
`;

SearchStoreContract.propTypes = {
  flag: PropTypes.string.isRequired,
};

export default SearchStoreContract;
