import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

function CardImage({
  src, boxShadow
}) {
  return (
    <Image
      src={src}
      boxShadow={boxShadow}
    />
  );
}

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  opacity: 0.5;
  transition: 1s;

  box-shadow: ${(props) => props.boxShadow};

  :hover {
    opacity: 1;
  }
`;

CardImage.propTypes = {
  src: PropTypes.string.isRequired,
  boxShadow: PropTypes.string,
};

CardImage.defaultProps = {
  boxShadow: '10px 10px 24px -14px rgba(0,0,0,0.75)',
};

export default CardImage;
