import React from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';

import CheckField from 'components/common/CheckField';
import FieldSection from 'components/common/FieldSection';
import TextAreaField from 'components/common/TextAreaField';
import Case from 'components/common/Case';
import Button from 'components/common/Button';
import ButtonCase from 'components/common/ButtonCase';
import CancelButton from 'components/common/CancelButton';

const InactiveForm = ({
  validations, handleSubmit, handleCancel, values
}) => {
  const formik = useFormik({
    initialValues: values,
    onSubmit: handleSubmit,
    validationSchema: Yup.object(validations),
  });

  return (
    <>
      <Form onSubmit={formik.handleSubmit}>
        <FieldSection title="Motivos de Desligamento">
          <Case gridTemplateColumns="1fr 1fr">
            <CheckField
              id="inactiveReasons"
              name="inactiveReasons"
              label="INADIMPLÊNCIA"
              type="checkbox"
              error={formik.errors.inactiveReasons}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value="nonPayment"
            />
            <CheckField
              id="inactiveReasons"
              name="inactiveReasons"
              label="FRUSTRAÇÃO COM A REDE"
              type="checkbox"
              error={formik.errors.inactiveReasons}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value="frustration"
            />
            <CheckField
              id="inactiveReasons"
              name="inactiveReasons"
              label="MIGROU PARA OUTRA REDE"
              type="checkbox"
              error={formik.errors.inactiveReasons}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value="migrated"
            />
            <CheckField
              id="inactiveReasons"
              name="inactiveReasons"
              label="REDUÇÃO DE CUSTO"
              type="checkbox"
              error={formik.errors.inactiveReasons}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value="reduction"
            />
            <CheckField
              id="inactiveReasons"
              name="inactiveReasons"
              label="DROGARIA FECHOU"
              type="checkbox"
              error={formik.errors.inactiveReasons}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value="close"
            />
            <CheckField
              id="inactiveReasons"
              name="inactiveReasons"
              label="DROGARIA VENDIDA"
              type="checkbox"
              error={formik.errors.inactiveReasons}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value="sell"
            />
            <CheckField
              id="inactiveReasons"
              name="inactiveReasons"
              label="OUTROS"
              type="checkbox"
              error={formik.errors.inactiveReasons}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value="others"
            />
          </Case>
        </FieldSection>
        <FieldSection title="Descrição">
          <TextAreaField
            id="description"
            name="description"
            placeholder="Escreva sobre o Desligamento"
            error={formik.errors.description}
            onBlur={formik.handleBlur}
            onChange={(e) => {
              formik.handleChange(e);
            }}
            value={formik.values.description}
            isError={formik.errors.description && formik.touched.description}
            height="150px"
          />
        </FieldSection>
        <ButtonCase>
          <CancelButton onClose={() => handleCancel(false)}>Cancelar</CancelButton>
          <Button type="submit">Salvar</Button>
        </ButtonCase>
      </Form>
    </>
  );
};

const Form = styled.form`
  width: 450px;
  padding: 7px;
`;

InactiveForm.propTypes = {
  validations: PropTypes.objectOf(PropTypes.any).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  values: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default InactiveForm;
