import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  getDashboardExpansion,
} from 'service/ReportService';
import getProfile from 'helpers/getProfile';

export const ExpansionDashboardContext = React.createContext({});

export const ExpansionDashboardProvider = ({ children }) => {
  const [expansionDashboardData, setExpansionDashboardData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const profile = getProfile();
      try {
        const data = await getDashboardExpansion(profile.user.id);
        setExpansionDashboardData(data);
      } catch {
        setExpansionDashboardData(false);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <ExpansionDashboardContext.Provider value={{
      expansionDashboardData,
      loading,
    }}
    >
      {children}
    </ExpansionDashboardContext.Provider>
  );
};

ExpansionDashboardProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export const useExpansionDashboard = () => React.useContext(ExpansionDashboardContext);
