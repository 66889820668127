import React from 'react';
import styled from 'styled-components';
import {
  IoTrashOutline
} from 'react-icons/io5';

import { useContracts } from 'providers/Contracts/contracts';

import { Card } from 'components/card';
import { phoneMask } from 'helpers/Mask';
import colors from 'assets/style/colors';

const Grid = () => {
  const { contacts, setContacts } = useContracts();
  const removeContact = (index) => {
    contacts.splice(index, 1);
    setContacts(Array.from(contacts));
    localStorage.setItem('contacts', JSON.stringify(contacts));
  };

  return (
    <Container>
      <CardGrid>
        {contacts.map((data, index) => {
          const id = index + 1;
          return (
            <Card key={id} cardStyle={{ backgroundColor: 'white' }}>
              <div><SubtitleCase>Nome: </SubtitleCase>{data.contactName}</div>
              <div><SubtitleCase>Email: </SubtitleCase>{data.contactEmail}</div>
              <div><SubtitleCase>Telefone: </SubtitleCase>{phoneMask(data.contactPhoneNumber)}</div>
              <div>
                {data.office.indexOf('mainContact') > -1 && <OfficeCase>Contato Principal</OfficeCase>}
                {data.office.indexOf('financialContact') > -1 && <OfficeCase>Contato Financeiro</OfficeCase>}
                {data.office.indexOf('general') > -1 && <OfficeCase>Comprador Geral</OfficeCase>}
                {data.office.indexOf('ethical') > -1 && <OfficeCase>Comprador de Ético</OfficeCase>}
                {data.office.indexOf('generic') > -1 && <OfficeCase>Comprador de Genérico</OfficeCase>}
                {data.office.indexOf('similar') > -1 && <OfficeCase>Comprador de Similar</OfficeCase>}
                {data.office.indexOf('perfumery') > -1 && <OfficeCase>Comprador de Perfumaria</OfficeCase>}
              </div>
              <div><IoTrashOutline style={{ marginLeft: '180px', cursor: 'pointer' }} onClick={() => removeContact(index)} /></div>
            </Card>
          );
        })}
      </CardGrid>
    </Container>
  );
};

const Container = styled.div`
  margin: 0 auto;
  width: 95%;
`;

const CardGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
  @media(max-width: 400px) {
  grid-template-columns: 1fr;
  }
`;

const SubtitleCase = styled.span`
  font-weight: bold;
`;

const OfficeCase = styled.div`
    border: 2px solid;
    border-radius: 25px;
    padding: 3px;
    margin: 3px;
    width: 190px;
    text-align: center;
    color: white;
    background-color: ${colors.primaryGreen};
`;

export default Grid;
