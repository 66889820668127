import axios from 'axios';
import { API } from 'helpers/constant';

const createStoreContacts = (contactData) => axios.post(`${API}/storeContacts`, contactData)
  .then((res) => res.data)
  .catch((error) => {
    if (!error.response) {
      return false;
    }
    return error.response.data;
  });

const editStoreContacts = (contactId, newData) => axios.put(`${API}/storeContacts/${contactId}`, newData)
  .then((res) => res.data)
  .catch((error) => {
    if (!error.response) {
      return false;
    }
    return error.response.data;
  });

const destroyStoreContacts = (id) => axios.delete(`${API}/storeContacts/${id}`)
  .then((res) => res.data)
  .catch((error) => error[0]);

export {
  createStoreContacts,
  editStoreContacts,
  destroyStoreContacts
};
