import React, { useState, useEffect } from 'react';
import BasePage from 'components/layout/BasePage';
import {
  getStoreContacts
} from 'service/ReportService';
import Financial from './Financial';

const index = () => {
  const [financialData, setFinancialData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getStoreContacts();
      setFinancialData(data);
    };
    fetchData();
  }, []);

  return (
    <BasePage>
      <Financial financialData={financialData} />
    </BasePage>
  );
};

export default index;
