import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { IoBanSharp, IoCheckmarkCircleOutline } from 'react-icons/io5';

import { useStores } from 'providers/Stores/stores';

import Modal from 'components/common/Modal';

import { CaseIcon } from 'components/card';
import { TableIcon } from 'components/table';
import Inactive from './Inactive';
import SearchStoreContract from '../CreateStore/SearchStoreContract';
import Active from './Active';

const index = ({ data, exibition }) => {
  const {
    activeModalState,
    setActiveModalState
  } = useStores();

  const [visibleInactive, setVisibleInactive] = useState(false);
  const [visibleActive, setVisibleActive] = useState(false);

  const changeModalStatus = (parameters) => {
    if (parameters) {
      setVisibleActive(true);
    } else {
      setVisibleActive(false);
      setActiveModalState(0);
    }
  };

  return (
    <>
      {exibition === 'card' && (
      <CaseIcon marginleft="10px">
        {data.active && <IoBanSharp title="Desativar" onClick={() => setVisibleInactive(true)} />}
        {!data.active && <IoCheckmarkCircleOutline title="Ativar" onClick={() => changeModalStatus(true)} />}
      </CaseIcon>
      )}
      {exibition === 'table' && (
        <TableIcon>
          {data.active && <IoBanSharp title="Desativar" onClick={() => setVisibleInactive(true)} />}
          {!data.active && <IoCheckmarkCircleOutline title="Ativar" onClick={() => changeModalStatus(true)} />}
        </TableIcon>
      )}
      <Modal visible={visibleInactive} onClose={() => setVisibleInactive(false)} title={`Desativar Drogaria ${data.legacyCode}`}>
        <Inactive id={data.id} setVisible={setVisibleInactive} />
      </Modal>
      <Modal visible={visibleActive} onClose={() => changeModalStatus(false)} title={`Ativar Drogaria ${data.legacyCode}`}>
        {(activeModalState === 0) && <SearchStoreContract flag="2" />}
        {(activeModalState === 1) && <Active oldStoreData={data} />}
      </Modal>
    </>
  );
};

index.propTypes = {
  data: PropTypes.arrayOf(PropTypes.element).isRequired,
  exibition: PropTypes.string.isRequired,
};

export default index;
