import React from 'react';

import Button from 'components/common/Button';
import Modal from 'components/common/Modal';
import LoadingBlocked from 'components/common/LoadingBlocked';
import verifyAclUser from 'helpers/verifyAclUser';
import acl from 'helpers/acl';

import { useStores } from 'providers/Stores/stores';

import SearchStoreContract from './SearchStoreContract';
import GenStoreByContract from './GenStoreByContract';

const index = () => {
  const {
    visible,
    setVisible,
    createModalState,
    setCreateModalState,
    createStoreLoading
  } = useStores();

  const changeModalStatus = (parameters) => {
    if (parameters) {
      setVisible(true);
    } else {
      setVisible(false);
      setCreateModalState(0);
    }
  };

  return (
    <>
      <LoadingBlocked active={createStoreLoading} />
      {(verifyAclUser(acl.create_store)) && <Button type="button" onClick={() => changeModalStatus(true)}>Novo</Button>}
      <Modal visible={visible} onClose={() => changeModalStatus(false)} title="Cadastro de drogaria">
        {(createModalState === 0) && <SearchStoreContract flag="1" />}
        {(createModalState === 1) && <GenStoreByContract />}
      </Modal>
    </>
  );
};

export default index;
