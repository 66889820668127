import React from 'react';
import styled from 'styled-components';
import FieldSection from 'components/common/FieldSection';

const Version = () => (
  <Page>
    <FieldSection title="Resumo - Versão 3.0.0" color="#224772">
      Este módulo contém informações sobre o conteúdo da versão oficial do sistema
      3.0 da Rede Melhor Compra. Para mais detalhes, consulte as seções
      Novas Funcionalidades, Alterações e Correções.
      <li>
        Disponibilizamos um formulário no Módulo FEEDBACK para coletar sugestões
        e reportar erros ou bugs no sistema. Seu Retorno é muito importante.
      </li>
    </FieldSection>
    <FieldSection title="Novas Funcionalidades" color="#93D367">
      <div style={{ marginTop: '10px' }}><span style={{ fontWeight: 'bold' }}>Módulo Home: </span>
        Onde se encontram os atalhos dos módulos oferecidos pelo sistema, será muito
        utilizado na versão mobile.
      </div>
      <div style={{ marginTop: '10px' }}><span style={{ fontWeight: 'bold' }}>Módulo Dashboard: </span>
        Onde são encontrados os gráficos resumindo informações importantes para o setor logado
        no sistema.
      </div>
      <div style={{ marginTop: '10px' }}><span style={{ fontWeight: 'bold' }}>Módulo Drogarias: </span>
        Nesse módulo se encontram as principais informações sobre as drogarias cadastradas
        no sistema.
      </div>
      <div style={{ marginTop: '10px' }}><span style={{ fontWeight: 'bold' }}>Módulo Diário: </span>
        Onde se encontram os diário das drogarias, através desse módulo é possível ter acesso ao
        histórico de informações sobre os Eventos, Visitas e Contatos de uma drogaria.
      </div>
      <div style={{ marginTop: '10px' }}><span style={{ fontWeight: 'bold' }}>Módulo Chamados: </span>
        Aqui se encontram todos os chamados que foram abertos para o seu setor, tendo destaque
        na parte superior aos que foram direcionados ao usuário logado.
      </div>
      <div style={{ marginTop: '10px' }}><span style={{ fontWeight: 'bold' }}>Módulo Usuários: </span>
        Nesse módulo é possível fazer a edição de seu usuário, inclusive alterar senha.
      </div>
    </FieldSection>
    <FieldSection title="Alterações" color="#224772">
      <span style={{ fontStyle: 'italic', fontWeight: 'lighter' }}>
        Essa versão não possui alterações.
      </span>
    </FieldSection>
    <FieldSection title="Correções" color="#FF6666">
      <span style={{ fontStyle: 'italic', fontWeight: 'lighter' }}>
        Essa versão não possui correções.
      </span>
    </FieldSection>
  </Page>
);

const Page = styled.div`
  margin: 10px;
`;

export default Version;
