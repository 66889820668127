import React from 'react';
import PropTypes from 'prop-types';

import AreaChart from 'components/common/AreaChart';
import CardChart from 'components/common/CardChart';
import Case from 'components/common/Case';
import PieChart from 'components/chart/PieChart';

function ContactDashboard({
  data,
  title,
  flowSubtitle,
  waySubtitle
}) {
  return (
    <>
      <AreaChart title={title}>
        <Case gridTemplateColumns="1fr 1fr">
          <CardChart title={flowSubtitle}>
            <PieChart data={data.contactsOfFlow} />
          </CardChart>
          <CardChart title={waySubtitle}>
            <PieChart data={data.contactsOfWay} />
          </CardChart>
        </Case>
      </AreaChart>
    </>
  );
}

ContactDashboard.propTypes = {
  data: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  flowSubtitle: PropTypes.string,
  waySubtitle: PropTypes.string,
};

ContactDashboard.defaultProps = {
  flowSubtitle: 'Fluxo de contatos do mês',
  waySubtitle: 'Tipos de contatos do mês',
};

export default ContactDashboard;
