import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment';

import {
  IoEyeOutline,
} from 'react-icons/io5';

import FieldSection from 'components/common/FieldSection';
import { CaseIcon } from 'components/card';
import { TableIcon } from 'components/table';
import Modal from 'components/common/Modal';
import { cnpjMask, phoneMask } from 'helpers/Mask';
import Case from 'components/common/Case';
import Scrollbar from 'components/common/Scrollbar';

const ViewData = ({ data, exibition }) => {
  moment.locale('pt-br');
  const [visible, setVisible] = useState(false);

  const title = `${data.legacyCode} - ${data.storeName}`;

  const closeModal = () => {
    setVisible(false);
  };
  const opemModal = () => setVisible(true);

  const handleServiceName = (servicesData) => {
    if (servicesData === 1) {
      return 'Não';
    }
    if (servicesData === 2) {
      return 'Sim';
    } return 'Sem Interesse';
  };

  const validateData = (value, key) => {
    if (value === null) {
      return 1;
    } return value[key];
  };

  const validateTxt = (value, txt, key) => {
    if (value === null) {
      return '';
    } if (value[key] !== 3) {
      return '';
    } return value[txt];
  };

  // const validateContact = (arr, key) => {
  //   const carveup = arr.map((cel) => {
  //     if (cel.mainContact) {
  //       if ((key === 'phoneNumber') && (cel.phoneNumber !== '')) {
  //         return phoneMask(cel.phoneNumber);
  //       }
  //       return cel[key];
  //     } return '';
  //   });
  //   return carveup;
  // };

  return (
    <>
      {exibition === 'card' && (
        <CaseIcon marginleft="10px">
          <IoEyeOutline title="Visualizar" onClick={() => opemModal()} />
        </CaseIcon>
      )}
      {exibition === 'table' && (
        <TableIcon>
          <IoEyeOutline title="Visualizar" onClick={() => opemModal()} />
        </TableIcon>
      )}
      { visible && (
        <Modal visible={visible} onClose={() => closeModal()} title={title}>
          <BodyModal>
            <Scrollbar height="450px">
              <FieldSection title="Dados do Drogaria">
                <div><span>Razão Social:</span> <span style={{ textTransform: 'uppercase', fontWeight: 'normal' }}>{data.storeName}</span></div>
                <div><span>Nome Fantasia:</span> <span style={{ textTransform: 'capitalize', fontWeight: 'normal' }}>{data.tradeName}</span></div>
                <div><span>CNPJ:</span> <span style={{ fontWeight: 'normal' }}>{cnpjMask(data.registrationNumber)}</span></div>
                <div><span>Telefone:</span> <span style={{ fontWeight: 'normal' }}>{phoneMask(data.phoneNumber)}</span></div>
                <div><span>Endereço:</span> <span style={{ textTransform: 'capitalize', fontWeight: 'normal' }}>{data.addressData.address}, {data.addressData.number}, {data.addressData.neighborhood}, {data.addressData.city} - {data.addressData.state}</span></div>
              </FieldSection>
              {/* <FieldSection title="Dados do Contato Principal">
                <div><span>Nome:</span> <span
                style={{ textTransform: 'capitalize', fontWeight: 'normal' }}>
                {validateContact(data.contactData, 'name')}</span></div>
                <div><span>Email:</span> <span
                style={{ fontWeight: 'normal' }}>
                {validateContact(data.contactData, 'email')}</span></div>
                <div><span>Telefone:</span> <span
                style={{ fontWeight: 'normal' }}>{validateContact(data.contactData,
                  'phoneNumber')}</span></div>
              </FieldSection> */}
              <FieldSection title="Dados de Controle">
                <div><span>Bandeira:</span> <span style={{ textTransform: 'uppercase', fontWeight: 'normal' }}>{data.brandData.name}</span></div>
                <div><span>Anjo:</span> <span style={{ textTransform: 'capitalize', fontWeight: 'normal' }}>{data.angelData.name}</span></div>
                <div><span>Consultor:</span> <span style={{ textTransform: 'capitalize', fontWeight: 'normal' }}>{data.consultantData.name}</span></div>
              </FieldSection>
              <FieldSection title="Serviços">
                <Case gridTemplateColumns="1fr 1fr">
                  <Dashed>
                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 2.8fr' }}><span>ABC FARMA:</span> <div>{handleServiceName(validateData(data.storeServicesData, 'abcfarma'))}</div></div>
                    {validateTxt(data.storeServicesData, 'abcfarmaTxt', 'abcfarma')}
                  </Dashed>
                  <Dashed>
                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 2fr' }}><span>FINANCIALCARD:</span> <div>{handleServiceName(validateData(data.storeServicesData, 'financialCard'))}</div></div>
                    {validateTxt(data.storeServicesData, 'financialCardTxt', 'financialCard')}
                  </Dashed>
                  <Dashed>
                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 7.5fr' }}><span>GEFAD:</span> <div>{handleServiceName(validateData(data.storeServicesData, 'gefad'))}</div></div>
                    {validateTxt(data.storeServicesData, 'gefadTxt', 'gefad')}
                  </Dashed>
                  <Dashed>
                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 7.5fr' }}><span>BRASILCARD:</span> <div>{handleServiceName(validateData(data.storeServicesData, 'brasilcard'))}</div></div>
                    {validateTxt(data.storeServicesData, 'brasilcardTxt', 'brasilcard')}
                  </Dashed>
                  <Dashed>
                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 2fr' }}><span>FARMÁCIA 360°:</span> <div>{handleServiceName(validateData(data.storeServicesData, 'farmacia360'))}</div></div>
                    {validateTxt(data.storeServicesData, 'farmacia360Txt', 'farmacia360')}
                  </Dashed>
                  <Dashed>
                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 7.5fr' }}><span>CONEKTA:</span> <div>{handleServiceName(validateData(data.storeServicesData, 'conekta'))}</div></div>
                    {validateTxt(data.storeServicesData, 'conektaTxt', 'conekta')}
                  </Dashed>
                  <Dashed>
                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 7.5fr' }}><span>CONTABILIDADE:</span> <div>{handleServiceName(validateData(data.storeServicesData, 'contabilidade'))}</div></div>
                    {validateTxt(data.storeServicesData, 'contabilidadeTxt', 'contabilidade')}
                  </Dashed>
                  <Dashed>
                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 7.5fr' }}><span>CIELO:</span> <div>{handleServiceName(validateData(data.storeServicesData, 'cielo'))}</div></div>
                    {validateTxt(data.storeServicesData, 'cieloTxt', 'cielo')}
                  </Dashed>
                  <Dashed>
                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 7.5fr' }}><span>CONSULT:</span> <div>{handleServiceName(validateData(data.storeServicesData, 'consult'))}</div></div>
                    {validateTxt(data.storeServicesData, 'consultTxt', 'consult')}
                  </Dashed>
                  <Dashed>
                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 7.5fr' }}><span>MEMED:</span> <div>{handleServiceName(validateData(data.storeServicesData, 'memed'))}</div></div>
                    {validateTxt(data.storeServicesData, 'memedTxt', 'memed')}
                  </Dashed>
                  <Dashed>
                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 7.5fr' }}><span>CLINICARX:</span> <div>{handleServiceName(validateData(data.storeServicesData, 'clinicarx'))}</div></div>
                    {validateTxt(data.storeServicesData, 'clinicarxTxt', 'clinicarx')}
                  </Dashed>
                  <Dashed>
                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 7.5fr' }}><span>FIDELIDADE:</span> <div>{handleServiceName(validateData(data.storeServicesData, 'fidelidade'))}</div></div>
                    {validateTxt(data.storeServicesData, 'fidelidadeTxt', 'fidelidade')}
                  </Dashed>
                  <Dashed>
                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 7.5fr' }}><span>PRECIFICAÇÃO:</span> <div>{handleServiceName(validateData(data.storeServicesData, 'precificacao'))}</div></div>
                    {validateTxt(data.storeServicesData, 'precificacaoTxt', 'precificacao')}
                  </Dashed>
                  <Dashed>
                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 7.5fr' }}><span>MYPHARMA:</span> <div>{handleServiceName(validateData(data.storeServicesData, 'mypharma'))}</div></div>
                    {validateTxt(data.storeServicesData, 'mypharmaTxt', 'mypharma')}
                  </Dashed>
                  <Dashed>
                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}><span>FINANCIAL CONVÊNIOS:</span> <div>{handleServiceName(validateData(data.storeServicesData, 'financialConvenios'))}</div></div>
                    {validateTxt(data.storeServicesData, 'financialConveniosTxt', 'financialConvenios')}
                  </Dashed>
                </Case>
              </FieldSection>
            </Scrollbar>
          </BodyModal>
        </Modal>
      )}
    </>
  );
};

const BodyModal = styled.div`
  padding: 5px;
  width: 60vw;
  span{
    font-size: 15px;
    font-weight: 600;
  }
`;

const Dashed = styled.div`
  border: dashed 2px #ccc;
  padding: 8px;
  margin: 10px;
`;

ViewData.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
  exibition: PropTypes.string.isRequired,
};

ViewData.defaultProps = {
  data: {}
};

export default ViewData;
