import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ExternalItemMenu = ({
  route,
  label,
  icon,
  color
}) => {
  const [style, setStyle] = useState('');

  useEffect(() => {
    const local = window.location.href.split('/')[3].toLowerCase();

    if (route === `/${local}`) {
      setStyle(`
        background-color: #F3F3F3; 
        color: #224772;    
        svg {
          color: #224772;
        } 
      `);
    }
  }, []);
  return (
    <>
      <Item onClick={() => window.open(`${route}`, '_blank')} itemstyle={style} color={color}>
        <Icon>{icon}</Icon>
        <Label icon={icon}>{label}</Label>
      </Item>
    </>
  );
};

const Label = styled.span`
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  text-transform: capitalize;
`;

const Icon = styled.span`
  font-size: 20px;
  margin: 5px;
`;

const Item = styled.div`
  height: 30px;
  text-decoration: none;

  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  cursor: pointer;
  color: ${(props) => props.color};
  font-weight: 450;
  transition: 0.3s;
  font-size: 15px;
  line-height: 5px;
  svg {
    color: #fff;
  }
  ${(props) => props.itemstyle}
  :hover {
    color: #224772;
    background-color: #F3F3F3;
    transition: 0.5s;
    svg {
      color: #224772;
    } 
  };
`;

ExternalItemMenu.propTypes = {
  route: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.objectOf(PropTypes.any).isRequired,
  color: PropTypes.string,
};

ExternalItemMenu.defaultProps = {
  color: '#fff',
};

export default ExternalItemMenu;
