import React from 'react';
import { AddressProvider } from 'providers/address';
import { ContractsProvider } from 'providers/Contracts/contracts';
import ContractsCreate from './ContractsCreate';

const exportCreateContract = () => (
  <ContractsProvider><AddressProvider><ContractsCreate /></AddressProvider></ContractsProvider>
);

export default exportCreateContract;
