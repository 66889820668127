import React from 'react';
import PropTypes from 'prop-types';
import Chart from 'react-google-charts';

function PieChart({ data, width }) {
  return (
    <>
      <Chart
        width={width}
        height="250px"
        chartType="PieChart"
        loader={<div>Loading Chart...</div>}
        data={[
          ['Status', 'Quantidade'],
          ...data,
        ]}
        options={{
          slices: {
            0: { color: '#DD4B39' },
            1: { color: '#4285F4' },
            2: { color: '#139F5B' },
            3: { color: '#FFCD17' },
            4: { color: '#b23eea' },
            5: { color: '#ff6f9c' },
          },
        }}
        rootProps={{ 'data-testid': '1' }}
      />
    </>
  );
}

PieChart.propTypes = {
  data: PropTypes.element.isRequired,
  width: PropTypes.string,
};

PieChart.defaultProps = {
  width: '400px',
};

export default PieChart;
