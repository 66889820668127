import React from 'react';

import BasePage from 'components/layout/BasePage';
import Loading from 'components/common/Loading';

import { useContracts } from 'providers/Contracts/contracts';

import ContractsGrid from './ContractsGrid';

const Contracts = () => {
  const { loading } = useContracts();

  return (
    <BasePage>
      <ContractsGrid />
      <Loading active={loading} />
    </BasePage>
  );
};

export default Contracts;
