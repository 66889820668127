import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const CardChart = ({ children, title }) => (
  <Card>
    <TitleSection>
      <Title>{title}</Title>
    </TitleSection>
    <Body>{children}</Body>
    <Footer />
  </Card>
);

const Card = styled.div`
  margin: 5px;
  padding: 5px;
  border: 4px solid #F3F3F3;
  border-radius: 5px;
  box-sizing: border-box;
  box-shadow: 2px 2px 2px rgb(0 0 0 / 20%);
  background-color: white;
`;

const TitleSection = styled.div`
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  padding-bottom: 5px;
  display: flex;
  width: 100%;
  background-color: #F3F3F3;
`;

const Title = styled.span`
  color: #838594;
  font-size: 16px;
  font-weight: 600;
  margin-left: 21px;
  padding: 5px;
  @media(max-width: 800px) {
  font-size: 15px;
  padding: 7px;
  }
`;

const Body = styled.div`
  background-color: white;
`;

const Footer = styled.span`
  padding: 5px;
`;

CardChart.propTypes = {
  children: PropTypes.element.isRequired,
  title: PropTypes.element.isRequired,
};

export default CardChart;
