import React from 'react';
import BasePage from 'components/layout/BasePage';
import { StoresAdminReportProvider } from 'providers/Reports/storesAdmin';
import StoresAdmin from './StoresAdmin';

const index = () => (
  <BasePage>
    <StoresAdminReportProvider>
      <StoresAdmin />
    </StoresAdminReportProvider>
  </BasePage>
);

export default index;
