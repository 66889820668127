import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import PropTypes from 'prop-types';

import verifyAclUser from 'helpers/verifyAclUser';
import acl from 'helpers/acl';

import { CgNotes } from 'react-icons/cg';

import { useDiary } from 'providers/diary';

import getProfile from 'helpers/getProfile';

import Swal from 'sweetalert2';
import Button from 'components/common/Button';
import { toastError } from 'helpers/notifications';
import LoadingBlocked from 'components/common/LoadingBlocked';
import Modal from 'components/common/Modal';

import DiaryForm from './DiaryForm';
import DiaryAngelForm from './DiaryAngelForm';
import CallsCreate from '../Calls/exportCallsCreate';

const DiaryCreate = ({ iconStyle, storeProfile }) => {
  const [visible, setVisible] = useState(false);
  const [visibleCalls, setVisibleCalls] = useState(false);
  const [loading, setLoading] = useState(false);
  const { create, diaryFinally } = useDiary();
  const title = 'Registro de Diário';

  const initialValues = {
    text: '',
    date: '',
    diarySubtype: '',
    flow: '',
    way: '',
    dealtWith: '',
    success: false,
    storeName: storeProfile ? localStorage.getItem('storeName') : '',
    defaultMetadata: verifyAclUser(acl.internal_consultant),
  };

  const setLocalStorageStoreProfile = () => {
    localStorage.setItem('searchStoreName', localStorage.getItem('storeName'));
    localStorage.setItem('searchStoreID', localStorage.getItem('storeId'));
    localStorage.setItem('storeAngel', localStorage.getItem('angelName'));
    localStorage.setItem('storeConsultant', localStorage.getItem('consultantName'));
  };

  useEffect(() => {
    if (storeProfile) {
      setLocalStorageStoreProfile();
    }
  }, []);

  const changeModalState = (parameter) => {
    setVisible(parameter);
    if (parameter === false) {
      localStorage.removeItem('searchStoreID');
      localStorage.removeItem('searchStoreName');
      localStorage.removeItem('storeAngel');
      localStorage.removeItem('storeConsultant');
    }
  };

  const onSubmit = async (values) => {
    const profile = getProfile();
    const data = values;
    data.storeId = localStorage.getItem('searchStoreID');
    if (!data.storeId) {
      toastError('Selecione uma Drogaria válida');
    } else {
      data.userId = profile.user.id;
      data.userTypeId = profile.user.userTypeId;
      await create(data);
      if (profile.user.userTypeId !== 3) {
        Swal.fire({
          title: 'Atenção',
          text: 'Deseja Abrir um Chamado',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#5CB85C',
          cancelButtonColor: '#CCCCCC',
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não'
        }).then(async (result) => {
          if (result.isConfirmed) {
            setVisible(false);
            setLoading(true);
            await new Promise(() => {
              setTimeout(
                async () => {
                  setLoading(false);
                  setVisibleCalls(true);
                },
                1000
              );
            });
          } else {
            localStorage.removeItem('searchStoreID');
            localStorage.removeItem('searchStoreName');
            localStorage.removeItem('storeAngel');
            localStorage.removeItem('storeConsultant');
            setVisible(false);
          }
        }).finally(() => diaryFinally());
      } else {
        localStorage.removeItem('searchStoreID');
        localStorage.removeItem('searchStoreName');
        localStorage.removeItem('storeAngel');
        localStorage.removeItem('storeConsultant');
        setVisible(false);
        diaryFinally();
      }
    }
  };

  const onCancel = () => {
    changeModalState(false);
    localStorage.removeItem('searchStoreID');
    localStorage.removeItem('searchStoreName');
    localStorage.removeItem('storeAngel');
    localStorage.removeItem('storeConsultant');
  };

  const validationSchema = {
    date: Yup.date().required('Informe o dia, mês e ano'),
    text: Yup.string().required('Informe o motivo do Diário'),
    diaryType: Yup.string().when('defaultMetadata', {
      is: (defaultMetadata) => defaultMetadata === true,
      then: Yup.string().required('I am required now the checkbox is checked')
    })
  };
  /* const UserId = getProfile();
  const changeButton = async () => {
    if (UserId.user.userTypeId === 5) {
      changeModalState(false);
    } else {
      changeModalState(false);
    }
  }; */ // Bloqueio de acesso por iD
  return (
    <>
      <LoadingBlocked active={loading} />
      <CallsCreate visibleCalls={visibleCalls} />
      {!iconStyle && (<Button onClick={() => changeModalState(false)}>Novo</Button>)}
      {iconStyle && (<DiaryIcon title="Novo diário" onClick={() => changeModalState(true)} />)}
      <Modal visible={visible} onClose={() => changeModalState(false)} title={title}>
        {!verifyAclUser(acl.diary_contact) && (
          <DiaryForm
            values={initialValues}
            handleSubmit={onSubmit}
            handleCancel={onCancel}
            validations={validationSchema}
          />
        )}
        {verifyAclUser(acl.diary_contact) && (
          <DiaryAngelForm
            values={initialValues}
            handleSubmit={onSubmit}
            handleCancel={onCancel}
            validations={validationSchema}
          />
        )}
      </Modal>
    </>
  );
};

DiaryCreate.propTypes = {
  iconStyle: PropTypes.bool,
  storeProfile: PropTypes.bool,
};

DiaryCreate.defaultProps = {
  iconStyle: false,
  storeProfile: false,
};

const DiaryIcon = styled(CgNotes)`
  cursor: pointer;
  margin: 0 5px;
`;

export default DiaryCreate;
