import React from 'react';
import { AiOutlineUser } from 'react-icons/ai';
import { BsPeople } from 'react-icons/bs';

import Loading from 'components/common/Loading';
import CallsDashboard from 'components/dashboard/calls/CallsDashboard';
import Case from 'components/common/Case';
import Refresh from 'components/common/Refresh';
import InfoCard from 'components/common/InfoCard';

import { useUserDashboard } from 'providers/Dashboard/user';

function Dashboard() {
  const { userDashboardData, loading } = useUserDashboard();

  function setComponent() {
    if (!userDashboardData) {
      return (<Refresh />);
    } return (
      <>
        <Loading active={loading} />
        { !loading && (
        <>
          <Case gridTemplateColumns="1fr 1fr 1fr 1fr">
            <InfoCard title="CHAMADOS ATIVOS MEUS" backgroundColor="#DD4B39" data={userDashboardData.userActiveCalls}>
              <AiOutlineUser style={{ color: 'white' }} />
            </InfoCard>
            <InfoCard title="CHAMADOS ATIVOS SETOR" backgroundColor="#ffcd17" data={userDashboardData.sectorActiveCalls}>
              <BsPeople style={{ color: 'white' }} />
            </InfoCard>
          </Case>
          <CallsDashboard data={userDashboardData.activeCalls} />
        </>
        )}
      </>
    );
  }
  return (
    <>
      {setComponent()}
    </>
  );
}

export default Dashboard;
