import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';
import { BiMenu } from 'react-icons/bi';

import TopBar from './TopBar';
import LeftMenu from './LeftMenu';
import Footer from './Footer';

const BasePage = ({ children }) => {
  const [menuState, setMenuState] = useState(true);

  const setCssMenu = (state) => {
    if (state) {
      return (
        'margin-left: 200px;'
      );
    } return '';
  };

  const setCssIcon = (state) => {
    if (state) {
      return (
        '200px;'
      );
    } return '20px';
  };

  return (
    <PageContainer>
      {menuState && (<LeftMenu />)}
      <RightSide>
        <SlideButtonDiv left={setCssIcon(menuState)}>
          <BiMenu onClick={() => setMenuState(!menuState)} />
        </SlideButtonDiv>
        <TopBar stateMenu={menuState} />
        <Content menuState={setCssMenu(menuState)}>
          {children}
        </Content>
        <Footer menuState={setCssMenu(menuState)} />
        <ToastContainer position="bottom-right" />
      </RightSide>
    </PageContainer>
  );
};

const PageContainer = styled.div`
  height: 100vh;
  @media(max-width: 800px) {
    display: grid;
    flex: 1;
    grid-template-columns: 1fr;
  }
  @media(max-width: 400px) {
    display: grid;
    flex: 1;
    grid-template-columns: 1fr;
  }
`;

const SlideButtonDiv = styled.div`
  cursor: pointer;
  position: fixed;
  font-size: 33px;
  top: 0;
  left: ${(props) => props.left};
  z-index: 1000;

  @media(max-width: 800px) {
    display: none;
  }
`;

const Content = styled.div`
  @media(min-width: 800px) { 
    margin-left: 10px;
    ${(props) => props.menuState}
    padding: 50px 10px 25px 10px;
    overflow: auto;
  }
`;

const RightSide = styled.div`
  background-color: #F3F3F3; 

  @media(max-width: 800px) {
    margin-left: 0;
  }
  
  @media(max-width: 400px) {
    margin: 0;
  }
`;

BasePage.propTypes = {
  children: PropTypes.element.isRequired,
};

export default BasePage;
