import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import InputField from './InputField';

const SearchBar = ({
  placeholder, marginLeft, width, setValue
}) => {
  const getSearchValue = (value, delay = 1000) => {
    const type = setTimeout(() => {
      setValue(value);
    }, delay);

    if (value === '') {
      window.location.reload();
    }
    return () => {
      clearTimeout(type);
    };
  };
  return (
    <Grid width={width} marginLeft={marginLeft}>
      <InputField
        type="text"
        placeholder={placeholder}
        onChange={(e) => getSearchValue(e.target.value)}
      />
    </Grid>
  );
};

const Grid = styled.div`
  width: ${(props) => props.width};
  margin-left: ${(props) => props.marginLeft};
`;

SearchBar.propTypes = {
  placeholder: PropTypes.string,
  marginLeft: PropTypes.string,
  width: PropTypes.string,
  setValue: PropTypes.func.isRequired
};

SearchBar.defaultProps = {
  placeholder: 'Digite algo para buscar',
  marginLeft: '25px',
  width: '350px'
};

export default SearchBar;
