import axios from 'axios';
import { API, UPLOAD_FILE_MICROSERVICE } from 'helpers/constant';

const getStores = () => axios.get(`${API}/stores`)
  .then((res) => res.data)
  .catch((error) => error[0]);

const getStoresByParameter = (searcher) => axios.post(`${API}/stores/searchStoreByParameter`, searcher)
  .then((res) => res.data)
  .catch((error) => error[0]);

const createStores = (storeData) => axios.post(`${API}/stores`, storeData)
  .then((res) => res.data)
  .catch((error) => {
    if (!error.response) {
      return false;
    }
    return error.response.data;
  });

const changeStoreStatus = (id, state) => axios.put(`${API}/stores/status/${id}`, state, {
})
  .then((res) => res.data)
  .catch((error) => error[0]);

const updateStore = (id, newData) => axios.put(`${API}/stores/${id}`, newData, {
})
  .then((res) => res.data)
  .catch((error) => error[0]);

const basicUpdateStore = (id, newData) => axios.put(`${API}/stores/basic/${id}`, newData, {
})
  .then((res) => res.data)
  .catch((error) => error[0]);

const updateControlData = (id, newData) => axios.put(`${API}/stores/updateControlData/${id}`, newData)
  .then((res) => res.data)
  .catch((error) => error[0]);

const getStatusStoreServices = () => axios.get(`${API}/statusService`)
  .then((res) => res.data)
  .catch((error) => error[0]);

const getStoresHistory = (id) => axios.get(`${API}/stores/history/${id}`)
  .then((res) => res.data)
  .catch((error) => error[0]);

const getToken = (registrationNumber) => axios.get(`${API}/stores/linkedfarma/${registrationNumber}`)
  .then((res) => res.data)
  .catch((error) => error[0]);

const getStore = (id) => axios.get(`${API}/stores/${id}`)
  .then((res) => res.data)
  .catch((error) => error[0]);

const updateToken = (registrationNumber, newData) => axios.put(`${API}/stores/linkedfarma/${registrationNumber}`, newData)
  .then((res) => res.data)
  .catch((error) => error[0]);

const uploadService = (data) => axios.post(`${UPLOAD_FILE_MICROSERVICE}/storesService`, data)
  .then((res) => res.data)
  .catch((error) => error[0]);

export {
  getStores,
  getStore,
  createStores,
  changeStoreStatus,
  updateStore,
  updateControlData,
  getStatusStoreServices,
  getStoresHistory,
  getStoresByParameter,
  getToken,
  uploadService,
  updateToken,
  basicUpdateStore
};
