import React from 'react';
import { AiOutlineUser } from 'react-icons/ai';
import { BsPeople } from 'react-icons/bs';

import Loading from 'components/common/Loading';
import CallsLeadersDashboard from 'components/dashboard/calls/CallsLeadersDashboard';
import Case from 'components/common/Case';
import InfoCard from 'components/common/InfoCard';
import Refresh from 'components/common/Refresh';
import { useLeaderDashboard } from 'providers/Dashboard/leader';

function Dashboard() {
  const { leaderDashboardData, loading } = useLeaderDashboard();

  function setComponent() {
    if (!leaderDashboardData) {
      return (<Refresh />);
    } return (
      <>
        <Loading active={loading} />
        { !loading && (
        <>
          <Case gridTemplateColumns="1fr 1fr 1fr 1fr">
            <InfoCard title="CHAMADOS ATIVOS MEUS" backgroundColor="#DD4B39" data={leaderDashboardData.userActiveCalls}>
              <AiOutlineUser style={{ color: 'white' }} />
            </InfoCard>
            <InfoCard title="CHAMADOS ATIVOS SETOR" backgroundColor="#ffcd17" data={leaderDashboardData.sectorActiveCalls}>
              <BsPeople style={{ color: 'white' }} />
            </InfoCard>
          </Case>
          <CallsLeadersDashboard data={leaderDashboardData} />
        </>
        )}
      </>
    );
  }

  return (
    <>
      {setComponent()}
    </>
  );
}

export default Dashboard;
