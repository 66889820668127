import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import colors from 'assets/style/colors';

function IconExternal({ name, icon, route }) {
  return (
    <IconLink onClick={() => window.open(`${route}`, '_blank')}>
      <Container>
        <Image>
          {icon}
        </Image>
        <Name>
          {name}
        </Name>
      </Container>
    </IconLink>
  );
}

const IconLink = styled.div`
  text-decoration: none;
  color: #fff;
  font-weight: 500;
  svg{
    color: #fff;
  }
`;

const Name = styled.div`
  display: flex;
  justify-content: center;
  align-self: center;
  align-items: center;
  height: 100%;
`;

const Container = styled.div`
  width: 100px;
  background-color: ${colors.primaryBlue};
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 10px;
  cursor: pointer;
  border-radius: 4px;
  align-self: center;
  align-items: center;
  height: 100%;
  
  :hover{
    text-decoration: none;
    background-color: ${colors.primaryGreen};
  }

  @media(max-width: 400px) {
    width: 100%;
    min-width: 100px;
  }

  @media(max-width: 800px) {
    width: 100%;
    min-width: 100px;
  }
`;

const Image = styled.span`
  font-size: 50px;
`;

IconExternal.propTypes = {
  name: PropTypes.string,
  icon: PropTypes.objectOf(PropTypes.any).isRequired,
  route: PropTypes.string.isRequired,
};

IconExternal.defaultProps = {
  name: 'Icon Name'
};
export default IconExternal;
