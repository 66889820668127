import React, { useState, useEffect } from 'react';
import {
  getDiarySubtypes,
  createDiarySubtypes,
  deleteDiarySubtypes
} from 'service/DiaryService';
import styled from 'styled-components';
import Icon from 'components/common/Icon';
import { AiOutlineSetting } from 'react-icons/ai';
import {
  IoTrashOutline,
} from 'react-icons/io5';
import Modal from 'components/common/Modal';
import Table from 'components/tableComponents/CompleteTable';
import * as Yup from 'yup';
import ParameterizationForm from './ParameterizationForm';

const Parameterization = () => {
  const [visible, setVisible] = useState(false);
  const [diarySubtypes, setDiarySubtypes] = useState([]);
  const [reloadTable, setReloadTable] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const subTypes = await getDiarySubtypes();
      setDiarySubtypes(subTypes);
    };
    if (reloadTable) {
      fetchData();
      setReloadTable(false);
    }
  }, [reloadTable]);

  const initialValues = {
    name: ''
  };

  const validationSchema = {
    name: Yup.string().required('Informe o nome do Assunto'),
  };

  const onSubmit = async (values, { resetForm }) => {
    await createDiarySubtypes(values);
    setReloadTable(true);
    resetForm({});
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'NOME',
        accessor: 'name',
        defaultFilter: true,
      },
      {
        Header: '',
        accessor: 'id',
        defaultFilter: false,
        Cell: (i) => {
          const { cell } = i;
          const data = cell.row.original;
          return (
            <IoTrashOutline
              style={{ cursor: 'pointer' }}
              onClick={() => {
                deleteDiarySubtypes(data.id);
                setReloadTable(true);
              }}
            />
          );
        }
      },
    ],
    []
  );

  return (
    <>
      <Icon icon={<AiOutlineSetting />} name="Assuntos Contato" route="/sistema" onClick={() => setVisible(!visible)} />
      <Modal visible={visible} onClose={() => setVisible(false)} title="Assuntos de Contato">
        <BodyModal>
          <Table data={diarySubtypes} columns={columns} marginTop="-5px" />
          <ParameterizationForm
            values={initialValues}
            handleSubmit={onSubmit}
            validations={validationSchema}
          />
        </BodyModal>
      </Modal>
    </>
  );
};

const BodyModal = styled.div`
  width: 30vw;
  margin: 10px;
  height: 500px;
  display: grid;
  grid-template-rows: 1fr 1fr;
`;

export default Parameterization;
