import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as Yup from 'yup';
import { IoCreateOutline, IoTrashOutline } from 'react-icons/io5';

import { useProfile } from 'providers/Stores/profile';
import verifyAclUser from 'helpers/verifyAclUser';
import acl from 'helpers/acl';

import Modal from 'components/common/Modal';
import Form from './Form';

const EditContact = ({ contactData }) => {
  const [modalEditContact, setModalEditContact] = useState(false);

  const {
    editContact,
    excludeContact
  } = useProfile();

  const initialValues = {
    id: contactData.id,
    name: contactData.name,
    email: contactData.email,
    phoneNumber: contactData.phoneNumber,
    mainContact: contactData.mainContact,
    financialContact: contactData.financialContact,
    general: contactData.general,
    ethical: contactData.ethical,
    generic: contactData.generic,
    similar: contactData.similar,
    perfumery: contactData.perfumery,
    storeId: contactData.storeId,
  };
  const validations = {
    name: Yup.string().required('Informe o nome do contato'),
    email: Yup.string().email().required('Informe o email do contato'),
    phoneNumber: Yup.string().required('Informe o telefone do contato'),
  };

  const onSubmit = async (values) => {
    await editContact(values);
    setModalEditContact(false);
  };
  return (
    <>
      {verifyAclUser(acl.manipulate_store_contact) && (
        <div style={{ textAlign: 'right' }}>
          <TrashIcon onClick={() => excludeContact(contactData.id)} />
          <IoCreateOutline style={{ cursor: 'pointer' }} onClick={() => setModalEditContact(true)} />
        </div>
      )}
      <Modal visible={modalEditContact} onClose={() => setModalEditContact(false)} title="Editar Contato">
        <Form
          values={initialValues}
          handleSubmit={onSubmit}
          handleCancel={setModalEditContact}
          validations={validations}
        />
      </Modal>
    </>
  );
};

EditContact.propTypes = {
  contactData: PropTypes.arrayOf(PropTypes.element).isRequired,
};

const TrashIcon = styled(IoTrashOutline)`
  margin-right: 10px;
  cursor: pointer;
`;

export default EditContact;
