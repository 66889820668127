import React from 'react';

import { useContracts } from 'providers/Contracts/contracts';

import * as Yup from 'yup';

import { cleanDataToString } from 'helpers/cleanDataToString';
import { toastError } from 'helpers/notifications';

import Form from './Form';
import Grid from './Grid';

const Page = () => {
  const { contacts, setContacts } = useContracts();

  const initialValues = {
    contactName: '',
    contactEmail: '',
    contactPhoneNumber: '',
    office: [],
  };
  localStorage.setItem('contacts', JSON.stringify(contacts));
  const onSubmit = async (values, { resetForm }) => {
    const data = values;
    if (data.office.length === 0) {
      toastError('Ao menos 1 atribuição deve ser feita ao contato');
    } else {
      data.contactPhoneNumber = cleanDataToString(values.contactPhoneNumber);
      setContacts([...contacts, data]);
      resetForm({});
    }
  };
  const validationSchema = {
    contactName: Yup.string().required('Nome do contato não pode estar vazio'),
    contactEmail: Yup.string().required('Número de telefone não pode estar vazio'),
    contactPhoneNumber: Yup.string().required('E-mail não pode estar vazio'),
  };
  return (
    <>
      <Form
        values={initialValues}
        handleSubmit={onSubmit}
        validations={validationSchema}
      />
      <Grid />
    </>
  );
};

export default Page;
