/*
import { manipulateDataToSQL } from 'helpers/cleanDataToString';

const handleAddress = (addressData, microregionData) => ({
  address: addressData.logradouro,
  neighborhood: addressData.bairro,
  city: addressData.localidade,
  state: addressData.uf,
  microregion: microregionData
});

const fetchMicroregion = async (addressData) => {
  const searcher = manipulateDataToSQL(addressData.localidade);
  return (
    fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/municipios/${searcher}`, {
      method: 'GET'
    })
      .then((response) => response.json())
      .then((microregionData) => handleAddress(addressData, microregionData.microrregiao.nome))
      .catch(() => handleAddress(addressData, 'MICRORREGIÃO NÃO ENCONTRADA'))
  );
};
const fetchAddress = async (search) => fetch(`https://viacep.com.br/ws/${search}/json/`, {
  method: 'GET'
})
  .then((response) => response.json())
  .then((data) => fetchMicroregion(data))
  .catch(() => handleAddress(
    {
      logradouro: '',
      bairro: '',
      localidade: '',
      uf: '',
    },
    ''
  ));
*/

const fetchAddress = (ev, setFieldValue) => {
  const { value } = ev.target;

  const cep = value?.replace(/[^0-9]/g, '');

  if (cep?.length !== 8) {
    return;
  }

  fetch(`https://viacep.com.br/ws/${cep}/json/`)
    .then((res) => res.json())
    .then((data) => {
      setFieldValue('address', data.logradouro);
      setFieldValue('neighborhood', data.bairro);
      setFieldValue('city', data.localidade);
      setFieldValue('state', data.uf);
    });
};

export default fetchAddress;
