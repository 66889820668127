import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Case from 'components/common/Case';

function SmallInfoCard({
  children,
  title,
  backgroundColor,
  data
}) {
  return (
    <Card backgroundColor={backgroundColor}>
      <Case gridTemplateColumns="1fr 2fr">
        <span>{children}</span>
        <div>
          <Title>{title}</Title>
          <Data>{data}</Data>
        </div>
      </Case>
    </Card>

  );
}

const Card = styled.div`
    margin-left: 10px;
    margin-bottom: 10px;
    background-color: #F3F3F3;
    height: 30px;
    width: 200px;
    font-size: 15px;
  span {
    background-color: ${(props) => props.backgroundColor};
    height: 30px;
    font-size: 18px;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    font-weight: 500;
  }

  box-shadow: 0 2px 2px rgb(0 0 0 / 10%);
  border-radius: 5px;
`;

const Title = styled.div`
  margin-top: 5px
`;

const Data = styled.div`
  font-weight: 600;
  font-size: 18px;
`;

SmallInfoCard.propTypes = {
  children: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  data: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
};

export default SmallInfoCard;
