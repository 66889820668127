import React, { useState } from 'react';
import { IoTrashOutline } from 'react-icons/io5';
import { AiOutlineFileDone, AiOutlineFileText } from 'react-icons/ai';
import moment from 'moment';
import Table from 'components/tableComponents/CompleteTable';
import { useContracts } from 'providers/Contracts/contracts';

import NoData from 'components/common/NoData';
import { cnpjMask } from 'helpers/Mask';

import { getPDFsContracts } from 'service/ContractService';

import LoadingBlocked from 'components/common/LoadingBlocked';
import { DeleteAlert } from 'components/alert';
import { TableCaseIcon, TableIcon } from 'components/tableComponents';

import { PDF_MICROSERVICE } from 'helpers/constant';

const ContractsTable = () => {
  const { contracts, destroyContract, loading } = useContracts();
  const [load, setLoad] = useState(false);

  const deleteContract = async (data) => {
    await DeleteAlert(data, 'Tem certeza que excluir o contrato?', destroyContract);
  };

  const changeContract = async (parameter, contractId, contractNumber) => {
    setLoad(true);
    await new Promise(() => {
      setTimeout(
        async () => {
          await getPDFsContracts(contractId,
            {
              contractName: contractNumber,
              parameters: parameter
            });
          window.open(`${PDF_MICROSERVICE}/contracts/${contractNumber}/${contractNumber}-${parameter}.pdf`, '_blank');
          setLoad(false);
        },
        5000
      );
    });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Número',
        accessor: 'contractNumber',
        defaultFilter: true,
      },
      {
        Header: 'RAZÃO SOCIAL',
        accessor: 'storeName',
        defaultFilter: true,
      },
      {
        Header: 'CNPJ',
        accessor: 'registrationNumber',
        defaultFilter: true,
        Cell: (i) => {
          const { cell } = i;
          const data = cell.row.original;
          return cnpjMask(data.registrationNumber);
        }
      },
      {
        Header: 'CIDADE',
        accessor: 'address.city',
        defaultFilter: true,
        Cell: (i) => {
          const { cell } = i;
          const data = cell.row.original;
          return (
            <span>
              {data.address.city} - {data.address.state}
            </span>
          );
        }
      },
      {
        Header: 'USUÁRIO',
        accessor: 'userData.name',
        defaultFilter: true,
        Cell: (i) => {
          const { cell } = i;
          const data = cell.row.original;
          return (
            <span>
              {data.userData.name}
            </span>
          );
        }
      },
      {
        Header: 'DAT.CAD',
        accessor: 'createdAt',
        Cell: (i) => {
          const { cell } = i;
          const data = cell.row.original;
          return moment(data.createdAt).format('DD/MM/YYYY');
        }
      },
      {
        Header: '',
        accessor: 'id',
        canFilter: null,
        Cell: (i) => {
          const { cell } = i;
          const data = cell.row.original;
          return (
            <TableCaseIcon>
              <TableIcon>
                <IoTrashOutline title="Excluir" onClick={() => deleteContract(data)} />
              </TableIcon>
              <TableIcon>
                <AiOutlineFileDone
                  title="Gerar Contrado"
                  onClick={() => changeContract(1, data.id, data.contractName)}
                />
              </TableIcon>
              <TableIcon>
                <AiOutlineFileText
                  title="Ficha Cadastral"
                  onClick={() => changeContract(2, data.id, data.contractName)}
                />
              </TableIcon>
            </TableCaseIcon>
          );
        }
      }
    ],
    []
  );

  return (
    <>
      <LoadingBlocked active={load} />
      {((contracts.length > 0) && (loading === false) && (
        <Table data={contracts} columns={columns} marginTop="-25px" />
      ))}
      {(contracts.length === 0) && (loading === false) && (
        <NoData />
      )}
    </>
  );
};

export default ContractsTable;
