import React from 'react';
import { AiOutlineUser } from 'react-icons/ai';
import { BsPeople } from 'react-icons/bs';

import Loading from 'components/common/Loading';
// import CallsLeadersDashboard from 'components/dashboard/calls/CallsLeadersDashboard';
import Case from 'components/common/Case';
import InfoCard from 'components/common/InfoCard';
import Refresh from 'components/common/Refresh';
import StoreExpansionDashboard from 'components/dashboard/stores/StoreExpansionDashboard';
import { useLeaderExpansionDashboard } from 'providers/Dashboard/leaderExpansion';

function Dashboard() {
  const { leaderExpansionDashboardData, loading } = useLeaderExpansionDashboard();

  function setComponent() {
    if (!leaderExpansionDashboardData) {
      return (<Refresh />);
    } return (
      <>
        <Loading active={loading} />
        { !loading && (
        <>
          <Case gridTemplateColumns="1fr 1fr 1fr 1fr">
            <InfoCard title="CHAMADOS ATIVOS MEUS" backgroundColor="#DD4B39" data={leaderExpansionDashboardData.userActiveCalls}>
              <AiOutlineUser style={{ color: 'white' }} />
            </InfoCard>
            <InfoCard title="CHAMADOS ATIVOS SETOR" backgroundColor="#ffcd17" data={leaderExpansionDashboardData.sectorActiveCalls}>
              <BsPeople style={{ color: 'white' }} />
            </InfoCard>
          </Case>
          <StoreExpansionDashboard data={leaderExpansionDashboardData} />
          {/* <CallsLeadersDashboard data={leaderExpansionDashboardData.activeCallsByUser} /> */}
        </>
        )}
      </>
    );
  }

  return (
    <>
      {setComponent()}
    </>
  );
}

export default Dashboard;
