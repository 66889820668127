import React from 'react';
import styled from 'styled-components';

import BasePage from 'components/layout/BasePage';
import TitleBar from 'components/layout/TitleBar';
import Loading from 'components/common/Loading';

import { useDiary } from 'providers/diary';

import DiaryCreate from './DiaryCreate';
import DiaryGrid from './DiaryGrid';

const Diary = () => {
  const title = 'Registro de Diário';
  const { loading } = useDiary();

  return (
    <BasePage title={title}>
      <TopMobile>
        <CreateMobile>
          <TitleBar>
            Diário
          </TitleBar>
          <DiaryCreate />
        </CreateMobile>
      </TopMobile>
      <DiaryGrid />
      <Loading active={loading} />
    </BasePage>
  );
};

const TopMobile = styled.div`
  margin-bottom: 12px;
  @media(min-width: 800px) {
    display: none;
  }
`;

const CreateMobile = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
`;

export default Diary;
