import Home from 'containers/Home';
import System from 'containers/System';
import Login from 'containers/Login';
import Dashboard from 'containers/Dashboard/index';
import Stores from 'containers/Stores';
import StoresMarketing from 'containers/StoresMarketing/index';
import Events from 'containers/Diary';
import Calls from 'containers/Calls';
import PatchNotes from 'containers/PatchNotes';
import Lead from 'containers/Lead';
import Error404 from 'containers/Errors';
import Reports from 'containers/Reports';
import ReportVisit from 'containers/Reports/Visits';
import ReportFinancial from 'containers/Reports/Financial';
import ReportConsultantVisit from 'containers/Reports/ConsultantVisit';
import ReportContacts from 'containers/Reports/Contacts';
import ReportStores from 'containers/Reports/Stores';
import ReportStoresAdmin from 'containers/Reports/StoresAdmin';
import Users from 'containers/System/Users/Users';
import Company from 'containers/System/Company';
import acl from 'helpers/acl';
import Contracts from 'containers/Contracts';
import ContractsCreate from 'containers/Contracts/ExportCreateContract';
import Associate from 'containers/Associate';

// import AccessControl from 'containers/System/AccessControl';
// import Site from 'containers/Site';
// import Banners from 'containers/Site/Banners';
// import Solutions from 'containers/Site/Solutions';
// import Partners from 'containers/Site/Partners';
// import SitePbm from 'containers/Site/Pbm';
// import Articles from 'containers/Site/Articles';
// import Depositions from 'containers/Site/Depositions';
// import Sections from 'containers/Site/Sections';
// import Providers from 'containers/System/Providers';
// import Financial from 'containers/Financial';
// import BillingType from 'containers/Financial/BillingType';
// import Bills from 'containers/Financial/Bills';
// import Pbm from 'containers/PBM';

export const privateRoutesArr = [
  { path: '/', component: Home, authProfiles: acl.router_rmc },
  { path: '/home', component: Home, authProfiles: acl.router_rmc },
  { path: '/diario', component: Events, authProfiles: acl.router_rmc },
  { path: '/chamados', component: Calls, authProfiles: acl.router_rmc },
  { path: '/notasdeatualizacao', component: PatchNotes, authProfiles: acl.router_rmc },
  { path: '/sistema', component: System, authProfiles: acl.router_rmc },
  { path: '/sistema/usuarios', component: Users, authProfiles: acl.router_rmc },
  { path: '/sistema/empresas', component: Company, authProfiles: acl.router_company },
  { path: '/drogarias', component: Stores, authProfiles: acl.router_rmc },
  { path: '/drogarias/:formCode', component: Stores, authProfiles: acl.router_rmc },
  { path: '/contratos', component: Contracts, authProfiles: acl.router_contracts },
  { path: '/novoContrato', component: ContractsCreate, authProfiles: acl.router_contracts },

  { path: '/dashboard', component: Dashboard, authProfiles: acl.router_rmc },
  { path: '/relatorios', component: Reports, authProfiles: acl.router_reports },
  { path: '/relatorios/visitas', component: ReportVisit, authProfiles: acl.router_visits },
  { path: '/relatorios/visitasconsultor', component: ReportConsultantVisit, authProfiles: acl.router_visit_consultant },
  { path: '/relatorios/contatos', component: ReportContacts, authProfiles: acl.report_contacts },
  { path: '/relatorios/contatofinanceiro', component: ReportFinancial, authProfiles: acl.router_store_contacts },
  { path: '/relatorios/drogarias', component: ReportStores, authProfiles: acl.router_stores },
  { path: '/relatorios/drogariasadmin', component: ReportStoresAdmin, authProfiles: acl.router_stores_admin },

  // Farmácias
  { path: '/loja', component: Associate, authProfiles: acl.router_assiciate },
  { path: '/loja-material-marketing', component: StoresMarketing, authProfiles: acl.router_assiciate },

  // { path: '/sistema/acl', component: AccessControl, authProfiles: [1] },
  // { path: '/sistema/fornecedores', component: Providers },
  // { path: '/site', component: Site },
  // { path: '/site/banners', component: Banners },
  // { path: '/site/solucoes', component: Solutions },
  // { path: '/site/parceiros', component: Partners },
  // { path: '/site/pbm', component: SitePbm },
  // { path: '/site/artigos', component: Articles },
  // { path: '/site/depoimentos', component: Depositions },
  // { path: '/site/secoes', component: Sections },

  // { path: '/financeiro', component: Financial },
  // { path: '/financeiro/contas', component: Bills },
  // { path: '/financeiro/tipocobranca', component: BillingType },

  // { path: '/pbm', component: Pbm },
];

export const routesArr = [
  { path: '/login', component: Login },
  { path: '/quero-ser-um-associado', component: Lead },
  { path: '*', component: Error404 },
];
