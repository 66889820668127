import React from 'react';

import verifyAclUser from 'helpers/verifyAclUser';
import acl from 'helpers/acl';

import { DirectorDashboardProvider } from 'providers/Dashboard/directors';
import { ExpansionDashboardProvider } from 'providers/Dashboard/expansion';
import { AngelDashboardProvider } from 'providers/Dashboard/angel';
import { LeaderDashboardProvider } from 'providers/Dashboard/leader';
import { LeaderExpansionDashboardProvider } from 'providers/Dashboard/leaderExpansion';
import { LeaderAngelDashboardProvider } from 'providers/Dashboard/leaderAngel';
import { UserDashboardProvider } from 'providers/Dashboard/user';
import { IntConsultantDashboardProvider } from 'providers/Dashboard/internalConsultant';

import BasePage from 'components/layout/BasePage';
import Dashboard from './Dashboard';
import DashboardExpansion from './DashboardExpansion';
import DashboardAngels from './DashboardAngels';
import DashboardLeaders from './DashboardLeaders';
import DashboardLeaderAngel from './DashboardLeaderAngel';
import DashboardLeadersExpansion from './DashboardLeadersExpansion';
import DashboardDirector from './DashboardDirector';
import DashboardInternalConsultant from './DashboardInternalConsultant';

const setComp = () => {
  if (verifyAclUser(acl.dashboard_leader)) {
    return (
      <LeaderDashboardProvider>
        <DashboardLeaders />
      </LeaderDashboardProvider>
    );
  }
  if (verifyAclUser(acl.dashboard_angel_leader)) {
    return (
      <LeaderAngelDashboardProvider>
        <DashboardLeaderAngel />
      </LeaderAngelDashboardProvider>
    );
  }
  if (verifyAclUser(acl.dashboard_expansion_leader)) {
    return (
      <LeaderExpansionDashboardProvider>
        <DashboardLeadersExpansion />
      </LeaderExpansionDashboardProvider>
    );
  }
  if (verifyAclUser(acl.dashboard_director)) {
    return (
      <DirectorDashboardProvider>
        <DashboardDirector />
      </DirectorDashboardProvider>
    );
  }
  if (verifyAclUser(acl.dashboard_angel)) {
    return (
      <AngelDashboardProvider>
        <DashboardAngels />
      </AngelDashboardProvider>
    );
  }
  if (verifyAclUser(acl.dashboard_user)) {
    return (
      <UserDashboardProvider>
        <Dashboard />
      </UserDashboardProvider>
    );
  }
  if (verifyAclUser(acl.dashboard_expansion)) {
    return (
      <ExpansionDashboardProvider>
        <DashboardExpansion />
      </ExpansionDashboardProvider>
    );
  }
  if (verifyAclUser(acl.dashboard_internal_consultant)) {
    return (
      <IntConsultantDashboardProvider>
        <DashboardInternalConsultant />
      </IntConsultantDashboardProvider>
    );
  }
  return (
    <div>
      Você não tem permissão para visualizar essa módulo, comunique ao administrador do sistema
    </div>
  );
};

const Index = () => (
  <BasePage>
    {setComp()}
  </BasePage>
);

export default Index;
