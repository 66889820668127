import React from 'react';
import PropTypes from 'prop-types';
import Chart from 'react-google-charts';

function PieChart({
  data,
  label,
  height,
  width,
  colorLabel,
}) {
  return (
    <>
      <Chart
        width={width}
        height={height}
        chartType="Bar"
        loader={<div>Loading Chart...</div>}
        data={[
          label,
          ...data,
        ]}
        options={{
          colors: colorLabel,
        }}
        rootProps={{ 'data-testid': '2' }}
      />
    </>
  );
}

PieChart.defaultProps = {
  height: '250px',
  width: '480px',
  colorLabel: ['#DB4538', '#4285F4', '#139F5B']
};

PieChart.propTypes = {
  data: PropTypes.element.isRequired,
  label: PropTypes.element.isRequired,
  height: PropTypes.string,
  width: PropTypes.string,
  colorLabel: PropTypes.string,
};

export default PieChart;
