import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { format } from 'date-fns';
import {
  IoTrashOutline,
} from 'react-icons/io5';
import {
  AiOutlineUpload,
  AiOutlineDownload
} from 'react-icons/ai';
import { useProfile } from 'providers/Stores/profile';

import Modal from 'components/common/Modal';
import DocumentationForm from './DocumentationForm';

const StoreDocumentation = ({ storeDocData }) => {
  const {
    importModal,
    setImportModal,
    deleteStoreDocumentation,
    downloadFile
  } = useProfile();
  const [title, setTitle] = useState('');
  const [selectedDoc, setSelectedDoc] = useState(0);

  const handleExpireTime = (expiredAt) => {
    if (expiredAt) {
      const expiredDocumentData = format(new Date(expiredAt), 'dd/MM/yyyy');
      if (new Date(expiredAt) < new Date()) {
        return <span style={{ color: 'red' }}> - Expirado em {expiredDocumentData}</span>;
      }
      return ` - Expira em ${expiredDocumentData}`;
    } return ' - Não Expira';
  };

  const handleLabel = (type, label) => {
    const count = storeDocData.filter((i) => i.type === type);
    if (count.length > 0) {
      const data = count[0];
      return (
        <Box>
          <DownloadIcon onClick={() => downloadFile(data.filePath, label)} />
          <span
            role="button"
            tabIndex={0}
            onKeyDown={() => downloadFile(data.filePath, label)}
            onClick={() => downloadFile(data.filePath, label)}
            style={{ cursor: 'pointer' }}
          >
            {label}{handleExpireTime(data.expiredAt)}
          </span>
          <IconsDiv>
            <IoTrashOutline
              style={{ cursor: 'pointer', fontSize: '20px' }}
              onClick={() => deleteStoreDocumentation(data.id)}
            />
          </IconsDiv>
        </Box>
      );
    } return (
      <Box>
        <UploadIcon
          onClick={() => {
            setImportModal(true);
            setTitle(`Upload de ${label}`);
            setSelectedDoc(type);
          }}
        />
        <Label>{label}</Label>
      </Box>
    );
  };

  return (
    <>
      <Modal visible={importModal} onClose={() => setImportModal(false)} title={title}>
        <DocumentationForm typeId={selectedDoc} />
      </Modal>
      {handleLabel(7, 'AFE')}
      {handleLabel(5, 'Alvará de Funcionamento')}
      {handleLabel(3, 'Alvará Sanitário')}
      {handleLabel(6, 'CRT/CRF')}
      {handleLabel(4, 'Contrato Social')}
      {handleLabel(8, 'Documento do Sócio do Administrativo com CPF')}
      {handleLabel(1, 'Comprovante de Endereço (Água, Luz ou Internet)')}
      {handleLabel(2, 'Declaração de Inclusão')}
      {handleLabel(9, 'Contrato')}
    </>
  );
};

const Box = styled.div`
  height: 45px;
  border-top: 1px solid #EEEEEE;
  border-bottom: 1px solid #EEEEEE;
  border-radius: 4px;
  padding: 10px;
  display: grid; 
  grid-template-columns: 1fr 17fr 5fr;
  
  :hover {
    background-color: #EEEEEE;
  }
`;

const Label = styled.span` 
  color: #a3a3a3;
`;
const IconsDiv = styled.div`
  text-align: right;
`;

const DownloadIcon = styled(AiOutlineDownload)`
  cursor: pointer;
  font-size: 25px;
  color: #20314F;
`;

const UploadIcon = styled(AiOutlineUpload)`
  cursor: pointer;
  font-size: 25px;
  color: #a3a3a3;
`;

StoreDocumentation.propTypes = {
  storeDocData: PropTypes.element.isRequired,
};

export default StoreDocumentation;
