import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import { useStores } from 'providers/Stores/stores';
import { useProfile } from 'providers/Stores/profile';

import getProfile from 'helpers/getProfile';
import InactiveForm from './InactiveForm';

const Inactive = ({ id, setVisible }) => {
  const { changeStatus } = useStores();
  const { searchStore } = useProfile();
  const initialValues = {
    inactiveReasons: [],
    description: '',
  };

  const onSubmit = async (values) => {
    const profile = getProfile();
    const data = values;
    data.obj = 'inactive';
    data.userId = profile.user.id;
    await changeStatus(id, data);
    setVisible(false);
    searchStore();
  };

  const validationSchema = {
    inactiveReasons: Yup.string().required('Required!'),
    description: Yup.string().required('Informe a descrição do desligamento'),
  };

  return (
    <InactiveForm
      values={initialValues}
      handleSubmit={onSubmit}
      handleCancel={setVisible}
      validations={validationSchema}
    />
  );
};

Inactive.propTypes = {
  id: PropTypes.number.isRequired,
  setVisible: PropTypes.func.isRequired,
};

export default Inactive;
