import React, { useState } from 'react';
import Swal from 'sweetalert2';
import styled from 'styled-components';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';

import { useCalls } from 'providers/calls';

import { toastError } from 'helpers/notifications';
import { CaseIcon } from 'components/card';
import { TableIcon } from 'components/table';
import Modal from 'components/common/Modal';
import AlertWarning from 'components/alert/AlertWarning';
import Button from 'components/common/Button';
import TextAreaField from 'components/common/TextAreaField';
import CancelButton from 'components/common/CancelButton';
import ButtonCase from 'components/common/ButtonCase';

import {
  MdDone,
} from 'react-icons/md';
import {
  HiOutlineInboxIn,
} from 'react-icons/hi';
import getProfile from 'helpers/getProfile';

const CallsEdit = ({ data, exibition }) => {
  const profile = getProfile();
  const { updateInteractionsCall, reopen } = useCalls();
  const [visible, setVisible] = useState(false);

  const title = 'Finalizar Chamado';

  const values = {
    text: '',
  };

  const validationSchema = {
    text: Yup.string().required('Ação obrigatória'),
  };

  const onCancel = () => {
    setVisible(false);
  };

  const Submit = async (formData) => {
    await updateInteractionsCall(data.id, {
      status: '2',
      userId: profile.user.id,
      ...formData
    });
    setVisible(false);
  };

  const reopenCall = async () => {
    await Swal.fire(AlertWarning('Tem certeza que deseja reabrir esse chamado?')).then(async (result) => {
      if (result.isConfirmed) {
        await reopen(data.id, { userId: profile.user.id });
        setVisible(false);
      }
    });
  };

  const openModal = () => {
    if (data.sector === 12 || data.sector === 3) {
      setVisible(true);
    } if (data.sector !== profile.user.userTypeId) {
      toastError('Você não tem permissão para finalizar o chamado de outro setor!');
    } else {
      setVisible(true);
    }
  };

  const formik = useFormik({
    initialValues: values,
    onSubmit: Submit,
    validationSchema: Yup.object(validationSchema),
  });

  return (
    <>
      {((exibition === 'card') && (data.status !== 2)) && (
        <CaseIcon marginleft="5px">
          <MdDone title="Finalizar" onClick={() => openModal()} />
        </CaseIcon>
      )}
      {((exibition === 'table') && (data.status !== 2)) && (
        <Button onClick={() => setVisible(true)}>Finalizar</Button>
      )}
      {((exibition === 'card') && (data.status === 2)) && (
        <CaseIcon marginleft="5px">
          <HiOutlineInboxIn title="Reabrir" onClick={() => reopenCall()} />
        </CaseIcon>
      )}
      {((exibition === 'table') && (data.status === 2)) && (
        <TableIcon>
          <HiOutlineInboxIn title="Reabrir" onClick={() => reopenCall()} />
        </TableIcon>
      )}
      { visible && (
        <Modal visible={visible} onClose={() => setVisible(false)} title={title}>
          <BodyModal>
            <Form data-testid="client-form" onSubmit={formik.handleSubmit}>
              <TextAreaField
                id="text"
                name="text"
                label="Retorno"
                placeholder="Discorra sobre o retorno do Chamado"
                isRequired
                error={formik.errors.text}
                onBlur={formik.handleBlur}
                onChange={(e) => {
                  formik.handleChange(e);
                }}
                value={formik.values.text}
                isError={formik.errors.text && formik.touched.text}
                height="200px"
              />
              <ButtonCase>
                <CancelButton onClose={onCancel}>Cancelar</CancelButton>
                <Button type="submit">Finalizar</Button>
              </ButtonCase>
            </Form>
          </BodyModal>
        </Modal>
      )}
    </>
  );
};

const BodyModal = styled.div`
  width: 30vw;

  @media(max-width: 800px) {
    width: 100%;
    overflow: auto;
  }
`;

const Form = styled.form`  
  padding: 5px;
  width: 30vw;

  @media(max-width: 800px) {
    width: 100%;
    overflow: auto;
  }
`;

CallsEdit.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  exibition: PropTypes.string.isRequired,
};

export default CallsEdit;
