import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import PropTypes from 'prop-types';

import { FaTasks } from 'react-icons/fa';

import { useCalls } from 'providers/calls';
import { toastError } from 'helpers/notifications';

import Button from 'components/common/Button';
import Modal from 'components/common/Modal';
import LoadingBlocked from 'components/common/LoadingBlocked';

import getProfile from 'helpers/getProfile';

import CallsForm from './CallsForm';

const CallsCreate = ({ iconStyle, storeProfile }) => {
  const [visible, setVisible] = useState(false);
  const { create, blockLoading } = useCalls();
  const title = 'Registro de Chamados';

  const initialValues = {
    text: '',
    sector: '',
    storeName: storeProfile ? localStorage.getItem('storeName') : '',
  };

  const setLocalStorageStoreProfile = () => {
    localStorage.setItem('searchStoreName', localStorage.getItem('storeName'));
    localStorage.setItem('searchStoreID', localStorage.getItem('storeId'));
    localStorage.setItem('storeAngel', localStorage.getItem('angelName'));
    localStorage.setItem('storeConsultant', localStorage.getItem('consultantName'));
  };

  useEffect(() => {
    if (storeProfile) {
      setLocalStorageStoreProfile();
    }
  }, []);

  const changeModalState = (parameter) => {
    setVisible(parameter);
    if (parameter === false) {
      localStorage.removeItem('searchStoreID');
      localStorage.removeItem('searchStoreName');
      localStorage.removeItem('storeAngel');
      localStorage.removeItem('storeConsultant');
    }
  };

  const onSubmit = async (values) => {
    const profile = getProfile();
    const data = values;
    data.storeId = localStorage.getItem('searchStoreID');
    if (!data.storeId) {
      toastError('Selecione uma Drogaria válida');
    } else {
      data.openBy = profile.user.id;
      await create(data);
      setVisible(false);
      localStorage.removeItem('searchStoreID');
      localStorage.removeItem('searchStoreName');
      localStorage.removeItem('storeAngel');
      localStorage.removeItem('storeConsultant');
    }
  };

  const onCancel = () => {
    changeModalState(false);
    localStorage.removeItem('searchStoreID');
    localStorage.removeItem('searchStoreName');
    localStorage.removeItem('storeAngel');
    localStorage.removeItem('storeConsultant');
  };

  const validationSchema = {
    text: Yup.string().required('Informe o motivo do Chamado'),
    sector: Yup.string().required('Informe o setor responsável'),
  };

  return (
    <>
      <LoadingBlocked active={blockLoading} />
      {!iconStyle && (<Button onClick={() => changeModalState(true)}>Novo</Button>)}
      {iconStyle && (<CallIcon title="Novo chamado" onClick={() => changeModalState(true)} />)}
      <Modal visible={visible} onClose={() => changeModalState(false)} title={title}>
        <CallsForm
          values={initialValues}
          handleSubmit={onSubmit}
          handleCancel={onCancel}
          validations={validationSchema}
        />
      </Modal>
    </>
  );
};

CallsCreate.propTypes = {
  iconStyle: PropTypes.bool,
  storeProfile: PropTypes.bool,
};

CallsCreate.defaultProps = {
  iconStyle: false,
  storeProfile: false,
};

const CallIcon = styled(FaTasks)`
  cursor: pointer;
  margin: 0 5px;
`;

export default CallsCreate;
