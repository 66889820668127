import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FiType } from 'react-icons/fi';
import { IoCreateOutline } from 'react-icons/io5';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import verifyAclUser from 'helpers/verifyAclUser';
import acl from 'helpers/acl';

import { basicUpdateStore } from 'service/StoresService';

import { useProfile } from 'providers/Stores/profile';

import Modal from 'components/common/Modal';
import TextAreaField from 'components/common/TextAreaField';
import Button from 'components/common/Button';
import CancelButton from 'components/common/CancelButton';
import { toastSuccess } from 'helpers/notifications';

const Comments = ({ commentsData, active, storeId }) => {
  const [visible, setVisible] = useState(false);

  const {
    searchStore,
    setLoading
  } = useProfile();

  const initialValues = {
    comments: commentsData
  };
  const validations = {
    comments: Yup.string().required('Informe um comentário'),
  };
  const onSubmit = async (values) => {
    setLoading(true);
    await basicUpdateStore(storeId, values);
    setLoading(false);
    setVisible(false);
    toastSuccess('Comentário atualizado com sucesso');
    searchStore();
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema: Yup.object(validations),
  });

  return (
    <>
      <Container>
        <FieldSet fieldStyle={active ? 'background-color: #FFFFFF' : 'background-color: #fae7e8'}>
          <TextIcon iconStyle={active ? 'color: #A6D16E' : 'color: #8f5340'} />Comentários
        </FieldSet>
        <div>
          {commentsData}
          {verifyAclUser(acl.comments) && (
            <div style={{ textAlign: 'end' }}>
              <EditIcon onClick={() => setVisible(true)} />
            </div>
          )}
        </div>
      </Container>
      <Modal visible={visible} onClose={() => setVisible(false)} title="Comentários">
        <FormData onSubmit={formik.handleSubmit}>
          <TextAreaField
            id="comments"
            name="comments"
            placeholder="Comentários"
            error={formik.errors.comments}
            onBlur={formik.handleBlur}
            onChange={(e) => {
              formik.handleChange(e);
            }}
            value={formik.values.comments}
            isError={formik.errors.comments && formik.touched.comments}
          />
          <ButtonCase>
            <CancelButton onClose={() => setVisible(false)}>Cancelar</CancelButton>
            <Button type="submit">Salvar</Button>
          </ButtonCase>
        </FormData>
      </Modal>
    </>
  );
};

const Container = styled.div`
  margin: 35px 8px 8px 8px;
  padding: 0 20px 20px 20px;
  border: 1px solid #9A9A9A;
  border-radius: 4px;   
`;

const FieldSet = styled.span`
  position: relative;
  right: 10px;
  top: -12px;
  ${(props) => props.fieldStyle};
  padding: 0 10px;
  font-family: Montserrat;
  font-weight: 400;
`;

const TextIcon = styled(FiType)`
  ${(props) => props.iconStyle};
  margin-right: 10px;
`;

const EditIcon = styled(IoCreateOutline)`
  font-size: 20px;
  cursor: pointer;
`;

const FormData = styled.form`  
  width: 30vw;
  margin: 10px;
`;

const ButtonCase = styled.div`
  text-align: right;
  margin-top: 30px;
`;

Comments.propTypes = {
  commentsData: PropTypes.string.isRequired,
  active: PropTypes.string.isRequired,
  storeId: PropTypes.string.isRequired,
};

export default Comments;
