import { getProfileAcl } from 'helpers/getProfileUserType';

const verifyAclUser = (authProfiles) => {
  if (authProfiles === '0|0|0') {
    return true;
  }
  if (authProfiles[0] === '*') {
    return true;
  }
  if (!authProfiles) {
    return false;
  }
  return getProfileAcl(authProfiles);
};

export default verifyAclUser;
