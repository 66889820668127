import React, { useState } from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { convertStringToYMD } from 'helpers/convertDate';

import { useDiary } from 'providers/diary';
import { toastError } from 'helpers/notifications';
import { CaseIcon } from 'components/card';
import { TableIcon } from 'components/table';
import Modal from 'components/common/Modal';
import LoadingBlocked from 'components/common/LoadingBlocked';
import { getProfileUserType } from 'helpers/getProfileUserType';
import getProfile from 'helpers/getProfile';

import {
  IoCreateOutline,
} from 'react-icons/io5';

import DiaryForm from './DiaryForm';
import DiaryAngelForm from './DiaryAngelForm';

const DiaryEdit = ({ data, exibition }) => {
  const { update, diaryContact } = useDiary();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [diaryContactData, setDiaryContactData] = useState([]);

  const fetchData = async () => {
    const contactData = await diaryContact(data.id);
    setDiaryContactData(contactData);
  };

  const title = 'Editar Diário';

  const initialValues = {
    date: convertStringToYMD(data.date),
    storeName: data.stores.storeName,
    text: data.text,
    diarySubtype: diaryContactData.diarySubtype,
    flow: diaryContactData.flow,
    way: diaryContactData.way,
    dealtWith: diaryContactData.dealtWith,
    success: diaryContactData.success,
    firstTry: diaryContactData.firstTry,
    secondTry: diaryContactData.secondTry,
    thirdTry: diaryContactData.thirdTry
  };

  const validationSchema = {
    date: Yup.date().required('Informe o dia, mês e ano'),
    text: Yup.string().required('Informe o motivo do Diário'),
  };

  const changeModalState = async (parameter) => {
    if (parameter === false) {
      localStorage.removeItem('searchStoreID');
      localStorage.removeItem('searchStoreName');
      localStorage.removeItem('storeAngel');
      localStorage.removeItem('storeConsultant');
      setVisible(false);
    } else {
      const profile = getProfile();
      if ((profile.user.id === data.userId) || (profile.user.id === 1)) {
        setLoading(true);
        await fetchData();
        setLoading(false);
        setVisible(true);
      } else {
        toastError('Você não tem permissão para editar o registro de outro usuário!');
      }
    }
  };

  const onCancel = () => {
    changeModalState(false);
    localStorage.removeItem('searchStoreID');
    localStorage.removeItem('searchStoreName');
    localStorage.removeItem('storeAngel');
    localStorage.removeItem('storeConsultant');
  };

  const onSubmit = async (values) => {
    const profile = getProfile();
    const dataForm = values;

    const storeId = localStorage.getItem('searchStoreID');

    if (storeId) {
      dataForm.storeId = storeId;
    }

    dataForm.userId = profile.user.id;
    await update(data.id, dataForm);
    setVisible(false);
    localStorage.removeItem('searchStoreID');
    localStorage.removeItem('searchStoreName');
    localStorage.removeItem('storeAngel');
    localStorage.removeItem('storeConsultant');
  };

  const setVisibleFields = () => {
    if (diaryContactData.way === 2) {
      return true;
    } return false;
  };

  return (
    <>
      <LoadingBlocked active={loading} />
      {exibition === 'card' && (
        <CaseIcon marginleft="5px">
          <IoCreateOutline title="Editar" onClick={() => changeModalState(true)} />
        </CaseIcon>
      )}
      {exibition === 'table' && (
        <TableIcon>
          <IoCreateOutline title="Editar" onClick={() => changeModalState(true)} />
        </TableIcon>
      )}
      { visible && (
        <Modal visible={visible} onClose={() => changeModalState(false)} title={title}>
          {!getProfileUserType([3]) && (
          <DiaryForm
            values={initialValues}
            handleSubmit={onSubmit}
            handleCancel={onCancel}
            validations={validationSchema}
          />
          )}
          {getProfileUserType([3]) && (
            <DiaryAngelForm
              values={initialValues}
              handleSubmit={onSubmit}
              handleCancel={onCancel}
              validations={validationSchema}
              visibleFields={setVisibleFields()}
            />
          )}
        </Modal>
      )}
    </>
  );
};

DiaryEdit.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  exibition: PropTypes.string.isRequired,
};

export default DiaryEdit;
