import React, { useState } from 'react';
import styled from 'styled-components';

import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { IoCreateOutline, IoMap } from 'react-icons/io5';

import { useCompany } from 'providers/company';

import { TableIcon } from 'components/table';
import Modal from 'components/common/Modal';

import CompanyForm from './CompanyForm';

const CompanyEdit = ({ data }) => {
  const { update } = useCompany();
  const [visible, setVisible] = useState(false);
  const [disabled, setDisabled] = useState('disabled');

  const title = 'Editar Empresa';

  const initialValues = {
    storeName: data.storeName,
    tradeName: data.tradeName,
    registrationNumber: data.registrationNumber,
    zipcode: data.addressData.zipcode,
    address: data.addressData.address,
    number: data.addressData.number,
    complement: data.addressData.complement,
    neighborhood: data.addressData.neighborhood,
    city: data.addressData.city,
    state: data.addressData.state,
  };

  const validationSchema = {
    storeName: Yup.string().required('Informe a razão social'),
    tradeName: Yup.string().required('Informe o nome fantasia'),
    registrationNumber: Yup.string().required('Informe o CNPJ').length(18, 'Formato inválido'),
    zipcode: Yup.string().required('Informe o CEP'),
    address: Yup.string().required('Não pode ser enviado em branco'),
    city: Yup.string().required('Não pode ser enviado em branco'),
    number: Yup.number('Não aceita letras').required('Não pode ser enviado em branco'),
    neighborhood: Yup.string().required('Não pode ser enviado em branco'),
    state: Yup.string().required('Não pode ser enviado em branco'),
  };

  const changeModalState = (parameter) => {
    setDisabled('disabled');
    setVisible(parameter);
  };

  const onCancel = () => {
    changeModalState(false);
  };

  const onSubmit = async (values) => {
    const updateData = values;
    if (disabled) {
      updateData.newAddress = false;
    } else {
      updateData.newAddress = true;
    }
    await update(data.id, updateData);
    setVisible(false);
  };

  return (
    <>
      <TableIcon>
        <IoCreateOutline title="Editar" onClick={() => changeModalState(true)} />
      </TableIcon>
      { visible && (
        <Modal visible={visible} onClose={() => changeModalState(false)} title={title}>
          <CompanyForm
            values={initialValues}
            handleSubmit={onSubmit}
            handleCancel={onCancel}
            validations={validationSchema}
            disabled={disabled}
          />
          <AddressIcon title="Cadastrar novo endereço" type="button" onClick={() => setDisabled('')}>Novo Endereço</AddressIcon>
        </Modal>
      )}
    </>
  );
};

const AddressIcon = styled(IoMap)`
  position: absolute;
  font-size: 25px;
  padding-left: 5px;
  bottom: 5px;
`;

CompanyEdit.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default CompanyEdit;
