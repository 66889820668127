import React from 'react';
import BasePage from 'components/layout/BasePage';
import { VisitsReportProvider } from 'providers/Reports/visits';
import Visits from './Visits';

const index = () => (
  <BasePage>
    <VisitsReportProvider>
      <Visits />
    </VisitsReportProvider>
  </BasePage>
);

export default index;
