import React from 'react';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import Styled from 'styled-components';

import InputField from 'components/common/InputField';
import SelectBoolField from 'components/common/SelectBoolField';
import CheckBooleanField from 'components/common/CheckBooleanField';
import SelectField from 'components/common/SelectField';
import Case from 'components/common/Case';
import ButtonCase from 'components/common/ButtonCase';
import Button from 'components/common/Button';
import CancelButton from 'components/common/CancelButton';
import { getProfileUserType } from 'helpers/getProfileUserType';

const UsersForm = ({
  values,
  handleSubmit,
  handleCancel,
  validations,
  usersType
}) => {
  const formik = useFormik({
    initialValues: values,
    onSubmit: handleSubmit,
    validationSchema: Yup.object(validations)
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Case gridTemplateColumns="5fr 5fr 4fr">
        <InputField
          id="name"
          name="name"
          label="Nome"
          placeholder="NOME"
          isRequired
          type="text"
          error={formik.errors.name}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.name}
          isError={formik.errors.name && formik.touched.name}
        />
        <InputField
          id="surname"
          name="surname"
          label="Sobrenome"
          placeholder="SOBRENOME"
          isRequired
          type="text"
          error={formik.errors.surname}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.surname}
          isError={formik.errors.surname && formik.touched.surname}
        />
        <InputField
          id="phoneNumber"
          name="phoneNumber"
          label="Número de telefone"
          placeholder="(99)9 9999-9999"
          isRequired
          type="text"
          error={formik.errors.phoneNumber}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.phoneNumber}
          isError={formik.errors.phoneNumber && formik.touched.phoneNumber}
          mask="(99)9 9999-9999"
        />
      </Case>
      <Case gridTemplateColumns="10fr 5fr 4fr">
        <InputField
          id="email"
          name="email"
          label="Email"
          placeholder="exemplo@exemplo.com"
          isRequired
          type="text"
          error={formik.errors.email}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.email}
          isError={formik.errors.email && formik.touched.email}
        />
        {getProfileUserType([1]) && (
          <>
            <SelectField
              id="usertypeId"
              name="usertypeId"
              label="Cargo?"
              value={formik.values.usertypeId}
              onBlur={formik.handleBlur}
              onChange={(e) => {
                formik.handleChange(e);
              }}
              options={usersType}
              error={formik.errors.usertypeId}
              isRequired
              isError={formik.errors.usertypeId && formik.touched.usertypeId}
            />
            <SelectBoolField
              id="active"
              name="active"
              label="Usuário ativo?"
              value={formik.values.active}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={formik.errors.active}
              isRequired
              isError={formik.errors.active && formik.touched.active}
            />
          </>
        )}
      </Case>
      <Case gridTemplateColumns="10fr 10fr 5fr">
        <InputField
          id="username"
          name="username"
          label="Username"
          placeholder="NOME.SOBRENOME"
          isRequired
          type="text"
          error={formik.errors.username}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.username}
          isError={formik.errors.username && formik.touched.username}
        />
        <InputField
          id="password"
          name="password"
          label="Senha"
          placeholder="******"
          isRequired
          type="password"
          error={formik.errors.password}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.password}
          isError={formik.errors.password && formik.touched.password}
        />
        <div style={{ marginTop: '25px' }}>
          <CheckBooleanField
            id="sectorLeader"
            name="sectorLeader"
            label="Líder de Setor"
            type="checkbox"
            error={formik.errors.sectorLeader}
            onBlur={formik.handleBlur}
            onChange={(e) => {
              formik.handleChange(e);
            }}
            value={formik.values.sectorLeader}
          />
        </div>
      </Case>
      <ButtonCase>
        <CancelButton onClose={handleCancel}>Cancelar</CancelButton>
        <Button type="submit">Salvar</Button>
      </ButtonCase>
    </Form>
  );
};

const Form = Styled.form`
  padding: 10px;
`;

UsersForm.propTypes = {
  values: PropTypes.objectOf(PropTypes.any).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  validations: PropTypes.objectOf(PropTypes.any).isRequired,
  usersType: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default UsersForm;
