import React, { useState, useEffect } from 'react';

import AddressAPI from 'service/ViaCepService';

import PropTypes from 'prop-types';

export const AddressContext = React.createContext({});

export const AddressProvider = ({ children }) => {
  const [zipcode, setZipcode] = useState('');
  const [address, setAddressField] = useState('');
  const [neighborhood, setNeighborhood] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      const data = await AddressAPI(zipcode.replace('-', ''));
      setAddressField(data.address);
      setNeighborhood(data.neighborhood);
      setCity(data.city);
      setState(data.state);
    };
    if (zipcode.length !== 0 && (zipcode.length === 8 || zipcode.length === 9)) {
      fetchData();
    }
  }, [zipcode]);
  return (
    <AddressContext.Provider value={{
      zipcode,
      setZipcode,
      address,
      setAddressField,
      neighborhood,
      setNeighborhood,
      city,
      setCity,
      state,
      setState
    }}
    >
      {children}
    </AddressContext.Provider>
  );
};

AddressProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export const useAddress = () => React.useContext(AddressContext);
