import React from 'react';
import VersionScript from 'containers/PatchNotes/version';
import styled from 'styled-components';
import marketing from 'assets/images/marketing.png';
import logoRMC from 'assets/images/logoRMC.svg';
import LoginForm from './LoginForm';

function Login() {
  const welcome = 'Faça seu Login';

  return (
    <Wrapper>
      <Box>
        <LoginBox>
          <LoginUtil>
            <LoginHeader>
              <Image src={logoRMC} alt="logo" />
              <Wellcome>{welcome}</Wellcome>
            </LoginHeader>
            <LoginForm />
            <Version>{VersionScript}</Version>
          </LoginUtil>
        </LoginBox>
        <MarketingBox />
      </Box>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  min-height: 100vh;

  display: -ms-flex;
  display: -webkit-flex;
  display: flex;

  -ms-align-items: center;
  -webkit-align-items: center;
  align-items: center;

  -ms-justify-content: center;
  -webkit-justify-content: center;
  justify-content: center;
`;

const Box = styled.div`
  height: 600px;
  width: 1280px;

  display: grid;
  grid-template-columns: 480px 800px;

  -webkit-box-shadow: 5px 3px 30px rgba(50, 50, 50, 0.2);
  -moz-box-shadow:    5px 3px 30px rgba(50, 50, 50, 0.2);
  box-shadow:         5px 3px 30px rgba(50, 50, 50, 0.2);

  @media(max-width: 800px) {
    grid-template-columns: none;
    height: auto;
    width: 480px;
    -webkit-box-shadow: none;
    -moz-box-shadow:    none;
    box-shadow:         none;
  }

  @media(max-width: 1150px) {
    grid-template-columns: none;
    height: auto;
    width: 480px;
    -webkit-box-shadow: none;
    -moz-box-shadow:    none;
    box-shadow:         none;
  }
`;

const LoginBox = styled.div`
  height: 600px;
  width: 480px;

  display: -ms-flex;
  display: -webkit-flex;
  display: flex;

  @media(max-width: 800px) {
    height: auto;
    width: auto;
    align-items: center;
  }
`;

const LoginUtil = styled.div`
  width: 360px;
  margin: 0 auto;
  margin-top: 30px;
  @media(max-width: 800px) {
    width: 320px;
    margin-top: 20px;
    align-self: center;
  }

  @media(max-width: 1150px) {
    width: 320px;
    margin-top: 20px;
    align-self: center;
  }
  
`;

const LoginHeader = styled.div`
  text-align: center;
  align-items: center;
`;

const Image = styled.img`
  height: 180px;
`;

const Wellcome = styled.span`
  font-size: 22px;
  color: #20314F;
  margin-top: 10px;
  display: block;
  font-weight: 500;

`;

const MarketingBox = styled.div`
  height: 600px;
  width: 800px;
  background-image: url(${marketing});
  background-repeat: no-repeat;
  background-size: cover;

  @media(max-width: 800px) {
    display:none;
  }

  @media(max-width: 1150px) {
    display:none;
  }
`;

const Version = styled.div`
  align-items: flex-end;
  text-align: right;
  font-size: 10px;
  font-weight: 700;
  color: #808080;
  font-family: 'Open Sans';
`;

export default Login;
