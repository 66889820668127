import React from 'react';
import styled from 'styled-components';

const NoData = () => <NoDataCase>Não existe dados cadastrados</NoDataCase>;

const NoDataCase = styled.div`
  font-weight: 500;
  margin-top: 10px;
  margin-left: 5px;
`;

export default NoData;
