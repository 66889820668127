import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toastError } from 'helpers/notifications';
import { getStoresByParameter, getStoresHistory, updateToken } from 'service/StoresService';
import { deleteDocumentation, getDocumentationFile } from 'service/StoreDocumentationService';
import {
  createStoreContacts,
  editStoreContacts,
  destroyStoreContacts
} from 'service/StoreContactsService';
import Swal from 'sweetalert2';
import AlertWarning from 'components/alert/AlertWarning';
import fileSaver from 'file-saver';
import base64ToBlob from 'b64-to-blob';

export const ProfileContext = React.createContext({});

export const ProfileProviders = ({ children }) => {
  const [activeStore, setActiveStore] = useState(true);
  const [stores, setStores] = useState([]);
  const [interactions, setInteractions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchedStore, setSearchedStore] = useState('');
  const [importModal, setImportModal] = useState(false);
  const [value, setValue] = useState('');
  const [modalCreateContact, setModalCreateContact] = useState(false);
  const searchStore = async () => {
    if (value !== '') {
      const sendDataArr = { input: value.toLowerCase() };
      setLoading(true);
      setStores([]);
      const results = await getStoresByParameter(sendDataArr);
      setLoading(false);
      if (results === null) {
        toastError('Drogaria Não encontrada!');
        setInteractions([]);
      } else {
        const interactionData = await getStoresHistory(results[0].id);
        localStorage.setItem('storeName', results[0].storeName);
        localStorage.setItem('storeId', results[0].id);
        localStorage.setItem('angelName', results[0].angelData.name);
        localStorage.setItem('consultantName', results[0].consultantData.name);
        setInteractions(interactionData);
        setStores(results);
        setActiveStore(results[0].active);
        setSearchedStore(results[0]);
      }
    }
  };

  const deleteStoreDocumentation = async (id) => {
    await Swal.fire(AlertWarning('Tem certeza que excluir permanentemente o documento?')).then(async (result) => {
      if (result.isConfirmed) {
        const res = await deleteDocumentation(id);
        if (res.name === 'ApplicationError') {
          toastError(res.errors[0]);
        } else {
          searchStore();
        }
      }
    });
  };

  const downloadFile = async (path, fileName) => {
    const b64 = await getDocumentationFile(path);
    const blob = base64ToBlob(b64, 'aplication/pdf');
    const file = new File([blob], `${fileName} - ${stores[0].storeName}.pdf`, { type: 'data:aplication/pdf;base64' });
    fileSaver.saveAs(file);
  };

  const officesValidation = (values) => {
    let officesCounter = 0;

    if (values.mainContact) {
      officesCounter += 1;
    }
    if (values.financialContact) {
      officesCounter += 1;
    }
    if (values.general) {
      officesCounter += 1;
    }
    if (values.ethical) {
      officesCounter += 1;
    }
    if (values.generic) {
      officesCounter += 1;
    }
    if (values.similar) {
      officesCounter += 1;
    }
    if (values.perfumery) {
      officesCounter += 1;
    }
    if (officesCounter < 1) {
      return false;
    }
    return true;
  };

  const createContact = async (values) => {
    if (!officesValidation(values)) {
      toastError('Ao menos 1 atribuição deve ser feita ao contato');
    } else {
      setLoading(true);
      const res = await createStoreContacts(values);
      setLoading(false);
      if (res.name === 'ApplicationError') {
        toastError(res.errors[0]);
      } else {
        setModalCreateContact(false);
        await searchStore();
      }
    }
  };

  const editContact = async (values) => {
    if (!officesValidation(values)) {
      toastError('Ao menos 1 atribuição deve ser feita ao contato');
    } else {
      setLoading(true);
      const res = await editStoreContacts(values.id, values);
      if (res.name === 'ApplicationError') {
        toastError(res.errors[0]);
      }
      setLoading(false);
      await searchStore();
    }
  };

  const excludeContact = async (contactId) => {
    await Swal.fire(AlertWarning('Tem certeza que excluir permanentemente o documento?')).then(async (result) => {
      if (result.isConfirmed) {
        await destroyStoreContacts(contactId);
        searchStore();
      }
    });
  };

  const submitToken = async (values) => {
    setLoading(true);
    await updateToken(values.params, values);
    setLoading(false);
    searchStore();
  };

  return (
    <ProfileContext.Provider value={{
      searchedStore,
      setSearchedStore,
      importModal,
      setImportModal,
      activeStore,
      stores,
      interactions,
      loading,
      searchStore,
      value,
      setValue,
      deleteStoreDocumentation,
      downloadFile,
      createContact,
      modalCreateContact,
      setModalCreateContact,
      editContact,
      excludeContact,
      submitToken,
      setLoading
    }}
    >
      {children}
    </ProfileContext.Provider>
  );
};

ProfileProviders.propTypes = {
  children: PropTypes.element.isRequired,
};

export const useProfile = () => React.useContext(ProfileContext);
