import React, { useState } from 'react';
import styled from 'styled-components';

import {
  BsFillGridFill
} from 'react-icons/bs';

import {
  FaThList
} from 'react-icons/fa';

import { useCalls } from 'providers/calls';
import { getProfileUserType } from 'helpers/getProfileUserType';

import SwitchCase from 'components/common/SwitchCase';
import SwitchButton from 'components/common/SwitchButton';
import IconCase from '../../components/skilled/TopPage/IconCase';
import CallsTable from './CallsTable';
import CallsCard from './CallsCard';
import CallsCreate from './CallsCreate';

const CallsGrid = () => {
  const {
    sector,
    setSector,
    endedCalls,
    setEndedCalls,
    endedCallsAdm,
    setEndedCallsAdm,
    setOpenCalls,
    openCalls,
  } = useCalls();
  const [tabletyle, setTaableStyle] = useState(false);

  const previewStyle = (value) => {
    setTaableStyle(value);
  };

  const setColor = (parameter, style) => {
    if (parameter === 'table') {
      if (style) {
        return '';
      } return '#224772';
    }
    if (!style) {
      return '';
    } return '#224772';
  };

  return (
    <>
      <DesktopMode>
        <Grid>
          <CallsCreate />
          {(!getProfileUserType([1, 2, 15])) && (
            <>
              <SwitchCase>
                <SwitchButton widthTag="250px" tag="Abertos por mim" value={openCalls} setActiveValue={setOpenCalls} />
              </SwitchCase>
              <SwitchCase>
                <SwitchButton widthTag="200px" tag="Concluídos" value={endedCalls} setActiveValue={setEndedCalls} />
              </SwitchCase>
            </>
          )}
          {(getProfileUserType([1, 2, 15])) && (
            <>
              <SwitchCase>
                <SwitchButton widthTag="100px" tag="Todos" value={sector} setActiveValue={setSector} />
              </SwitchCase>
              <SwitchCase>
                <SwitchButton widthTag="200px" tag="Concluídos" value={endedCallsAdm} setActiveValue={setEndedCallsAdm} />
              </SwitchCase>
            </>
          )}
          <IconCase>
            <TableIcon title="Tabela" color={setColor('table', tabletyle)} onClick={() => previewStyle(false)} />
          </IconCase>
          <IconCase>
            <BsFillGridFill title="Card" color={setColor('card', tabletyle)} onClick={() => previewStyle(true)} />
          </IconCase>
        </Grid>
        { tabletyle && (
          <CallsCard />
        )}
        { !tabletyle && (
          <CallsTable />
        )}
      </DesktopMode>
      <PhoneMode>
        <CallsCard />
      </PhoneMode>
    </>
  );
};

const Grid = styled.div`
  display: flex;
  justify-content: flex-end;
 @media(max-width: 800px) {
   display: none;
 }
`;

const DesktopMode = styled.div`
  @media(max-width: 800px) {
    display: none;
  }
`;

const PhoneMode = styled.div`
  @media(min-width: 800px) {
    display: none;
  }
`;

const TableIcon = styled(FaThList)`
  color: ${(props) => props.color};
`;

export default CallsGrid;
