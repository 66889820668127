import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import getProfile from 'helpers/getProfile';

import {
  useTable,
  useSortBy,
  usePagination,
  useFilters,
  useExpanded,
} from 'react-table';

import {
  AiOutlineArrowDown,
  AiOutlineArrowUp
} from 'react-icons/ai';
import {
  IoIosArrowBack,
  IoIosArrowForward
} from 'react-icons/io';

import {
  Table,
  Th,
  Thead,
  Td,
  Tr,
  TableSpan
} from 'components/tableComponents';

const DefaultColumnFilter = ({
  column: { filterValue, setFilter },
}) => (
  <input
    value={filterValue || ''}
    onChange={(e) => {
      setFilter(e.target.value || undefined);
    }}
    style={{ height: '24px' }}
  />
);

const SelectColumnFilter = ({
  column: {
    filterValue,
    setFilter,
    preFilteredRows,
    id
  },
}) => {
  const options = React.useMemo(() => {
    const setOptions = new Set();
    preFilteredRows.forEach((row) => {
      setOptions.add(row.values[id]);
    });
    return [...setOptions.values()];
  }, [id, preFilteredRows]);

  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value=""> </option>
      {options.map((option) => (
        <option key={option.id} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
};

const MineFilter = ({
  column: {
    setFilter,
  },
}) => {
  const [value, setValue] = React.useState(false);
  const [color, setColor] = React.useState('background-color: white;');
  const loggedUser = getProfile();
  return (
    <CheckMineDiv>
      <InputCheck
        value={value}
        type="checkbox"
        onChange={(e) => {
          if (e.target.checked) {
            setFilter(loggedUser.user.name);
            setValue(true);
            setColor('background-color: #5CB85C;');
          } else {
            setFilter('');
            setValue(false);
            setColor('background-color: white;');
          }
        }}
        state={color}
      />
      <span>Meus Registros</span>
    </CheckMineDiv>
  );
};

const CompleteTable = ({
  data,
  columns,
  changeTableSize,
  renderRowSubComponent,
  changeInactiveStyle,
  marginTop,
  sortOrder,
  textMarking,
  pagination,
}) => {
  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const setColor = (cell) => {
    if (textMarking) {
      if (cell) {
        return '';
      }
      return '#faf3a7';
    }
    return '';
  };

  const sortBy = () => {
    switch (sortOrder) {
      case 'visitReport':
        return [{
          id: 'Dias1',
          desc: true
        }];
      default:
        return [];
    }
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    pageOptions,
    rows,
    state: { pageIndex, pageSize },
    previousPage,
    nextPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
    visibleColumns
  } = useTable({
    columns,
    data,
    defaultColumn,
    initialState: {
      sortBy: sortBy(),
      pageSize: changeTableSize
    },
  },
  useFilters,
  useSortBy,
  useExpanded,
  usePagination);

  const HandleOrderIcon = (column) => {
    if (column.isSorted) {
      if (column.isSortedDesc) {
        return <AiOutlineArrowDown />;
      } return <AiOutlineArrowUp />;
    } return '';
  };

  const controlColumnWidth = (val) => {
    if (val.setWidth) {
      return val.setWidth;
    } if (val.Header.length < 4) {
      return '4%';
    } return '';
  };

  return (
    <FontColorInactive marginTop={marginTop} incactiveStyle={changeInactiveStyle && (data.map((indx) => !indx.active && ('color: #7a191f;')))}>
      <FloatSide>
        <TotalGrid>
          TOTAL: {rows.length}
        </TotalGrid>
      </FloatSide>
      <Table {...getTableProps()}>
        <Thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  width={controlColumnWidth(column)}
                >
                  <Th {...column.getHeaderProps(column.getSortByToggleProps({ title: 'Ordenado por...' }))}>
                    {column.render('Header')}
                    <span>
                      {HandleOrderIcon(column)}
                    </span>
                  </Th>
                  {column.defaultFilter ? column.render('Filter') : null}
                  {column.mineFilter && <MineFilter column={column} />}
                  {column.selectFilter && <SelectColumnFilter column={column} />}
                </th>
              ))}
            </tr>
          ))}
        </Thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <>
                <Tr
                  {...row.getRowProps()}
                  style={{ backgroundColor: setColor(row?.cells[7]?.value) }}
                >
                  {row.cells.map((cell) => (
                    <Td
                      {...cell.getCellProps()}
                    >
                      <TableSpan>{cell.render('Cell')}</TableSpan>
                    </Td>
                  ))}
                </Tr>
                {row.isExpanded ? (
                  <tr>
                    <td colSpan={visibleColumns.length}>
                      {renderRowSubComponent({ row })}
                    </td>
                  </tr>
                ) : null}
              </>
            );
          })}
        </tbody>
      </Table>
      <Footer>
        {pagination && (
          <div>
            <Button type="button" onClick={() => previousPage()} disabled={!canPreviousPage}>
              <IoIosArrowBack style={{ color: 'white' }} />
            </Button>
            <Button type="button" onClick={() => nextPage()} disabled={!canNextPage}>
              <IoIosArrowForward style={{ color: 'white' }} />
            </Button>
          </div>
        )}
        <div style={{ textAlign: 'center' }}>
          Página{' '}
          <em>
            {pageIndex + 1} de {pageOptions.length}
          </em>
        </div>
        {pagination && (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <select
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[15, 30, 50, 100].map((i) => (
                <option key={i} value={i}>
                  Mostrar {i}
                </option>
              ))}
            </select>
          </div>
        )}
      </Footer>
    </FontColorInactive>
  );
};

const FontColorInactive = styled.div`
  ${(props) => props.incactiveStyle}
  margin-top: ${(props) => props.marginTop};
`;

const FloatSide = styled.div`
  margin-bottom: 5px;
  height: 30px;
  display: flex;
  align-items: center;
`;

const Footer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;

const Button = styled.button`
  background: #5CB85C;
  font-weight: 700;
  border: 0;
  margin: 0;
  color: #fff;
  cursor: pointer;
  height: 23px;
  width: 25px;
  border-radius: 4px;
  justify-content: flex-end;

  -webkit-box-shadow: 5px 3px 20px rgba(50, 50, 50, 0.2);
  -moz-box-shadow:    5px 3px 20px rgba(50, 50, 50, 0.2);
  box-shadow:         5px 3px 20px rgba(50, 50, 50, 0.2);

  transition: 0.3s;
  opacity: 1;
  margin: 5px;

  :disabled {
    background: #838594;
  }

  :hover{
    opacity: 0.8;
  };
`;

const TotalGrid = styled.span`
  background-color: #DAE6F3;
  color: #20314F;
  font-weight: bold;
  margin-left: 2px;
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 5px;
`;

const CheckMineDiv = styled.div`
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 340px;
  top: 55px;
`;

const InputCheck = styled.input`
  ${(props) => props.state}
  margin-right: 5px;
  width: 20px;
  height: 20px;
`;

CompleteTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.element).isRequired,
  columns: PropTypes.arrayOf(PropTypes.element).isRequired,
  changeTableSize: PropTypes.number,
  renderRowSubComponent: PropTypes.arrayOf(PropTypes.element),
  changeInactiveStyle: PropTypes.bool,
  marginTop: PropTypes.string,
  sortOrder: PropTypes.bool,
  textMarking: PropTypes.string,
  pagination: PropTypes.bool,
};

CompleteTable.defaultProps = {
  changeTableSize: 15,
  sortOrder: false,
  renderRowSubComponent: {},
  changeInactiveStyle: false,
  marginTop: '90px',
  textMarking: false,
  pagination: true,
};

DefaultColumnFilter.propTypes = {
  filterValue: PropTypes.element.isRequired,
  setFilter: PropTypes.element.isRequired,
  column: PropTypes.arrayOf(PropTypes.element).isRequired,
};

SelectColumnFilter.propTypes = {
  column: PropTypes.arrayOf(PropTypes.element).isRequired,
  filterValue: PropTypes.element.isRequired,
  setFilter: PropTypes.element.isRequired,
  preFilteredRows: PropTypes.element.isRequired,
  id: PropTypes.element.isRequired,
};

MineFilter.propTypes = {
  column: PropTypes.arrayOf(PropTypes.element).isRequired,
  setFilter: PropTypes.element.isRequired,
};

export default CompleteTable;
