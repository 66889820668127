import axios from 'axios';
import { API } from 'helpers/constant';

const getConsultant = () => axios
  .get(`${API}/users/consultant`)
  .then((res) => res.data)
  .catch((error) => error);

const consultantandinternalconsultant = () => axios
  .get(`${API}/users/consultantandinternalconsultant`)
  .then((res) => res.data)
  .catch((error) => error);

export { getConsultant, consultantandinternalconsultant };
