import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Label = ({ name, children }) => <LabelCase htmlFor={name}>{children}</LabelCase>;

const LabelCase = styled.span`
  color: #838594;
  font-weight: 500;
  font-size: 13px;
`;

Label.propTypes = {
  children: PropTypes.element.isRequired,
  name: PropTypes.string.isRequired
};

export default Label;
