import React from 'react';
import PropTypes from 'prop-types';
import Label from 'components/common/Label';
import Asterisk from 'components/common/Asterisk';
import MensageError from 'components/common/MensageError';

const SelectField = ({
  label,
  name,
  options,
  isRequired,
  error,
  onChange,
  isError,
  onBlur,
  value
}) => (
  <div>
    <Label htmlFor={name}>{label}</Label> {isRequired && <Asterisk>*</Asterisk> }
    <select
      id={name}
      name={name}
      onChange={onChange}
      onBlur={onBlur}
      as="select"
      value={value}
    >
      <option value="" disabled selected hidden>Selecione... </option>
      {
        options.map(
          (index) => (
            <option key={index.id} value={index.id}>
              {index.name}
            </option>
          )
        )
      }
    </select>
    { isError && <MensageError>{error}</MensageError> }
  </div>
);

SelectField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.any).isRequired,
  onChange: PropTypes.func.isRequired,
  isRequired: PropTypes.bool,
  error: PropTypes.string,
  isError: PropTypes.string.isRequired,
  onBlur: PropTypes.func.isRequired,
  value: PropTypes.bool.isRequired,
};

SelectField.defaultProps = {
  isRequired: false,
  error: '',
};

export default SelectField;
