import { validateTokenService } from 'service/LoginService';
import { isAuthenticated } from './isAuthenticated';

const verifyToken = async () => {
  let profile = await localStorage.getItem('profile');
  if (profile) {
    profile = JSON.parse(profile);
    const res = await validateTokenService({ token: profile.token });
    return res.data;
  }
  return false;
};

export const isAuthorized = async () => {
  // verificar se o token e valido
  const tokenValid = await verifyToken();
  // verificar se o usuario esta autenticado
  // verificar se o usuario esta autorizado a executar a ação

  if (isAuthenticated() && tokenValid) {
    return true;
  }
  return false;
};
