import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { DualRing } from 'react-spinners-css';

function Loading({
  active,
}) {
  return (
    <Case>
      {active && (
        <DualRing color="#5CB85C" size="60" />
      )}
    </Case>
  );
}

const Case = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

Loading.propTypes = {
  active: PropTypes.bool.isRequired,
};

export default Loading;
