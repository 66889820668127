import getProfile from './getProfile';

const getProfileUserType = (userTypeArr = []) => {
  const profile = getProfile();
  const { userTypeId } = profile.user;
  if (userTypeArr.indexOf(userTypeId) === -1) {
    return false;
  } return true;
};

const getProfileAcl = (userTypeArr = []) => {
  const profile = getProfile();
  const { acl } = profile.user;
  if (userTypeArr.indexOf(acl) === -1) {
    return false;
  } return true;
};

export { getProfile, getProfileUserType, getProfileAcl };
