import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { IoCloseOutline } from 'react-icons/io5';

const FilterGrid = ({ children, visible, onCLose }) => (
  visible && (
  <Container>
    <Header><IoCloseOutline style={{ cursor: 'pointer', fontSize: '20px' }} onClick={() => onCLose()} /></Header>
    <Grid>
      {children}
    </Grid>
  </Container>
  )
);

const Container = styled.div`
  background-color: #90D165;
  margin-bottom: 12px;

  border-radius: 5px;
  -webkit-box-shadow: 3px 3px 0px 0px rgba(180,198,212, 0.3);
  -moz-box-shadow:    3px 3px 0px 0px rgba(180,198,212, 0.3);
  box-shadow:         3px 3px 0px 0px rgba(180,198,212, 0.3);

  @media(max-width: 800px) {
    display: flex;
    flex-direction: row;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 10px;
  padding: 10px;
  align-items: flex-start;
  justify-content: flex-start; 
`;

const Header = styled.div`
  text-align: right;
  height: 5px;
  padding: 5px;
  margin: 5px;
`;

FilterGrid.propTypes = {
  children: PropTypes.element.isRequired,
  visible: PropTypes.element.isRequired,
  onCLose: PropTypes.func.isRequired,
};

export default FilterGrid;
