import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Shorten = ({ children, maxWidth }) => (
  <ShortenComponent maxWidth={maxWidth}>{children}</ShortenComponent>
);

const ShortenComponent = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: ${(props) => props.maxWidth};
`;

Shorten.propTypes = {
  children: PropTypes.objectOf(PropTypes.any).isRequired,
  maxWidth: PropTypes.string,
};

Shorten.defaultProps = {
  maxWidth: '324px',
};

export default Shorten;
