import React from 'react';
import { AddressProvider } from 'providers/address';
import { CompanyProvider } from 'providers/company';
import Company from './Company';

const index = () => (
  <CompanyProvider>
    <AddressProvider>
      <Company />
    </AddressProvider>
  </CompanyProvider>
);

export default index;
