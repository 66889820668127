import React from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';

import Button from 'components/common/Button';
import ButtonCase from 'components/common/ButtonCase';
import CancelButton from 'components/common/CancelButton';
import FieldSection from 'components/common/FieldSection';
import InputField from 'components/common/InputField';
import CheckBooleanField from 'components/common/CheckBooleanField';
import Case from 'components/common/Case';

const Form = ({
  values,
  handleSubmit,
  validations,
  handleCancel
}) => {
  const formik = useFormik({
    initialValues: values,
    onSubmit: handleSubmit,
    validationSchema: Yup.object(validations),
  });
  return (
    <FormData onSubmit={formik.handleSubmit}>
      <Case gridTemplateColumns="1fr 1fr">
        <FieldSection title="Dados Pessoais">
          <InputField
            id="name"
            name="name"
            label="Nome:"
            placeholder="Nome do contato"
            isRequired
            type="text"
            error={formik.errors.name}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.name}
            isError={formik.errors.name && formik.touched.name}
          />
          <InputField
            id="email"
            name="email"
            label="Email:"
            placeholder="Email do contato"
            isRequired
            type="text"
            error={formik.errors.email}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.email}
            isError={formik.errors.email && formik.touched.email}
          />
          <InputField
            id="phoneNumber"
            name="phoneNumber"
            label="Telefone:"
            placeholder="(00) 0 0000-0000"
            isRequired
            type="text"
            error={formik.errors.phoneNumber}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.phoneNumber}
            isError={formik.errors.phoneNumber && formik.touched.phoneNumber}
            mask="(99) 9 9999-9999"
          />
        </FieldSection>
        <FieldSection title="Atribuições">
          <Case gridTemplateColumns="1fr 1fr">
            <CheckBooleanField
              id="mainContact"
              name="mainContact"
              label="Contato Principal"
              type="checkbox"
              error={formik.errors.mainContact}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.mainContact}
            />
            <CheckBooleanField
              id="financialContact"
              name="financialContact"
              label="Contato Financeiro"
              type="checkbox"
              error={formik.errors.financialContact}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.financialContact}
            />
            <CheckBooleanField
              id="general"
              name="general"
              label="Comprador Geral"
              type="checkbox"
              error={formik.errors.general}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.general}
            />
            <CheckBooleanField
              id="ethical"
              name="ethical"
              label="Comprador de Ético"
              type="checkbox"
              error={formik.errors.ethical}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.ethical}
            />
            <CheckBooleanField
              id="generic"
              name="generic"
              label="Comprador de Genérico"
              type="checkbox"
              error={formik.errors.generic}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.generic}
            />
            <CheckBooleanField
              id="similar"
              name="similar"
              label="Comprador de Similar"
              type="checkbox"
              error={formik.errors.similar}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.similar}
            />
            <CheckBooleanField
              id="perfumery"
              name="perfumery"
              label="Comprador de Perfumaria"
              type="checkbox"
              error={formik.errors.perfumery}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.perfumery}
            />
          </Case>
        </FieldSection>
      </Case>
      <ButtonCase>
        <CancelButton onClose={() => handleCancel(false)}>Cancelar</CancelButton>
        <Button type="submit">Salvar</Button>
      </ButtonCase>
    </FormData>
  );
};

const FormData = styled.form`  
  width: 60vw;
  height: 280px;
  margin: 10px;
`;

Form.propTypes = {
  validations: PropTypes.objectOf(PropTypes.any).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  values: PropTypes.objectOf(PropTypes.any).isRequired
};

export default Form;
