import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import TableTh from './TableTh';

const TableHeader = ({ children, gridTemplateColumns }) => (
  <List>
    <TableTh gridTemplateColumns={gridTemplateColumns}>{children}</TableTh>
  </List>
);

const List = styled.li`
  list-style-type: none;
  background-color: #DAE6F3;
`;

TableHeader.propTypes = {
  children: PropTypes.PropTypes.arrayOf(PropTypes.element).isRequired,
  gridTemplateColumns: PropTypes.string.isRequired,
};

export default TableHeader;
