import styled from 'styled-components';
import TableTd from './TableTd';

const TableLabel = styled(TableTd)`
  margin: 7px;
  overflow: hidden;
  max-height: 25px;
`;

export default TableLabel;
