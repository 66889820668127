import axios from 'axios';
import { API_REPORT } from 'helpers/constant';

const getDashboardDirector = () => axios.get(`${API_REPORT}/dashboardDirector`)
  .then((res) => res.data)
  .catch((error) => error(error[0]));

const getDashboardDirectorForPeriod = (startDate, endDate) => axios.get(`${API_REPORT}/dashboardDirector/${startDate}/${endDate}`)
  .then((res) => res.data)
  .catch((error) => error(error[0]));

const getDashboardLeader = (userId) => axios.get(`${API_REPORT}/dashboardLeader/${userId}`)
  .then((res) => res.data)
  .catch((error) => error(error[0]));

const getDashboardLeaderExpansion = (userId) => axios.get(`${API_REPORT}/dashboardLeaderExpansion/${userId}`)
  .then((res) => res.data)
  .catch((error) => error(error[0]));

const getDashboardLeaderAngel = (userId) => axios.get(`${API_REPORT}/dashboardLeaderAngel/${userId}`)
  .then((res) => res.data)
  .catch((error) => error(error[0]));

const getDashboardLeaderAngelForPeriod = (userId, startDate, endDate) => axios.get(`${API_REPORT}/dashboardLeaderAngel/${userId}/${startDate}/${endDate}`)
  .then((res) => res.data)
  .catch((error) => error(error[0]));

const getDashboardAngel = (userId) => axios.get(`${API_REPORT}/dashboardAngel/${userId}`)
  .then((res) => res.data)
  .catch((error) => error(error[0]));

const getDashboardIntConsultant = (userId) => axios.get(`${API_REPORT}/dashboardIntConsultant/${userId}`)
  .then((res) => res.data)
  .catch((error) => error(error[0]));

const getDashboardUser = (userId) => axios.get(`${API_REPORT}/dashboardUser/${userId}`)
  .then((res) => res.data)
  .catch((error) => error(error[0]));

const getDashboardExpansion = (userId) => axios.get(`${API_REPORT}/dashboardExpansion/${userId}`)
  .then((res) => res.data)
  .catch((error) => error(error[0]));

const getVisits = () => axios.get(`${API_REPORT}/reportVisits`)
  .then((res) => res.data)
  .catch((error) => error(error[0]));

const getStoreContacts = () => axios.get(`${API_REPORT}/reportStoreContacts`)
  .then((res) => res.data)
  .catch((error) => error(error[0]));

const getVisitsByConsultant = (userId) => axios.get(`${API_REPORT}/reportVisits/${userId}`)
  .then((res) => res.data)
  .catch((error) => error(error[0]));

const getConsultantVisitReports = () => axios.get(`${API_REPORT}/reportConsultantVisit`)
  .then((res) => res.data)
  .catch((error) => error(error[0]));

const getConsultantVisitReportsForPeriod = (startDate, endDate) => axios.get(`${API_REPORT}/reportConsultantVisit/${startDate}/${endDate}`)
  .then((res) => res.data)
  .catch((error) => error(error[0]));

const getContactsReports = () => axios.get(`${API_REPORT}/reportContacts`)
  .then((res) => res.data)
  .catch((error) => error(error[0]));

const getContactsReportsForPeriod = (startDate, endDate) => axios.get(`${API_REPORT}/reportContacts/${startDate}/${endDate}`)
  .then((res) => res.data)
  .catch((error) => error(error[0]));

const getUserContactsReports = (userId) => axios.get(`${API_REPORT}/reportContacts/${userId}`)
  .then((res) => res.data)
  .catch((error) => error(error[0]));

const getAllStores = () => axios.get(`${API_REPORT}/reportStores`)
  .then((res) => res.data)
  .catch((error) => error(error[0]));

const getAllStoresAdmin = () => axios.get(`${API_REPORT}/reportStoresAdmin`)
  .then((res) => res.data)
  .catch((error) => error(error[0]));

const getAllStoresByStatus = (storeStatus) => axios.get(`${API_REPORT}/reportStores/${storeStatus}`)
  .then((res) => res.data)
  .catch((error) => error(error[0]));

export {
  getContactsReportsForPeriod,
  getDashboardExpansion,
  getDashboardDirector,
  getDashboardLeader,
  getDashboardUser,
  getDashboardAngel,
  getDashboardLeaderAngel,
  getVisits,
  getVisitsByConsultant,
  getConsultantVisitReports,
  getContactsReports,
  getUserContactsReports,
  getConsultantVisitReportsForPeriod,
  getDashboardLeaderExpansion,
  getDashboardIntConsultant,
  getDashboardLeaderAngelForPeriod,
  getDashboardDirectorForPeriod,
  getStoreContacts,
  getAllStores,
  getAllStoresAdmin,
  getAllStoresByStatus
};
