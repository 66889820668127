import styled from 'styled-components';

const TableCaseIcon = styled.span`
  font-size: 15px;
  display: flex;
  white-space: normal;
  justify-content: space-around;
`;

export default TableCaseIcon;
