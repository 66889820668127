import React from 'react';
import { CallsProvider } from 'providers/calls';
import { UserDashboardProvider } from 'providers/Dashboard/user';
import Calls from './Calls';

const index = () => (
  <CallsProvider>
    <UserDashboardProvider><Calls /></UserDashboardProvider>
  </CallsProvider>
);

export default index;
