import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import InputField from 'components/common/InputField';
import Button from 'components/common/Button';
import ButtonCase from 'components/common/ButtonCase';

const StoreDocumentationForm = ({
  values,
  handleSubmit,
  validations,
}) => {
  const formik = useFormik({
    initialValues: values,
    onSubmit: handleSubmit,
    validationSchema: Yup.object(validations),
  });

  return (
    <Form data-testid="client-form" onSubmit={formik.handleSubmit}>
      <InputField
        id="name"
        name="name"
        label="Adicionar Documento"
        placeholder="Nome do Documento"
        type="text"
        error={formik.errors.name}
        onBlur={formik.handleBlur}
        onChange={(e) => {
          formik.handleChange(e);
        }}
        value={formik.values.name}
        isError={formik.errors.name && formik.touched.name}
      />
      <ButtonCase>
        <Button type="submit">Salvar</Button>
      </ButtonCase>
    </Form>
  );
};

const Form = styled.form`  
  padding: 5px;

  @media(max-width: 800px) {
    width: 100%;
    overflow: auto;
  }
`;

StoreDocumentationForm.propTypes = {
  validations: PropTypes.objectOf(PropTypes.any).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  values: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default StoreDocumentationForm;
