import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

function Case({
  children,
  display,
  gridTemplateColumns,
  alignItems,
  gap,
  justifyContent,
  height,
  width,
  margin
}) {
  return (
    <AreaCase
      gridTemplateColumns={gridTemplateColumns}
      alignItems={alignItems}
      gap={gap}
      width={width}
      display={display}
      justifyContent={justifyContent}
      height={height}
      margin={margin}
    >
      {children}
    </AreaCase>
  );
}

const AreaCase = styled.div`
  width: ${(props) => props.width};
  display: ${(props) => props.display};
  grid-template-columns: ${(props) => props.gridTemplateColumns};
  align-items: ${(props) => props.alignItems};
  justify-content:  ${(props) => props.justifyContent};
  gap: ${(props) => props.gap};
  height: ${(props) => props.height};
  margin: ${(props) => props.margin};
`;

Case.propTypes = {
  children: PropTypes.element.isRequired,
  display: PropTypes.string,
  gridTemplateColumns: PropTypes.string,
  alignItems: PropTypes.string,
  gap: PropTypes.string,
  width: PropTypes.string,
  justifyContent: PropTypes.string,
  height: PropTypes.string,
  margin: PropTypes.string,
};

Case.defaultProps = {
  gridTemplateColumns: '1fr',
  display: 'grid',
  alignItems: 'stretch',
  gap: '10px',
  width: 'auto',
  justifyContent: 'flex-start',
  height: 'auto',
  margin: '0',
};
export default Case;
