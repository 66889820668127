import React, { useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import {
  ImUser,
  ImPhone,
  ImUserTie,
  ImSortAmountDesc,
} from 'react-icons/im';
import { MdMoneyOff, MdPermPhoneMsg } from 'react-icons/md';
import { GiLibertyWing } from 'react-icons/gi';
import {
  FaTimesCircle,
  FaCheckCircle,
  FaBookOpen,
  FaRegCalendarAlt,
  FaMoneyBillAlt,
  FaWindowClose,
  FaTools,
  FaExclamationCircle
} from 'react-icons/fa';
import { TiArrowSortedDown, TiArrowSortedUp } from 'react-icons/ti';
import {
  RiMapPin2Fill,
  RiFlag2Fill,
  RiFileForbidFill,
  RiEmotionUnhappyFill,
  RiQuestionFill,
} from 'react-icons/ri';
import { GrMail } from 'react-icons/gr';
import { IoDocumentsSharp } from 'react-icons/io5';
import { HiUsers } from 'react-icons/hi';

import { useProfile } from 'providers/Stores/profile';
import { Card } from 'components/card';

import verifyAclUser from 'helpers/verifyAclUser';
import acl from 'helpers/acl';

// import Shorten from 'components/common/Shorten';
import LoadingBlocked from 'components/common/LoadingBlocked';
import ShortenParagraph from 'components/common/ShortenParagraph';

import { cnpjMask, phoneMask } from 'helpers/Mask';
import colors from 'assets/style/colors';
import Button from 'components/common/Button';

import CallsCreate from 'containers/Calls/CallsCreate';
import DiaryCreate from 'containers/Diary/DiaryCreate';
import InitialEdit from './InitialEdit';
import ChangeStatus from './ChangeStatus';
import StoreDocumentation from './StoreDocumentation';
import CreateContact from './Contacts/CreateContact';
import EditContact from './Contacts/EditContact';
import LinkedToken from './LinkedToken';
import Comments from './Comments';

const StoresProfile = () => {
  const {
    activeStore,
    stores,
    interactions,
    loading,
    searchStore,
    setValue
  } = useProfile();

  const [reasonsDisplay, setReasonsDisplay] = useState('');
  const [callDisplay, setCallDisplay] = useState('');
  const [diaryDisplay, setDiaryDisplay] = useState('');

  const changeCallDisplay = (id) => {
    if (id === callDisplay) {
      setCallDisplay('');
    } else {
      setCallDisplay(id);
    }
  };

  const changeDiaryDisplay = (id) => {
    if (id === diaryDisplay) {
      setDiaryDisplay('');
    } else {
      setDiaryDisplay(id);
    }
  };

  const changeReasonDisplay = (id) => {
    if (id === reasonsDisplay) {
      setReasonsDisplay('');
    } else {
      setReasonsDisplay(id);
    }
  };

  const setColor = (parameter) => {
    if (parameter) {
      return 'background: #FFFFFF';
    } return 'background-color: #fae7e8; color: #7a191f';
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      searchStore();
    }
  };

  const handleIcon = (serviceData, Txt) => {
    if (serviceData === 2) {
      return <FaCheckCircle style={{ color: '#A6D16E' }} />;
    }
    if (serviceData === 1) {
      return <FaExclamationCircle style={{ color: '#ffdb58' }} />;
    }
    return <FaTimesCircle style={{ cursor: Txt ? 'pointer' : 'auto', color: '#FF6347' }} />;
  };

  return (
    <>
      <LoadingBlocked active={loading} />
      <Container>
        <Top>
          <Input
            onChange={(e) => {
              setValue(e.target.value);
            }}
            placeholder="Pesquise por Código ou Razão Social"
            onKeyDown={handleKeyDown}
          />
          <Button onClick={() => searchStore()}>
            Buscar
          </Button>
        </Top>
        <Grid>
          {
            stores.map((data) => (
              <div>
                <div>
                  <PrincipalCard key={data.id} cardStyle={`${setColor(data.active)}`}>
                    <Header headerStyle={data.active ? 'color: #707070' : 'color: #915643'}>
                      <LegacyCode>{data.legacyCode}</LegacyCode>
                      <StoreName>{data.storeName}</StoreName>
                    </Header>
                    <Line>
                      {cnpjMask(data.registrationNumber)}
                      <Span><BrandIcon iconStyle={data.active ? 'color: #A6D16E' : 'color: #915643'} /> {data.brandData.initials}</Span>
                      <Span><AngeIcon iconStyle={data.active ? 'color: #A6D16E' : 'color: #8f5340'} />{data.angelData.name}</Span>
                      <Span><ConsultantIcon iconStyle={data.active ? 'color: #A6D16E' : 'color: #8f5340'} />{data.consultantData.name}</Span>
                      <Span><CommercialAttendantIcon iconStyle={data.active ? 'color: #A6D16E' : 'color: #8f5340'} />{data.commercialAttendantData.name}</Span>
                    </Line>
                    <Line>
                      <span>
                        <PhoneIcon iconStyle={data.active ? 'color: #A6D16E' : 'color: #8f5340'} />{!data.phoneNumber && (
                          'Não cadastrado'
                        )}
                        {data.phoneNumber && (
                          phoneMask(data.phoneNumber)
                        )}
                      </span>
                      <Span>
                        <MailIcon iconStyle={data.active ? 'color: #A6D16E' : 'color: #8f5340'} />
                        {!data.email && (
                          'Não cadastrado'
                        )}
                        {data.email && (
                          data.email
                        )}
                      </Span>
                    </Line>
                    {/* <Line>
                      <span>
                        <UserIcon iconStyle={data.active ? 'color: #A6D16E' : 'color: #8f5340'} />
                        {data.contactData && (
                          <Shorten maxWidth="200px">{data.contactData?.name} Responsável</Shorten>
                        )}
                        {!data.contactData && (
                          'Não cadastrado'
                        )}
                      </span>
                      <Span>
                        <PhoneIcon iconStyle={data.active ? 'color: #A6D16E' : 'color: #8f5340'} />
                        Não cadastrado
                      </Span>
                    </Line> */}
                    <AddressCase>
                      <FieldSet fieldStyle={data.active ? 'background-color: #FFFFFF' : 'background-color: #fae7e8'}>
                        <ContactsIcon iconStyle={data.active ? 'color: #A6D16E' : 'color: #8f5340'} /> Contatos
                      </FieldSet>
                      <CreateContact storeId={data.id} />
                      {!data.contactData && (
                        'Não cadastrado'
                      )}
                      {data.contactData && (
                        <ContactCardGrid>
                          {data.contactData.map(
                            (contact) => (
                              <Card key={contact.id}>
                                <span><SubtitleCase>Nome: </SubtitleCase>{contact.name}</span>
                                <span><SubtitleCase>Email: </SubtitleCase>{contact.email}</span>
                                <span>
                                  <SubtitleCase>Telefone: </SubtitleCase>
                                  {phoneMask(contact.phoneNumber)}
                                </span>
                                {contact.mainContact ? <ContactOfficeCase>Contato Principal</ContactOfficeCase> : ''}
                                {contact.financialContact ? <ContactOfficeCase>Contato Financeiro</ContactOfficeCase> : ''}
                                {contact.general ? <ContactOfficeCase>Comprador Geral</ContactOfficeCase> : ''}
                                {contact.ethical ? <ContactOfficeCase>Comprador de Ético</ContactOfficeCase> : ''}
                                {contact.generic ? <ContactOfficeCase>Comprador de Genérico</ContactOfficeCase> : ''}
                                {contact.similar ? <ContactOfficeCase>Comprador de Similar</ContactOfficeCase> : ''}
                                {contact.perfumery ? <ContactOfficeCase>Comprador de Perfumaria</ContactOfficeCase> : ''}
                                <EditContact contactData={contact} />
                              </Card>
                            )
                          )}
                        </ContactCardGrid>
                      )}
                    </AddressCase>
                    <AddressCase>
                      <FieldSet fieldStyle={data.active ? 'background-color: #FFFFFF' : 'background-color: #fae7e8'}>
                        <AddressIcon iconStyle={data.active ? 'color: #A6D16E' : 'color: #8f5340'} /> Endereço
                      </FieldSet>
                      {!data.addressData && (
                        'Não cadastrado'
                      )}
                      {data.addressData && (
                        <AddressDatas>
                          <Line>
                            <span>CEP: {data.addressData.zipcode}</span>
                            <Span>Logradouro: {data.addressData.address}</Span>
                          </Line>
                          <Line>
                            <span>Bairro: {data.addressData.neighborhood}</span>
                            <Span>Número: {data.addressData.number}</Span>
                            {data.addressData.complement && (
                              <Span>Complemento: {data.addressData.complement}</Span>
                            )}
                          </Line>
                          <Line>
                            <span>Cidade: {data.addressData.city}</span>
                            <Span>UF: {data.addressData.state}</Span>
                          </Line>
                        </AddressDatas>
                      )}
                    </AddressCase>
                    <Comments
                      commentsData={data.comments}
                      active={data.active}
                      storeId={data.id}
                    />
                    <LinkedToken data={data} />
                    {((!data.active) && (data.inactive_reasons !== null) && (
                      <DeactivateCase>
                        <FieldSet fieldStyle="background-color: #fae7e8">
                          <InactiveIcon /> Motivos de Desligamento
                        </FieldSet>
                        <Arrow>
                          {(
                            (reasonsDisplay !== data.id) && (
                              <DisplayOpenIcon onClick={() => changeReasonDisplay(data.id)} />
                            )
                          )}
                          {(
                            (reasonsDisplay === data.id) && (
                            <DisplayClosedIcon onClick={() => changeReasonDisplay(data.id)} />
                            )
                          )}
                        </Arrow>
                        <ReasonCase>
                          {data.reasonsInactiveData?.non_payment && (<SmallSpan><NonPaymentIcon />{data.reasonsInactiveData?.non_payment && ('Inadimplência')}</SmallSpan>)}
                          {data.reasonsInactiveData?.frustration && (<SmallSpan><FrustrationIcon />{data.reasonsInactiveData?.frustration && ('Frustação com a Rede')}</SmallSpan>)}
                          {data.reasonsInactiveData?.reduction && (<SmallSpan><ReductionIcon />{data.reasonsInactiveData?.reduction && ('Redução de Custo')}</SmallSpan>)}
                          {data.reasonsInactiveData?.close && (<SmallSpan><CloseIcon />{data.reasonsInactiveData?.close && ('Fechamento')}</SmallSpan>)}
                          {data.reasonsInactiveData?.sell && (<SmallSpan><SellIcon />{data.reasonsInactiveData?.sell && ('Vendida')}</SmallSpan>)}
                          {data.reasonsInactiveData?.others && (<SmallSpan><OtherIcon />{data.reasonsInactiveData?.others && ('Outros')}</SmallSpan>)}
                        </ReasonCase>
                        <Description>
                          {((reasonsDisplay !== data.id) && (
                            <ShortenParagraph webkitLineClamp="3" maxHeight="75px" textAlign="left">
                              {data.reasonsInactiveData?.description}
                            </ShortenParagraph>
                          ))}
                          {((reasonsDisplay === data.id) && (
                            <div>
                              {data.reasonsInactiveData?.description}
                            </div>
                          ))}
                        </Description>
                      </DeactivateCase>
                    ))}
                    <Footer>
                      <InitialEdit data={data} exibition="card" />
                      {(
                        (verifyAclUser(acl.disable_store)) && (<ChangeStatus data={data} exibition="card" />)
                      )}
                    </Footer>
                  </PrincipalCard>
                </div>
                <div>
                  {((stores.length > 0) && (verifyAclUser(acl.documents))) && (
                    <PrincipalCard cardStyle={`${setColor(activeStore)}`}>
                      <Header headerStyle={activeStore ? 'color: #707070' : 'color: #915643'}>
                        <Title>
                          <ToolsIcon iconStyle={activeStore ? 'color: #A6D16E' : 'color: #8f5340'} />
                          Serviços
                        </Title>
                      </Header>
                      <Service>
                        <span title={data.storeServicesData?.abcfarmaTxt}>{
                          handleIcon(data.storeServicesData?.abcfarma,
                            data.storeServicesData?.abcfarmaTxt)
                          } ABC FARMA
                        </span>
                        <span title={data.storeServicesData?.financialCardTxt}>{
                          handleIcon(data.storeServicesData?.financialCard,
                            data.storeServicesData?.financialCardTxt)
                          } FINANCIALCARD
                        </span>
                        <span title={data.storeServicesData?.gefadTxt}>{
                          handleIcon(data.storeServicesData?.gefad,
                            data.storeServicesData?.gefadTxt)
                          } GEFAD
                        </span>
                        <span title={data.storeServicesData?.brasilcardTxt}>{
                          handleIcon(data.storeServicesData?.brasilcard,
                            data.storeServicesData?.brasilcardTxt)
                          } BRASILCARD
                        </span>
                        <span title={data.storeServicesData?.farmacia360Txt}>{
                          handleIcon(data.storeServicesData?.farmacia360,
                            data.storeServicesData?.farmacia360Txt)
                          } FARMÁCIA360°
                        </span>
                        <span title={data.storeServicesData?.conektaTxt}>{
                          handleIcon(data.storeServicesData?.conekta,
                            data.storeServicesData?.conektaTxt)
                          } CONEKTA
                        </span>
                        <span title={data.storeServicesData?.contabilidadeTxt}>{
                          handleIcon(data.storeServicesData?.contabilidade,
                            data.storeServicesData?.contabilidadeTxt)
                          } CONTABILIDADE
                        </span>
                        <span title={data.storeServicesData?.cieloTxt}>{
                          handleIcon(data.storeServicesData?.cielo,
                            data.storeServicesData?.cieloTxt)
                          } CIELO
                        </span>
                        <span title={data.storeServicesData?.consultTxt}>{
                          handleIcon(data.storeServicesData?.consult,
                            data.storeServicesData?.consultTxt)
                            } CONSULT
                        </span>
                        <span title={data.storeServicesData?.memedTxt}>{
                          handleIcon(data.storeServicesData?.memed,
                            data.storeServicesData?.memedTxt)
                        } MEMED
                        </span>
                        <span title={data.storeServicesData?.clinicarxTxt}>{
                          handleIcon(data.storeServicesData?.clinicarx,
                            data.storeServicesData?.clinicarxTxt)
                        } CLINICARX
                        </span>
                        <span title={data.storeServicesData?.fidelidadeTxt}>{
                          handleIcon(data.storeServicesData?.fidelidade,
                            data.storeServicesData?.fidelidadeTxt)
                        } FIDELIDADE
                        </span>
                        <span title={data.storeServicesData?.precificacaoTxt}>{
                          handleIcon(data.storeServicesData?.precificacao,
                            data.storeServicesData?.precificacaoTxt)
                        } PRECIFICAÇÃO
                        </span>
                        <span title={data.storeServicesData?.mypharmaTxt}>{
                          handleIcon(data.storeServicesData?.mypharma,
                            data.storeServicesData?.mypharmaTxt)
                        } MYPHARMA
                        </span>
                        <span title={data.storeServicesData?.financialConveniosTxt}>{
                          handleIcon(data.storeServicesData?.financialConvenios,
                            data.storeServicesData?.financialConveniosTxt)
                        } FINANCIAL CONVÊNIOS
                        </span>
                      </Service>
                    </PrincipalCard>
                  )}
                </div>
                <div>
                  {((stores.length > 0) && (verifyAclUser(acl.documents))) && (
                    <PrincipalCard cardStyle={`${setColor(activeStore)}`}>
                      <Header headerStyle={activeStore ? 'color: #707070' : 'color: #915643'}>
                        <Title>
                          <DocmentsIcon iconStyle={activeStore ? 'color: #A6D16E' : 'color: #8f5340'} />
                          Documentação
                        </Title>
                      </Header>
                      <StoreDocumentation storeDocData={stores[0].docData} />
                    </PrincipalCard>
                  )}
                </div>
              </div>
            ))
          }
          {
            stores.length === 0 && (
              <EmptyCard>
                <Header>
                  <LegacyCode>#</LegacyCode>
                  <StoreName>Perfil</StoreName>
                </Header>
              </EmptyCard>
            )
          }
          { interactions.length !== 0 && (
            <PrincipalCard cardStyle={`${setColor(activeStore)}`}>
              <HeaderGrid headerStyle={activeStore ? 'color: #707070' : 'color: #915643'}>
                <Title>
                  <HistoryIcon iconStyle={activeStore ? 'color: #A6D16E' : 'color: #8f5340'} />
                  Interações
                </Title>
                {activeStore && (
                  <span>
                    <CallsCreate
                      iconStyle
                      storeProfile
                    />
                    <DiaryCreate iconStyle storeProfile />
                  </span>
                )}
              </HeaderGrid>
              {interactions.map((interact) => (
                <>
                  {
                    interact?.callNumber && (
                      <PrincipalCard key={interact.id} cardStyle={activeStore ? 'background-color: #fff1e8; color: #707070' : 'background-color: #fcd6b6; color: #7a191f'}>
                        <Subtitle>
                          <span>{interact.callNumber}</span>
                          <span>Chamados</span>
                          {(
                            (callDisplay !== interact.id) && (
                              <DisplayOpenIcon onClick={() => changeCallDisplay(interact.id)} />
                            )
                          )}
                          {(
                            (callDisplay === interact.id) && (
                            <DisplayClosedIcon onClick={() => changeCallDisplay(interact.id)} />
                            )
                          )}
                        </Subtitle>
                        {((callDisplay !== interact.id) && (
                          <ShortenParagraph webkitLineClamp="4" maxHeight="75px" textAlign="left">
                            <Text>
                              {interact.text}
                            </Text>
                          </ShortenParagraph>
                        ))}
                        {((callDisplay === interact.id) && (
                          <div>
                            <Text>
                              {interact.text}
                            </Text>
                          </div>
                        ))}
                        <Bottom bottomStyle={activeStore ? 'color: #707070' : 'color: #915643'}>
                          <span>
                            <UserIcon iconStyle={activeStore ? 'color: #A6D16E' : 'color: #8f5340'} />{interact.userData?.name} {interact.sectorData?.name}
                          </span>
                          <span>{interact.callStatusData.name}</span>
                          <span><CalendarIcon iconStyle={activeStore ? 'color: #A6D16E' : 'color: #8f5340'} />{moment(interact.createdAt).format('DD/MM/YYYY')}</span>
                        </Bottom>
                      </PrincipalCard>
                    )
                  }
                  {
                    interact?.date && (
                      <PrincipalCard key={interact.id} cardStyle={activeStore ? 'background-color: #ebebfa; color: #707070' : 'background-color: #e3e3e3; color: #7a191f'}>
                        <Subtitle>
                          <span>{interact.id}</span>
                          <span>Diário</span>
                          {(
                            (diaryDisplay !== interact.id) && (
                              <DisplayOpenIcon onClick={() => changeDiaryDisplay(interact.id)} />
                            )
                          )}
                          {(
                            (diaryDisplay === interact.id) && (
                              <DisplayClosedIcon onClick={() => changeDiaryDisplay(interact.id)} />
                            )
                          )}
                        </Subtitle>
                        {((diaryDisplay !== interact.id) && (
                          <ShortenParagraph webkitLineClamp="4" maxHeight="75px" textAlign="left">
                            <Text>
                              {interact.text}
                            </Text>
                          </ShortenParagraph>
                        ))}
                        {((diaryDisplay === interact.id) && (
                          <div>
                            <Text>
                              {interact.text}
                            </Text>
                          </div>
                        ))}
                        <Bottom>
                          <span><ConsultantIcon iconStyle={activeStore ? 'color: #A6D16E' : 'color: #8f5340'} />{interact.userData.name}</span>
                          <span><CalendarIcon iconStyle={activeStore ? 'color: #A6D16E' : 'color: #8f5340'} />{moment(interact.date).format('DD/MM/YYYY')}</span>
                        </Bottom>
                      </PrincipalCard>
                    )
                  }
                </>
              ))}
            </PrincipalCard>
          )}
          {interactions.length === 0 && (
            <EmptyCard>
              <Header>
                <Title>
                  <HistoryIcon iconStyle={activeStore ? 'color: #A6D16E' : 'color: #8f5340'} />
                  Interações
                </Title>
              </Header>
            </EmptyCard>
          )}
        </Grid>
      </Container>
    </>
  );
};

const EmptyCard = styled.div`
  box-shadow: 0px 3px 6px #00000029;
  background: #FCFCFC 0% 0% no-repeat padding-box;
  border-radius: 8px;
  justify-content: space-between;
  padding: 10px;
  margin-bottom: 15px;
  height: 70px;
  color: #707070;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 15px;
  margin: 10px;
  @media(max-width: 800px) {
  grid-template-columns: 1fr;
  }
`;

const PrincipalCard = styled.div`
  box-shadow: 0px 3px 6px #00000029;
  background: #FCFCFC 0% 0% no-repeat padding-box;
  border-radius: 8px;
  justify-content: space-between;
  padding: 10px;
  margin-bottom: 15px;
  ${(props) => props.cardStyle};
`;

const Service = styled.div`
  margin: 20px 10px 10px 10px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  display: grid;
  gap: 10px;
  justify-content: flex-start;

  
  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 11px;
  ${(props) => props.headerStyle};
  letter-spacing: 0px;
`;

const HeaderGrid = styled(Header)`
  display: grid;
  grid-template-columns: 5fr 1fr;

  @media(min-width: 1380px){
    grid-template-columns: 9fr 1fr;
  }

`;

const LegacyCode = styled.div`
  font-size: 25px;
  font-weight: bold;
  margin-left: 12px;
  margin-right: 12px;
  line-height: 26px;
  font-family: Montserrat;
  letter-spacing: 0px;
`;

const StoreName = styled.div`
  font-size: 25px;
  font-family: Montserrat;
  text-transform: uppercase;
  font-weight: 500;
`;

const Line = styled.div`
  margin-left: 12px;
  text-transform: capitalize;
  font-size: 16px;
  margin-bottom: 22px;
  font-family: Montserrat;
  font-weight: 400;
`;

const Span = styled.span`
  margin-left: 34px;
`;

const SmallSpan = styled.span`
  margin-right: 12px;
  margin-bottom: 12px;
`;

const FieldSet = styled.span`
  position: relative;
  right: 10px;
  top: -12px;
  ${(props) => props.fieldStyle};
  padding: 0 10px;
  font-family: Montserrat;
  font-weight: 400;
`;

const AddressCase = styled.div`
  margin: 35px 8px 8px 8px;
  padding: 0 20px 20px 20px;
  border: 1px solid #9A9A9A;
  border-radius: 4px;   
`;

const DeactivateCase = styled.div`
   margin: 30px 8px 8px 8px;
   border: 1px solid #9A9A9A;
   border-radius: 4px;   
   padding: 0 20px 20px 20px;

`;

const Arrow = styled.div`
  margin-top: -5px;
  position: relative;
  text-align: right;  
`;

const AddressDatas = styled.div`
  margin: 20px 20px 20px 8px;
`;

const ReasonCase = styled.div`
  margin: 0 0 18px 18px;
  font-family: Montserrat;
`;

const Description = styled.div`
  margin-left: 18px;
  font-size: 12px;
  font-family: Montserrat;
  text-transform: uppercase;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-top: 6px;
  align-items: center;

  @media(max-width: 800px) {
    display: none;
  }
`;

const Subtitle = styled.div`
  margin: 5px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
`;

const Text = styled.div`
  margin: 10px 5px;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.3px;
  word-spacing: 1px;
  line-height: 16px;
`;

const Bottom = styled.div`
  ${(props) => props.bottomStyle};
  text-transform: capitalize;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  margin: 5px;
  align-items: center;

  @media(max-width: 800px) {
    display: none;
  }
`;

const Top = styled.div`
  display: flex;
  justify-content: flex-end;

`;

const Input = styled.input`
  width: 400px;
  margin-right: 10px;
  margin-top: 1px;
`;

const Container = styled.div`
  margin-top: 30px;

  @media(max-width: 400px) {
    margin-top: 0;
  }
`;

const Title = styled.div`
  font-size: 20px;
  font: Montserrat;
  ${(props) => props.extraProp};
`;

const BrandIcon = styled(RiFlag2Fill)`
  margin-right: 2px;
  ${(props) => props.iconStyle};
`;

const AngeIcon = styled(GiLibertyWing)`
  margin-right: 5px;
  ${(props) => props.iconStyle};
`;

const ConsultantIcon = styled(ImUserTie)`
  margin-right: 8px;
  ${(props) => props.iconStyle};
`;

const CommercialAttendantIcon = styled(MdPermPhoneMsg)`
  margin-right: 8px;
  ${(props) => props.iconStyle};
`;

const UserIcon = styled(ImUser)`
  margin-right: 8px;
  ${(props) => props.iconStyle};
`;

const PhoneIcon = styled(ImPhone)`
  margin-right: 8px;
  ${(props) => props.iconStyle};
`;

const MailIcon = styled(GrMail)`
  margin-right: 8px;
  ${(props) => props.iconStyle};
`;

const ContactsIcon = styled(HiUsers)`
  ${(props) => props.iconStyle};
`;

const AddressIcon = styled(RiMapPin2Fill)`
  ${(props) => props.iconStyle};
`;

const InactiveIcon = styled(RiFileForbidFill)`
  color: #8f5340;
`;

const DisplayOpenIcon = styled(TiArrowSortedDown)`
  cursor: pointer;
`;

const DisplayClosedIcon = styled(TiArrowSortedUp)`
  cursor: pointer;
`;

const CalendarIcon = styled(FaRegCalendarAlt)`
  margin: 0px 8px 0 0;
  ${(props) => props.iconStyle};
`;

const HistoryIcon = styled(FaBookOpen)`
  margin: 0px 8px 0 8px;
  height: 20px;
  ${(props) => props.iconStyle};
`;

const ToolsIcon = styled(FaTools)`
  margin: 0px 8px 0 8px;
  height: 20px;
  ${(props) => props.iconStyle};
`;

const NonPaymentIcon = styled(MdMoneyOff)`
  margin: 0px 8px 0 0;
  cursor: pointer;
  color: #8f5340;
`;

const FrustrationIcon = styled(RiEmotionUnhappyFill)`
  margin: 0px 8px 0 8px;
  height: 20px;
  color: #8f5340;
`;

const ReductionIcon = styled(ImSortAmountDesc)`
  margin: 0px 8px 0 8px;
  height: 20px;
  color: #8f5340;
`;

const CloseIcon = styled(FaWindowClose)`
  margin: 0px 8px 0 8px;
  height: 20px;
  color: #8f5340;
`;

const SellIcon = styled(FaMoneyBillAlt)`
  margin: 0px 8px 0 8px;
  height: 20px;
  color: #8f5340;
`;

const OtherIcon = styled(RiQuestionFill)`
  margin: 0px 8px 0 8px;
  height: 20px;
  color: #8f5340;
`;

const DocmentsIcon = styled(IoDocumentsSharp)`
  margin: 0px 8px 0 8px;
  height: 20px;
  ${(props) => props.iconStyle};
`;

const SubtitleCase = styled.span`
  font-weight: bold;
`;

const ContactCardGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  @media(max-width: 400px) {
  grid-template-columns: 1fr;
  }
`;

const ContactOfficeCase = styled.div`
    border: 2px solid;
    border-radius: 25px;
    padding: 3px;
    margin: 3px;
    width: 190px;
    text-align: center;
    color: white;
    background-color: ${colors.primaryGreen};
`;

export default StoresProfile;
