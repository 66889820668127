import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';

import { updateStorePassword } from 'service/LoginService';

import InputField from 'components/common/InputField';
import ButtonCase from 'components/common/ButtonCase';
import Button from 'components/common/Button';
import Case from 'components/common/Case';

const FirstAccess = ({ isFirstAcess, userId }) => {
  const values = { password: '', confirmPassword: '' };

  const validations = {
    password: Yup.string()
      .min(8, 'Muito Pequena!')
      .max(50, 'Muito Longa!')
      .required('Campo Obrigatório!'),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref('password'), null],
      'Senhas incompatíveis'
    ),
  };

  const handleSubmit = async (data, { setSubmitting, resetForm }) => {
    await updateStorePassword(userId, data);
    setSubmitting(false);
    resetForm();
    await Swal.fire({
      icon: 'success',
      title: 'Senha alterada com sucesso',
      text: ' Realize login novamente para continuar!',
    });
    localStorage.removeItem('profile');
    window.location.reload();
  };

  const formik = useFormik({
    initialValues: values,
    onSubmit: handleSubmit,
    validationSchema: Yup.object(validations)
  });
  return (
    <>
      {isFirstAcess && (
      <>
        <OutsideModal />
        <ModalWindow>
          <Header>
            Cadastrar Senha
          </Header>
          <Body>
            Bem-Vindo(a) ao novo sistema de pedidos da Rede Melhor Compra,
            cadastre uma nova senha abaixo para realizar seus pedidos:
            <FormData onSubmit={formik.handleSubmit}>
              <Case gridTemplateColumns="1fr 1fr">
                <InputField
                  id="password"
                  name="password"
                  label="Senha"
                  placeholder="******"
                  isRequired
                  type="password"
                  error={formik.errors.password}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  isError={formik.errors.password && formik.touched.password}
                />
                <InputField
                  id="confirmPassword"
                  name="confirmPassword"
                  label="Confirmar Senha"
                  placeholder="******"
                  isRequired
                  type="password"
                  error={formik.errors.confirmPassword}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.confirmPassword}
                  isError={formik.errors.confirmPassword && formik.touched.confirmPassword}
                />
              </Case>
              <ButtonCase>
                <Button type="submit">Salvar</Button>
              </ButtonCase>
            </FormData>
          </Body>
        </ModalWindow>
      </>
      )}
    </>
  );
};

const Body = styled.div`
  margin: 10px;
  height: 160px;
`;

const OutsideModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 898;
  display: block;
`;

const ModalWindow = styled.div`
  background-color: #fff;
  position: fixed;
  background: #fff;
  height: auto;
  max-height: 90vh;
  top: 50%;
  left: 50%;
  text-align: left;
  transform: translate(-50%, -50%);
  border-radius: 4px;
  z-index: 899;
  text-align: left;

  @media(max-width: 800px) {
    width: 90%;
    overflow: auto;
  }
`;

const Header = styled.div`
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  width: 100%;
  height: 60px;
  background: #224772; 
  padding: 15px;

  display: flex;
  align-items: center;

  font-size: 20px;
  font-weight: 600;
  color: #ffffff;
`;

const FormData = styled.form``;

FirstAccess.propTypes = {
  isFirstAcess: PropTypes.bool.isRequired,
  userId: PropTypes.number.isRequired,
};

export default FirstAccess;
