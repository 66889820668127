import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import {
  Formik,
  Form,
  Field,
  ErrorMessage
} from 'formik';
import * as yup from 'yup';
import Swal from 'sweetalert2';

import colors from 'assets/style/colors';
import { loginService } from 'service/LoginService';
import verifyAclUser from 'helpers/verifyAclUser';
import acl from 'helpers/acl';

import LoadingBlocked from 'components/common/LoadingBlocked';

const initialValues = {
  username: '',
  password: '',
};

const validationSchema = yup.object().shape({
  username: yup
    .string()
    .required('Digite seu Login ou CNPJ'),
  password: yup
    .string()
    .required('Digite sua senha')
});
const LoginForm = () => {
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSubmit = async (values) => {
    setLoading(true);
    const res = await loginService(values);

    if (res.errors) {
      setErrorMessage(res.errors);
      setLoading(false);
    } else if (res.data) {
      setLoading(false);
      const data = JSON.stringify(res.data);
      localStorage.setItem('profile', data);
      if (verifyAclUser(acl.home_dashboard)) {
        history.push('/dashboard');
      } else if (verifyAclUser(acl.external_consultant)) {
        history.push('/');
      } else if (verifyAclUser(acl.router_assiciate)) {
        history.push('/loja');
      }
    } else {
      setLoading(false);
      Swal.fire({
        title: 'Ops! ',
        text: 'Algo deu errado e o servidor não conseguiu fazer oque você solicitou, vamos tentar novamente?',
        confirmButtonColor: '#93D367',
        confirmButtonText: 'Sim, atualizar e tentar novamente'
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
    }
  };

  const goToRegister = () => {
    window.open('https://www.redemelhorcompra.com/', '_blank');
  };

  return (
    <>
      <LoadingBlocked active={loading} />
      {errorMessage && <MessageError>{errorMessage}</MessageError>}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => onSubmit(values)}
      >
        <FormStyle>
          <Input placeholder="Use seu login ou CNPJ" name="username" type="text" />
          <Error><ErrorMessage name="username" /></Error>

          <Input placeholder="Digite sua senha" name="password" type="password" />
          <Error><ErrorMessage name="password" /></Error>

          <GridBox>
            <Button type="submit">Entrar</Button>
            <OutlineButton type="button" onClick={() => goToRegister()}>Cadastrar</OutlineButton>

          </GridBox>

          <RecoveryPassword />
        </FormStyle>
      </Formik>
    </>
  );
};

const MessageError = styled.div`
  background-color: #fdeff0;
  color: #e52320;
  border: solid 1px #fad5d5;
  padding: 10px;
  border-radius: 4px;
  margin: 30px 0 0 0;
`;

const FormStyle = styled(Form)`
  margin-top: 20px;
`;

const Input = styled(Field)`
  height: 40px;
  margin-top: 20px;
  font-size: medium;
  border: 0;
  border-radius: 21px;
  padding-left: 23px;
  border: solid 1px #f7f7f7;
  background: #f7f7f7;

  transition: 0.4s;
  
  :focus{
    border: solid 1px ${colors.primaryGreen};
    background: #fff;
  }
`;

const Error = styled.span`
  color: ${colors.errorColor};
  padding-left: 20px;
  margin-bottom: 20px;
  font-size: 12px;
`;

const GridBox = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr 1fr;
  grid-gap: 15px;
  margin-top: 20px;
`;

const Button = styled.button`
  background: ${colors.primaryGreen};
  font-weight: 700;
  border: 0;
  margin: 0;
  color: #fff;
  height: 40px;
  border-radius: 20px;

  -webkit-box-shadow: 5px 3px 20px rgba(50, 50, 50, 0.2);
  -moz-box-shadow:    5px 3px 20px rgba(50, 50, 50, 0.2);
  box-shadow:         5px 3px 20px rgba(50, 50, 50, 0.2);

  transition: 0.3s;
  opacity: 1;

  :hover{
    opacity: 0.8;
  };
`;

const OutlineButton = styled.button`
  background: #fff;
  font-weight: 700;
  border: solid 2px ${colors.primaryGreen};;
  margin: 0;
  color: ${colors.primaryGreen};;
  height: 40px;
  border-radius: 20px;

  transition: 0.2s;
  opacity: 1;

  :hover{
    background: ${colors.primaryGreen};
    color: ${colors.white};
    -webkit-box-shadow: 5px 3px 20px rgba(50, 50, 50, 0.2);
    -moz-box-shadow:    5px 3px 20px rgba(50, 50, 50, 0.2);
    box-shadow:         5px 3px 20px rgba(50, 50, 50, 0.2);
  }
`;

const RecoveryPassword = styled.div``;

export default LoginForm;
