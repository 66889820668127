import React, { useState } from 'react';
import styled from 'styled-components';

import {
  BsFillGridFill
} from 'react-icons/bs';

import {
  FaThList
} from 'react-icons/fa';

import { useStores } from 'providers/Stores/stores';
import { ProfileProviders } from 'providers/Stores/profile';

import SwitchCase from 'components/common/SwitchCase';
import SwitchButton from 'components/common/SwitchButton';
import CreateStore from './CreateStore';
import IconCase from '../../components/skilled/TopPage/IconCase';
import StoresTable from './StoresTable';
import StoresProfile from './StoresProfile';

const StoresGrid = () => {
  const [tabletyle, setTaableStyle] = useState(false);
  const { activeValue, setActiveValue } = useStores();

  const previewStyle = (value) => {
    setTaableStyle(value);
  };

  const setColor = (parameter, style) => {
    if (parameter === 'table') {
      if (style) {
        return '';
      } return '#224772';
    }
    if (!style) {
      return '';
    } return '#224772';
  };

  return (
    <>
      <DesktopMode>
        <Grid>
          <CreateStore />
          <SwitchCase>
            <SwitchButton widthTag="50px" tag="Inativas" value={activeValue} setActiveValue={setActiveValue} />
          </SwitchCase>
          <IconCase>
            <TableIcon title="Tabela" color={setColor('table', tabletyle)} onClick={() => previewStyle(false)} />
          </IconCase>
          <IconCase>
            <BsFillGridFill title="Card" color={setColor('card', tabletyle)} onClick={() => previewStyle(true)} />
          </IconCase>
        </Grid>
        { tabletyle && (
          <ProfileProviders>
            <StoresProfile />
          </ProfileProviders>
        )}
        { !tabletyle && (
          <StoresTable />
        )}
      </DesktopMode>
      <PhoneMode>
        <ProfileProviders>
          <StoresProfile />
        </ProfileProviders>
      </PhoneMode>
    </>
  );
};

const Grid = styled.div`
  display: flex;
  justify-content: flex-end;
 @media(max-width: 800px) {
   display: none;
 }
`;

const DesktopMode = styled.div`
  @media(max-width: 800px) {
    display: none;
  }
`;

const PhoneMode = styled.div`
  @media(min-width: 800px) {
    display: none;
  }
`;

const TableIcon = styled(FaThList)`
  color: ${(props) => props.color};
`;

export default StoresGrid;
