import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Button from 'components/common/Button';

function CancelButton({ children, onClose }) {
  return (
    <Cancel
      type="button"
      onClick={() => {
        onClose();
      }}
    >
      {children}
    </Cancel>
  );
}

const Cancel = styled(Button)`
  background: #CCCCCC;
  margin-right: 8px;
`;

CancelButton.propTypes = {
  children: PropTypes.element.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CancelButton;
