import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const SwitchButton = ({
  value,
  setActiveValue,
  tag,
  widthTag
}) => (
  <Case widthTag={widthTag}>
    <Tag>{tag}</Tag>
    <div className="button r" id="button-1">
      <input type="checkbox" className="checkbox" value={value} onClick={() => setActiveValue(!value)} />
      <div className="knobs" />
      <div className="layer" />
    </div>
  </Case>
);

const Case = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 10px;
  background-color: white;
  border-radius: 10px;
  padding-left: 15px;
  height: 30px;
  margin: 0px 3px;
`;

const Tag = styled.div`
  margin-right: 3px;
  font-weight: 500;
  font-size: 12px;

`;

SwitchButton.propTypes = {
  value: PropTypes.bool.isRequired,
  setActiveValue: PropTypes.func.isRequired,
  tag: PropTypes.string,
  widthTag: PropTypes.string,
};

SwitchButton.defaultProps = {
  tag: '',
  widthTag: '100px'
};

export default SwitchButton;
