import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import verifyAclUser from 'helpers/verifyAclUser';
import acl from 'helpers/acl';

import SelectField from 'components/common/SelectField';
import InputField from 'components/common/InputField';
import SearchStore from 'components/skilled/SearchStore';
import TextAreaField from 'components/common/TextAreaField';
import Button from 'components/common/Button';
import CancelButton from 'components/common/CancelButton';
import ButtonCase from 'components/common/ButtonCase';

const DiaryForm = ({
  values, handleSubmit, handleCancel, validations
}) => {
  const formik = useFormik({
    initialValues: values,
    onSubmit: handleSubmit,
    validationSchema: Yup.object(validations),
  });

  return (
    <Form data-testid="client-form" onSubmit={formik.handleSubmit}>
      <SearchStore
        label="Drogaria"
        name="storeID"
        storeName={values.storeName}
        storeId={values.storeId}
        isRequired
        type="text"
        error={formik.errors.storeName}
        onBlur={formik.handleBlur}
        onChange={(e) => {
          formik.handleChange(e);
        }}
      />
      <InputField
        id="date"
        name="date"
        label="Data do Diário"
        placeholder="Informe o dia, mês e ano"
        isRequired
        type="date"
        error={formik.errors.date}
        onBlur={formik.handleBlur}
        onChange={(e) => {
          formik.handleChange(e);
        }}
        value={formik.values.date}
        isError={formik.errors.date && formik.touched.date}
      />
      {verifyAclUser(acl.internal_consultant) && (
        <SelectField
          id="diaryType"
          name="diaryType"
          label="Tipo de Diário"
          isRequired
          value={formik.values.diaryType}
          onBlur={formik.handleBlur}
          onChange={(e) => {
            formik.handleChange(e);
          }}
          options={[{ id: 1, name: 'Contato' }, { id: 3, name: 'Visita' }]}
          error={formik.errors.diaryType}
          isError={formik.errors.diaryType && formik.touched.diaryType}
        />
      )}
      <TextAreaField
        id="text"
        name="text"
        label="Descrição"
        placeholder="Escreva o motivo do Diário"
        isRequired
        error={formik.errors.text}
        onBlur={formik.handleBlur}
        onChange={(e) => {
          formik.handleChange(e);
        }}
        value={formik.values.text}
        isError={formik.errors.text && formik.touched.text}
        height="200px"
      />
      <ButtonCase>
        <CancelButton onClose={handleCancel}>Cancelar</CancelButton>
        <Button type="submit">Salvar</Button>
      </ButtonCase>
    </Form>
  );
};

const Form = styled.form`  
  padding: 5px;
  width: 30vw;

  @media(max-width: 800px) {
    width: 100%;
    overflow: auto;
  }
`;

DiaryForm.propTypes = {
  validations: PropTypes.objectOf(PropTypes.any).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  values: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default DiaryForm;
