import React, {
  useState,
  useEffect,
  createContext,
  useContext
} from 'react';
import PropTypes from 'prop-types';

import { getAllStores } from 'service/ReportService';

import verifyAclUser from 'helpers/verifyAclUser';
import acl from 'helpers/acl';

export const StoresReportContext = createContext({});

export const StoresReportProvider = ({ children }) => {
  const [storesReportData, setStoresReportData] = useState([]);
  const [loading, setLoading] = useState(true);

  const list = async () => {
    setLoading(true);
    let data = [];
    try {
      if (verifyAclUser(acl.report_stores) || verifyAclUser(acl.report_stores)) {
        data = await getAllStores();
      }
      setStoresReportData(data);
    } catch {
      setStoresReportData(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      list();
    };
    fetchData();
  }, []);

  return (
    <StoresReportContext.Provider value={{
      storesReportData,
      setStoresReportData,
      loading,
      setLoading,
    }}
    >
      {children}
    </StoresReportContext.Provider>
  );
};

StoresReportProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export const useStoresReport = () => useContext(StoresReportContext);
