import React, { useState } from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';

import { createUsers } from 'service/UsersService';

import Modal from 'components/common/Modal';
import Button from 'components/common/Button';

import UsersForm from './UsersForm';

const UsersCreate = ({ usersType }) => {
  const [visible, setVisible] = useState(false);

  const initialValues = {
    name: '',
    surname: '',
    phoneNumber: '',
    email: '',
    usertypeId: '',
    active: true,
    username: '',
    password: '',
    sectorLeader: false
  };

  const changeModalState = (parameter) => {
    setVisible(parameter);
  };

  const onSubmit = async (values) => {
    await createUsers(values);
    window.location.reload();
  };

  const onCancel = () => {
    window.location.reload();
  };

  const validationSchema = {
    name: Yup.string().required('O nome é obrigatiório para cadastro de usuário'),
    surname: Yup.string().required('O sobrenome é obrigatiório para cadastro de usuário'),
    phoneNumber: Yup.string().required('O número de telefone é obrigatiório para cadastro de usuário'),
    email: Yup.string().email('Formato de email incorreto').required('O email é obrigatiório para cadastro de usuário'),
    usertypeId: Yup.string().required('O é obrigatiório para cadastro de usuário'),
    active: Yup.boolean().required('O status obrigatiório para cadastro de usuário'),
    username: Yup.string().required('O nome de usuário é obrigatiório para cadastro de usuário'),
    password: Yup.string().required('A senha é obrigatiório para cadastro de usuário'),
  };

  return (
    <>
      <Button type="button" onClick={() => changeModalState(true)}>Novo</Button>
      <Modal visible={visible} onClose={() => changeModalState(false)} title="Criar Usuário">
        <UsersForm
          values={initialValues}
          handleSubmit={onSubmit}
          handleCancel={onCancel}
          validations={validationSchema}
          usersType={usersType}
        />
      </Modal>
    </>
  );
};

UsersCreate.propTypes = {
  usersType: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default UsersCreate;
