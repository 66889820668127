import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import { useCalls } from 'providers/calls';

import Modal from 'components/common/Modal';
import { toastError } from 'helpers/notifications';
import getProfile from 'helpers/getProfile';

import CallsForm from 'containers/Calls/CallsForm';

const CallsCreate = ({ visibleCalls }) => {
  const [visible, setVisible] = useState(false);
  const { create } = useCalls();
  const title = 'Registro de Chamados';

  useEffect(() => {
    setVisible(visibleCalls);
  }, [visibleCalls]);

  const initialValues = {
    text: '',
    sector: '',
    storeName: localStorage.getItem('searchStoreName')
  };

  const changeModalState = (parameter) => {
    setVisible(parameter);
    if (parameter === false) {
      localStorage.removeItem('searchStoreID');
      localStorage.removeItem('searchStoreName');
      localStorage.removeItem('storeAngel');
      localStorage.removeItem('storeConsultant');
    }
  };

  const onSubmit = async (values) => {
    const profile = getProfile();
    const data = values;
    data.storeId = localStorage.getItem('searchStoreID');
    if (!data.storeId) {
      toastError('Selecione uma Drogaria válida');
    } else {
      data.openBy = profile.user.id;
      await create(data);
      setVisible(false);
      localStorage.removeItem('searchStoreID');
      localStorage.removeItem('searchStoreName');
      localStorage.removeItem('storeAngel');
      localStorage.removeItem('storeConsultant');
    }
  };

  const onCancel = () => {
    changeModalState(false);
    localStorage.removeItem('searchStoreID');
    localStorage.removeItem('searchStoreName');
    localStorage.removeItem('storeAngel');
    localStorage.removeItem('storeConsultant');
  };

  const validationSchema = {
    text: Yup.string().required('Informe o motivo do Chamado'),
    sector: Yup.string().required('Informe o setor responsável'),
  };

  return (
    <>
      <Modal visible={visible} onClose={() => changeModalState(false)} title={title}>
        <CallsForm
          values={initialValues}
          handleSubmit={onSubmit}
          handleCancel={onCancel}
          validations={validationSchema}
        />
      </Modal>
    </>
  );
};

CallsCreate.propTypes = {
  visibleCalls: PropTypes.bool.isRequired,
};

export default CallsCreate;
