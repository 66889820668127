import axios from 'axios';
import { API } from 'helpers/constant';

const createStoreContract = (storeData) => axios.post(`${API}/stores/contract`, storeData)
  .then((res) => res.data)
  .catch((error) => {
    if (!error.response) {
      return false;
    }
    return error.response.data;
  });

const getStoresContracts = () => axios.get(`${API}/stores/contracts`)
  .then((res) => res.data)
  .catch((error) => error[0]);

const destroyStoreContract = (id) => axios.delete(`${API}/stores/contract/${id}`)
  .then((res) => res.data)
  .catch((error) => {
    if (!error.response) {
      return false;
    }
    return error.response.data;
  });

const getStoreContract = (id) => axios.get(`${API}/stores/contract/${id}`)
  .then((res) => res.data)
  .catch((error) => error[0]);

const getPDFsContracts = (contractId, data) => axios.post(`${API}/stores/contractId/${contractId}`, data)
  .then((res) => res.data)
  .catch((error) => error[0]);

const streamPDF = (storeId) => axios.get(`${API}/streamPDF/${storeId}`)
  .then((res) => res.data)
  .catch((error) => error[0]);

const getStoreContractByContractNumber = (contractNumber) => axios.get(`${API}/stores/storecontract/${contractNumber}`)
  .then((res) => res.data)
  .catch((error) => error[0]);

export {
  getPDFsContracts,
  streamPDF,
  createStoreContract,
  getStoresContracts,
  destroyStoreContract,
  getStoreContract,
  getStoreContractByContractNumber
};
