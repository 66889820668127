import React, { useRef } from 'react';
import styled from 'styled-components';
import Swal from 'sweetalert2';

import Table from 'components/tableComponents/CompleteTable';

import { useConsultantVisitReports } from 'providers/Reports/consultantVisit';
import { CSVLink } from 'react-csv';
import { RiFileDownloadLine } from 'react-icons/ri';

import Loading from 'components/common/Loading';
import Case from 'components/common/Case';
import Button from 'components/common/Button';
import TitleBar from 'components/layout/TitleBar';
import Refresh from 'components/common/Refresh';
import NoData from 'components/common/NoData';

import { getConsultantVisitReportsForPeriod } from 'service/ReportService';

const ConsultantVisit = () => {
  const {
    visitsReportsData,
    setVisitsReportsData,
    list,
    loading,
    setLoading
  } = useConsultantVisitReports();
  const startDate = useRef('');
  const endDate = useRef('');

  const columns = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        defaultFilter: true,
      },
      {
        Header: 'Cod',
        accessor: 'Cod',
        defaultFilter: true,
      },
      {
        Header: 'RAZÃO SOCIAL',
        accessor: 'RazaoSocial',
        defaultFilter: true,
      },
      {
        Header: 'CIDADE',
        accessor: 'Cidade',
        defaultFilter: true,
      },
      {
        Header: 'Descrição da Visita',
        accessor: 'Descricao',
        defaultFilter: true,
      },
      {
        Header: 'PROMOTOR',
        accessor: 'Promotor',
        defaultFilter: true,
      },
      {
        Header: 'Data',
        accessor: 'Data',
        defaultFilter: true,
      },
    ],
    []
  );

  const handleDate = async () => {
    if ((startDate.current.value) && (endDate.current.value)) {
      setLoading(true);
      const data = await getConsultantVisitReportsForPeriod(
        startDate.current.value, endDate.current.value
      );
      setVisitsReportsData(data);
      setLoading(false);
    } else if ((startDate.current.value === '') && (endDate.current.value === '')) {
      list();
    } else {
      Swal.fire(
        'Aviso',
        'Informe o período para a pesquisa',
      );
    }
  };

  function setComponent() {
    if (!visitsReportsData) {
      return (<Refresh />);
    } return (
      <>
        <TitleBar>
          Visitas por Consultor
        </TitleBar>
        <SearchBox>
          <Case gridTemplateColumns="1fr 1fr 1fr 1fr" justifyContent="flex-end" display="flex">
            <input ref={startDate} type="date" id="startDate" />
            <input ref={endDate} type="date" id="endDate" />
            <SearchButton type="button" onClick={handleDate}>Pesquisar</SearchButton>
            {((loading === false) && (visitsReportsData.length > 0) && (
              <CSVLink data={visitsReportsData} separator=";">
                <IconCase>
                  <DownloadIcon title="Baixar Planilha" />
                </IconCase>
              </CSVLink>
            ))}
          </Case>
        </SearchBox>
        <Loading active={loading} />
        {((loading === false) && (visitsReportsData.length > 0) && (
          <Table data={visitsReportsData} changeTableSize={50} columns={columns} marginTop="0px" />
        ))}
        {((visitsReportsData.length === 0) && (loading === false) && (
          <NoData />
        ))}
      </>
    );
  }

  return (
    <>
      {setComponent()}
    </>
  );
};

const SearchButton = styled(Button)`
  height: 30px;
  margin-right: 0px;
`;

const DownloadIcon = styled(RiFileDownloadLine)`
  font-size: 30px;
  width: 40px;
  margin-top: 5px;
  margin-right: 0px;
`;

const IconCase = styled.div`
  font-size: 23px;
  cursor: pointer;
`;

const SearchBox = styled.div`
  position: absolute;
  right: 0;
  margin-right: 2px;
  top: 110px;
`;

export default ConsultantVisit;
