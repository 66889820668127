import React from 'react';

import Swal from 'sweetalert2';

import { FaCheckCircle } from 'react-icons/fa';
import { ImBlocked } from 'react-icons/im';

import { useCompany } from 'providers/company';

import Loading from 'components/common/Loading';
import NoData from 'components/common/NoData';
import Table from 'components/tableComponents/CompleteTable';
import {
  TableCaseIcon,
  TableIcon
} from 'components/tableComponents';

import AlertWarning from '../../../components/alert/AlertWarning';
import CompanyEdit from './CompanyEdit';

const CompanyTable = () => {
  const { companyData, change, loading } = useCompany();

  const actionConfirm = async (data) => {
    await Swal.fire(AlertWarning('Tem certeza que desaja ativar essa empresa?')).then(async (result) => {
      if (result.isConfirmed) {
        await change(data.id, { current: true });
      }
    });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: 'RAZÃO SOCIAL',
        accessor: 'storeName',
      },
      {
        Header: 'NOME FANTASIA',
        accessor: 'tradeName',
      },
      {
        Header: 'CNPJ',
        accessor: 'registrationNumber',
      },
      {
        Header: 'Endereço',
        accessor: 'addressData.address',
      },
      {
        Header: 'Bairro',
        accessor: 'addressData.neighborhood',
      },
      {
        Header: 'OPÇÕES',
        accessor: 'current',
        Cell: (i) => {
          const { cell } = i;
          const data = cell.row.original;
          return (
            <TableCaseIcon>
              <TableIcon>
                <CompanyEdit data={data} />
                {data.current && <FaCheckCircle title="Ativa" onClick={() => actionConfirm(data)} />}
                {!data.current && <ImBlocked title="Definir Padrão" onClick={() => actionConfirm(data)} />}
              </TableIcon>
            </TableCaseIcon>
          );
        }
      },
    ],
    []
  );

  return (
    <>
      <Loading active={loading} />
      {((companyData.length > 0) && (loading === false) && (
        <Table data={companyData} columns={columns} marginTop="-30px" />
      ))}
      {(companyData.length === 0) && (loading === false) && (
        <NoData />
      )}
    </>
  );
};

export default CompanyTable;
