import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const ShortenParagraph = ({
  children, maxWidth, maxHeight, textAlign, webkitLineClamp
}) => (
  <ShortenComponent
    maxWidth={maxWidth}
    textAlign={textAlign}
    maxHeight={maxHeight}
    webkitLineClamp={webkitLineClamp}
  >
    {children}
  </ShortenComponent>
);

const ShortenComponent = styled.div`
  max-height: ${(props) => props.maxHeight};
  max-width: ${(props) => props.maxWidth};
  text-align: ${(props) => props.textAlign};
  overflow: hidden; 
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: ${(props) => props.webkitLineClamp};
  -webkit-box-orient: vertical;
`;

ShortenParagraph.propTypes = {
  children: PropTypes.objectOf(PropTypes.any).isRequired,
  maxWidth: PropTypes.string.isRequired,
  maxHeight: PropTypes.string.isRequired,
  textAlign: PropTypes.string,
  webkitLineClamp: PropTypes.number
};

ShortenParagraph.defaultProps = {
  webkitLineClamp: 6,
  textAlign: 'justify',
};

export default ShortenParagraph;
