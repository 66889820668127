import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import {
  BsFillGridFill
} from 'react-icons/bs';

import {
  FaThList
} from 'react-icons/fa';

import Button from 'components/common/Button';
import IconCase from '../../components/skilled/TopPage/IconCase';

import ContractsTable from './ContractsTable';
import ContractsCard from './ContractsCard';

const ContractsGrid = () => {
  const [tabletyle, setTaableStyle] = useState(false);
  const history = useHistory();

  const previewStyle = (value) => {
    setTaableStyle(value);
  };

  const setColor = (parameter, style) => {
    if (parameter === 'table') {
      if (style) {
        return '';
      } return '#224772';
    }
    if (!style) {
      return '';
    } return '#224772';
  };

  return (
    <>
      <DesktopMode>
        <Grid>
          <Button type="button" onClick={() => history.push('/novoContrato')}>Novo</Button>
          <IconCase>
            <TableIcon title="Tabela" color={setColor('table', tabletyle)} onClick={() => previewStyle(false)} />
          </IconCase>
          <IconCase>
            <CardIcon title="Card" color={setColor('card', tabletyle)} onClick={() => previewStyle(true)} />
          </IconCase>
        </Grid>
        { tabletyle && (
          <ContractsCard />
        )}
        { !tabletyle && (
          <ContractsTable />
        )}
      </DesktopMode>
      <PhoneMode>
        <ButtonMobileDiv>
          <Button type="button" onClick={() => history.push('/novoContrato')}>Novo</Button>
        </ButtonMobileDiv>
        <ContractsCard />
      </PhoneMode>
    </>
  );
};

const Grid = styled.div`
  display: flex;
  justify-content: flex-end;
 @media(max-width: 800px) {
   display: none;
 }
`;

const DesktopMode = styled.div`
  @media(max-width: 800px) {
    display: none;
  }
`;

const PhoneMode = styled.div`
  @media(min-width: 800px) {
    display: none;
  }
`;

const ButtonMobileDiv = styled.div`
  text-align: right;
  margin-bottom: 10px;
  margin-top: 10px;
`;

const TableIcon = styled(FaThList)`
  color: ${(props) => props.color};
`;

const CardIcon = styled(BsFillGridFill)`
`;

export default ContractsGrid;
