import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import LogoImage from 'assets/images/logo.svg';
import colors from 'assets/style/colors';
import Menu from './Menu';

function LeftMenu() {
  const history = useHistory();
  return (
    <Content>
      <LogoBox>
        <Image onClick={() => history.push('./home')} src={LogoImage} alt="Empresa" />
      </LogoBox>
      <Menu />
    </Content>
  );
}

const Content = styled.div`
  color: ${colors.white};
  background: ${colors.primaryBlue};
  width: 200px;
  height: 100vh;
  position: fixed;
  top: 0;

  @media(max-width: 800px) {
    display: none;
  }

  @media(max-width: 400px) {
    display: none;
  }
`;

const LogoBox = styled.div`
`;

const Image = styled.img`
  display: block;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 70%;
  cursor: pointer;
`;
export default LeftMenu;
