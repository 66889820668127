import React, { useState } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import { useFormik } from 'formik';

import { useCalls } from 'providers/calls';
import getProfile from 'helpers/getProfile';

import { toastError } from 'helpers/notifications';
import LoadingBlocked from 'components/common/LoadingBlocked';
import Modal from 'components/common/Modal';
import Button from 'components/common/Button';
import TextAreaField from 'components/common/TextAreaField';
import SelectField from 'components/common/SelectField';
import CancelButton from 'components/common/CancelButton';
import ButtonCase from 'components/common/ButtonCase';

const CallInteractions = ({ data }) => {
  const { updateInteractionsCall, callStatus, blockLoading } = useCalls();
  const profile = getProfile();
  const [visible, setVisible] = useState(false);

  const title = 'Criar Interação';

  const values = {
    status: data.status,
    text: '',
  };

  const validationSchema = {
    status: Yup.string().required('Status obrigatório'),
    text: Yup.string()
      .when('status', {
        is: (val) => (val !== '4'),
        then: Yup.string().required('Informe a interação')
      }),
  };

  const Submit = async (formData) => {
    if (data.sector !== profile.user.userTypeId) {
      toastError('Você não tem permissão para interagir com o chamado de outro setor!');
    }
    if ((data.status !== 1) && (formData.status === '1')) {
      toastError('Você não pode voltar o Status para Aberto!');
    }
    if (formData.status === '2') {
      Swal.fire({
        title: 'Você quer realmente concluir o chamado?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#224772',
        cancelButtonColor: '#224772',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não'
      }).then((result) => {
        if (result.isConfirmed) {
          const newData = { userId: profile.user.id, ...formData };
          updateInteractionsCall(data.id, newData);
          setVisible(false);
        }
      });
    } else {
      const newData = { userId: profile.user.id, ...formData };
      updateInteractionsCall(data.id, newData);
      setVisible(false);
    }
  };

  const formik = useFormik({
    initialValues: values,
    onSubmit: Submit,
    validationSchema: Yup.object(validationSchema),
  });

  const onCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <LoadingBlocked active={blockLoading} />
      <ButtonCase>
        <Button onClick={() => setVisible(true)}>Interagir</Button>
      </ButtonCase>
      <Modal visible={visible} onClose={() => setVisible(false)} title={title}>
        <BodyModal>
          <Form data-testid="client-form" onSubmit={formik.handleSubmit}>
            <SelectField
              id="status"
              name="status"
              label="Status"
              value={formik.values.status}
              onBlur={formik.handleBlur}
              isRequired
              onChange={(e) => {
                formik.handleChange(e);
              }}
              options={callStatus}
              error={formik.errors.status}
              isError={formik.errors.status && formik.touched.status}
            />
            <TextAreaField
              id="text"
              name="text"
              label="Interação"
              placeholder="Escreva a Interação"
              isRequired
              error={formik.errors.text}
              onBlur={formik.handleBlur}
              onChange={(e) => {
                formik.handleChange(e);
              }}
              value={formik.values.text}
              isError={formik.errors.text && formik.touched.text}
              height="200px"
            />
            <ButtonCase>
              <CancelButton onClose={onCancel}>Cancelar</CancelButton>
              <Button type="submit">Salvar</Button>
            </ButtonCase>
          </Form>
        </BodyModal>
      </Modal>
    </>
  );
};

const BodyModal = styled.div`
  width: 30vw;

  @media(max-width: 800px) {
    width: 100%;
    overflow: auto;
  }
`;

const Form = styled.form`  
  padding: 5px;
  width: 30vw;

  @media(max-width: 800px) {
    width: 100%;
    overflow: auto;
  }
`;

CallInteractions.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default CallInteractions;
