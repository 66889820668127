import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  getDashboardDirector,
} from 'service/ReportService';

export const DirectorDashboardContext = React.createContext({});

export const DirectorDashboardProvider = ({ children }) => {
  const [dashboardDirectorData, setDashboardDirectorData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [period, setPeriod] = useState('');

  const list = async () => {
    setLoading(true);
    try {
      const data = await getDashboardDirector();
      setDashboardDirectorData(data);
    } catch {
      setDashboardDirectorData(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      list();
    };
    fetchData();
  }, []);

  return (
    <DirectorDashboardContext.Provider value={{
      dashboardDirectorData,
      setDashboardDirectorData,
      setLoading,
      loading,
      list,
      period,
      setPeriod,
    }}
    >
      {children}
    </DirectorDashboardContext.Provider>
  );
};

DirectorDashboardProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export const useDirectorDashboard = () => React.useContext(DirectorDashboardContext);
