import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import colors from 'assets/style/colors';

function Footer({ menuState }) {
  return (
    <Content menuState={menuState}>
      Todos os direitos reservados.
    </Content>
  );
}

const Content = styled.footer`
  ${(props) => props.menuState}
  background: ${colors.primaryLight};
  padding-left: 10px;
  bottom: 0;
  position: fixed;
  width: 100%;

  @media(max-width: 800px) {
    display: none;
  }
`;

Footer.propTypes = {
  menuState: PropTypes.func.isRequired,
};

export default Footer;
