import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { format } from 'date-fns';

import {
  IoEyeOutline,
} from 'react-icons/io5';

import { useCalls } from 'providers/calls';
import FieldSection from 'components/common/FieldSection';
import { CaseIcon } from 'components/card';
import { TableIcon } from 'components/table';
import Modal from 'components/common/Modal';

const ViewData = ({ data, exibition }) => {
  const [visible, setVisible] = useState(false);
  const { getHistory } = useCalls();
  const [history, setHistory] = useState([]);

  const fetchData = async () => {
    const historyData = await getHistory(data.id);
    setHistory(historyData);
  };
  const title = `Chamado Número ${data.id}`;

  const closeModal = () => {
    setVisible(false);
  };
  const opemModal = () => {
    setVisible(true);
    fetchData();
  };

  return (
    <>
      {exibition === 'card' && (
        <CaseIcon marginleft="5px">
          <IoEyeOutline title="Visualizar" onClick={() => opemModal(true)} />
        </CaseIcon>
      )}
      {exibition === 'table' && (
        <TableIcon>
          <IoEyeOutline title="Visualizar" onClick={() => opemModal()} />
        </TableIcon>
      )}
      { visible && (
        <Modal visible={visible} onClose={() => closeModal()} title={title}>
          <BodyModal>
            <FieldSection title="Controle do Chamado">
              <Legend>Drogaria:</Legend> <span style={{ textTransform: 'uppercase' }}>{data.stores.legacyCode} - {data.stores.storeName}</span>
              <br />
              <Legend>Status:</Legend> {data.status === 1 && 'Aberto'}{data.status === 2 && 'Concluído'}
              <br />
              <Legend>Registrado por:</Legend> <span style={{ textTransform: 'capitalize' }}>{data.openByData.name}</span>
              {!data.userData && <><Legend>Responsável:</Legend> <div style={{ textTransform: 'capitalize' }}>Nenhum responsável atribuído</div></>}
              {data.userData && <><br /><Legend>Responsável:</Legend> <span style={{ textTransform: 'capitalize' }}>{data.userData.name}</span></>}
              <br />
              <Legend>Setor responsável:</Legend> <span style={{ textTransform: 'capitalize' }}>{data.sectorData.name}</span>
              {!data.endedBy && <><br /><Legend>Finalizado por:</Legend> <span style={{ textTransform: 'capitalize' }}>Chamado ainda em aberto</span></>}
              {data.endedBy && <><br /><Legend>Finalizado por:</Legend> <span style={{ textTransform: 'capitalize' }}>{data.endedByData.name}</span></>}
              <><br /><Legend>Data do Registro:</Legend> { format(new Date(data.createdAt), "dd/MM/yyyy'-'HH:mm") } </>
              <><br /><Legend>Data da Atualização:</Legend> { format(new Date(data.updatedAt), "dd/MM/yyyy'-'HH:mm") } </>
            </FieldSection>
            <FieldSection title="Descrição do Chamado">{data.text}</FieldSection>
            {data.endedTxt && <FieldSection title="Retorno">{data.endedTxt}</FieldSection>}
            {history.map((idx) => (
              <FieldSection title="Interação">
                {idx.flag === 1 && (
                  <>
                    <span>Interação</span>
                    <br />
                    <br />
                    {idx.responsible && (
                    <><Legend>Responsável:</Legend><span>{idx.responsibleData.name}</span></>
                    )}
                    <br />
                    <Legend>Descrição:</Legend>
                    <br />
                    <span>{idx.text}</span>
                    <br />
                  </>
                )}
                {idx.flag === 2 && (
                  <>
                    <span>Transferência de chamado</span>
                    <br />
                    <br />
                    {idx.previousSector && (
                    <>
                      <Legend>Setor Anterior: </Legend><span>{idx.previousSectorData.name}</span>
                      <br />
                    </>
                    )}
                    {idx.sector && (
                    <>
                      <Legend>Setor Atual: </Legend><span>{idx.sectorData.name}</span>
                      <br />
                    </>
                    )}
                    {idx.previousResponsible && (
                    <>
                      <Legend>Responsável anterior: </Legend>
                      <span>{idx.previousResponsibleData.name}</span>
                      <br />
                    </>
                    )}
                    {idx.responsible && (
                    <>
                      <Legend>Responsável atual: </Legend><span>{idx.responsibleData.name}</span>
                      <br />
                    </>
                    )}
                    {idx.transferBy && (
                    <>
                      <Legend>Transferido por: </Legend><span>{idx.transferByData.name}</span>
                      <br />
                    </>
                    )}
                    <br />
                    <span>Descrição da mudança:</span>
                    <br />
                    <span>{idx.text}</span>
                    <br />
                    <br />
                  </>
                )}
                {idx.flag === 3 && (
                  <>
                    <span>{idx.text}</span>
                    <br />
                    <br />
                    {idx.responsible && (
                    <><Legend>Responsável:</Legend><span>{idx.responsibleData.name}</span></>
                    )}
                    <br />
                    <br />
                  </>
                )}
                <Legend>Data: </Legend><span>{format(new Date(idx.createdAt), "dd/MM/yyyy'-'HH:mm")}</span>
              </FieldSection>
            ))}
          </BodyModal>
        </Modal>
      )}
    </>
  );
};

const BodyModal = styled.div`
  padding: 10px;
  width: 30vw;
  font-size: 14px;
  overflow: auto;
  max-height: 550px;

  @media(max-width: 800px) {
    width: 100%;
    overflow: auto;
  }
`;

const Legend = styled.span`
  font-weight: bold;
`;

ViewData.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
  exibition: PropTypes.string.isRequired,
};

ViewData.defaultProps = {
  data: {}
};

export default ViewData;
