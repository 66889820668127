import React from 'react';
import PropTypes from 'prop-types';

import AreaChart from 'components/common/AreaChart';
import CardChart from 'components/common/CardChart';
import Case from 'components/common/Case';
import BarChart from 'components/chart/BarChart';

function CallsDashboard({ data }) {
  return (
    <>
      <AreaChart title="CHAMADOS">
        <Case gridTemplateColumns="1fr">
          <CardChart title="Geral de chamados ativos">
            <BarChart data={data} label={['', 'Abertos', 'Executando', 'Pendentes']} />
          </CardChart>
        </Case>
      </AreaChart>
    </>
  );
}

CallsDashboard.propTypes = {
  data: PropTypes.element.isRequired,
};

export default CallsDashboard;
