import React from 'react';
// import styled from 'styled-components';

// import moment from 'moment';
// import Swal from 'sweetalert2';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import { AiOutlineShop } from 'react-icons/ai';
import { SiOpenstreetmap } from 'react-icons/si';
import { FiPhoneCall } from 'react-icons/fi';

import Loading from 'components/common/Loading';
import AreaChart from 'components/common/AreaChart';
import CardChart from 'components/common/CardChart';
import Case from 'components/common/Case';
import InfoCard from 'components/common/InfoCard';
import SmallInfoCard from 'components/common/SmallInfoCard';
import StoresDirectorDashboard from 'components/dashboard/stores/StoresDirectorDashboard';
import BarChart from 'components/chart/BarChart';
import Refresh from 'components/common/Refresh';

import { useDirectorDashboard } from 'providers/Dashboard/directors';

function DashboardDirector() {
  const {
    dashboardDirectorData,
    loading,
  } = useDirectorDashboard();

  function setComponent() {
    if (!dashboardDirectorData) {
      return (<Refresh />);
    } return (
      <>
        <Loading active={loading} />
        { !loading && (
        <>
          <Case gridTemplateColumns="1fr 1fr 1fr 1fr">
            <InfoCard title="LOJAS" backgroundColor="#00C0EF" data={dashboardDirectorData.activeAllStores}>
              <AiOutlineShop style={{ color: 'white' }} />
            </InfoCard>
            <InfoCard title="ESTADOS" backgroundColor="#3D9970" data={dashboardDirectorData.numberOfStates}>
              <HiOutlineLocationMarker style={{ color: 'white' }} />
            </InfoCard>
            <InfoCard title="CIDADES" backgroundColor="#DD4B39" data={dashboardDirectorData.numberOfCitys}>
              <SiOpenstreetmap style={{ color: 'white' }} />
            </InfoCard>
            <InfoCard title="CHAMADOS ATIVOS" backgroundColor="#ffcd17" data={dashboardDirectorData.activeCalls}>
              <FiPhoneCall style={{ color: 'white' }} />
            </InfoCard>
          </Case>
          <AreaChart title="CHAMADOS">
            <CardChart title="Chamados ativos">
              <BarChart data={dashboardDirectorData.activeCallsBySector} label={['', 'Abertos', 'Pendentes', 'Executando']} width="1000px" />
              <Case gridTemplateColumns="1fr 1fr 1fr 1fr" margin="20px 0 0 0" alignItems="center" justifyContent="center" display="flex">
                <SmallInfoCard title="ABERTOS" backgroundColor="#DD4B39">
                  <span style={{ color: 'white' }}>{dashboardDirectorData.openCalls}</span>
                </SmallInfoCard>
                <SmallInfoCard title="PENDENTES" backgroundColor="#00C0EF">
                  <span style={{ color: 'white' }}>{dashboardDirectorData.pendingCalls}</span>
                </SmallInfoCard>
                <SmallInfoCard title="EXECUTANDO" backgroundColor="#3D9970">
                  <span style={{ color: 'white' }}>{dashboardDirectorData.executingCalls}</span>
                </SmallInfoCard>
              </Case>
            </CardChart>
          </AreaChart>
          <StoresDirectorDashboard data={dashboardDirectorData} />
        </>
        )}
      </>
    );
  }

  return (
    <>
      {setComponent()}
    </>
  );
}

export default DashboardDirector;
