import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import { getBrands } from 'service/BrandService';
import { getStores } from 'service/StoresService';
import { getSystem } from 'service/SystemService';

export const OptionsContext = React.createContext({});

export const OptionsProvider = ({ children }) => {
  const [brands, setBrands] = useState([]);
  const [stores, setStores] = useState([]);
  const [systems, setSystems] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const brandsData = await getBrands();
      setBrands(brandsData);
      const storesData = await getStores();
      setStores(storesData);
      const systemsData = await getSystem();
      setSystems(systemsData);
    };
    fetchData();
  }, []);

  return (
    <OptionsContext.Provider value={{
      brands,
      stores,
      systems
    }}
    >
      {children}
    </OptionsContext.Provider>
  );
};

OptionsProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export const useOptions = () => React.useContext(OptionsContext);
