import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const AreaChart = ({ children, title, color }) => (
  <Card>
    <TitleSection>
      <Title color={color}>{title}</Title>
    </TitleSection>
    <Body>{children}</Body>
    <Footer />
  </Card>
);

const Card = styled.div`
  box-shadow: 0 2px 2px rgb(0 0 0 / 10%);
  border-top: solid 3px #5CB85C;
  margin: 10px;
  border-radius: 5px;
  padding: 5px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const TitleSection = styled.div`
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  padding-bottom: 5px;
  display: flex;
  width: 100%;
  border-bottom: 1px solid #F3F3F3;
`;

const Title = styled.span`
  color: ${(props) => props.color};;
  font-size: 16px;
  font-weight: 600;
  margin-left: 21px;
  padding: 5px;
  @media(max-width: 800px) {
  font-size: 15px;
  padding: 7px;
  }
`;

const Body = styled.div`
  background-color: white;
`;

const Footer = styled.span`
  padding: 5px;
`;

AreaChart.propTypes = {
  children: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  color: PropTypes.string,
};

AreaChart.defaultProps = {
  color: '#838594'
};

export default AreaChart;
