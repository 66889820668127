import React from 'react';
import styled from 'styled-components';

const Asterisk = () => <AsteriskCase>*</AsteriskCase>;

const AsteriskCase = styled.span`
  color: #D9534F;
  font-weight: 500;
`;

export default Asterisk;
