import styled from 'styled-components';

const Td = styled.td`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 220px;
  font-size: 12px;
  padding: 1px;
`;

export default Td;
