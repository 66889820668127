import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

function TableImage({
  src, width, height
}) {
  return (
    <Image
      src={src}
      width={width}
      height={height}
    />
  );
}

const Image = styled.img`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  opacity: 0.5;
  transition: 1s;

  :hover {
    opacity: 1;
  }
`;

TableImage.propTypes = {
  src: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
};

TableImage.defaultProps = {
  width: '100%',
  height: '100%'
};

export default TableImage;
