import axios from 'axios';
import { API } from 'helpers/constant';

const createDiary = async (data) => {
  await axios.post(`${API}/diary`, data)
    .then((res) => res.data)
    .catch((error) => error);
};
const getAllDiary = () => axios.get(`${API}/diary`)
  .then((res) => res.data)
  .catch((error) => error[0]);

const getAllDiaryByStoreParameters = (parameters) => axios.post(`${API}/diary/searchDiaryByStoreParameters`, parameters)
  .then((res) => res.data)
  .catch((error) => error[0]);

const updateDiary = async (id, data) => {
  await axios.put(`${API}/diary/${id}`, data)
    .then((res) => res.data)
    .catch((error) => error);
};

const deleteDiary = async (id) => {
  await axios.delete(`${API}/diary/${id}`)
    .then((res) => res.data)
    .catch((error) => error);
};

const diaryContact = (id) => axios.get(`${API}/diary/diaryContact/${id}`)
  .then((res) => res.data)
  .catch((error) => error);

const getDiaryTypes = () => axios.get(`${API}/diaryTypes`)
  .then((res) => res.data)
  .catch((error) => error[0]);

const getDiarySubtypes = () => axios.get(`${API}/diarySubtypes`)
  .then((res) => res.data)
  .catch((error) => error[0]);

const createDiarySubtypes = (data) => axios.post(`${API}/diarySubtypes`, data)
  .then((res) => res.data)
  .catch((error) => error[0]);

const deleteDiarySubtypes = (id) => axios.delete(`${API}/diarySubtypes/${id}`)
  .then((res) => res.data)
  .catch((error) => error[0]);

const getDiaryWayTypes = () => axios.get(`${API}/diaryWayTypes`)
  .then((res) => res.data)
  .catch((error) => error[0]);

export {
  createDiary,
  getAllDiary,
  updateDiary,
  deleteDiary,
  getDiaryTypes,
  getDiarySubtypes,
  getDiaryWayTypes,
  diaryContact,
  getAllDiaryByStoreParameters,
  createDiarySubtypes,
  deleteDiarySubtypes
};
