import React from 'react';
import styled from 'styled-components';

import Table from 'components/tableComponents/CompleteTable';

import { useStoresAdminReport } from 'providers/Reports/storesAdmin';
import { CSVLink } from 'react-csv';
import { RiFileDownloadLine } from 'react-icons/ri';

import TitleBar from 'components/layout/TitleBar';
import Loading from 'components/common/Loading';
import Refresh from 'components/common/Refresh';
import NoData from 'components/common/NoData';

const StoresAdmin = () => {
  const { storesAdminReportData, loading } = useStoresAdminReport();

  const columns = React.useMemo(
    () => [
      {
        Header: 'COD',
        accessor: 'cod',
        defaultFilter: true,
      },
      {
        Header: 'RAZÃO SOCIAL',
        accessor: 'razaoSocial',
        defaultFilter: true,
      },
      {
        Header: 'NOME FANTASIA',
        accessor: 'nomeFantasia',
        defaultFilter: true,
      },
      {
        Header: 'CNPJ',
        accessor: 'cnpj',
        defaultFilter: true,
      },
      {
        Header: 'ATIVO',
        accessor: 'ativo',
        defaultFilter: true,
      },
      {
        Header: 'DATA CONTRATO',
        accessor: 'dataContrato',
        defaultFilter: true,
      },
      {
        Header: 'BANDEIRA',
        accessor: 'bandeira',
        defaultFilter: true,
      },
      {
        Header: 'CIDADE',
        accessor: 'cidade',
        defaultFilter: true,
      },
      {
        Header: 'ESTADO',
        accessor: 'estado',
        defaultFilter: true,
      },
      {
        Header: 'ANJO',
        accessor: 'anjo',
        defaultFilter: true,
      },
      {
        Header: 'CONSULTOR',
        accessor: 'consultor',
        defaultFilter: true,
      },
      {
        Header: 'CONSULTOR PAI',
        accessor: 'consultorPai',
        defaultFilter: true,
      },
      {
        Header: 'ATEND. COMERCIAL',
        accessor: 'atendenteComercial',
        defaultFilter: true,
      },
      {
        Header: 'DATA CAD.',
        accessor: 'dataCadastro',
        Cell: (i) => {
          const { cell } = i;
          const data = cell.row.original;
          if (data.dataCadastro) {
            return data.dataCadastro;
          }
          return '';
        }
      },
      {
        Header: 'DATA SAÍDA',
        accessor: 'dataDescredenciamento',
        Cell: (i) => {
          const { cell } = i;
          const data = cell.row.original;
          if (data.dataDescredenciamento) {
            return data.dataDescredenciamento;
          }
          return '';
        }
      },
    ],
    []
  );

  function setComponent() {
    if (!storesAdminReportData) {
      return (<Refresh />);
    } return (
      <>
        <TitleBar>
          Drogarias
        </TitleBar>
        {((loading === false) && (storesAdminReportData.length > 0) && (
          <CSVLink data={storesAdminReportData} separator=";">
            <IconCase>
              <DownloadIcon title="Baixar Planilha" />
            </IconCase>
          </CSVLink>
        ))}
        <Loading active={loading} />
        {((storesAdminReportData.length > 0) && (loading === false) && (
          <Table data={storesAdminReportData} changeTableSize={1000000} columns={columns} marginTop="0px" sortOrder="storesAdminReport" pagination={false} textMarking />
        ))}
        {(storesAdminReportData.length === 0) && (loading === false) && (
          <NoData />
        )}
      </>
    );
  }

  return (
    <>
      {setComponent()}
    </>
  );
};

const DownloadIcon = styled(RiFileDownloadLine)`
  font-size: 30px;
  width: 40px;
  margin-top: 5px;
  margin-right: 0px;
`;

const IconCase = styled.div`
  font-size: 23px;
  cursor: pointer;
  position: absolute;
  right: 0;
  margin-right: 2px;
  top: 110px;
`;

export default StoresAdmin;
