import styled from 'styled-components';
import TableTd from './TableTd';

const TableTitle = styled(TableTd)`
  margin: 10px;
  font-weight: 600;
  text-decoration: none;
`;

export default TableTitle;
