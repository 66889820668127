import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { IoIosClose } from 'react-icons/io';

const Modal = ({
  children, visible, onClose, title
}) => {
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        onClose();
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  return (
    visible && (
    <>
      <OutsideModal />
      <ModalWindow>
        <Header>
          { title }
          <Close
            data-testid="modal-close-icon"
            onClick={() => {
              onClose();
            }}
          />
        </Header>
        {children}
      </ModalWindow>
    </>
    ));
};
const OutsideModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 898;
  display: block;
`;

const ModalWindow = styled.div`
  background-color: #fff;
  position: fixed;
  background: #fff;
  height: auto;
  max-height: 90vh;
  top: 50%;
  left: 50%;
  text-align: left;
  transform: translate(-50%, -50%);
  border-radius: 4px;
  z-index: 899;
  text-align: left;

  @media(max-width: 800px) {
    width: 90%;
    overflow: auto;
  }
`;

const Header = styled.div`
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  width: 100%;
  height: 60px;
  background: #224772; 
  padding: 15px;

  display: flex;
  align-items: center;

  font-size: 20px;
  font-weight: 600;
  color: #ffffff;
`;

const Close = styled(IoIosClose)`
  position: fixed;
  right: 15px;
  cursor: pointer;
  color: #ffffff;
  font-size: 30px;
`;

Modal.propTypes = {
  children: PropTypes.element.isRequired,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
};

Modal.defaultProps = {
  title: '',
};

export default Modal;
