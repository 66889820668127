import Swal from 'sweetalert2';
import AlertWarning from './AlertWarning';

const DeleteAlert = async (id, msg, registerDelete) => {
  await Swal.fire(AlertWarning(msg)).then(async (result) => {
    if (result.isConfirmed) {
      await registerDelete(id);
    }
  });
};

export default DeleteAlert;
