// export const URL_BACKEND = 'http://localhost:5000';

export const URL_BACKEND = 'https://api2.redemelhorcompra.com.br';
export const API_REPORT = 'https://reports-ms.redemelhorcompra.com.br';

export const API = `${URL_BACKEND}/api`;

export const API_INTEGRATION = 'https://integration.redemelhorcompra.com.br';
export const PDF_MICROSERVICE = 'https://pdf-ms2.redemelhorcompra.com.br';
export const UPLOAD_FILE_MICROSERVICE = 'https://upload-file.redemelhorcompra.com.br';

// // export const URL_BACKEND = 'http://localhost:5000';

// export const URL_BACKEND = 'https://api.redemelhorcompra.com.br';
// export const API_REPORT = 'https://reports-ms.redemelhorcompra.com.br';

// export const API = `${URL_BACKEND}/api`;

// export const API_INTEGRATION = 'https://integration.redemelhorcompra.com.br';
// export const PDF_MICROSERVICE = 'https://pdf-ms.redemelhorcompra.com.br';
// export const UPLOAD_FILE_MICROSERVICE = 'https://upload-file.redemelhorcompra.com.br';
