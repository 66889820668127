import React, {
  useState,
  useEffect,
  createContext,
  useContext
} from 'react';
import PropTypes from 'prop-types';

import {
  getVisits,
  getVisitsByConsultant,
} from 'service/ReportService';

import verifyAclUser from 'helpers/verifyAclUser';
import acl from 'helpers/acl';
import getProfile from 'helpers/getProfile';

export const VisitsReportContext = createContext({});

export const VisitsReportProvider = ({ children }) => {
  const [visitsReportData, setVisitsReportData] = useState([]);
  const [loading, setLoading] = useState(true);

  const list = async () => {
    setLoading(true);
    let data = [];
    try {
      const profile = getProfile();
      if (verifyAclUser(acl.external_consultant) || verifyAclUser(acl.internal_consultant)) {
        data = await getVisitsByConsultant(profile.user.id);
      } else {
        data = await getVisits();
      }
      setVisitsReportData(data);
    } catch {
      setVisitsReportData(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      list();
    };
    fetchData();
  }, []);

  return (
    <VisitsReportContext.Provider value={{
      visitsReportData,
      setVisitsReportData,
      loading,
      setLoading,
    }}
    >
      {children}
    </VisitsReportContext.Provider>
  );
};

VisitsReportProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export const useVisitsReport = () => useContext(VisitsReportContext);
