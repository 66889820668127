import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import styled from 'styled-components';

import Scrollbar from 'components/common/Scrollbar';
import Button from 'components/common/Button';
import ButtonCase from 'components/common/ButtonCase';
import CancelButton from 'components/common/CancelButton';

import FieldSection from 'components/common/FieldSection';
import InputField from 'components/common/InputField';
import SelectField from 'components/common/SelectField';
import Case from 'components/common/Case';

import { useStores } from 'providers/Stores/stores';

const EditForm = ({
  validations, handleSubmit, handleCancel, values
}) => {
  const formik = useFormik({
    initialValues: values,
    onSubmit: handleSubmit,
    validationSchema: Yup.object(validations),
  });

  const {
    angels,
    consultant,
    storesServicesStatus,
    brands,
    commercialAttendant,
  } = useStores();

  const [gefadoptions, setGefadOptions] = useState(values.gefad);
  const [hidenGefadTxt, setHidenGefadTxt] = useState(false);

  const [farmacia360options, setFarmacia360Options] = useState(values.farmacia360);
  const [hidenFarmacia360Txt, setHidenFarmacia360Txt] = useState(false);

  const [contabilidadeoptions, setContabilidadeOptions] = useState(values.contabilidade);
  const [hidenContabilidadeTxt, setHidenContabilidadeTxt] = useState(false);

  const [clinicarxOptions, setClinicarxOptions] = useState(values.clinicarx);
  const [hidenClinicarxTxt, setHidenClinicarxTxt] = useState(false);

  const [precificacaoOptions, setPrecificacaoOptions] = useState(values.precificacao);
  const [hidenPrecificacaoTxt, setHidenPrecificacaoTxt] = useState(false);

  const [brasilcardoptions, setBrasilcardOptions] = useState(values.brasilcard);
  const [hidenBrasilcardTxt, setHidenBrasilcardTxt] = useState(false);

  const [
    financialconveniosoptions,
    setFinancialConveniosOptions
  ] = useState(values.financialConvenios);
  const [hidenFinancialConveniosTxt, setHidenFinancialConveniosTxt] = useState(false);

  const [
    financialcardoptions,
    setFinancialCardOptions
  ] = useState(values.financialCard);
  const [hidenFinancialCardTxt, setHidenFinancialCardTxt] = useState(false);

  const [cielooptions, setCieloOptions] = useState(values.cielo);
  const [hidenCieloTxt, setHidenCieloTxt] = useState(false);

  const [conektaoptions, setConektaOptions] = useState(values.conekta);
  const [hidenConektaTxt, setHidenConektaTxt] = useState(false);

  const [consultoptions, setConsultOptions] = useState(values.consult);
  const [hidenConsultTxt, setHidenConsultTxt] = useState(false);

  const [memedoptions, setMemedOptions] = useState(values.memed);
  const [hidenMemedTxt, setHidenMemedTxt] = useState(false);

  const [fidelidadeoptions, setFidelidadeOptions] = useState(values.fidelidade);
  const [hidenFidelidadeTxt, setHidenFidelidadeTxt] = useState(false);

  const [mypharmaoptions, setMypharmaOptions] = useState(values.mypharma);
  const [hidenMypharmaTxt, setMypharmaTxt] = useState(false);

  const [abcfarmaoptions, setAbcfarmaoptions] = useState(values.abcfarma);
  const [hidenAbcfarmaTxt, setAbcfarmaTxt] = useState(false);

  useEffect(() => {
    if (abcfarmaoptions === 3) {
      setAbcfarmaTxt(true);
    } else {
      setAbcfarmaTxt(false);
    }
  }, [abcfarmaoptions]);

  useEffect(() => {
    if (mypharmaoptions === 3) {
      setMypharmaTxt(true);
    } else {
      setMypharmaTxt(false);
    }
  }, [mypharmaoptions]);

  useEffect(() => {
    if (fidelidadeoptions === 3) {
      setHidenFidelidadeTxt(true);
    } else {
      setHidenFidelidadeTxt(false);
    }
  }, [fidelidadeoptions]);

  useEffect(() => {
    if (memedoptions === 3) {
      setHidenMemedTxt(true);
    } else {
      setHidenMemedTxt(false);
    }
  }, [memedoptions]);

  useEffect(() => {
    if (gefadoptions === 3) {
      setHidenGefadTxt(true);
    } else {
      setHidenGefadTxt(false);
    }
  }, [gefadoptions]);

  useEffect(() => {
    if (farmacia360options === 3) {
      setHidenFarmacia360Txt(true);
    } else {
      setHidenFarmacia360Txt(false);
    }
  }, [farmacia360options]);

  useEffect(() => {
    if (contabilidadeoptions === 3) {
      setHidenContabilidadeTxt(true);
    } else {
      setHidenContabilidadeTxt(false);
    }
  }, [contabilidadeoptions]);

  useEffect(() => {
    if (clinicarxOptions === 3) {
      setHidenClinicarxTxt(true);
    } else {
      setHidenClinicarxTxt(false);
    }
  }, [clinicarxOptions]);

  useEffect(() => {
    if (precificacaoOptions === 3) {
      setHidenPrecificacaoTxt(true);
    } else {
      setHidenPrecificacaoTxt(false);
    }
  }, [precificacaoOptions]);

  useEffect(() => {
    if (brasilcardoptions === 3) {
      setHidenBrasilcardTxt(true);
    } else {
      setHidenBrasilcardTxt(false);
    }
  }, [brasilcardoptions]);

  useEffect(() => {
    if (financialconveniosoptions === 3) {
      setHidenFinancialConveniosTxt(true);
    } else {
      setHidenFinancialConveniosTxt(false);
    }
  }, [financialconveniosoptions]);

  useEffect(() => {
    if (financialcardoptions === 3) {
      setHidenFinancialCardTxt(true);
    } else {
      setHidenFinancialCardTxt(false);
    }
  }, [financialcardoptions]);

  useEffect(() => {
    if (cielooptions === 3) {
      setHidenCieloTxt(true);
    } else {
      setHidenCieloTxt(false);
    }
  }, [cielooptions]);

  useEffect(() => {
    if (conektaoptions === 3) {
      setHidenConektaTxt(true);
    } else {
      setHidenConektaTxt(false);
    }
  }, [conektaoptions]);

  useEffect(() => {
    if (consultoptions === 3) {
      setHidenConsultTxt(true);
    } else {
      setHidenConsultTxt(false);
    }
  }, [consultoptions]);

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Scrollbar height="450px" margin="0px 0px 10px 0px">
        <FieldSection title="Dados de Drogaria">
          <Case gridTemplateColumns="1fr 1fr">
            <InputField
              id="storeName"
              name="storeName"
              label="Razão Social:"
              placeholder="Razão Social"
              isRequired
              type="text"
              error={formik.errors.storeName}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.storeName}
              isError={formik.errors.storeName && formik.touched.storeName}
            />
            <InputField
              id="tradeName"
              name="tradeName"
              label="Nome Fantasia:"
              placeholder="Nome Fantasia"
              isRequired
              type="text"
              error={formik.errors.tradeName}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.tradeName}
              isError={formik.errors.tradeName && formik.touched.tradeName}
            />
            <InputField
              id="phoneNumber"
              name="phoneNumber"
              label="Telefone:"
              placeholder="(00) 0 0000-0000"
              isRequired
              type="text"
              error={formik.errors.phoneNumber}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.phoneNumber}
              isError={formik.errors.phoneNumber && formik.touched.phoneNumber}
              mask="(99) 9 9999-9999"
            />
            <InputField
              id="email"
              name="email"
              label="Email:"
              placeholder="Email do contato"
              isRequired
              type="text"
              error={formik.errors.email}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.email}
              isError={formik.errors.email && formik.touched.email}
            />
          </Case>
        </FieldSection>
        <FieldSection title="Dados de Controle">
          <Case gridTemplateColumns="1.5fr 1fr 1fr 1fr">
            <SelectField
              id="brandId"
              name="brandId"
              label="Bandeira"
              value={formik.values.brandId}
              onBlur={formik.handleBlur}
              onChange={(e) => {
                formik.handleChange(e);
              }}
              options={brands}
              error={formik.errors.brandId}
              isRequired
              isError={formik.errors.brandId && formik.touched.brandId}
            />
            <SelectField
              id="angelId"
              name="angelId"
              label="Anjo"
              value={formik.values.angelId}
              onBlur={formik.handleBlur}
              onChange={(e) => {
                formik.handleChange(e);
              }}
              options={angels}
              error={formik.errors.angelId}
              isRequired
              isError={formik.errors.angelId && formik.touched.angelId}
            />
            <SelectField
              id="consultantId"
              name="consultantId"
              label="Consultor"
              value={formik.values.consultantId}
              onBlur={formik.handleBlur}
              onChange={(e) => {
                formik.handleChange(e);
              }}
              options={consultant}
              error={formik.errors.consultantId}
              isRequired
              isError={formik.errors.consultantId && formik.touched.consultantId}
            />
            <SelectField
              id="commercialAttendantId"
              name="commercialAttendantId"
              label="Atendente Comercial"
              value={formik.values.commercialAttendantId}
              onBlur={formik.handleBlur}
              onChange={(e) => {
                formik.handleChange(e);
              }}
              options={commercialAttendant}
              error={formik.errors.commercialAttendantId}
              isRequired
              isError={formik.errors.commercialAttendantId && formik.touched.commercialAttendantId}
            />
          </Case>
        </FieldSection>
        <FieldSection title="Serviços">
          <Case gridTemplateColumns="1fr 1fr">
            <Dashed>
              <SelectField
                id="abcfarma"
                name="abcfarma"
                label="ABC FARMA"
                value={formik.values.abcfarma}
                onBlur={formik.handleBlur}
                onChange={(e) => {
                  formik.handleChange(e);
                  setAbcfarmaoptions(e.nativeEvent.srcElement.options.selectedIndex);
                }}
                options={storesServicesStatus}
                error={formik.errors.abcfarma}
                isRequired
                isError={formik.errors.abcfarma && formik.touched.abcfarma}
              />
              {hidenAbcfarmaTxt && (
                <InputField
                  id="abcfarmaTxt"
                  name="abcfarmaTxt"
                  label="Motivo:"
                  placeholder="Digite o motivo da recusa do serviço"
                  isRequired
                  type="text"
                  error={formik.errors.abcfarmaTxt}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.abcfarmaTxt}
                  isError={formik.errors.abcfarmaTxt && formik.touched.abcfarmaTxt}
                />
              )}
            </Dashed>
            <Dashed>
              <SelectField
                id="financialCard"
                name="financialCard"
                label="FINANCIALCARD"
                value={formik.values.financialCard}
                onBlur={formik.handleBlur}
                onChange={(e) => {
                  formik.handleChange(e);
                  setFinancialCardOptions(e.nativeEvent.srcElement.options.selectedIndex);
                }}
                options={storesServicesStatus}
                error={formik.errors.financialCard}
                isRequired
                isError={formik.errors.financialCard && formik.touched.financialCard}
              />
              {hidenFinancialCardTxt && (
                <InputField
                  id="financialCardTxt"
                  name="financialCardTxt"
                  label="Motivo:"
                  placeholder="Digite o motivo da recusa do serviço"
                  isRequired
                  type="text"
                  error={formik.errors.financialCardTxt}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.financialCardTxt}
                  isError={formik.errors.financialCardTxt && formik.touched.financialCardTxt}
                />
              )}
            </Dashed>
            <Dashed>
              <SelectField
                id="gefad"
                name="gefad"
                label="GEFAD"
                value={formik.values.gefad}
                onBlur={formik.handleBlur}
                onChange={(e) => {
                  formik.handleChange(e);
                  setGefadOptions(e.nativeEvent.srcElement.options.selectedIndex);
                }}
                options={storesServicesStatus}
                error={formik.errors.gefad}
                isRequired
                isError={formik.errors.gefad && formik.touched.gefad}
              />
              {hidenGefadTxt && (
                <InputField
                  id="gefadTxt"
                  name="gefadTxt"
                  label="Motivo:"
                  placeholder="Digite o motivo da recusa do serviço"
                  isRequired
                  type="text"
                  error={formik.errors.gefadTxt}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.gefadTxt}
                  isError={formik.errors.gefadTxt && formik.touched.gefadTxt}
                />
              )}
            </Dashed>
            <Dashed>
              <SelectField
                id="brasilcard"
                name="brasilcard"
                label="BRASILCARD"
                value={formik.values.brasilcard}
                onBlur={formik.handleBlur}
                onChange={(e) => {
                  formik.handleChange(e);
                  setBrasilcardOptions(e.nativeEvent.srcElement.options.selectedIndex);
                }}
                options={storesServicesStatus}
                error={formik.errors.brasilcard}
                isRequired
                isError={formik.errors.brasilcard && formik.touched.brasilcard}
              />
              {hidenBrasilcardTxt && (
                <InputField
                  id="brasilcardTxt"
                  name="brasilcardTxt"
                  label="Motivo:"
                  placeholder="Digite o motivo da recusa do serviço"
                  isRequired
                  type="text"
                  error={formik.errors.brasilcardTxt}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.brasilcardTxt}
                  isError={formik.errors.brasilcardTxt && formik.touched.brasilcardTxt}
                />
              )}
            </Dashed>
            <Dashed>
              <SelectField
                id="farmacia360"
                name="farmacia360"
                label="FARMÁCIA 360°"
                value={formik.values.farmacia360}
                onBlur={formik.handleBlur}
                onChange={(e) => {
                  formik.handleChange(e);
                  setFarmacia360Options(e.nativeEvent.srcElement.options.selectedIndex);
                }}
                options={storesServicesStatus}
                error={formik.errors.farmacia360}
                isRequired
                isError={formik.errors.farmacia360 && formik.touched.farmacia360}
              />
              {hidenFarmacia360Txt && (
                <InputField
                  id="farmacia360Txt"
                  name="farmacia360Txt"
                  label="Motivo:"
                  placeholder="Digite o motivo da recusa do serviço"
                  isRequired
                  type="text"
                  error={formik.errors.farmacia360Txt}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.farmacia360Txt}
                  isError={formik.errors.farmacia360Txt && formik.touched.farmacia360Txt}
                />
              )}
            </Dashed>
            <Dashed>
              <SelectField
                id="conekta"
                name="conekta"
                label="CONEKTA"
                value={formik.values.conekta}
                onBlur={formik.handleBlur}
                onChange={(e) => {
                  formik.handleChange(e);
                  setConektaOptions(e.nativeEvent.srcElement.options.selectedIndex);
                }}
                options={storesServicesStatus}
                error={formik.errors.conekta}
                isRequired
                isError={formik.errors.conekta && formik.touched.conekta}
              />
              {hidenConektaTxt && (
                <InputField
                  id="conektaTxt"
                  name="conektaTxt"
                  label="Motivo:"
                  placeholder="Digite o motivo da recusa do serviço"
                  isRequired
                  type="text"
                  error={formik.errors.conektaTxt}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.conektaTxt}
                  isError={formik.errors.conektaTxt && formik.touched.conektaTxt}
                />
              )}
            </Dashed>
            <Dashed>
              <SelectField
                id="contabilidade"
                name="contabilidade"
                label="CONTABILIDADE"
                value={formik.values.contabilidade}
                onBlur={formik.handleBlur}
                onChange={(e) => {
                  formik.handleChange(e);
                  setContabilidadeOptions(e.nativeEvent.srcElement.options.selectedIndex);
                }}
                options={storesServicesStatus}
                error={formik.errors.contabilidade}
                isRequired
                isError={formik.errors.contabilidade && formik.touched.contabilidade}
              />
              {hidenContabilidadeTxt && (
              <InputField
                id="contabilidadeTxt"
                name="contabilidadeTxt"
                label="Motivo:"
                placeholder="Digite o motivo da recusa do serviço"
                isRequired
                type="text"
                error={formik.errors.contabilidadeTxt}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.contabilidadeTxt}
                isError={formik.errors.contabilidadeTxt && formik.touched.contabilidadeTxt}
              />
              )}
            </Dashed>
            <Dashed>
              <SelectField
                id="cielo"
                name="cielo"
                label="CIELO"
                value={formik.values.cielo}
                onBlur={formik.handleBlur}
                onChange={(e) => {
                  formik.handleChange(e);
                  setCieloOptions(e.nativeEvent.srcElement.options.selectedIndex);
                }}
                options={storesServicesStatus}
                error={formik.errors.cielo}
                isRequired
                isError={formik.errors.cielo && formik.touched.cielo}
              />
              {hidenCieloTxt && (
                <InputField
                  id="cieloTxt"
                  name="cieloTxt"
                  label="Motivo:"
                  placeholder="Digite o motivo da recusa do serviço"
                  isRequired
                  type="text"
                  error={formik.errors.cieloTxt}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.cieloTxt}
                  isError={formik.errors.cieloTxt && formik.touched.cieloTxt}
                />
              )}
            </Dashed>
            <Dashed>
              <SelectField
                id="consult"
                name="consult"
                label="CONSULT"
                value={formik.values.consult}
                onBlur={formik.handleBlur}
                onChange={(e) => {
                  formik.handleChange(e);
                  setConsultOptions(e.nativeEvent.srcElement.options.selectedIndex);
                }}
                options={storesServicesStatus}
                error={formik.errors.consult}
                isRequired
                isError={formik.errors.consult && formik.touched.consult}
              />
              {hidenConsultTxt && (
              <InputField
                id="consultTxt"
                name="consultTxt"
                label="Motivo:"
                placeholder="Digite o motivo da recusa do serviço"
                isRequired
                type="text"
                error={formik.errors.consultTxt}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.consultTxt}
                isError={formik.errors.consultTxt && formik.touched.consultTxt}
              />
              )}
            </Dashed>
            <Dashed>
              <SelectField
                id="memed"
                name="memed"
                label="MEMED"
                value={formik.values.memed}
                onBlur={formik.handleBlur}
                onChange={(e) => {
                  formik.handleChange(e);
                  setMemedOptions(e.nativeEvent.srcElement.options.selectedIndex);
                }}
                options={storesServicesStatus}
                error={formik.errors.memed}
                isRequired
                isError={formik.errors.memed && formik.touched.memed}
              />
              {hidenMemedTxt && (
                <InputField
                  id="memedTxt"
                  name="memedTxt"
                  label="Motivo:"
                  placeholder="Digite o motivo da recusa do serviço"
                  isRequired
                  type="text"
                  error={formik.errors.memedTxt}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.memedTxt}
                  isError={formik.errors.memedTxt && formik.touched.memedTxt}
                />
              )}
            </Dashed>
            <Dashed>
              <SelectField
                id="clinicarx"
                name="clinicarx"
                label="CLINICARX"
                value={formik.values.clinicarx}
                onBlur={formik.handleBlur}
                onChange={(e) => {
                  formik.handleChange(e);
                  setClinicarxOptions(e.nativeEvent.srcElement.options.selectedIndex);
                }}
                options={storesServicesStatus}
                error={formik.errors.clinicarx}
                isRequired
                isError={formik.errors.clinicarx && formik.touched.clinicarx}
              />
              {hidenClinicarxTxt && (
              <InputField
                id="clinicarxTxt"
                name="clinicarxTxt"
                label="Motivo:"
                placeholder="Digite o motivo da recusa do serviço"
                isRequired
                type="text"
                error={formik.errors.clinicarxTxt}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.clinicarxTxt}
                isError={formik.errors.clinicarxTxt && formik.touched.clinicarxTxt}
              />
              )}
            </Dashed>
            <Dashed>
              <SelectField
                id="fidelidade"
                name="fidelidade"
                label="FIDELIDADE"
                value={formik.values.fidelidade}
                onBlur={formik.handleBlur}
                onChange={(e) => {
                  formik.handleChange(e);
                  setFidelidadeOptions(e.nativeEvent.srcElement.options.selectedIndex);
                }}
                options={storesServicesStatus}
                error={formik.errors.fidelidade}
                isRequired
                isError={formik.errors.fidelidade && formik.touched.fidelidade}
              />
              {hidenFidelidadeTxt && (
                <InputField
                  id="fidelidadeTxt"
                  name="fidelidadeTxt"
                  label="Motivo:"
                  placeholder="Digite o motivo da recusa do serviço"
                  isRequired
                  type="text"
                  error={formik.errors.fidelidadeTxt}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.fidelidadeTxt}
                  isError={formik.errors.fidelidadeTxt && formik.touched.fidelidadeTxt}
                />
              )}
            </Dashed>
            <Dashed>
              <SelectField
                id="precificacao"
                name="precificacao"
                label="PRECIFICAÇÃO"
                value={formik.values.precificacao}
                onBlur={formik.handleBlur}
                onChange={(e) => {
                  formik.handleChange(e);
                  setPrecificacaoOptions(e.nativeEvent.srcElement.options.selectedIndex);
                }}
                options={storesServicesStatus}
                error={formik.errors.precificacao}
                isRequired
                isError={formik.errors.precificacao && formik.touched.precificacao}
              />
              {hidenPrecificacaoTxt && (
              <InputField
                id="precificacaoTxt"
                name="precificacaoTxt"
                label="Motivo:"
                placeholder="Digite o motivo da recusa do serviço"
                isRequired
                type="text"
                error={formik.errors.precificacaoTxt}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.precificacaoTxt}
                isError={formik.errors.precificacaoTxt && formik.touched.precificacaoTxt}
              />
              )}
            </Dashed>
            <Dashed>
              <SelectField
                id="mypharma"
                name="mypharma"
                label="MYPHARMA"
                value={formik.values.mypharma}
                onBlur={formik.handleBlur}
                onChange={(e) => {
                  formik.handleChange(e);
                  setMypharmaOptions(e.nativeEvent.srcElement.options.selectedIndex);
                }}
                options={storesServicesStatus}
                error={formik.errors.mypharma}
                isRequired
                isError={formik.errors.mypharma && formik.touched.mypharma}
              />
              {hidenMypharmaTxt && (
                <InputField
                  id="mypharmaTxt"
                  name="mypharmaTxt"
                  label="Motivo:"
                  placeholder="Digite o motivo da recusa do serviço"
                  isRequired
                  type="text"
                  error={formik.errors.mypharmaTxt}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.mypharmaTxt}
                  isError={formik.errors.mypharmaTxt && formik.touched.mypharmaTxt}
                />
              )}
            </Dashed>
            <Dashed>
              <SelectField
                id="financialConvenios"
                name="financialConvenios"
                label="FINANCIAL CONVÊNIOS"
                value={formik.values.financialConvenios}
                onBlur={formik.handleBlur}
                onChange={(e) => {
                  formik.handleChange(e);
                  setFinancialConveniosOptions(e.nativeEvent.srcElement.options.selectedIndex);
                }}
                options={storesServicesStatus}
                error={formik.errors.financialConvenios}
                isRequired
                isError={formik.errors.financialConvenios && formik.touched.financialConvenios}
              />
              {hidenFinancialConveniosTxt && (
                <InputField
                  id="financialConveniosTxt"
                  name="financialConveniosTxt"
                  label="Motivo:"
                  placeholder="Digite o motivo da recusa do serviço"
                  isRequired
                  type="text"
                  error={formik.errors.financialConveniosTxt}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.financialConveniosTxt}
                  isError={formik.errors.financialConveniosTxt
                    && formik.touched.financialConveniosTxt}
                />
              )}
            </Dashed>
          </Case>
        </FieldSection>
      </Scrollbar>
      <ButtonCase>
        <CancelButton onClose={handleCancel}>Cancelar</CancelButton>
        <Button type="submit">Salvar</Button>
      </ButtonCase>
    </Form>
  );
};

const Form = styled.form`  
  width: 60vw;
  height: 500px;
`;

const Dashed = styled.div`
  border: dashed 2px #ccc;
  padding: 8px;
  margin: 10px;
`;

EditForm.propTypes = {
  validations: PropTypes.objectOf(PropTypes.any).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  values: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default EditForm;
