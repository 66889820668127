import React, { useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';

import { DeleteAlert } from 'components/alert';
import { useDiary } from 'providers/diary';

import {
  IoTrashOutline,
} from 'react-icons/io5';

import { getAllDiaryByStoreParameters } from 'service/DiaryService';
import getProfile from 'helpers/getProfile';
import { Card, CardIcon, CardFooter } from 'components/card';
import LoadingBlocked from 'components/common/LoadingBlocked';
import Case from 'components/common/Case';
import Button from 'components/common/Button';
import ButtonCase from 'components/common/ButtonCase';

import { toastError } from 'helpers/notifications';
import DiaryEdit from './DiaryEdit';
import ViewData from './ViewData';

const DiaryCard = () => {
  const { deleteRegister } = useDiary();
  const [value, setValue] = useState('');
  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(false);

  const deleteDiaryById = async (id, userId) => {
    const profile = await getProfile();
    if ((profile.user.id === userId) || (profile.user.id === 1)) {
      await DeleteAlert(id, 'Tem certeza que excluir o registro dessa visita?', deleteRegister);
    } else {
      toastError('Você não tem permissão para excluir o registro de outro usuário!');
    }
  };

  const searchStore = async (searcher) => {
    if (searcher !== '') {
      const sendDataArr = { input: searcher.toLowerCase() };
      setLoading(true);
      setCards([]);
      const results = await getAllDiaryByStoreParameters(sendDataArr);
      setLoading(false);
      if (results === null) {
        toastError('Nenhum dado relacionado encontrado!');
      } else {
        setCards(results);
      }
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      searchStore(value);
    }
  };

  return (
    <>
      <LoadingBlocked active={loading} />
      <Container>
        <input
          onChange={(e) => {
            setValue(e.target.value);
          }}
          placeholder="Pesquise por Código ou Razão Social"
          onKeyDown={handleKeyDown}
        />
        <ButtonCase>
          <Button onClick={() => searchStore(value)}>
            Buscar
          </Button>
        </ButtonCase>
        <Grid>{ (
          cards.map((data) => (
            <Card key={data.id} cardStyle="background-color: white">
              <Case gridTemplateColumns="1fr 1fr" alignItems="center" justifyContent="space-between" display="flex">
                <Header>
                  <LegacyCode>{data.stores.legacyCode}</LegacyCode>
                  <StoreName>{data.stores.storeName}</StoreName>
                </Header>
              </Case>
              <Subtitle>{data.diarytypes.name} - {data.id}</Subtitle>
              <NoteBody gridTemplateColumns="1fr">
                {data.text}
              </NoteBody>
              <CardFooter gridTemplateColumns="1fr 1fr 1fr">
                <div>{data.userData.name}</div>
                <div>
                  {moment(data.date).format('DD/MM/YYYY')}
                </div>
                <div>
                  <CardIcon marginleft="5px">
                    <DiaryEdit data={data} exibition="card" />
                    <ViewData data={data} exibition="card" />
                    <IoTrashOutline title="Excluir" style={{ fontSize: '19px' }} onClick={() => deleteDiaryById(data.id, data.userData.id)} />
                  </CardIcon>
                </div>
              </CardFooter>
            </Card>
          ))
        ) }
        </Grid>
      </Container>
    </>
  );
};

const Container = styled.div`
  margin-top: 113px;

  @media(max-width: 400px) {
    margin-top: 0;
  }
`;

const Grid = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
 @media(max-width: 800px) { 
  grid-template-columns: 1fr;
 }
`;

const NoteBody = styled.div`
  overflow: hidden;
  max-height: 150px;
 @media(max-width: 800px) {
  max-height: 100px;
 }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  color: #20314F;
`;

const Subtitle = styled.div`
  font-weight: 600;
  margin-bottom: 17px;
  margin-top: 7px;
  text-align: center;
`;

const LegacyCode = styled.div`
  font-size: 26px;
  font-weight: 600;
  margin-right: 1rem;
  line-height: 26px;
`;

const StoreName = styled.div`
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 600;
`;

export default DiaryCard;
