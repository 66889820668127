import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { ImUserPlus } from 'react-icons/im';

import { useProfile } from 'providers/Stores/profile';
import verifyAclUser from 'helpers/verifyAclUser';
import acl from 'helpers/acl';

import Modal from 'components/common/Modal';
import Form from './Form';

const CreateContact = ({ storeId }) => {
  const {
    createContact,
    modalCreateContact,
    setModalCreateContact,
  } = useProfile();

  const initialValues = {
    name: '',
    email: '',
    phoneNumber: '',
    mainContact: false,
    financialContact: false,
    general: false,
    ethical: false,
    generic: false,
    similar: false,
    perfumery: false,
    storeId
  };
  const validations = {
    name: Yup.string().required('Informe o nome do contato'),
    email: Yup.string().email().required('Informe o email do contato'),
    phoneNumber: Yup.string().required('Informe o telefone do contato'),
  };
  return (
    <>
      {verifyAclUser(acl.manipulate_store_contact) && (
        <div style={{ textAlign: 'right' }}><ImUserPlus style={{ cursor: 'pointer' }} onClick={() => setModalCreateContact(true)} /></div>
      )}
      <Modal visible={modalCreateContact} onClose={() => setModalCreateContact(false)} title="Criar Contato">
        <Form
          values={initialValues}
          handleSubmit={createContact}
          handleCancel={setModalCreateContact}
          validations={validations}
        />
      </Modal>
    </>
  );
};

CreateContact.propTypes = {
  storeId: PropTypes.number.isRequired,
};

export default CreateContact;
