import React, { useRef } from 'react';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import { CSVLink } from 'react-csv';
import { RiFileDownloadLine } from 'react-icons/ri';

import { useContactsReports } from 'providers/Reports/contacts';

import Table from 'components/tableComponents/CompleteTable';
import TitleBar from 'components/layout/TitleBar';
import Loading from 'components/common/Loading';
import Case from 'components/common/Case';
import NoData from 'components/common/NoData';
import Refresh from 'components/common/Refresh';
import Button from 'components/common/Button';

import { getContactsReportsForPeriod } from 'service/ReportService';

const Contacts = () => {
  const {
    setContactsReportsData,
    contactsReportsData,
    loading,
    setLoading,
    list
  } = useContactsReports();
  const startDate = useRef('');
  const endDate = useRef('');

  const handleDate = async () => {
    if ((startDate.current.value) && (endDate.current.value)) {
      setLoading(true);
      const data = await getContactsReportsForPeriod(
        startDate.current.value, endDate.current.value
      );
      setContactsReportsData(data);
      setLoading(false);
    } else if ((startDate.current.value === '') && (endDate.current.value === '')) {
      list();
    } else {
      Swal.fire(
        'Aviso',
        'Informe o período para a pesquisa',
      );
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'COD',
        accessor: 'Cod',
        defaultFilter: true,
      },
      {
        Header: 'USUÁRIO',
        accessor: 'Usuario',
        defaultFilter: true
      },
      {
        Header: 'DATA',
        accessor: 'Data',
        defaultFilter: true,
      },
      {
        Header: 'RESPONSÁVEL',
        accessor: 'Responsavel',
        defaultFilter: true,
      },
      {
        Header: 'VIA',
        accessor: 'Via',
        selectFilter: true
      },
      {
        Header: 'ASSUNTO',
        accessor: 'Assunto',
        defaultFilter: true,
      },
      {
        Header: 'FLUXO',
        accessor: 'Fluxo',
        selectFilter: true
      },
      {
        Header: 'SUCESSO',
        accessor: 'Sucesso',
        selectFilter: true,
      },
      {
        Header: 'COMENTÁRIO',
        accessor: 'Comentario',
        defaultFilter: true,
      },
    ],
    []
  );

  function setComponent() {
    if (!contactsReportsData) {
      return (<Refresh />);
    } return (
      <>
        <TitleBar>
          Contatos
        </TitleBar>
        <SearchBox>
          <Case gridTemplateColumns="1fr 1fr 1fr 1fr" justifyContent="flex-end" display="flex">
            <input ref={startDate} type="date" id="startDate" />
            <input ref={endDate} type="date" id="endDate" />
            <SearchButton type="button" onClick={handleDate}>Pesquisar</SearchButton>
            {((loading === false) && (contactsReportsData.length > 0) && (
              <CSVLink data={contactsReportsData} separator=";">
                <IconCase>
                  <DownloadIcon title="Baixar Planilha" />
                </IconCase>
              </CSVLink>
            ))}
          </Case>
        </SearchBox>
        <Loading active={loading} />
        {((contactsReportsData.length > 0) && (loading === false) && (
          <Table data={contactsReportsData} changeTableSize={50} columns={columns} marginTop="0px" filename="my-file.csv" />
        ))}
        {(contactsReportsData.length === 0) && (loading === false) && (
          <NoData />
        )}
      </>
    );
  }

  return (
    <>
      {setComponent()}
    </>
  );
};

const SearchButton = styled(Button)`
  height: 30px;
  margin-right: 0px;
`;

const DownloadIcon = styled(RiFileDownloadLine)`
  font-size: 30px;
  width: 40px;
  margin-top: 5px;
  margin-right: 0px;
`;

const IconCase = styled.div`
  font-size: 23px;
  cursor: pointer;
`;

const SearchBox = styled.div`
  position: absolute;
  right: 0;
  margin-right: 2px;
  top: 110px;
`;

export default Contacts;
