import React from 'react';
import styled from 'styled-components';

import {
  FaThList
} from 'react-icons/fa';

import IconCase from 'components/skilled/TopPage/IconCase';

import CompanyCreate from './CompanyCreate';
import CompanyTable from './CompanyTable';

const CompanyGrid = () => (
  <DesktopMode>
    <Grid>
      <CompanyCreate />
      <IconCase>
        <TableIcon title="Tabela" color="#224772" />
      </IconCase>
    </Grid>
    <CompanyTable />
  </DesktopMode>
);

const Grid = styled.div`
  display: flex;
  justify-content: flex-end;
 @media(max-width: 800px) {
   display: none;
 }
`;

const DesktopMode = styled.div`
  @media(max-width: 800px) {
    display: none;
  }
`;

const TableIcon = styled(FaThList)`
  color: ${(props) => props.color};
`;

export default CompanyGrid;
