import axios from 'axios';
import { API } from 'helpers/constant';

const getAngels = () => axios.get(`${API}/users/angels`)
  .then((res) => res.data)
  .catch((error) => error[0]);

const angelsandinternalconsultant = () => axios.get(`${API}/users/angelsandinternalconsultant`)
  .then((res) => res.data)
  .catch((error) => error[0]);

export { getAngels, angelsandinternalconsultant };
