import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { BiDotsVertical } from 'react-icons/bi';

import { getPDFsContracts } from 'service/ContractService';

import LoadingBlocked from 'components/common/LoadingBlocked';
import { URL_BACKEND } from 'helpers/constant';

const CardMenu = ({ options }) => {
  const [menuState, setMenuState] = useState(false);
  const [loading, setLoading] = useState(false);

  const changeContract = async (parameter) => {
    setLoading(true);
    await new Promise(() => {
      setTimeout(
        async () => {
          const { contractName } = options;
          await getPDFsContracts(options.contractId,
            {
              contractName: options.contractName,
              parameters: parameter
            });
          window.open(`${URL_BACKEND}/contracts/${contractName}/${contractName}-${parameter}.pdf`, '_blank');
          setLoading(false);
        },
        5000
      );
    });
  };

  return (
    <>
      <CaseMenu onClick={() => setMenuState(!menuState)}>
        <BiDotsVertical />
      </CaseMenu>
      {menuState && (
        <FloatMenu>
          <ContentMenu>
            <Item onClick={() => changeContract(1)}>Contrato</Item>
            <Item onClick={() => changeContract(6)}>Ficha Cadastral</Item>
          </ContentMenu>
        </FloatMenu>
      )}
      <LoadingBlocked active={loading} />
    </>
  );
};

const CaseMenu = styled.div`
  cursor: pointer;
  font-size: 28px;
  text-align: right;
  padding-top: 5px;
  position: relative;
`;

const FloatMenu = styled.div`
  position: absolute;
  display: flex;
  width: 100px;
  z-index: 897;
  @media(max-width: 800px) {
  right: -130px;
  }
`;
const ContentMenu = styled.div`
  list-style: none;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
  -webkit-box-shadow: 3px 3px 0px 0px rgba(180,198,212, 0.3);
  -moz-box-shadow:    3px 3px 0px 0px rgba(180,198,212, 0.3);
  box-shadow:         3px 3px 0px 0px rgba(180,198,212, 0.3);

  li{
    padding: 3px;
    justify-content: flex-end;
    background-color: #fff;
  }
  li:hover{
    background-color: #93D367;
    color: #fff;
  }
`;

const Item = styled.li`
  padding: 3px;
  background-color: #fff;
  cursor: pointer;
  :hover{
    background-color: #93D367;
    color: #fff;
  }
`;

CardMenu.propTypes = {
  options: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default CardMenu;
