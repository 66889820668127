import React, { useRef } from 'react';
import styled from 'styled-components';

import moment from 'moment';
import Swal from 'sweetalert2';
import { AiOutlineUser } from 'react-icons/ai';
import { BsPeople } from 'react-icons/bs';
import { FiPhoneCall } from 'react-icons/fi';

import Loading from 'components/common/Loading';
import ContactDashboard from 'components/dashboard/contacts/ContactDashboard';
import Case from 'components/common/Case';
import Refresh from 'components/common/Refresh';
import InfoCard from 'components/common/InfoCard';
import CallsLeadersDashboard from 'components/dashboard/calls/CallsLeadersDashboard';
import Button from 'components/common/Button';

import { useLeaderAngelDashboard } from 'providers/Dashboard/leaderAngel';

import { getDashboardLeaderAngelForPeriod } from 'service/ReportService';

import getProfile from 'helpers/getProfile';

function Dashboard() {
  const {
    leaderAngelDashboardData,
    setLeaderAngelDashboardData,
    periodStart,
    setPeriodStart,
    periodEnd,
    setPeriodEnd,
    setLoading,
    loading,
    list,
  } = useLeaderAngelDashboard();
  const profile = getProfile();
  const startDate = useRef('');
  const endDate = useRef('');

  const handleDate = async () => {
    if ((startDate.current.value) && (endDate.current.value)) {
      setLoading(true);
      setPeriodStart(moment(startDate.current.value).format('DD/MM/YYYY'));
      setPeriodEnd(moment(endDate.current.value).format('DD/MM/YYYY'));
      const chartData = await getDashboardLeaderAngelForPeriod(
        profile.user.id, startDate.current.value, endDate.current.value
      );
      setLeaderAngelDashboardData(chartData);
      setLoading(false);
    } else if ((startDate.current.value === '') && (endDate.current.value === '')) {
      setPeriodStart('mês');
      setPeriodEnd('mês');
      list();
    } else {
      Swal.fire(
        'Aviso',
        'Informe o período para a pesquisa',
      );
    }
  };

  function setComponent() {
    if (!leaderAngelDashboardData) {
      return (<Refresh />);
    } return (
      <>
        <Loading active={loading} />
        { !loading && (
        <div>
          <Case gridTemplateColumns="1fr 1fr 1fr 1fr">
            <InfoCard title="CHAMADOS ATIVOS MEUS" backgroundColor="#DD4B39" data={leaderAngelDashboardData.userActiveCalls}>
              <AiOutlineUser style={{ color: 'white' }} />
            </InfoCard>
            <InfoCard title="CHAMADOS ATIVOS SETOR" backgroundColor="#ffcd17" data={leaderAngelDashboardData.sectorActiveCalls}>
              <BsPeople style={{ color: 'white' }} />
            </InfoCard>
            <InfoCard title="TODOS CONTATOS" backgroundColor="#139F5B" data={leaderAngelDashboardData.numberOfContacts}>
              <FiPhoneCall style={{ color: 'white' }} />
            </InfoCard>
            <InfoCard title="CONTATOS TELEFONE" backgroundColor="#4285F4" data={leaderAngelDashboardData.phoneContactsOfMonth}>
              <FiPhoneCall style={{ color: 'white' }} />
            </InfoCard>
          </Case>
          <SearchCase>
            <Case gridTemplateColumns="2fr 2fr 1fr">
              <input ref={startDate} type="date" id="startDate" />
              <input ref={endDate} type="date" id="endDate" />
              <SearchButton type="button" onClick={handleDate}>Pesquisar</SearchButton>
            </Case>
          </SearchCase>
          <ContactDashboard data={leaderAngelDashboardData} title="CONTATOS" flowSubtitle={`Fluxo de contatos do ${periodStart} a ${periodEnd}`} waySubtitle={`Tipos de contatos do ${periodStart} a ${periodEnd}`} />
          <CallsLeadersDashboard data={leaderAngelDashboardData} />
        </div>
        )}
      </>
    );
  }

  return (
    <>
      {setComponent()}
    </>
  );
}

const SearchCase = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
`;

const SearchButton = styled(Button)`
  height: 30px;
  margin-right: 0px;
`;

export default Dashboard;
