import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useCalls } from 'providers/calls';
import { format } from 'date-fns';

import Modal from 'components/common/Modal';
import Button from 'components/common/Button';
import ButtonCase from 'components/common/ButtonCase';
import Table from 'components/tableComponents/CompleteTable';

const CallsLog = ({ callData }) => {
  const { getLogs } = useCalls();
  const [visible, setVisible] = useState(false);
  const [history, setHistory] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const logData = await getLogs(callData.id);
      setHistory(logData);
    };
    fetchData();
  }, []);

  const title = 'Log';

  const columns = React.useMemo(
    () => [
      {
        Header: 'LOG',
        accessor: 'text',
        setWidth: '60%'
      },
      {
        Header: 'USUÁRIO',
        accessor: 'userData.name',
        Cell: (i) => {
          const { cell } = i;
          const data = cell.row.original;
          return `${data.responsibleData.name}`;
        }
      },
      {
        Header: 'DATA',
        accessor: 'date',
        Cell: (i) => {
          const { cell } = i;
          const data = cell.row.original;
          return format(new Date(data.createdAt), "dd/MM/yyyy'-'HH:mm");
        }
      },
    ],
    []
  );

  return (
    <>
      <ButtonCase>
        <Button onClick={() => setVisible(true)}>Ver Log</Button>
      </ButtonCase>
      <Modal visible={visible} onClose={() => setVisible(false)} title={title}>
        <BodyModal>
          <TitleDiv>Chamado {callData.callNumber} - {callData.stores.storeName}</TitleDiv>
          <Table data={history} columns={columns} />
        </BodyModal>
      </Modal>
    </>
  );
};

const BodyModal = styled.div`
  width: 750px;
  height: auto;
  padding: 10px;
`;

const TitleDiv = styled.div`
  position: absolute;
  font-size: 20px;
  right: 200px;
`;

CallsLog.propTypes = {
  callData: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default CallsLog;
