import React, { useState } from 'react';
import styled from 'styled-components';
import { ImUserTie } from 'react-icons/im';
import { GoLocation } from 'react-icons/go';
import { MdDateRange } from 'react-icons/md';
import { IoTrashOutline } from 'react-icons/io5';

import { useContracts } from 'providers/Contracts/contracts';

import { Card } from 'components/card';
import Case from 'components/common/Case';

import CardMenu from 'components/skilled/contracts/CardMenu';
import Button from 'components/common/Button';
import ButtonCase from 'components/common/ButtonCase';

import { cnpjMask } from 'helpers/Mask';
import { convertDateToDMY } from 'helpers/convertDate';
import { DeleteAlert } from 'components/alert';

const ContractsCard = () => {
  const { getStoresContracts, destroyContract } = useContracts();
  const [value, setValue] = useState('');
  const [cards, setCards] = useState([]);

  const deleteContract = async (data) => {
    await DeleteAlert(data, 'Tem certeza que excluir o contrato?', destroyContract);
  };

  const searchStore = async (searcher) => {
    if (searcher !== '') {
      const data = await getStoresContracts();
      const results = data.filter(
        (i) => ((i.storeName.toLowerCase().includes(searcher.toLowerCase()))
        || (i.contractNumber.toLowerCase().includes(searcher.toLowerCase())))
      );
      setCards(results);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      searchStore(value);
    }
  };

  return (
    <Container>
      <input
        onChange={(e) => {
          setValue(e.target.value);
        }}
        placeholder="Pesquise por Código ou Razão Social"
        onKeyDown={handleKeyDown}
      />
      <ButtonCase>
        <Button onClick={() => searchStore(value)}>
          Buscar
        </Button>
      </ButtonCase>
      <Grid>
        {cards.map((data) => (
          <Card key={data.id} cardStyle="background-color: #FFFFFF" display="grid" gridTemplateColumns="10fr 1fr">
            <Case alignItems="left" gap="0px">
              <Header>
                <ContractCode>
                  {data.contractNumber}
                </ContractCode>
              </Header>
              <div>{data.storeName}</div>
              <div>{cnpjMask(data.registrationNumber)}</div>
              <div>
                <div>
                  <GoLocation />{!data.address && (
                    'Não cadastrado'
                  )}
                  {data.address && (
                    `${data.address.city} - ${data.address.state}`
                  )}
                </div>
              </div>
              <CardFooter>
                <div>
                  <ImUserTie />
                  {data.userData.name}
                </div>
                <div>
                  <MdDateRange />{!data.createdAt && (
                    '00/00/0000'
                  )}
                  {data.createdAt && (
                    convertDateToDMY(data.createdAt)
                  )}
                </div>
              </CardFooter>
            </Case>
            <div>
              <CardMenu options={{
                contractId: data.id,
                contractName: data.contractName
              }}
              />
              <TrashDiv>
                <IoTrashOutline onClick={() => deleteContract(data)} />
              </TrashDiv>
            </div>
          </Card>
        ))}
      </Grid>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 40px;

  @media(max-width: 400px) {
    margin-top: 0;
  }
`;

const Grid = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
 @media(max-width: 800px) { 
  grid-template-columns: 1fr;
 }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: -3px;
  color: #20314F;
`;

const ContractCode = styled.div`
  font-size: 18px;
  font-weight: 600;
`;

const CardFooter = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const TrashDiv = styled.div`
  font-size: 20px;
  cursor: pointer;
  text-align: right;
  margin-top: 80px;
`;

export default ContractsCard;
