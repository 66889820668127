import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  getDashboardLeaderExpansion,
} from 'service/ReportService';
import getProfile from 'helpers/getProfile';

export const LeaderExpansionDashboardContext = React.createContext({});

export const LeaderExpansionDashboardProvider = ({ children }) => {
  const [leaderExpansionDashboardData, setLeaderExpansionDashboardData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const profile = getProfile();
      try {
        const data = await getDashboardLeaderExpansion(profile.user.id);
        setLeaderExpansionDashboardData(data);
      } catch {
        setLeaderExpansionDashboardData(false);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <LeaderExpansionDashboardContext.Provider value={{
      leaderExpansionDashboardData,
      loading,
    }}
    >
      {children}
    </LeaderExpansionDashboardContext.Provider>
  );
};

LeaderExpansionDashboardProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export const useLeaderExpansionDashboard = () => React.useContext(LeaderExpansionDashboardContext);
