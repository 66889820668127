import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Scrollbar = ({ children, height, margin }) => (
  <Case height={height} margin={margin}>
    {children}
  </Case>
);

const Case = styled.div`
  height: ${(props) => props.height};
  margin: ${(props) => props.margin};
  text-decoration: none;
  width: 100%;
  overflow: auto;
`;

Scrollbar.propTypes = {
  children: PropTypes.PropTypes.arrayOf(PropTypes.element).isRequired,
  height: PropTypes.string.isRequired,
  margin: PropTypes.string,
};

Scrollbar.defaultProps = {
  margin: '0',
};

export default Scrollbar;
