import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  getStores,
  getStatusStoreServices,
  updateStore,
  changeStoreStatus,
  getStoresHistory,
  createStores
} from 'service/StoresService';
import { angelsandinternalconsultant } from 'service/AngelsService';
import { consultantandinternalconsultant } from 'service/ConsultantService';
import { getCommercialAttendant } from 'service/CommercialAttendantService';
import { getBrands } from 'service/BrandService';

import { toastSuccess, toastError } from 'helpers/notifications';

export const StoresContext = React.createContext({});

export const StoresProviders = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [loadingBlocked, setLoadingBlocked] = useState(false);
  const [stores, setStores] = useState([]);
  const [activeValue, setActiveValue] = useState(true);
  const [reload, setReload] = useState(false);

  const [angels, setAngels] = useState([]);
  const [consultant, setConsultant] = useState([]);
  const [commercialAttendant, setCommercialAttendant] = useState([]);
  const [brands, setBrands] = useState([]);
  const [storesServicesStatus, setStoresServicesStatus] = useState([]);

  const [visible, setVisible] = useState(false);
  const [createModalState, setCreateModalState] = useState(0);
  const [createdStoreData, setCreatedStoreData] = useState([]);
  const [createStoreLoading, setcreateStoreLoading] = useState(false);

  const [activeModalState, setActiveModalState] = useState(0);
  const [activeStoreData, setActiveStoreData] = useState([]);

  const list = async () => {
    const storesData = await getStores();
    const results = storesData.filter(
      (i) => ((i.active === activeValue) && (!i.isExemple))
    );
    setStores(results);
    setLoading(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      const angelsData = await angelsandinternalconsultant();
      setAngels(angelsData);
      const consultantData = await consultantandinternalconsultant();
      setConsultant(consultantData);
      const commercialAttendantData = await getCommercialAttendant();
      setCommercialAttendant(commercialAttendantData);
      const brandData = await getBrands();
      setBrands(brandData);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const statusServiceData = await getStatusStoreServices();
      setStoresServicesStatus(statusServiceData);
    };
    fetchData();
  }, []);

  useEffect(() => {
    list();
  }, [activeValue, loading, reload]);

  const update = async (id, values) => {
    setLoadingBlocked(true);
    await updateStore(id, values);
    setLoadingBlocked(false);
    setReload(!reload);
    toastSuccess('Alterado com sucesso!');
  };

  const changeStatus = async (id, values) => {
    setLoadingBlocked(true);
    await changeStoreStatus(id, values);
    setLoadingBlocked(false);
    setReload(!reload);
    if (values.obj === 'inactive') {
      toastSuccess('Desativada com Sucesso!');
    } else {
      toastSuccess('Ativado com Sucesso!');
    }
  };

  const createStore = async (values) => {
    setcreateStoreLoading(true);
    createdStoreData.angelId = values.angelId;
    createdStoreData.consultantId = values.consultantId;
    createdStoreData.commercialAttendantId = values.commercialAttendantId;
    createdStoreData.storeName = values.storeName;
    createdStoreData.tradeName = values.tradeName;
    createdStoreData.phoneNumber = values.phoneNumber;
    createdStoreData.email = values.email;
    createdStoreData.address.zipcode = values.zipcode;
    createdStoreData.address.address = values.address;
    createdStoreData.address.number = values.number;
    createdStoreData.address.complement = values.complement;
    createdStoreData.address.neighborhood = values.neighborhood;
    createdStoreData.address.state = values.state;
    createdStoreData.address.city = values.city;
    const res = await createStores(createdStoreData);
    setcreateStoreLoading(false);
    if (res.name === 'ApplicationError') {
      toastError(res.errors[0]);
    } else {
      setVisible(false);
      setReload(!reload);
      setCreateModalState(0);
      toastSuccess('Drogaria Gerada!');
    }
  };

  return (
    <StoresContext.Provider value={{
      loading,
      setLoading,
      stores,
      setStores,
      activeValue,
      setActiveValue,
      reload,
      setReload,
      list,
      angels,
      consultant,
      brands,
      storesServicesStatus,
      update,
      changeStatus,
      getStoresHistory,
      visible,
      setVisible,
      createModalState,
      setCreateModalState,
      createdStoreData,
      setCreatedStoreData,
      createStore,
      createStoreLoading,
      loadingBlocked,
      activeModalState,
      setActiveModalState,
      activeStoreData,
      setActiveStoreData,
      commercialAttendant
    }}
    >
      {children}
    </StoresContext.Provider>
  );
};

StoresProviders.propTypes = {
  children: PropTypes.element.isRequired,
};

export const useStores = () => React.useContext(StoresContext);
