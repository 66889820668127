import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  getConsultantVisitReports,
} from 'service/ReportService';

export const ConsultantVisitReportContext = React.createContext({});

export const ConsultantVisitReportsProvider = ({ children }) => {
  const [visitsReportsData, setVisitsReportsData] = useState([]);
  const [loading, setLoading] = useState(true);

  const list = async () => {
    setLoading(true);
    try {
      const data = await getConsultantVisitReports();
      setVisitsReportsData(data);
    } catch {
      setVisitsReportsData(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      list();
    };
    fetchData();
  }, []);

  return (
    <ConsultantVisitReportContext.Provider value={{
      visitsReportsData,
      setVisitsReportsData,
      loading,
      setLoading,
      list,
    }}
    >
      {children}
    </ConsultantVisitReportContext.Provider>
  );
};

ConsultantVisitReportsProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export const useConsultantVisitReports = () => React.useContext(ConsultantVisitReportContext);
