import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  getDashboardAngel,
} from 'service/ReportService';
import getProfile from 'helpers/getProfile';

export const AngelDashboardContext = React.createContext({});

export const AngelDashboardProvider = ({ children }) => {
  const [angelDashboardData, setAngelDashboardData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const profile = getProfile();
      try {
        const data = await getDashboardAngel(profile.user.id);
        setAngelDashboardData(data);
      } catch {
        setAngelDashboardData(false);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <AngelDashboardContext.Provider value={{
      angelDashboardData,
      loading,
    }}
    >
      {children}
    </AngelDashboardContext.Provider>
  );
};

AngelDashboardProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export const useAngelDashboard = () => React.useContext(AngelDashboardContext);
