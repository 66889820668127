import styled from 'styled-components';

const Button = styled.button`
  background: #5CB85C;
  font-weight: 700;
  border: 0;
  margin: 0;
  color: #fff;
  cursor: pointer;
  height: 30px;
  width: 100px;
  border-radius: 4px;
  justify-content: flex-end;

  -webkit-box-shadow: 5px 3px 20px rgba(50, 50, 50, 0.2);
  -moz-box-shadow:    5px 3px 20px rgba(50, 50, 50, 0.2);
  box-shadow:         5px 3px 20px rgba(50, 50, 50, 0.2);

  transition: 0.3s;
  opacity: 1;
  margin-right: 5px;

  :hover{
    opacity: 0.8;
  };

  :disabled {
    background: #838594;
  }
`;

export default Button;
