import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { IoEyeOutline } from 'react-icons/io5';

import Modal from 'components/common/Modal';
import CardIcon from 'components/card/CardIcon';
import Case from 'components/common/Case';
import List from 'components/common/List';
import ButtonCase from 'components/common/ButtonCase';
import Button from 'components/common/Button';
import { phoneMask } from 'helpers/Mask';

const ViewData = ({ children }) => {
  const [visible, setVisible] = useState(false);
  const history = useHistory();

  const changeModalState = (parameter) => {
    setVisible(parameter);
  };

  const onBack = () => {
    history.push('/sistema/usuarios');
    document.location.reload();
  };

  return (
    <>
      <CardIcon>
        <IoEyeOutline onClick={() => changeModalState(true)} />
      </CardIcon>
      <Modal visible={visible} onClose={() => changeModalState(false)} title="Ver Dados">
        <Body>
          <Case alignItems="left" gap="0px">
            <List><SubtitleCase>Nome: </SubtitleCase>{children.name}</List>
            <List><SubtitleCase>Sobrenome: </SubtitleCase>{children.surname ? children.surname : 'Dado não cadastrado'}</List>
            <List><SubtitleCase>Nome de usuário: </SubtitleCase>{children.username}</List>
            <List><SubtitleCase>Email: </SubtitleCase>{children.email}</List>
            <List>
              <SubtitleCase>Número de telefone: </SubtitleCase>{children.phoneNumber ? phoneMask(children.phoneNumber) : 'Dado não cadastrado'}
            </List>
            <List><SubtitleCase>Cargo: </SubtitleCase>{children.usertype.name}</List>
          </Case>
        </Body>
        <ButtonCase>
          <Button type="button" onCLick={() => onBack()}>Voltar</Button>
        </ButtonCase>
      </Modal>
    </>
  );
};

const Body = styled.div`
  padding: 10px;
`;

const SubtitleCase = styled.span`
  font-weight: bold;
`;

ViewData.propTypes = {
  children: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ViewData;
