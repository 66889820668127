import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import TableTh from './TableTh';

const TableRow = ({ children, gridTemplateColumns }) => (
  <List>
    <TableTh gridTemplateColumns={gridTemplateColumns} backgroundColor="#78d6ff">{children}</TableTh>
  </List>
);

const List = styled.li`
  list-style-type: none;
  :nth-child(odd) {
    background-color: #ffffff;
    }
  :nth-child(even) {
    background-color: #F1F3F4;
  }
`;

TableRow.propTypes = {
  children: PropTypes.PropTypes.arrayOf(PropTypes.element).isRequired,
  gridTemplateColumns: PropTypes.string.isRequired,
};

export default TableRow;
