import React from 'react';
import styled from 'styled-components';

import verifyAclUser from 'helpers/verifyAclUser';
import acl from 'helpers/acl';

import BasePage from 'components/layout/BasePage';
import Icon from 'components/common/Icon';
import { AiOutlineUser, AiOutlineAudit } from 'react-icons/ai';
import Parametrization from './Parameterization';
import UploadStoreService from './UploadStoreService';
import StoreDocumentation from './StoreDocumentation';

function System() {
  return (
    <>
      <BasePage>
        <Body>
          <IconsSide>
            <Icon icon={<AiOutlineUser />} name="Usuários" route="/sistema/usuarios" />
            {(verifyAclUser(acl.parametrization)) && (<Parametrization />)}
            {((verifyAclUser(acl.router_company)) && (
              <Icon icon={<AiOutlineAudit />} name="Empresas" route="/sistema/empresas" />
            ))}
            {(verifyAclUser(acl.documentation)) && (<StoreDocumentation />)}
            {(verifyAclUser(acl.service_upload)) && (<UploadStoreService />)}
            {
            /*
              <Update
              icon={<BsArrowRepeat />} name="Atualizar Drogaria" onClick={() => getStores()} />
            */
            }
            {/* <Icon icon={<AiOutlineControl />} name="Controle de Acesso" route="/sistema/acl" />
            <Icon icon={<AiFillTag />} name="Fornecedores" route="/sistema/fornecedores" /> */}
          </IconsSide>
          {/* <UtilSide>
            area livre
          </UtilSide> */}
        </Body>
      </BasePage>
    </>
  );
}

const Body = styled.div`
  margin-top: 15px;
  display: grid;
  grid-template-columns: 540px 1fr;
  gap: 10px; 
`;

const IconsSide = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(7, 1fr);
  gap: 10px;

  @media(max-width: 800px) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(5, 1fr);
  }
`;

/* const UtilSide = styled.div`
  background-color: gray;
  display: grid;
  width: 100%;
  height: 100%;
  min-width: 300px;
  @media(max-width: 800px) {
    min-width: 150px;
    display: none;
  }
`; */
export default System;
