import React from 'react';
import BasePage from 'components/layout/BasePage';
import { StoresReportProvider } from 'providers/Reports/stores';
import Stores from './Stores';

const index = () => (
  <BasePage>
    <StoresReportProvider>
      <Stores />
    </StoresReportProvider>
  </BasePage>
);

export default index;
