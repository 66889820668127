import React from 'react';
import InputMask from 'react-input-mask';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Label from 'components/common/Label';
import Asterisk from 'components/common/Asterisk';
import MensageError from 'components/common/MensageError';

const InputField = ({
  label,
  name,
  mask,
  placeholder,
  onChange,
  value,
  type,
  isRequired,
  disabled,
  error,
  isError,
  onBlur
}) => (
  <Case>
    <Label htmlFor={name}>{label}</Label> {isRequired && (<Asterisk />) }
    <InputMask
      id={name}
      name={name}
      placeholder={placeholder}
      type={type}
      onChange={onChange}
      disabled={disabled}
      onBlur={onBlur}
      value={value}
      mask={mask}
    />
    { isError && <MensageError>{error}</MensageError> }
  </Case>
);

const Case = styled.div`
  margin-bottom: 5px;
`;

InputField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  isRequired: PropTypes.bool,
  mask: PropTypes.string,
  isError: PropTypes.string.isRequired,
  onBlur: PropTypes.func.isRequired
};

InputField.defaultProps = {
  isRequired: false,
  mask: '',
  disabled: false,
  error: ''
};
export default InputField;
