import axios from 'axios';
import { API } from 'helpers/constant';

const AssociateClick = async (data) => {
  await axios.post(`${API}/users/addaccess`, data)
    .then((res) => res.data)
    .catch((error) => error[0]);
};

export default AssociateClick;
