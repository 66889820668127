import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import { DualRing } from 'react-spinners-css';
import parse from 'html-react-parser';

import { toastError } from 'helpers/notifications';

import { useStores } from 'providers/Stores/stores';
import { streamPDF } from 'service/ContractService';
import GenStoreForm from './GenStoreForm';

const GenStoreByContract = () => {
  const [contractLoader, setContractLoader] = useState(true);
  const [contractText, setContractText] = useState('');

  const { createdStoreData, createStore } = useStores();

  const initialValues = {
    storeName: createdStoreData.storeName,
    tradeName: createdStoreData.tradeName,
    phoneNumber: createdStoreData.phoneNumber,
    email: createdStoreData.email,
    zipcode: createdStoreData.address.zipcode,
    address: createdStoreData.address.address,
    number: createdStoreData.address.number,
    complement: createdStoreData.address.complement,
    neighborhood: createdStoreData.address.neighborhood,
    state: createdStoreData.address.state,
    city: createdStoreData.address.city,
    angelId: '',
    consultantId: '',
    commercialAttendantId: '',
  };

  const validationSchema = {
    angelId: Yup.string().required('Informe o Anjo'),
    consultantId: Yup.string().required('Informe o Consultor'),
    commercialAttendantId: Yup.string().required('Informe o Atendente Comercial'),
    zipcode: Yup.string().required('Informe o CEP'),
    address: Yup.string().required('Não pode ser enviado em branco'),
    city: Yup.string().required('Não pode ser enviado em branco'),
    number: Yup.number('Não aceita letras').required('Não pode ser enviado em branco'),
    neighborhood: Yup.string().required('Não pode ser enviado em branco'),
    state: Yup.string().required('Não pode ser enviado em branco'),
  };

  useEffect(() => {
    const fetchData = async () => {
      const text = await streamPDF(createdStoreData.id);
      if (text) {
        setContractText(text);
      } else {
        toastError('Algo deu errado com a geração do contrato!');
      }
      setContractLoader(false);
    }; fetchData();
  }, []);

  const onSubmit = async (values) => {
    await createStore(values);
  };

  return (
    <Container>
      <Body>
        {contractLoader && (<LoadingDiv><DualRing color="#5CB85C" size="60" /></LoadingDiv>)}
        {parse(contractText)}
      </Body>
      <GenStoreForm
        validations={validationSchema}
        values={initialValues}
        handleSubmit={onSubmit}
      />
    </Container>
  );
};

const Container = styled.div`
  height: 570px;
  overflow: scroll;
`;

const Body = styled.div`
  color: black;
  padding: 50px;
  height: 500;
  overflow: scroll;
  @media(max-width: 1366px){
    width: 790px;  
  } 
`;

const LoadingDiv = styled.div`
  padding-top: 250px;
  padding-left: 395px;
`;

export default GenStoreByContract;
