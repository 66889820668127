import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  getDashboardIntConsultant,
} from 'service/ReportService';
import getProfile from 'helpers/getProfile';

export const IntConsultantDashboardContext = React.createContext({});

export const IntConsultantDashboardProvider = ({ children }) => {
  const [intConsultantDashboardData, setIntConsultantDashboardData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const profile = getProfile();
      try {
        const data = await getDashboardIntConsultant(profile.user.id);
        setIntConsultantDashboardData(data);
      } catch {
        setIntConsultantDashboardData(false);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <IntConsultantDashboardContext.Provider value={{
      intConsultantDashboardData,
      loading,
    }}
    >
      {children}
    </IntConsultantDashboardContext.Provider>
  );
};

IntConsultantDashboardProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export const useIntConsultantDashboard = () => React.useContext(IntConsultantDashboardContext);
