import React from 'react';
import PropTypes from 'prop-types';
import Chart from 'react-google-charts';

function BarChartRangeFilter({
  data,
  label,
  height,
  width,
  colorLabel,
  maxValue,
}) {
  return (
    <>
      <Chart
        width={width}
        height={height}
        chartType="Bar"
        loader={<div>Loading Chart...</div>}
        data={[
          label,
          ...data,
        ]}
        options={{
          colors: colorLabel,
          chartArea: { top: 0, right: 0, bottom: 0 },
        }}
        rootProps={{ 'data-testid': '4' }}
        chartPackages={['corechart', 'controls']}
        controls={[
          {
            controlType: 'NumberRangeFilter',
            options: {
              ui: {
                label: 'Quantidade de Lojas: ',
              },
              filterColumnIndex: 1,
              minValue: 0,
              maxValue,
            },
          },
        ]}
      />
    </>
  );
}

BarChartRangeFilter.propTypes = {
  data: PropTypes.element.isRequired,
  label: PropTypes.element.isRequired,
  height: PropTypes.string,
  width: PropTypes.string,
  colorLabel: PropTypes.string,
  maxValue: PropTypes.string.isRequired,
};

BarChartRangeFilter.defaultProps = {
  height: '250px',
  width: '480px',
  colorLabel: ['#DB4538', '#4285F4', '#139F5B'],
};

export default BarChartRangeFilter;
